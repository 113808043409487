import React, {useEffect, useState } from 'react';
import {IonContent, IonSearchbar} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import { ContentItem } from '../../models/ContentItem';
import DeliveryModuleScreen from '../../components/DeliveryModuleScreen';
import WurldDeliveryModuleScreen from '../../components/WurldDeliveryModuleScreen';
import {search} from "ionicons/icons";


interface WurldChallengesListProps {
    loading: boolean,
    user: any,
    showButtonShare: boolean,
}


const WurldChallengesList: React.FC<WurldChallengesListProps> = (props) => {

    const [challengeList, setChallengeList] = useState([]);
    const [searchWurldChallenges, setSearchWurldChallenges] = useState('');

    useEffect(() => {

        let queryChallengesFeed = searchWurldChallenges.toLowerCase()
        let stringBoundChallengesFeed = queryChallengesFeed.slice(0, queryChallengesFeed.length-1)
            + String.fromCharCode(search.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (queryChallengesFeed!='' && queryChallengesFeed.length >=1) {
            // console.log("query", query);
            const searchJourneyRef = firestore.collection('content').where('publicContent','==',true).where("contentType", "in", ["journey","training", "development"])
                .where('completedByHeroName','>=', queryChallengesFeed)
                // .where('completedByHeroName','<', stringBoundChallengesFeed)
                // .where('completedByHeroName','!=', '')
                // .orderBy('completedByHeroName', 'asc')
                .orderBy('timeCreated', 'desc')

            searchJourneyRef.onSnapshot(snapshot => {
                setChallengeList(snapshot.docs.map(journeyDoc => ({...journeyDoc.data(), uid: journeyDoc.id })));

            });

        }
        else {

            let challengesRef =  firestore.collection('content').where('publicContent','==',true).where("contentType", "in", ["journey","training", "development"]).orderBy("timeCreated", "desc")
                .onSnapshot(snapshot => {
                    let challengesList = [];

                    snapshot.forEach(docChallenge =>

                        challengesList.push({ ...docChallenge.data(), uid: docChallenge.id }),
                    );
                    setChallengeList(challengesList);

                });


        }


    }, [searchWurldChallenges]);

    return (
        <>
            {/*<IonSearchbar value={searchWurldChallenges} autoCapitalize="on" onIonChange={e => setSearchWurldChallenges(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}></IonSearchbar>*/}

            {challengeList.map((challengeItem, idxC) => {
                return (

                    <WurldDeliveryModuleScreen key={idxC} deliveryModule={challengeItem} showButtons={true} loggedInUser={props.user} tab="challenge" showPopup={props.showButtonShare}/>

                )
            })}

        </>
    );


}
export default WurldChallengesList;

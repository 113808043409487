import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonText,
    IonHeader,
    IonRow,
    IonCol,
    IonImg,
    IonItem,
    IonLabel,
    IonThumbnail,
    IonPage,
    IonFab,
    IonFabButton,
    IonGrid,
    IonList,
    IonToolbar,
    IonBackButton,
    IonRouterLink,
    IonButtons,
    IonButton,
    IonIcon,
    IonCheckbox,
    IonFooter,
    IonAvatar,
    IonInput, IonToggle, IonCardContent, IonItemDivider
} from '@ionic/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { RouteComponentProps, withRouter} from 'react-router';
import {useHistory} from "react-router-dom";

import { firestore, storageRef } from '../../firebaseConfig';
import CheckboxNote from "../../components/ChecboxNote";
import CheckboxBtn from "../../components/CheckboxBtn";
import authWrapper from '../../components/authWrapper';
import {sendOutline} from "ionicons/icons";
import {toast} from "../../toast";


interface ConversationDetailProps {
    location: any;
    history: any;
}
const ConversationDetail: React.FC<ConversationDetailProps> = (props) => {

    const [checked, setChecked] = useState(false);
    const [answerContent, setAnswerContent] = useState('');

    if (!props.location.state)
        return null
    const {conversation, user, fromPage} = props?.location.state as any;

    //console.log('props', props);
    function sendReply(answerContent,checked) {
        // console.log("answerContent", answerContent);
        // console.log("checked", checked);
        // console.log("from", user);
        // console.log("to", message.receiver);
        if (answerContent == '') {
            return toast('Enter a message before sending');
        }

        const chatFeedItemRef = firestore.collection('directMessage').add({
            senderId: conversation.senderId,
            messageContent: answerContent,
            inCharacter: checked,
            senderImage: '',
            sendTime: Date.now(),
            senderHeroName: user.heroName,
            receiverId: conversation.receiverId,
        }).then(ref => {
            //
            // console.log('Added message with ID: ', ref.id);
            // window.location.reload(false);
            //add ot list of messages
        });

    }

    return (

        <IonPage>
            <Header fromPage={fromPage} noChat={true}/>

            <IonContent className="ion-padding" scrollX scrollY>
                <IonList lines="none">
                    {conversation.messages && conversation.messages !== undefined && conversation.messages.length === 0 &&
                    <IonItemDivider>Welcome To JFH CAST CHAT</IonItemDivider>}
                    {conversation.messages && conversation.messages?.map((chatItem, chatIdx) => {
                        return (

                            <IonItem key={chatIdx} lines="none">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="2">
                                            <IonAvatar>
                                                <IonImg src={"https://www.gravatar.com/avatar?d=mm"}/>
                                            </IonAvatar>
                                        </IonCol>
                                        <IonCol size="8">

                                            <IonRow>
                                                <IonText style={{'fontWeight':'bold'}}>{chatItem.senderHeroName}</IonText>
                                            </IonRow>
                                            <IonRow>
                                                {chatItem.inCharacter ?
                                                    <IonText style={{'flexWrap':'noWrap'}}>
                                                        {chatItem.messageContent}
                                                    </IonText>
                                                    : <IonText style={{'color':'#005494'}}>
                                                        {chatItem.messageContent}
                                                    </IonText>
                                                }
                                            </IonRow>

                                        </IonCol>

                                    </IonRow>
                                </IonGrid>
                            </IonItem>

                        );
                    })}

                </IonList>
            </IonContent>
            <IonItem slot="fixed" className="messageBox" lines="none">
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', background: 'rgba(180,180,180,0.3)'}}>
                        <IonButtons slot="start">
                            <IonAvatar>
                                <IonImg src={"https://www.gravatar.com/avatar?d=mm"}/>
                            </IonAvatar>
                        </IonButtons>

                        <IonInput
                            value={answerContent}
                            onIonChange={(e: any) => setAnswerContent(e.target.value)}
                            clearInput
                            type="text"
                            autoCorrect="on"
                            placeholder={"Write Message here ... "}
                            className="input ion-padding-horizontal"
                        ></IonInput>

                        <IonButtons slot="end">

                            <IonCol>
                                <IonRow>
                                    <IonLabel style={{'fontSize':'7px', minWidth: 64}}>{checked ? `In-Character`: 'Out-Of-Character'}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} />

                                </IonRow>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={() => sendReply(answerContent,checked)}>
                                    <IonIcon icon={sendOutline}/>
                                </IonButton>
                            </IonCol>
                        </IonButtons>
                    </div>


            </IonItem>
            <Footer />
        </IonPage>
    );
};

export default authWrapper(withRouter(ConversationDetail));

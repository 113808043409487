import React, {useEffect, useState} from 'react';
import {
    IonContent,

    IonSegmentButton, IonLabel, IonSegment, IonText
} from '@ionic/react';
import User from '../../models/User';


import CharacterNameRequests from "./CharacterNameRequests";
import MissionCallsChangeRequests from "./MissionCallsChangeRequests";



interface CTAChangeRequestsState{

}

const CTAChangeRequests:React.FC<CTAChangeRequestsState> = (props) => {

    const [searchContent, setSearchContent] = useState('');
    const [users, setUsers] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [currentTab, setCurrentTab]= useState('missionCalls');
    const [searchUser, setSearchUser]= useState('');

    useEffect(() => {

        // let queryProfile = searchUser.toLowerCase()
        // let stringBoundProfile = queryProfile.slice(0, queryProfile.length-1)
        //     + String.fromCharCode(queryProfile.slice(-1).charCodeAt(0) + 1)
        // //console.log("search receiver", searchReceiver);
        //
        // if (queryProfile!='' && queryProfile.length >=1) {
        //     // console.log("query", query);
        //     const searchCastRef = firestore.collection('users').where('complete', '==', true)
        //         .where('characterName_nocase','>=', queryProfile)
        //         .where('characterName_nocase','<', stringBoundProfile)
        //         .where('characterName_nocase','!=', '')
        //         .orderBy('characterName_nocase', 'asc')
        //         .orderBy('dateJoined', 'desc')
        //
        //     searchCastRef.onSnapshot(snapshot => {
        //         setUsers(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));
        //
        //     });
        //
        // }
        // else {
        //
        //     let castRef = firestore.collection('users').where('complete', '==', true).orderBy('dateJoined', 'desc')
        //         .onSnapshot(snapshot => {
        //
        //             let users = [];
        //
        //             snapshot.forEach(castDoc =>
        //                 users.push({...castDoc.data(), uid: castDoc.id}),
        //             );
        //
        //             setUsers(users);
        //         });
        //
        //
        // }


    }, [searchUser]);

    function changeSegmentContent(selectedSegment) {

        let changesContentContainer = document.getElementById(selectedSegment + 'Feed');
        changesContentContainer.style.display = 'block';
        let missionCallsContainer = document.getElementById('missionCallsFeed');
        let challengeCallsContainer = document.getElementById('challengeCallsFeed');

        if (selectedSegment === 'missionCalls') {
            missionCallsContainer.style.display='block';
            challengeCallsContainer.style.display='none';

            setCurrentTab('missionCalls');
        } else if (selectedSegment === 'challengeCalls') {
            missionCallsContainer.style.display='none';
            challengeCallsContainer.style.display='block';
            setCurrentTab('challengeCalls');
        }
    }
    return (

        <>

            <IonSegment value={currentTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)} >
                <IonSegmentButton value="missionCalls">
                    <IonLabel className="labelText">Missions</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="challengeCalls">
                    <IonLabel className="labelText">Challenges</IonLabel>
                </IonSegmentButton>

            </IonSegment>

            <IonContent id="missionCallsFeed" scrollX scrollY>
                {currentTab === 'missionCalls' && <MissionCallsChangeRequests/>}
            </IonContent>
            <IonContent id="challengeCallsFeed" scrollX scrollY>
                {currentTab === 'challengesCalls' && <IonText> Challenge CTA List</IonText>}
            </IonContent>


        </>


    );
}

export default CTAChangeRequests;

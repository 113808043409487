export function selectSegment(selectedSegment) {
    let contentContainer = document.getElementById(selectedSegment + 'Feed');
    contentContainer.style.display = 'block';
    let userMissionsFeedContainer = document.getElementById('userMissionsFeed');
    let userChallengesFeedContainer = document.getElementById('userChallengesFeed');
    let userBackstoryFeedContainer = document.getElementById('userBackstoryFeed');

    if (selectedSegment === 'userMissions') {
        userBackstoryFeedContainer.style.display = 'none';
        if (userChallengesFeedContainer!== null) {
            userChallengesFeedContainer.style.display = 'none';
        }
    } else if (selectedSegment === 'userChallenges') {
        userMissionsFeedContainer.style.display = 'none';
        userBackstoryFeedContainer.style.display = 'none';
    } else if (selectedSegment === 'userBackstory') {
        if (userChallengesFeedContainer!== null) {
            userChallengesFeedContainer.style.display = 'none';
        }
        userMissionsFeedContainer.style.display = 'none';
    }
}

export function selectSection(selectedSection) {
    let contentContainer = document.getElementById(selectedSection + 'Feed');
    contentContainer.style.display = 'block';
    let callsFeedContainer = document.getElementById('callsFeed');
    let missionsCreatedContainer = document.getElementById('completedMissionsFeed');
    if (selectedSection === 'calls') {
        missionsCreatedContainer.style.display='none';
    }
    else if (selectedSection === 'completedMissions') {
        callsFeedContainer.style.display='none';
    }
}
import React, {useState} from 'react';
import {
        IonSlide,
        IonText,
        IonRow,
        IonCol,
        IonGrid,
        IonRouterLink,

        IonImg,
        IonList,

        IonItem,
        IonModal, IonHeader, IonContent, IonLabel, IonFooter, IonButton
} from '@ionic/react';
import {sendJoinTestTeamEmail} from "../../util/sendEmail";

interface ApprovedUserScreen4Props {
        user:any;
}
const ApprovedUserScreen4: React.FC<ApprovedUserScreen4Props> = (props) => {
        const [showJoin, setShowJoin] = useState(false);

        const joinTestTeam = () => {
                console.log("props", props);
                sendJoinTestTeamEmail(props.user);
                setShowJoin(false);
        }

  return (
          <IonSlide>
                  <IonModal isOpen={showJoin}
                            cssClass='join-custom-class'
                            onDidDismiss={() => setShowJoin(false)}>
                          <IonHeader>
                                  <IonRow style={{padding: 10}}>
                                          <IonText className="appTextMain center">
                                                  {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                                          </IonText>
                                  </IonRow>
                          </IonHeader>
                          <IonContent className='join-custom-class'
                                      scrollEvents={true}
                                      onIonScrollStart={() => {
                                      }}
                                      onIonScroll={() => {
                                      }}
                                      onIonScrollEnd={() => {
                                      }} scrollX scrollY>

                                  {props.user.testTeamMember
                                      ?<><IonRow><IonCol></IonCol></IonRow>
                                              <IonRow><IonCol></IonCol></IonRow>
                                              <IonRow>
                                                      <IonText className="appTextMain center">Check your email for the invite! </IonText>
                                              </IonRow>
                                              <IonRow><IonCol></IonCol></IonRow>
                                      </>
                                      :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                          </IonContent>
                          <IonFooter>
                                  <IonRow className="ion-justify-content-center">

                                          {props.user.testTeamMember === undefined  &&
                                          <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                                                  <IonLabel className="italic bolder">
                                                          Join
                                                  </IonLabel>
                                          </IonButton>}
                                  </IonRow>
                          </IonFooter>
                  </IonModal>
          <IonGrid className="stepScreen">
                  <IonRow>
                          <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                                  <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>

                                  </div>
                                  <IonRouterLink onClick={() => setShowJoin(true)}>
                                  {/*<IonRouterLink href="/challenges">*/}
                                          <IonImg style={{'width':'50px'}} src={"assets/img/super.png"}  className="menuButton"/>
                                  </IonRouterLink>
                                  <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>

                                  </div>
                          </div>
                  </IonRow>
                  <IonRow><IonCol></IonCol></IonRow>

                  <IonRow>
                          <IonCol>
                                  <IonText className="appTextMain bolder ion-text-justify">
                                          Challenges are all about your story. <br/> There are 3 types:                                  </IonText>
                          </IonCol>
                  </IonRow>
                  <IonRow><IonCol></IonCol></IonRow>
                  <IonRow><IonCol></IonCol></IonRow>
                  <IonRow><IonCol></IonCol></IonRow>

                  <IonList style={{'background':'none'}}>
                          <IonItem lines="none" style={{'--background':'none', 'marginLeft':'20%'}}>
                                  <IonText className="appTextMainItalic ion-text-center bolder" color="light">
                                          Journey Challenges
                                  </IonText>
                          </IonItem>
                          <IonItem lines="none" style={{'--background':'none','marginLeft':'20%'}}>
                                  <IonText className="appTextMainItalic ion-text-center bolder" color="light">
                                          Training Challenges
                                  </IonText>
                          </IonItem>
                          <IonItem lines="none" style={{'--background':'none', 'marginLeft':'20%'}}>
                                  <IonText className="appTextMainItalic ion-text-center bolder" color="light">
                                          Development Challenges
                                  </IonText>
                          </IonItem>
                  </IonList>

          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          </IonGrid>
          </IonSlide>
         
  );
};


export default ApprovedUserScreen4;



import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonImg, IonLabel,
    IonListHeader,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonThumbnail
} from '@ionic/react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import CollectiveJourneyFeed from "./CollectiveJourneyFeed";
import SeriesContentFeed from "./SeriesContentFeed";
import IRLContentFeed from "./IRLContentFeed";
import {withRouter} from "react-router";
import authWrapper from "../../components/authWrapper";
import ProductionContentFeed from "./ProductionContentFeed";
import {markProfileComplete} from "../User/userOperations";
import MoreResourcesFeed from "./MoreResourcesFeed";


const WatchPage: React.FC = (props) => {

    const [showStop, setShowStop] = useState(false);

    useEffect (() => {
        console.log("props user", props['user']['uid']);
        setShowStop(!props['user']['complete']);
    }, [props])

    let completeProfile = (props) => {
        markProfileComplete(props['user']['uid']);
        setShowStop(false);
    }

    return (
        <IonPage id="main">

        <Header menu={true}/>
        <IonContent className="content-bg ion-padding-bottom">
            <IonModal isOpen={showStop}
                      cssClass='stop-modal'
                      backdropDismiss={false}
                      onDidDismiss={() => setShowStop(false)}>

                <IonContent className="stop-modal"
                            scrollEvents={true}
                            onIonScrollStart={() => {
                            }}
                            onIonScroll={() => {
                            }}
                            onIonScrollEnd={() => {
                            }} scrollX scrollY>


                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'center'}}>
                        <IonThumbnail>
                            <IonImg src={'/assets/img/stopSign.png'}></IonImg>
                        </IonThumbnail>
                    </div>

                    <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                        <IonText className="appBrandText ion-text-justify">
                            To build a Cinematic Universe takes COMMITMENT. <span className="bolder">We’re going to make movies and shows together for years to come, all because of the stories we tell together on this app.</span> When you join JFH, other characters build on your story. Contributing to a crowdsourced cinematic universe is like placing a brick in a bridge. We all rely on each other’s contributions, especially as our universe grows. Therefore, <span className="italic">once you create a profile, you won’t be able to delete it </span>(you’ll be able to make changes, though). Your contributions to the story through your character and content will live on whether or not you continue to create new content in JFH. If your content is ever used in a production that makes money, you will share in the profits. This is not your typical social network. If you’re ready to be a part of a cinematic universe, then we look forward to seeing you inside.                      </IonText>
                    </IonRow>

                </IonContent>
                <IonFooter>
                    <IonRow className="ion-justify-content-center">

                        <IonButton onClick={() => completeProfile(props)} style={{'--background':'none'}} className="center">
                            <IonLabel className="italic bolder">
                                Agree & Finish
                            </IonLabel>
                        </IonButton>
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonListHeader>
                <IonText className="sectionTitle">SERIES</IonText>
            </IonListHeader>
            <SeriesContentFeed />
            <IonListHeader>
                <IonText className="sectionTitle">IN PRODUCTION</IonText>
            </IonListHeader>
            <ProductionContentFeed/>
            <IonListHeader>
                <IonText className="sectionTitle">SPOTLIGHT</IonText>
            </IonListHeader>
            <CollectiveJourneyFeed/>
            <IonListHeader>
                <IonText className="sectionTitle">HOW TO</IonText>
            </IonListHeader>
            <IRLContentFeed/>
            <IonListHeader>
                <IonText className="sectionTitle">MORE RESOURCES</IonText>
            </IonListHeader>
            <MoreResourcesFeed/>
        </IonContent>
        <Footer/>
        </IonPage>

    );
};

export default authWrapper(withRouter(WatchPage));

import React, {useEffect, useRef, useState} from 'react';
import {IonIcon, IonRow} from "@ionic/react";
import {playOutline} from "ionicons/icons";
import {playVideoFromRef, toggleFullscreen} from "../util/videoUtils";
import {Device} from "@capacitor/core";
import {getAssetFullAddress, getPictureAddress} from "../util/avatarUtils";

interface DirectionVideoContainerProps {
    assetName: string;
    posterAssetName: string;
}


const DirectionVideoContainer: React.FC<DirectionVideoContainerProps> = (props) => {

    const [platformInfoName, setPlatformInfoName] = useState('web');

    const directionVideoRef = useRef(null);

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);

            setPlatformInfoName(info.operatingSystem);
        } );
    }, [props.assetName]);


    function playDirectionVideo(e) {
        let directionVideoCurrentRefEl  = directionVideoRef.current;
        playVideoFromRef(directionVideoCurrentRefEl, platformInfoName);
    }

    return (

        <div className="video-wrapper" >
            <div className="overlay"
                 onClick={playDirectionVideo}
            >

                <div style={{margin: 5}} className="left deliveryModuleTitle bolder">

                    <IonRow>
                        <IonIcon style={{padding: 2}} item-end icon={playOutline}/>

                    </IonRow>
                </div>

            </div>

            <video ref={directionVideoRef}
                   poster={getAssetFullAddress(props.posterAssetName)}
                   onEnded={e=> {directionVideoRef.current.load(); document.exitFullscreen().then(()=>console.log("exit full screen"))}}
                   onPause={e=> {directionVideoRef.current.load(); directionVideoRef.current.controls=false; }} preload="metadata">
                <source src={getAssetFullAddress(props.assetName)} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="playpause"></div>

        </div>
    );
};

export default DirectionVideoContainer;


import React from 'react';
import {
    IonContent,
    IonPage,
    IonText,
    IonRow,
    IonCol,
    IonGrid,
    IonFab,
    IonFabButton,
    IonLabel,
    IonButton,
    IonItem
} from '@ionic/react';
import {useHistory} from "react-router-dom";

import {  firestore, auth } from '../../firebaseConfig';
import './Hero.css';
import Header from '../../components/Header';
import OnboardingVideo from '../../components/Registration/OnboardingVideo';


const Hero: React.FC = (props) => {

    const history = useHistory();

    if (history.location.state == null) return null;

    const {villain} = history.location.state as any;

    console.log("villain", villain);

    let currentUser = auth.currentUser;

    if (currentUser === null) {
        console.log(
            "Sign In Annon"
        );

        auth.signInAnonymously()
            .catch(function(error) {
                console.log('Error sign in annon');
            });
    }

    async function getHeroIndex() {

        let heroCounter = await firestore.collection("heroes").get().then(function(querySnapshot) {
            return querySnapshot.size;
        });

        console.log("document index:"+ heroCounter);
        return heroCounter;
    }


    async function uploadLater() {
        let heroCounter = await getHeroIndex();
        let currentUser = auth.currentUser;
        const heroesRef = firestore.collection("heroes").add({
            heroName: '',
            profileVideo: "",
            userId: currentUser.uid,
            heroNumber: heroCounter+1,
            poseAsVillain: villain ? villain : false,
        }).then(ref => {
            history.push({
                pathname: '/register',
                state: { hero: ref.id, role: 'hero', profileVideoUrl: '', idx: heroCounter+1, villain: villain }
            });
            console.log('Added hero with ID: ', ref.id, 'index', heroCounter+1);
        });
    }

    const onChange = async (e: any) => {
        e.preventDefault();
        let file = e.target.files[0];
        let fileURL = URL.createObjectURL(file);
        let currentUser = auth.currentUser;
        let heroCounter = await getHeroIndex();

        history.push({
            pathname: '/profileVideoReview',
            state: {file, fileURL, role: 'hero', idx: heroCounter+1, userId: currentUser.uid, fromPage: 'hero',   villain: villain}
        })
    }

    async function recordVideo() {
        document.getElementById('recorder').click();
    }

    return (
        <IonPage>
            <Header noChat={true} fromPage="chooseRole"/>
            <IonContent className="app ion-padding ion-justify-content-center">
                <IonGrid id="preCamera">

                    {/*<div style={{ paddingBottom: '20%', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>*/}

                    <IonRow style={{height:'18px'}}>
                        <IonCol style={{textAlign:'center'}}>
                            <IonText className="appTextMain">
                                SHOOT YOUR PROFILE VIDEO
                            </IonText>
                        </IonCol>
                    </IonRow>

                        <IonRow>
                            <IonCol></IonCol>
                            <IonCol>
                                <IonItem className="app" lines="none">
                                    <IonLabel className="contentTextTitle" style={{'color':'#ffffff'}}>(under 1min)</IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                    <IonRow>
                        <IonCol>

                                <IonLabel className="appTextMain" style={{'color':'#ffffff'}}> DIRECTION:
                                </IonLabel>

                        </IonCol>


                    </IonRow>
                        <IonRow>

                            <IonCol className="app">
                                {villain &&
                                <IonText  className="appTextMain" color="light">
                                   1. Say your HERO name (pretend to be a Hero for now)
                                </IonText>
                                }
                                {!villain &&

                                <IonText  className="appTextMain" color="light">
                                    1. Say your HERO name
                                </IonText>}

                            </IonCol>

                        </IonRow>
                        <IonRow>

                            <IonCol className="app">
                                <IonText  className="appTextMain" color="light">
                                  2. Why are you the HERO to hire?
                                </IonText>
                            </IonCol>

                        </IonRow>

                        <IonRow>
                            <IonCol></IonCol>
                        </IonRow>
                        {villain &&
                        <OnboardingVideo tab={'villainRegistration'} videoAssetUrl={'onboarding/Villain_Role-JFHAppOnboardingVideo1080p.mp4'} posterAssetUrl={'onboarding/howtobeavillain.jpg'}/>
                            }
                            {!villain &&
                            <OnboardingVideo tab={'heroRegistration'} videoAssetUrl={'onboarding/Hero_Role-JFHAppOnboardingVideo1080p.mp4'} posterAssetUrl={'onboarding/howtobeahero.jpg'}/>
                            }

                    <IonRow>
                        <IonCol></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol></IonCol>
                    </IonRow>
                                {/*</div>*/}


                    <IonRow className="ion-justify-content-center">
                        <div style={{height: 106,width: 106}}>
                            <IonFab style={{
                                borderStyle: 'solid',
                                borderRadius: '50%',
                                borderWidth: 3,
                                borderColor: 'red'
                            }}>
                                <IonFabButton onClick={() => recordVideo()} style={{
                                    '--background': '#ffffff',
                                    minHeight: 100,
                                    width: 100,
                                    '--border-color': '#FF0000'
                                }}>
                                    <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                                        ACTION
                                    </IonLabel>
                                </IonFabButton>
                                <input type="file" accept="video/*" capture="camera"
                                       id="recorder"
                                       className="uploadVideoBtn"
                                       onChange={onChange}
                                />
                            </IonFab>

                        </div>
                        {/*<div>*/}
                        {/*    <IonButton onClick={uploadLater} className="uploadLaterButtonText" style={{'float':'right'}}>*/}
                        {/*        Upload Later*/}
                        {/*    </IonButton>*/}
                        {/*</div>*/}

                    </IonRow>


                    <IonRow className="ion-justify-content-end">
                        <IonCol>
                            <IonButton onClick={uploadLater} className="uploadLaterButtonText" style={{'float':'right'}}>
                                Upload Later
                            </IonButton>
                         </IonCol>
                    </IonRow>


                </IonGrid>

                <video id="player" style={{'display':'none'}} controls  controlsList="nodownload" className="videoModule"></video>


            </IonContent>

        </IonPage>
    );
};

export default Hero;

import React, {useRef, useState} from 'react';
import { firestore, storageRef } from '../firebaseConfig';
import {IonRow, IonFab, IonFabButton, IonLabel, IonContent, IonPage, IonButton} from '@ionic/react';
import authWrapper from '../components/authWrapper';
import {Device} from "@capacitor/core";

interface RecorderScreenProps {
    duration: number; //60 seconds countdown
    objectId: string;
    objectName: string;
}

const RecorderScreen: React.FC<RecorderScreenProps> = ({ objectId, objectName, duration}) => {


    const videoCaptureContent = useRef(null);
    const preview = useRef(null);
    const recording = useRef(null);
    const startButton = useRef(null);
    const stopButton = useRef(null);
    const downloadButton = useRef(null);

  duration = 60;
 //  function recordVideo() {
 //    let recorderProfileContainer = document.getElementById('recorderProfile');
 //    if (recorderProfileContainer != null) {
 //      recorderProfileContainer.click();
 //      recorderProfileContainer.addEventListener('change', async function(e:any) {
 //          let recordedFile = e.target.files[0];
 //          //create url
 //          let recordedFileUrl = URL.createObjectURL(recordedFile);
 //          console.log("Recorded File: ", recordedFileUrl);
 //
 //          videoCaptureContent.current.src= recordedFileUrl;
 //
 //          // Do something with the video file.
 //          // window.location.href='/clientDeliveryReview';
 //          //send it to the review window
 //          //player.set('display', 'block');
 //          //playerMissionContentContainer.style.display='block';
 //          //preCameraContainerEl.style.display='none';
 //          //playerMissionContentContainer.setAttribute('src', missionFileUrl);
 //          //console.log(file);
 //          //console.log("heroIdx"+ heroIdx);
 //          // let title=match.params.missionTitle.replace(/\s+/g, '-');
 //
 //          // let videoName = "Mission-"+title+"-"+match.params.heroName+".mp4";
 //          // //console.log("Video name:" + videoName);
 //          // //upload file to Firebase heroes/index
 //          // var missionVideosRef = storageRef.child('content/missions/in-progress/'+videoName);
 //
 //          // missionVideosRef.put(missionFile).then(function(snapshot) {
 //          //   console.log('Uploaded mission content file!');
 //          //   createMissionContent(missionVideosRef, videoName, match.params.heroId, match.params.id);
 //          // });
 //
 //      });
 //
 //    }
 // }

    function wait(delayInMS) {
        return new Promise(resolve => setTimeout(resolve, delayInMS));
    }

    function startRecording(stream, lengthInMS) {
        let recorder = new MediaRecorder(stream);
        let data = [];

        recorder.ondataavailable = event => data.push(event.data);
        recorder.start();
        console.log(recorder.state + " for " + (lengthInMS/1000) + " seconds...");

        let stopped = new Promise((resolve, reject) => {
            recorder.onstop = resolve;
            recorder.onerror = event => reject(event);
        });

        let recorded = wait(lengthInMS).then(
            () => recorder.state == "recording" && recorder.stop()
        );

        return Promise.all([
            stopped,
            recorded
        ])
            .then(() => data);
    }
    function stop(stream) {
        stream.getTracks().forEach(track => track.stop());
    }

    function startAction() {
        // Device.n({
        //     video: true,
        //     audio: true
        // }).then(stream => {
        //     preview.current.srcObject = stream;
        //     downloadButton.current.href = stream;
        //     preview.current.captureStream = preview.current.captureStream || preview.current.mozCaptureStream;
        //     return new Promise(resolve => preview.current.onplaying = resolve);
        // }).then(() => startRecording(preview.current.captureStream(), 1000))
        //     .then (recordedChunks => {
        //         let recordedBlob = new Blob(recordedChunks, { type: "video/mp4" });
        //         recording.current.src = URL.createObjectURL(recordedBlob);
        //         downloadButton.current.href = recording.current.src;
        //         downloadButton.current.download = "RecordedVideo.mp4";
        //         //uploadVideo(recordedBlob);
        //
        //         // log("Successfully recorded " + recordedBlob.size + " bytes of " +
        //         //  recordedBlob.type + " media.");
        //     })
        //     .catch(error => console.log(error));
  }

  function stopAction() {
      stop(preview.current.srcObject);
  }
  return (
      <IonPage>
          <div>
              <IonButton ref={startButton} onClick={startAction} className="button">
                  Start
              </IonButton>
              <h2>Preview</h2>

              <video ref={preview} width="160" height="120" autoPlay={false} muted={false}></video>
          </div>


          <div>
              <IonButton ref={stopButton} onClick={stopAction} className="button">
                  Stop
              </IonButton>

              <video ref={recording} width="160" height="120" controls={true}></video>
          </div>

          <IonButton ref={downloadButton} className="button">
              Submit Profile Video
          </IonButton>
      </IonPage>
   );
};

export default authWrapper(RecorderScreen);


import React from 'react';
import { IonContent} from '@ionic/react';
import './Challenges.css';
import { Challenge } from '../../models/Challenge';
import { firestore } from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import ChallengeListItem from "./ChallengeListItem";

interface DevelopmentListPageState {
  loading: boolean;
  developmentList: Challenge[];
  user: any;
  // userId: string;
  // heroName: string;
  // heroId: string;
  // userPhotoURL: string;
}

class DevelopmentListPage extends React.Component<{}, DevelopmentListPageState>  {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      developmentList: [],
      user: props.user,
      // userId: props.user.uid,
      // heroName: props.user.heroName,
      // heroId: props.user.heroId,
      // userPhotoURL: props.user.photoURL,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    firestore.collection('challenges').where("type", "==", "development").where("premium", "==", false).orderBy('index', 'asc')
    .onSnapshot(snapshot => {
      let developmentList = [];

      snapshot.forEach(doc =>
        developmentList.push({ ...doc.data(), uid: doc.id }),
      );

      this.setState({
        developmentList,
        loading: false,
      });
    });
  }



  render() {
    const { developmentList, loading, user} = this.state;

      const hide  = true;

  return (

  <>
    {developmentList.map((development, devIdx) => {
        return (
            <ChallengeListItem key={devIdx} challenge={development} index={devIdx} showButtons={true} user={user}/>

        );
      })}
  </>
  );
};
}

export default authWrapper(DevelopmentListPage);

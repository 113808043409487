import React, {Component, useEffect, useState} from 'react';
import {
    IonButtons,
    IonButton,
    IonItem,
    IonIcon,
    IonList,
    IonAvatar,
    IonImg,
    IonText,
    IonModal,
    IonHeader,
    IonRow,
    IonContent,
    IonLabel,
    IonFabButton,
    IonRouterLink,
    IonInput, IonCol
} from '@ionic/react';
import {caretDown, chatbubbleOutline, addOutline, people, caretUp} from 'ionicons/icons';
import './ModalStyles.css';
import { ContentItem } from '../../models/ContentItem';
import {toast} from "../../toast";
import firebase from "firebase";
import {firestore} from "../../firebaseConfig";
import Moment from 'react-moment';
import {RouteComponentProps} from "react-router";
import { getPictureAddress } from '../../util/avatarUtils';

interface PostCommentProps {
    deliveryModule: ContentItem;
    loggedInUser: any;
    showInput: boolean;
    commentsOn: boolean;
    tab: string;
}


const PostComment: React.FC<PostCommentProps> = ({deliveryModule, loggedInUser, showInput, commentsOn, tab}) => {


    // onChange = (event: any) => {
    //     this.setState({ [event.target.name]: event.target.value });
    // };
//    console.log("post comment as" ,loggedInUser);
   const [commentText, setCommentText] = useState('');

   const [addedComments, setAddedComments] = useState([]);
   // useEffect(() => {
   //     console.log('refresh');
   // },[showInput]);

    function saveComment(deliveryModule) {
        //console.log('delivery module', deliveryModule.uid);
        if (commentText != '') {
            let commentData = {
                commentText: commentText,
                commentAuthorId: loggedInUser.uid,
                commentAuthorHeroName: loggedInUser.characterName? loggedInUser.characterName : (loggedInUser.heroName ? loggedInUser.heroName : loggedInUser.clientName),
                commentAuthorPosterUrl: loggedInUser.photoURL,
                commentTime: firebase.firestore.Timestamp.fromDate(new Date())
            }

            addedComments.push(commentData);
            setAddedComments(addedComments);

            // let deliveryModuleRef = firestore.collection('content').doc(deliveryModule.uid);
            const deliveryModuleRef =  firestore.doc(`content/${deliveryModule.uid}`);

           //console.log("comments:",deliveryModule.comments);
           if (deliveryModule.comments !== 'undefined') {
               let commentArrUnion = deliveryModuleRef.update({
                   comments: firebase.firestore.FieldValue.arrayUnion(commentData)
               }).then(() => {
                   // window.location.reload(false);
                   console.log('Comment was posted successfully.');
                   setCommentText('');
               }).catch(function(error) {
                   toast('We have encountered an error posting this comment. Please contact support');
               });
           }
           else {
               let commentArrUnion = deliveryModuleRef.set({
                   comments: [commentData]
               }, {merge:true}).then(() => {
                   // window.location.reload(false);
                   console.log('Comment was posted successfully.');
                   setCommentText('');
               }).catch(function(error) {
                   toast('We have encountered an error posting this comment. Please contact support');
               });

           }

            // console.log("Delivery module", deliveryModule.uid);
            // console.log("Commentator ID", hero.uid + "name", hero.heroName);
            // console.log("Comment", comment);
        }
        else {
            toast("Please enter a comment before posting.");
        }
        setCommentText('');

    }

    function  viewProfile(profileId) {
        window.location.href=`/viewAssociation/${profileId}`;
    }

//    console.log("Show comments", commentsOn, "showInput", showInput);

        return(
            <>
                {showInput && <IonList className={deliveryModule.contentType === 'mission' ? 'missionContainer' : 'challengeContainer'} style={{'--padding-start':'0px'}}>
                    {addedComments && addedComments.map((commentItem, cIDx) => {
                        return (
                            <IonItem lines="none" className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} key={cIDx} style={{'--padding-start':'0px'}}>
                                <IonCol>
                                    <IonRow>
                                        <IonText><span className="commentAuthor">{commentItem.commentAuthorHeroName}</span>: {commentItem.commentText}</IonText>
                                    </IonRow>
                                    <IonRow>
                                        <Moment format='MMMM Do YYYY, h:mm a' style={{fontSize:'8px'}}>{commentItem.commentTime.toDate()}</Moment>
                                    </IonRow>
                                </IonCol>

                            </IonItem>
                        )})}
                </IonList>}

            {showInput &&
            <IonList className={deliveryModule.contentType === 'mission' ? 'missionContainer' : 'challengeContainer'} >
                <IonItem lines='none'
                         className={deliveryModule.contentType === 'mission' ? 'missionContainer messageBox' : 'challengeContainer messageBox'} style={{'--padding-start':'8px'}}>

                    <IonAvatar style={{ paddingLeft:'0px'}}>

                        <IonImg id={'commentor'+loggedInUser.uid+tab} src={loggedInUser.photoURL ? getPictureAddress(loggedInUser.photoURL, loggedInUser.uid, 'commentor'+loggedInUser.uid+tab) : "https://www.gravatar.com/avatar?d=mm"}

                                onClick={() => viewProfile(loggedInUser.uid)}/>
                    </IonAvatar>
                    <IonInput name='commentText'
                              value={commentText}
                              autocapitalize="on"
                              placeholder={"Add a comment as " + loggedInUser.heroName}
                              onIonChange={(e: any) => setCommentText(e.target
                                  .value)}
                              style={{'fontSize':'12px', '--overflow-scroll':'hidden'}}
                              className="input ion-padding-horizontal"
                              clear-input="true"
                              type="text"
                    >

                    </IonInput>
                    <IonButton slot="end" size="small" className="appBtn"
                               onClick={() => saveComment(deliveryModule)}>Post</IonButton>
                </IonItem>
            </IonList>
            }

            </>
        );
}

export default PostComment;
import React, {useEffect, useRef, useState} from 'react';
import {
    IonContent,
    IonText,
    IonPage,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonFab,
    IonFabButton,
    IonGrid,
    IonList,
     IonButton,
     IonCheckbox,
     IonModal,
     IonHeader,
     IonImg,
     IonFooter

} from '@ionic/react';

import Footer from '../../components/Footer';
import { firestore } from '../../firebaseConfig';
import {useLocation, withRouter} from "react-router-dom";
import CheckboxNote from "../../components/ChecboxNote";
import CheckboxBtn from "../../components/CheckboxBtn";
import Header from "../../components/Header";
import { sendEmail } from '../../util/email';
import {findUserAndSendAssetsEmail, sendAssetsEmail } from '../../util/sendEmail';
import { toast } from '../../toast';
import { notification } from '../../util/notification';
import DirectionVideoContainer from '../../components/DirectionVideoContainer';

interface MissionDetailProps {
  location:any;
  history:any;
}


const MissionDeliveryChecklist: React.FC<MissionDetailProps> = (props) => {

    const [directionList, setDirectionList] = useState(['']);
    const [clientDetails, setClientDetails] = useState({});

    const [showGetAssets, setShowGetAssets] = useState(false);

    const [option0, setOption0] = useState(false);
    const [option1, setOption1] = useState(false);
    const [option2, setOption2] = useState(false);
    const [options, setOptions] = useState([]);
    let actionsDisabled = options.length ? options.some(option => !option)
                                         : !(option0 && option1 && option2)

    const {missionId, missionTitle,heroId, heroName, userId, userPhotoURL, fromPage } = props?.location.state as any;

    const getAssets = () => {
        //console.log("props user", userId);
        findUserAndSendAssetsEmail(userId);
        setShowGetAssets(false);
        notification('Check your Inbox for the download link.');
    }


    useEffect(()=> {
        let query = firestore.doc(`missions/${missionId}`);

        query.get()
            .then(function (doc) {

                //console.log("direction list:", doc.data()['direction']);
                if (doc.data() && doc.data() !== undefined) {
                    console.log("doc.data", doc.data());
                    //setMissionDetailsId(doc.data()['title']);
                    //find client photo url
                    if (doc.data()['submittedBy'] && doc.data()['submittedBy']) {
                        let queryClient = firestore.doc(`users/${doc.data()['submittedBy']}`);
                        queryClient.get().then(function(clientDoc) {
                            console.log("client doc", clientDoc.data());
                            setClientDetails({ ...clientDoc.data(), uid: clientDoc.id });
                        });
                    }

                    if (doc.data()['direction'] && doc.data() ['direction']!== undefined) {
                        setDirectionList(doc.data()['direction']);
                        if (doc.data()['direction'].length && doc.data()['direction'][0] !== "")
                            setOptions(Array(doc.data()['direction'].length).fill(false))
                        console.log("Mission Details:" , doc.data());
                    }
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }, [missionId]);

    const onChange = (e: any) => {
        e.preventDefault();
        let missionFile = e.target.files[0];
        let missionFileURL = URL.createObjectURL(missionFile);

        props.history.push({
            pathname: '/missionDeliveryReview',
            state: { missionFile, missionFileURL, missionTitle, missionId, heroName, heroId, userId, userPhotoURL, clientDetails}
        });
        //window.location.reload(false);

    }

  async function recordVideo() {
       document.getElementById('recorderMission').click();
    }

    const onMissionChange = (e: any) => {
        e.preventDefault();
        let uploadMissionFile = e.target.files[0];
        let uploadMissionFileUrl = URL.createObjectURL(uploadMissionFile);

        // console.log("params" + uploadMissionFile+ uploadMissionFile.type + uploadMissionFileUrl + missionTitle +missionId);
        props.history.push({
            pathname: '/missionDeliveryReview',
            state: { missionFile:uploadMissionFile, missionFileURL:uploadMissionFileUrl, missionTitle, missionId, heroName, heroId, userId, userPhotoURL, clientDetails}
        });
    }

    async function clickUploadMission(e) {
       document.getElementById('uploadVideoMission').click();
   }


  return (
    <IonPage>

      <Header fromPage={fromPage}/>
      <IonContent className="app" scrollX scrollY>
          <IonModal isOpen={showGetAssets}
                    cssClass='my-assets-class'
                    onDidDismiss={() => setShowGetAssets(false)}>
              <IonHeader>
                  <IonRow style={{padding: 10}}>

                  </IonRow>
              </IonHeader>
              <IonContent className='my-assets-class'
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>


                  <IonImg src="/assets/img/JFH_assets_pop_up_graphic.jpg" ></IonImg>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={getAssets} style={{'--background':'#005494'}} className="center">
                          <IonLabel className="italic bolder">
                              Get Assets
                          </IonLabel>
                      </IonButton>
                  </IonRow>
              </IonFooter>
          </IonModal>
        <IonGrid>
        <IonRow>
            <IonCol className="ion-text-center">
                <IonItem className="app" lines="none">
                    <IonLabel className="contentTextTitle" style={{'color':'#ffffff'}}> MISSION DIRECTION  <br/>  {missionTitle}</IonLabel>
                </IonItem>
            </IonCol>
        </IonRow>
            {missionTitle === 'Enter The Cowboy' &&
            <DirectionVideoContainer assetName={"brand/Mission-Enter_the_Cowboy_DIRECTION_CTA.mp4"}
              posterAssetName={"Missions/thumbnails/cowboy_misisondirection.jpg"}/>}
            <IonList style={{'background':'none'}} lines="none">
               {directionList && directionList.length && directionList[0] !== "" ?
                  directionList.map((item, index) => (
                     <IonItem key={index} className="app" lines="none">
                        <IonCol size="1">
                            {/*<CheckboxBtn idx={index} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={index} totalChecks={directionList.length}/>*/}
                            <IonCheckbox className="checkbox" onClick={() => {
                                options[index] = !options[index]
                                setOptions([...options])
                            }}/>
                        </IonCol>
                         <IonCol>
                           <IonText className="directionText" style={{color:'#ffffff'}}> {item}</IonText>
                         </IonCol>
                     </IonItem>
                  ))
               :
               <>
                  <IonItem className="app" style={{'borderBottom': 'none !important'}} lines="none">
                     {/*<IonCheckbox checked={true} className="checkbox-direction"/>*/}
                      <IonCol size="1" style={{marginLeft:'2px'}}>
                        <IonCheckbox className="checkbox" onClick={() => setOption0(!option0)}/>
                     </IonCol>
                     {/*<CheckboxBtn idx={0} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={0} totalChecks={3}/>*/}
                     <IonText className="directionText" style={{color:'#ffffff', paddingLeft:'5px'}}>Always start your mission by clearly stating your  hero name followed by the phrase "on mission". <br/>
                     <p className="contentTextItalic">For example: Vintij on Mission </p></IonText>
                  </IonItem>
                  <IonItem className="app" lines="none">
                      <IonCol size="1" style={{marginLeft:'2px'}}>
                        <IonCheckbox className="checkbox" onClick={() => setOption1(!option1)}/>
                     </IonCol>
                     {/*<CheckboxBtn idx={1} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={1} totalChecks={3}/>*/}
                     <IonText className="directionText" style={{color:'#ffffff', paddingLeft:'5px'}}> No firearms or anything that resembles a real weapon</IonText>
                  </IonItem>
                  <IonItem className="app" lines="none">
                      <IonCol size="1" style={{marginLeft:'2px'}}>
                        <IonCheckbox className="checkbox" onClick={() => setOption2(!option2)}/>
                     </IonCol>
                     {/*<CheckboxBtn idx={2} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={2} totalChecks={3}/>*/}
                     <IonText className="directionText" style={{color:'#ffffff', paddingLeft:'5px'}}> For everyone's safety, make sure people around you know you're shooting a movie.</IonText>
                  </IonItem>
               </>
               }
            </IonList>


            <IonRow><IonCol></IonCol></IonRow>
            <IonRow>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <IonText className="appTextMain" style={{'paddingBottom': '30px', 'color':'#ffffff', '--color':'#ffffff'}}>
                        Complete the mission in a single shot now, or upload on the bottom right.
                    </IonText>
                    <div style={{height: 106,width: 106}}>
                        <IonFab style={{
                                borderStyle: 'solid',
                                borderRadius: '50%',
                                borderWidth: 3,
                                borderColor: 'red'
                        }}>
                            <IonFabButton disabled={actionsDisabled} onClick={() => recordVideo()} style={{
                                '--background': '#ffffff',
                                minHeight: 100,
                                width: 100,
                                '--border-color': '#FF0000'
                            }}>
                                <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                                    ACTION
                                </IonLabel>
                            </IonFabButton>
                            <input type="file" accept="video/*" capture="camera"
                                   id="recorderMission"
                                   className="uploadVideoBtn"
                                   onChange={onChange}
                            />
                        </IonFab>
                    </div>
                </div>
            </IonRow>
        {/*<UploadVideoBtn />*/}
            <IonRow style={{'color':'#005494'}}>
                <IonCol>
                    <IonButton
                               className="noShadowBtnBox"
                               style={{'--background': '#fffff', 'float':'left', 'marginTop':'10px'}}
                               onClick={(e) => {
                                   setShowGetAssets(true)
                               }}>
                        <IonLabel style={{'color':'#ffffff'}} className="italic"> Get Assets </IonLabel>

                    </IonButton>
                </IonCol>
                <IonCol>
                    <IonButton disabled={actionsDisabled}
                               className="noShadowBtnBox"
                               style={{'--background': '#fffff', 'float':'right', 'marginTop':'10px'}}
                               onClick={(e) => {
                                   clickUploadMission(e)
                               }}>
                        <IonLabel style={{'color':'#ffffff'}} className="italic"> Upload Video </IonLabel>
                        <input type="file" accept="video/*" className="uploadVideoBtn"
                               id="uploadVideoMission"
                               onChange={onMissionChange}
                        />
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonContent>
        <CheckboxNote/>
    <Footer/>
    </IonPage>
  );
};

export default withRouter(MissionDeliveryChecklist);

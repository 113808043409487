import React, {useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,

  IonRow,

  IonImg,
  IonText,

  IonCard,
  IonThumbnail,
  IonGrid,
  IonListHeader,
  IonSearchbar,
  IonItem
} from '@ionic/react';
import { firestore } from '../../firebaseConfig';
import User from '../../models/User';

import {downloadPictureContent,  viewAssociation } from '../../util/avatarUtils';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from '../../components/authWrapper';


interface UserState {
  loading: boolean;
  users: User[];
  user: any;
}

const Users:React.FC<UserState> = (props) => {

  const [searchUser, setSearchUser] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {

    let query = searchUser.toLowerCase()
    let stringBound = query.slice(0, query.length-1)
        + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)
    //console.log("search receiver", searchReceiver);

    if (query!='' && query.length >=1) {
      // console.log("query", query);
      const searchCastRef = firestore.collection('users').where('complete', '==', true)
          .where('characterName_nocase','>=', query)
          .where('characterName_nocase','<', stringBound)
          .where('characterName_nocase','!=', '')
          .orderBy('characterName_nocase', 'asc')
          .orderBy('dateJoined', 'desc')

      searchCastRef.onSnapshot(snapshot => {
        setUsers(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));

      });

    }
    else {

      let castRef = firestore.collection('users').where('complete', '==', true).orderBy('dateJoined', 'desc')
          .onSnapshot(snapshot => {

            let users = [];

            snapshot.forEach(castDoc =>
                users.push({...castDoc.data(), uid: castDoc.id}),
            );

            setUsers(users);
          });


    }


  }, [searchUser]);


    return (

        <IonPage>


          <Header />


          <IonContent>
            <IonListHeader>
              <IonText className="brandText">CAST</IonText>
            </IonListHeader>
              <IonItem>
                  <IonText>There are {users.length} JFH Characters </IonText>
              </IonItem>
            <IonSearchbar value={searchUser} onIonChange={e => setSearchUser(e.detail.value!)} placeholder="Search ... " debounce={1000}></IonSearchbar>

            {users
            && users.map((castMember, idx)=> {
              return (
                  <IonCard key={idx} className="child3">
                    <IonThumbnail style={{'height':'74%', 'width':'100%'}}>
                      <IonImg id={'castMemberMenu'+idx+castMember.uid} src={castMember.photoURL ? downloadPictureContent(castMember.photoURL,castMember.uid, 'castMemberMenu'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                              alt={"Profile Image"}  onClick={() => viewAssociation(castMember.uid, 'none', props.user.uid)}/>
                    </IonThumbnail>

                    <div>
                      <IonGrid>
                        <IonRow>
                          {castMember.characterName ? castMember.characterName : (castMember.heroName ? castMember.heroName :castMember.clientName)}
                        </IonRow>

                      </IonGrid>
                    </div>

                  </IonCard>
              );
            })}


          </IonContent>

          <Footer/>
        </IonPage>

    );
}

export default authWrapper(Users);

import React from 'react';
import {
    IonContent,
    IonPage,
    IonText,
    IonRow,
    IonCol,
    IonGrid,
    IonFab,
    IonFabButton,
    IonLabel,
    IonButton,
    IonItem
} from '@ionic/react';

import { firestore, auth } from '../../firebaseConfig';
import { useHistory } from 'react-router';
import './Client.css';
import Header from "../../components/Header";
import OnboardingVideo from '../../components/Registration/OnboardingVideo';

const Client: React.FC = () => {

  let currentUser = auth.currentUser;

  if (currentUser === null) {
    auth.signInAnonymously()
    .catch(function(error) {
      console.log('Error sign in annon');
    });
  }


  const history = useHistory();


  async function getClientIndex() {

    let clientCounter = await firestore.collection("clients").get().then(function(querySnapshot) {
      return querySnapshot.size;
    });

    console.log("client index:"+ clientCounter);
    return clientCounter;

    }

    const onChange = async (e: any) => {
        let file = e.target.files[0];
        let fileURL = URL.createObjectURL(file);
        let currentUser = auth.currentUser;
        let clientIndex = await getClientIndex();

        history.push({
            pathname: '/profileVideoReview',
            state: {file, fileURL, role: 'client', idx: clientIndex, userId: currentUser.uid, fromPage: '/client'}
        })
    }
    async function recordVideo() {
        document.getElementById('recorder').click();

    }

    async function uploadLater() {
      let clientIndex = await getClientIndex();
      let currentUser = auth.currentUser;
      const clientRef = firestore.collection("clients").add({
        clientName: '',
        profileVideo: "",
        clientNumber: clientIndex,
        userId: currentUser.uid,
      }).then(ref => {
       history.push({
        pathname: '/registerClient',
        state: { client: ref.id, role: 'client', profileVideoUrl: "", idx: clientIndex+1 }
       });
        console.log('Added client with ID: ', ref.id);
      });
    }


  return (
    <IonPage>
      <Header noChat={true} fromPage="chooseRole"/>
        <IonContent className="app ion-padding ion-justify-content-center">
       <IonGrid id="preCamera">
           {/*<div style={{ paddingBottom: '20%', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>*/}
           <IonRow style={{height:'18px'}}>
               <IonCol style={{textAlign:'center'}}>
                   <IonText className="appTextMain">
                       SHOOT YOUR PROFILE VIDEO
                   </IonText>
               </IonCol>
           </IonRow>
           <IonRow>
               <IonCol></IonCol>
               <IonCol>
                   <IonItem className="app" lines="none">
                       <IonLabel className="contentTextTitle" style={{'color':'#ffffff'}}>(under 1min)</IonLabel>
                   </IonItem>
               </IonCol>
               <IonCol></IonCol>
           </IonRow>
           <IonRow>
               <IonCol>

                   <IonLabel className="appTextMain" style={{'color':'#ffffff'}}> DIRECTION:
                   </IonLabel>

               </IonCol>


           </IonRow>

               <IonRow>

                   <IonCol className="app">


                       <IonText  className="appTextMain" color="light">
                           1. Say Your CLIENT Name
                       </IonText>

                   </IonCol>

               </IonRow>
               <IonRow>

                   <IonCol className="app">
                       <IonText  className="appTextMain" color="light">
                           2. Why do you need help, or what needs changing in the world?
                       </IonText>
                   </IonCol>

               </IonRow>
           <IonRow>
               <IonCol></IonCol>
           </IonRow>
           <IonRow>
               <IonCol></IonCol>
           </IonRow>
               <OnboardingVideo tab={'clientRegistration'} videoAssetUrl={'onboarding/Client_Role-JFHAppOnboardingVideo1080p.mp4'} posterAssetUrl={'onboarding/howtobeaclient.jpg'}/>
           {/*</div>*/}
           <IonRow>
               <IonCol></IonCol>
           </IonRow>
           <IonRow>
               <IonCol></IonCol>
           </IonRow>

        <IonRow className="ion-justify-content-center">
            <div style={{width: 106}}>
                <IonFab style={{
                    borderStyle: 'solid',
                    borderRadius: '50%',
                    borderWidth: 3,
                    borderColor: 'red'
                }}>
                    <IonFabButton onClick={() => recordVideo()} style={{
                        '--background': '#ffffff',
                        minHeight: 100,
                        width: 100,
                        '--border-color': '#FF0000'
                    }}>
                        <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                            ACTION
                        </IonLabel>
                    </IonFabButton>
                    <input type="file" accept="video/*" capture="camera"
                           id="recorder"
                           className="uploadVideoBtn"
                           onChange={onChange}
                    />
                </IonFab>
            </div>


        </IonRow>


           <IonRow><IonCol></IonCol></IonRow>
           <IonRow><IonCol></IonCol></IonRow>
           <IonRow><IonCol></IonCol></IonRow>
           <IonRow><IonCol></IonCol></IonRow>
           <IonRow><IonCol></IonCol></IonRow>
           <IonRow><IonCol></IonCol></IonRow>
           <IonRow><IonCol></IonCol></IonRow>
           <IonRow>
               <IonCol></IonCol>
           </IonRow>
           <IonRow>
               <IonCol></IonCol>
           </IonRow>
           <IonRow>
               <IonCol></IonCol>
           </IonRow>

           <IonRow className="ion-justify-content-end">
               <IonCol></IonCol>
               <IonCol></IonCol>
               <IonCol>
                   <IonButton onClick={uploadLater} className="uploadLaterButtonText" style={{'float':'right'}}>
                       Upload Later
                   </IonButton>
               </IonCol>
           </IonRow>

       </IonGrid>

       <video id="player" controls style={{'display':'none'}} className="videoModule"></video>
      </IonContent>

    </IonPage>
  );
};

export default Client;

import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonText,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonPage,
    IonFab,
    IonFabButton,
    IonGrid,
    IonList,
    IonButton, IonCheckbox, IonFooter, IonModal, IonHeader, IonImg
} from '@ionic/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { withRouter} from 'react-router';

import { firestore} from '../../firebaseConfig';
import CheckboxNote from "../../components/ChecboxNote";

import { findUserAndSendAssetsEmail } from '../../util/sendEmail';
import { notification } from '../../util/notification';


interface ChallengeListItemDetailProps {
    location: any;
    history: any;
}
const ChallengeListItemDetail: React.FC<ChallengeListItemDetailProps> = (props) => {

    const [directionList, setDirectionList]=useState(['']);

    const [showGetAssets, setShowGetAssets] = useState(false);

    const [option0, setOption0] = useState(false);
    const [option1, setOption1] = useState(false);
    const [option2, setOption2] = useState(false);
    const [options, setOptions] = useState([]);
    let actionsDisabled = options.length ? options.some(option => !option)
        : !(option0 && option1 && option2)

    const {challengeId, challengeTitle,challengeType, heroId, heroName, userId, userPhotoURL, fromPage } = props?.location.state as any;

    const getAssets = () => {
        //console.log("props user", userId);
        findUserAndSendAssetsEmail(userId);
        setShowGetAssets(false);
        notification('Check your Inbox for the download link.');
    }

  useEffect( () => {
      let isSubscribed = true;

      let query = firestore.doc(`challenges/${challengeId}`);

      query.get()
          .then(function (doc) {
          if (isSubscribed) {
              if (doc.data()['direction'] && doc.data() ['direction']!== undefined) {
                  setDirectionList(doc.data()['direction']);
                  if (doc.data()['direction'].length && doc.data()['direction'][0] !== "")
                      setOptions(Array(doc.data()['direction'].length).fill(false))
                  console.log("Mission Details:" , doc.data());
              }
              //setDirectionList(doc.data()['direction']);
          }

          })
          .catch(function (error) {
              console.log("Error getting documents: ", error);
          });
      return () => {isSubscribed = false}
  }, [challengeId]);

    const onChange =  (e: any) => {
        let challengeFile = e.target.files[0];
        let challengeFileURL = URL.createObjectURL(challengeFile);

        props.history.push({
            pathname: '/challengeDeliveryReview',
            state: { challengeFile, challengeFileURL, challengeTitle, challengeId, challengeType, heroName, heroId, userId, userPhotoURL}
        });
    }

    async function recordVideo() {
        document.getElementById('recorderJourney').click();
    }


    const onVideoChange = (e: any) => {

        let uploadJourneyFile = e.target.files[0];
        let uploadJourneyFileUrl = URL.createObjectURL(uploadJourneyFile);

        props.history.push({
            pathname: '/challengeDeliveryReview',
            state: { challengeFile:uploadJourneyFile, challengeFileURL:uploadJourneyFileUrl, challengeTitle, challengeId, challengeType: challengeType, heroName, heroId, userId, userPhotoURL}
        });
    }

    async function clickUploadVideo(e) {
        document.getElementById('uploadVideoJourney').click();
    }
  return (


    <IonPage>
    <Header fromPage={fromPage}/>

     <IonContent className="app" scrollX scrollY>
         <IonModal isOpen={showGetAssets}
                   cssClass='my-assets-class'
                   onDidDismiss={() => setShowGetAssets(false)}>
             <IonHeader>
                 <IonRow style={{padding: 10}}>

                 </IonRow>
             </IonHeader>
             <IonContent className='my-assets-class'
                         scrollEvents={true}
                         onIonScrollStart={() => {
                         }}
                         onIonScroll={() => {
                         }}
                         onIonScrollEnd={() => {
                         }} scrollX scrollY>


                 <IonImg src="/assets/img/JFH_assets_pop_up_graphic.jpg" ></IonImg>

             </IonContent>
             <IonFooter>
                 <IonRow className="ion-justify-content-center">

                     <IonButton onClick={getAssets} style={{'--background':'#005494'}} className="center">
                         <IonLabel className="italic bolder">
                             Get Assets
                         </IonLabel>
                     </IonButton>
                 </IonRow>
             </IonFooter>
         </IonModal>
      <IonGrid>
      <IonRow>
          <IonCol>
              <IonItem className="app" lines="none">
                  <IonLabel className="labelText" style={{'color':'#ffffff'}}> {challengeType.toUpperCase()} DIRECTION</IonLabel>
              </IonItem>
          </IonCol>
      </IonRow>
          <IonRow>
              <IonCol>
                  <IonItem className="app" lines="none">
                      <IonLabel className="labelText" style={{'color':'#ffffff'}}> {challengeTitle}
                      </IonLabel>
                  </IonItem>
              </IonCol>
          </IonRow>

          <IonList style={{'background':'none'}} lines="none">
              {directionList && directionList.length && directionList[0] !== "" ?
                  directionList.map((item, index) => (
                      <IonItem key={index} className="app" lines="none">
                          <IonCol size="1">
                              {/*<CheckboxBtn idx={index} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={index} totalChecks={directionList.length}/>*/}
                              <IonCheckbox className="checkbox" onClick={() => {
                                  options[index] = !options[index]
                                  setOptions([...options])
                              }}/>
                          </IonCol>
                          <IonCol size="10" style={{marginLeft:'5px'}}>
                             <IonText className="directionText ion-text-wrap" style={{color:'#ffffff', textAlign:'justify'}}> {item}</IonText>
                          </IonCol>
                      </IonItem>
                  ))
                  :
                  <>
                      <IonItem className="app" style={{'borderBottom': 'none !important'}} lines="none">
                          {/*<IonCheckbox checked={true} className="checkbox-direction"/>*/}
                          <IonCol size="1">
                              <IonCheckbox className="checkbox" onClick={() => setOption0(!option0)}/>
                          </IonCol>
                          <IonCol size="10" style={{marginLeft:'5px'}}>
                          {/*<CheckboxBtn idx={0} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={0} totalChecks={3}/>*/}
                           <IonText className="directionText"> Your hero’s "{challengeType}" challenges all string together to tell one story. Think of it as your own movie.</IonText>
                          </IonCol>
                      </IonItem>
                      <IonItem className="app" lines="none">
                          <IonCol size="1">
                              <IonCheckbox className="checkbox" onClick={() => setOption1(!option1)}/>
                          </IonCol>
                          <IonCol size="10" style={{marginLeft:'5px'}}>
                          {/*<CheckboxBtn idx={1} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={1} totalChecks={3}/>*/}
                          <IonText className="directionText">Be cinematic; think big even if it's small.</IonText>
                          </IonCol>
                      </IonItem>
                      <IonItem className="app" lines="none">
                          <IonCol size="1">
                              <IonCheckbox className="checkbox" onClick={() => setOption2(!option2)}/>
                          </IonCol>
                          <IonCol size="10" style={{marginLeft:'5px'}}>
                          {/*<CheckboxBtn idx={2} btnName="recordMission" uploadBtnName="uploadVideoMissionBtn" countChecks={2} totalChecks={3}/>*/}
                          <IonText className="directionText">Let performances from you (and your friends) tell a story.</IonText>
                          </IonCol>
                      </IonItem>
                  </>
              }
          </IonList>

              <IonRow />
              <IonRow />
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow>
              <IonCol className="app ion-text-center">
                  <IonText className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff', '--color':'#ffffff'}}>
                      Complete the challenge in a single shot now, or upload on the bottom right.
                  </IonText>
              </IonCol>
        </IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow className="ion-justify-content-center">
            <div style={{height: 106,width: 106}}>
              <IonFab style={{
                  borderStyle: 'solid',
                  borderRadius: '50%',
                  borderWidth: 3,
                  borderColor: 'red'
              }}>
                  <IonFabButton id="recordJourney" disabled={actionsDisabled} onClick={() => recordVideo()} style={{
                      '--background': '#ffffff',
                      minHeight: 100,
                      width: 100,
                      '--border-color': '#FF0000'
                  }}>
                      <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                          ACTION
                      </IonLabel>
                  </IonFabButton>
                  <input type="file" accept="video/*" capture="camera"
                         id="recorderJourney"
                         className="uploadVideoBtn"
                         onChange={onChange}
                  />
              </IonFab>
            </div>
          </IonRow>
          {/*<UploadVideoBtn />*/}
          <IonRow>
              <IonCol>
                  <IonButton
                      className="noShadowBtnBox"
                      style={{'--background': '#fffff', 'float':'left', 'marginTop':'10px'}}
                      onClick={(e) => {
                          setShowGetAssets(true)
                      }}>
                      <IonLabel style={{'color':'#ffffff'}} className="italic"> Get Assets </IonLabel>

                  </IonButton>
              </IonCol>
              <IonCol>
                  <IonButton disabled={actionsDisabled} id="uploadVideoJourneyBtn"
                             style={{'--background': '#fffff', 'float':'right', 'marginTop':'10px', '--boxShadow':'none'}}
                             onClick={(e) => {
                                 clickUploadVideo(e)
                             }}>
                      <IonLabel style={{'color':'#ffffff', '--boxShadow':'none'}} className="italic"> Upload Video </IonLabel>
                      <input type="file" accept="video/*" className="uploadVideoBtn"
                             id="uploadVideoJourney"
                             onChange={onVideoChange}
                      />
                  </IonButton>
              </IonCol>
          </IonRow>
         </IonGrid>
      </IonContent>
        <CheckboxNote/>
      <Footer />
      </IonPage>
  );
};

export default withRouter(ChallengeListItemDetail);

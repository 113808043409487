
import React, {useEffect, useState} from 'react';
import './ExploreContainer.css';

import {IonCard, IonSpinner, IonText} from '@ionic/react';

import DeliveryModuleBtnContainer from '../pages/Wurld/DeliveryModuleBtnContainer';
import {firestore} from "../firebaseConfig";
import ChatDeliveryModuleVideo from "../pages/Chat/ChatDeliveryModuleVideo";
import MissionDeliveryComponent from './MissionDeliveryComponent';


interface ChatDeliveryModuleProps {
    deliveryModuleId: string;
    showButtons: boolean;
    loggedInUser: any;
    tab: string;
    messageId: string;
    sentObjectType: string;
}


const ChatDeliveryModule: React.FC<ChatDeliveryModuleProps> = ({ deliveryModuleId, showButtons, loggedInUser, tab, messageId, sentObjectType }) => {

    const [deliveryModule, setDeliveryModule] = useState<any>();

    const [loading, setLoading] = useState(true);

    useEffect( () => {
        let isSubscribed = true;

        let query = firestore.doc(`content/${deliveryModuleId}`);

        if (sentObjectType === 'mission') {
            query = firestore.doc(`missions/${deliveryModuleId}`)
        }
        query.get().then((doc)=> {
            let documentRef = {uid: doc.id, ...doc.data()};
                console.log("documentRef:", documentRef);

            setDeliveryModule(documentRef);
            setLoading(false);
        });

        return () => {isSubscribed = false}
    }, [deliveryModuleId]);

    if (loading)
        return <IonSpinner/>
    else
        return (
            <>
            {sentObjectType === 'mission' &&
              <MissionDeliveryComponent mission={deliveryModule} showButtons={true} loggedInUser={loggedInUser} index={1} fromPage="chat" showCompletedSection={false}/>
            }
            {sentObjectType === 'deliveryModule' &&
            <IonCard className={deliveryModule.contentType==='mission'? 'missionContainer dm-card': 'deliveryModuleContainer dm-card'}>
                <ChatDeliveryModuleVideo item={deliveryModule} index={messageId} tab={tab}/>
                <DeliveryModuleBtnContainer deliveryModule={deliveryModule} loggedInUser={loggedInUser} tab={tab}/>
            </IonCard>
            }
            </>
        );
};

export default ChatDeliveryModule;


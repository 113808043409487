import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonPage,
    IonRow,
    IonImg,
    IonCard,
    IonThumbnail,
    IonGrid,

    IonSearchbar, IonIcon, IonSegmentButton, IonLabel, IonSegment, IonText, IonCol, IonCardContent, IonItem, IonButton
} from '@ionic/react';
import {firestore} from '../../firebaseConfig';
import User from '../../models/User';

import {downloadContent, downloadPictureContent} from '../../util/avatarUtils';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from '../../components/authWrapper';
import {cloudDownload, pin} from "ionicons/icons";

import AdminCTAFeed from "./AdminCTAFeed";
import AdminMissionFeed from "./AdminMissionFeed";
import AdminJourneyFeed from "./AdminJourneyFeed";
import AdminTrainingFeed from "./AdminTrainingFeed";
import AdminDevFeed from "./AdminDevFeed";


interface ViewContentState {
    loading: boolean;
    users: User[];
    user: any;
}

const ViewContent:React.FC<ViewContentState> = (props) => {

    const [searchContent, setSearchContent] = useState('');
    const [users, setUsers] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [currentTab, setCurrentTab]= useState('profile');
    const [searchUser, setSearchUser]= useState('');

    useEffect(() => {

        let queryProfile = searchUser.toLowerCase()
        let stringBoundProfile = queryProfile.slice(0, queryProfile.length-1)
            + String.fromCharCode(queryProfile.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (queryProfile!='' && queryProfile.length >=1) {
            // console.log("query", query);
            const searchCastRef = firestore.collection('users').where('complete', '==', true)
                .where('characterName_nocase','>=', queryProfile)
                .where('characterName_nocase','<', stringBoundProfile)
                .where('characterName_nocase','!=', '')
                .orderBy('characterName_nocase', 'asc')
                .orderBy('dateJoined', 'desc')

            searchCastRef.onSnapshot(snapshot => {
                setUsers(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));

            });

        }
        else {

            let castRef = firestore.collection('users').where('complete', '==', true).orderBy('dateJoined', 'desc')
                .onSnapshot(snapshot => {

                    let users = [];

                    snapshot.forEach(castDoc =>
                        users.push({...castDoc.data(), uid: castDoc.id}),
                    );

                    setUsers(users);
                });


        }


    }, [searchUser]);

    function changeSegmentContent(selectedSegment) {

        let contentContainer = document.getElementById(selectedSegment + 'Feed');
        contentContainer.style.display = 'block';
        let profileFeedContainer = document.getElementById('profileFeed');
        let ctaFeedContainer = document.getElementById('ctaFeed');
        let missionsFeedContainer = document.getElementById('missionsFeed');
        let journeyFeedContainer = document.getElementById('journeyFeed');
        let trainingFeedContainer = document.getElementById('trainingFeed');
        let developmentFeedContainer = document.getElementById('developmentFeed');

        if (selectedSegment === 'profile') {
            ctaFeedContainer.style.display='none';
            missionsFeedContainer.style.display = 'none';
            journeyFeedContainer.style.display = 'none';
            trainingFeedContainer.style.display = 'none';
            developmentFeedContainer.style.display = 'none';
            setCurrentTab('profile');
        } else if (selectedSegment === 'cta') {
            // setShowJoin(true);
            profileFeedContainer.style.display='none';
            missionsFeedContainer.style.display = 'none';
            journeyFeedContainer.style.display = 'none';
            trainingFeedContainer.style.display = 'none';
            developmentFeedContainer.style.display = 'none';
            setCurrentTab('cta');
        } else if (selectedSegment === 'missions') {
            profileFeedContainer.style.display='none';
            ctaFeedContainer.style.display = 'none';
            journeyFeedContainer.style.display = 'none';
            trainingFeedContainer.style.display = 'none';
            developmentFeedContainer.style.display = 'none';
            setCurrentTab('missions');
        }else if (selectedSegment === 'journey') {
            profileFeedContainer.style.display='none';
            ctaFeedContainer.style.display = 'none';
            missionsFeedContainer.style.display = 'none';
            trainingFeedContainer.style.display = 'none';
            developmentFeedContainer.style.display = 'none';
            setCurrentTab('journey');
        } else if (selectedSegment === 'training') {
            profileFeedContainer.style.display='none';
            ctaFeedContainer.style.display = 'none';
            missionsFeedContainer.style.display = 'none';
            journeyFeedContainer.style.display = 'none';
            developmentFeedContainer.style.display = 'none';
            setCurrentTab('training');
        } else if (selectedSegment === 'development') {
            profileFeedContainer.style.display='none';
            ctaFeedContainer.style.display = 'none';
            journeyFeedContainer.style.display = 'none';
            trainingFeedContainer.style.display = 'none';
            missionsFeedContainer.style.display = 'none';
            setCurrentTab('development');
        }
    }
    return (





            <IonContent>
                {/*<IonListHeader>*/}
                {/*    <IonText className="brandText">CONTENT</IonText>*/}
                {/*</IonListHeader>*/}

                <IonSegment value={currentTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)} >
                    <IonSegmentButton value="profile">
                        <IonLabel className="labelText">Profile</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="cta">
                        <IonLabel className="labelText">CTA’s </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="missions">
                        <IonLabel className="labelText">Missions</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="journey">
                        <IonLabel className="labelText">Journey</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="training">
                        <IonLabel className="labelText">Training</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="development">
                        <IonLabel className="labelText">Development</IonLabel>
                    </IonSegmentButton>
                </IonSegment>



                <IonContent id="profileFeed" scrollX scrollY>
                    <IonSearchbar value={searchUser} onIonChange={e => setSearchUser(e.detail.value!)} placeholder="Search by user name ... " debounce={0}></IonSearchbar>
                    <IonRow>
                        <IonText>There are {users.length} JFH Characters </IonText>
                    </IonRow>
                    {users
                    && users.map((castMember, idx)=> {
                        return (

                            <IonCard key={idx} className="child2">

                                <IonThumbnail style={{'height':'75%', 'width':'100%'}}>
                                   <IonImg id={'castMemberMenu'+idx+castMember.uid} src={castMember.photoURL ? downloadPictureContent(castMember.photoURL,castMember.uid, 'castMemberMenu'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                                                     alt={"Profile Image"} />
                                </IonThumbnail>
                                <IonGrid style={{paddingLeft:'0px'}}>

                                    <IonRow>
                                      <IonText style={{whiteSpace:'nowrap'}}>{castMember.characterName ? castMember.characterName : (castMember.heroName ? castMember.heroName :castMember.clientName)}</IonText>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol size="2">
                                            {castMember.profileVideo !== '' ?

                                                <a href={downloadContent(castMember.profileVideo)} target="_blank"><IonIcon icon={cloudDownload}/></a>


                                                : (castMember.photoURL ?

                                                    <a href={downloadContent(castMember.profileVideo)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                                    : '')}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCard>

                        );
                    })}
                </IonContent>
                <IonContent id="ctaFeed" style={{display: 'none'}}  scrollX scrollY>
                    { currentTab === 'cta' &&
                        <AdminCTAFeed />}
                </IonContent>
                <IonContent id="missionsFeed" style={{display: 'none'}}  scrollX scrollY>

                    { currentTab === 'missions' &&
                    <AdminMissionFeed />}
                </IonContent>
                <IonContent id="journeyFeed" style={{display: 'none'}}  scrollX scrollY>
                    { currentTab === 'journey' &&
                    <AdminJourneyFeed/>}
                </IonContent>
                <IonContent id="trainingFeed" style={{display: 'none'}}  scrollX scrollY>
                    { currentTab === 'training' &&
                    <AdminTrainingFeed/>}
                </IonContent>
                <IonContent id="developmentFeed" style={{display: 'none'}}  scrollX scrollY>
                    { currentTab === 'development' &&
                    <AdminDevFeed/>}
                </IonContent>
            </IonContent>
       

    );
}

export default authWrapper(ViewContent);

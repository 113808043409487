import React, {useEffect, useState } from 'react';
import {IonCard, IonGrid, IonIcon, IonImg, IonRow, IonSearchbar, IonThumbnail} from '@ionic/react';

import { firestore } from '../../firebaseConfig';

import {downloadContent, downloadPictureContent} from "../../util/avatarUtils";
import {cloudDownload, search} from "ionicons/icons";


interface AdminTrainingFeedProps {

}


const AdminTrainingFeed: React.FC<AdminTrainingFeedProps> = (props) => {

    const [trainingContentList, setTrainingContentList] = useState([]);
    const [searchTraining, setSearchTraining] = useState('');

    useEffect(() => {
        let queryTrainingFeed = searchTraining
        let stringBoundTraining = queryTrainingFeed.slice(0, queryTrainingFeed.length-1)
            + String.fromCharCode(search.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (queryTrainingFeed!='' && queryTrainingFeed.length >=1) {
            // console.log("query", query);
            const searchTrainingRef = firestore.collection('content').where('contentType', '==', 'training')
                .where('objectRefTitle','>=', queryTrainingFeed)
                .where('objectRefTitle','!=', '')
                .orderBy('objectRefTitle', 'asc')
                .orderBy('timeCreated', 'desc')

            searchTrainingRef.onSnapshot(snapshot => {
                setTrainingContentList(snapshot.docs.map(trainingDoc => ({...trainingDoc.data(), uid: trainingDoc.id })));

            });

        }
        else {

            let trainingRef =  firestore.collection('content').where('contentType', '==', 'training').orderBy("timeCreated", "desc")
                .onSnapshot(snapshot => {

                    let trainingDocList = [];

                    snapshot.forEach(trainingDoc =>
                        trainingDocList.push({...trainingDoc.data(), uid: trainingDoc.id}),
                    );

                    setTrainingContentList(trainingDocList);
                });


        }
    }, [searchTraining]);

    return (
        <>
            <IonSearchbar value={searchTraining} autoCapitalize="on" onIonChange={e => setSearchTraining(e.detail.value!)} placeholder="Search by prompt name ... " debounce={1000}></IonSearchbar>

            {trainingContentList.map((trainingItem, idxM) => {
                return (
                    <IonCard key={idxM} className="child2">
                        <IonThumbnail style={{'height':'70%', 'width':'100%'}}>
                            <IonImg id={'contentItemAdmin'+idxM+trainingItem.uid} src={trainingItem.posterUrl ? downloadPictureContent(trainingItem.posterUrl,trainingItem.uid, 'contentItemAdmin'+`${idxM}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    alt={"Content Image"}  />
                        </IonThumbnail>

                        <div>
                            <IonGrid>
                                <IonRow>
                                    {trainingItem.objectRefTitle}_{trainingItem.completedByHeroName}
                                </IonRow>
                                <IonRow>
                                    <a href={downloadContent(trainingItem.contentVideo)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCard>
                )
            })}
        </>
    );


}
export default AdminTrainingFeed;

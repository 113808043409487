import React, { useState }from 'react';
import { IonContent, IonPage,  IonRow, IonCol, IonGrid, IonThumbnail, IonImg,
    IonHeader, IonInput, IonLabel,  IonItemGroup, IonButton, IonText, IonFab,
    IonFabButton, IonRouterOutlet, IonRouterLink, IonToolbar, IonButtons, IonBackButton, IonIcon} from '@ionic/react';

import Header from '../components/Header';
import { withRouter } from 'react-router-dom';
import authWrapper from '../components/authWrapper'
import Footer from '../components/Footer';
import {toast} from "../toast";
import DirectionVideoContainer from "../components/DirectionVideoContainer";

interface ClientDeliveryProps {
    history: any;
}
const ClientDelivery: React.FC<ClientDeliveryProps> = (props) => {
    const [problemStatement, setProblemStatement] = useState("");
    const [description, setDescription] = useState("")


    const {user:{clientName, heroName, uid}} = props as any;

    console.log("user id in client delivery", uid);
    const onChange = (e: any) => {

        let clientDeliveryFile = e.target.files[0];
        let clientDeliveryFileType = clientDeliveryFile.type.split("/").pop();
        let clientDeliveryFileURL = URL.createObjectURL(clientDeliveryFile);

        let createdByName = clientName ? clientName : heroName;
        props.history.push({
            pathname: '/clientDeliveryReview',
            state: { clientDeliveryFile, clientDeliveryFileType, clientDeliveryFileURL, problemStatement, description, createdByName, uid }
        })
    }

    async function clickUpload(e) {
        document.getElementById('uploadVideo').click();
    }

    async function recordVideo() {
        document.getElementById('recorder').click();

    }

    return (
    <IonPage>
        <Header/>
        <IonContent className="app" id="mainSection" scrollX scrollY>


            <IonGrid id="preRecording">

                <IonRow><IonCol/></IonRow>
                <IonRow><IonCol/></IonRow>
                <DirectionVideoContainer assetName={"brand/explainers/missionCTA/how_to_create_a_mission_4K.mp4"}
                                         posterAssetName={"brand/explainers/missionCTA/how_to_create_a_mission.png"}/>
                <IonRow><IonCol/></IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel className="appTextMain"> Create a Mission to direct Heroes to Solve a Problem.</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow><IonCol/></IonRow>

                <IonRow>
                    <IonItemGroup className="app" style={{'paddingRight': '20px', 'paddingLeft': '20px'}}>
                        <IonLabel className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff', '--color':'#ffffff'}}>
                            Title of Mission
                        </IonLabel>
                        <IonInput
                            value={problemStatement}
                            autocapitalize="on"
                            style={{
                                'color': '#005494',
                                '--background': '#ffffff',
                                '--placeholder-color': '#005494',
                                '--placeholder-opacity': 0.75,
                                'align': 'center'
                            }}
                            type="text"
                            placeholder="Describe the problem you have"
                            maxlength={30}
                            onKeyUp={(e: any) => {if (e.target.value.toString().length==30) alert('You can use maximum 30 characters for the problem statement');}}
                            onIonChange={(e: any) => setProblemStatement(e.target.value)}
                        />
                    </IonItemGroup>
                </IonRow>

                <IonRow/>
                <IonRow/>
                <IonRow/>

                <IonRow>
                    <IonItemGroup className="app"
                                  style={{'paddingRight': '20px', 'paddingLeft': '20px', 'paddingTop': '20px'}}>
                        <IonLabel className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff','--color':'#ffffff'}}>
                            How can a hero help?
                        </IonLabel>
                        <IonInput
                            value={description}
                            autocapitalize="on"
                            style={{
                                '--background': '#ffffff',
                                '--color': '#005494',
                                '--placeholder-opacity': 0.75,
                                '--placeholder-color': '#005494',
                                'padding':'10px'
                            }}
                            type="text"
                            placeholder={"Describe how a hero can help"}
                            onIonChange={(e: any) => setDescription(e.target.value)}
                        >
                        </IonInput>
                    </IonItemGroup>
                </IonRow>

                <IonRow><IonCol/></IonRow>
                <IonRow><IonCol/></IonRow>
                <IonRow><IonCol/></IonRow>
                <IonRow><IonCol/></IonRow>

                <IonRow>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <IonText className="appTextMain" style={{'paddingBottom': '30px', 'color':'#ffffff', '--color':'#ffffff'}}>
                            Explain the problem and a potential solution in a single, concise shot.
                        </IonText>
                        <div style={{height: 106,width: 106}}>
                            <IonFab style={{
                                borderStyle: 'solid',
                                borderRadius: '50%',
                                borderWidth: 3,
                                borderColor: 'red'
                            }}>
                                <IonFabButton onClick={() => recordVideo()} style={{
                                    '--background': '#ffffff',
                                    minHeight: 100,
                                    width: 100,
                                    '--border-color': '#FF0000'
                                }}>
                                    <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                                        ACTION
                                    </IonLabel>
                                </IonFabButton>
                                <input type="file" accept="video/*" capture="camera"
                                       id="recorder"
                                       className="uploadVideoBtn"
                                       onChange={onChange}
                                />
                            </IonFab>
                        </div>
                    </div>
                </IonRow>

                <IonRow/>
                <IonRow className="ion-justify-content-end">
                    <IonCol>
                        <IonButton id="uploadVideoImgBtn"
                                   className="noShadowBtnBox"
                                   style={{'background': 'none', 'float':'right'}}
                                      onClick={(e) => {
                                          clickUpload(e)
                                      }}>
                            <IonText style={{'color':'#ffffff'}} className="italic"> Upload Video </IonText>
                            <input type="file" accept="video/*" className="uploadVideoBtn"
                                   id="uploadVideo"
                                   onChange={onChange}
                            />
                        </IonButton>
                    </IonCol>
                </IonRow>


            </IonGrid>

        </IonContent>
        <Footer />
    </IonPage>
    )
};


export default authWrapper(withRouter(ClientDelivery));

import React, { useState } from 'react';
import { IonInput, IonRow,  IonContent, IonButton, IonPage, IonRouterLink, IonCol, IonCard, IonCardContent, IonGrid, IonCardHeader, IonThumbnail, IonImg, IonText, IonDatetime, IonFooter, IonLabel, IonModal } from '@ionic/react';

import './Login.css';
import { registerUser, existingClient} from '../firebaseConfig';
import { toast } from '../toast';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase';
import { getDefaultDOB } from '../util/dateUtils';
import { sendVerificationEmail } from '../util/email';
import {sendCompleteProfile} from "../util/sendEmail";



interface RegisterClientProps {
  role: string;
  profileVideoUrl: string;
  client: string;

}

const backgroundVideoUrl = "https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFHAppBGSignupVideo.mp4?alt=media&token=80dc4d83-fd1c-46c0-8b15-206ea03a0d9a";


const RegisterClient: React.FC<RegisterClientProps> = ({role, profileVideoUrl, client}) => {

    const location = useLocation();
 
    const [email, setEmail] = useState('');
    //const [username, setUsername] = useState('');
    const [clientNameInput, setClientNameInput] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [birthday, setBirthday] = useState('');
    const [approved, setApproved] = useState(false)
    const [error, setError] = useState(null);
    const [showStopClient, setShowStopClient] = useState(false);

    const [showAlready, setShowAlready] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    async function validateClientAttributes() {
        if (password && cpassword && password !== cpassword) {
            return toast('Passwords do not match')
        }

        if (!location.state['clientName'] && clientNameInput.trim() === '') {
            return toast('Please enter your client name')
        }

        if (!location.state['clientName']) {
            if (clientNameInput.trim() !== '') {
                let clientExist = await  existingClient(clientNameInput);

                if (clientExist > 0) {
                    return setShowAlready(true);
                    //return toast('A client with that name already exists. Use a different client name');
                }
            }
        }

        if (password.trim() === '' || email.trim() === '') {
            return toast('Please enter your email address and password')
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
        {
            toast('Please use a valid email address.');
        }

        if (birthday === '') {
            return toast('Please fill in your birthday. Minimum age to join JFH is 13.');
        }

        return true;
    }

    async function registerClient() {


        setEmail(email);
        setClientNameInput(clientNameInput);
        setBirthday(birthday);

        let registerClientProfileData = {
            email:email,
            password:password,
            heroId:'',
            clientId:location.state['client'],
            role:location.state['role'],
            profileVideo:location.state['profileVideoUrl']?location.state['profileVideoUrl']:'',
            heroName:location.state['heroName']?location.state['heroName']:'',
            heroName_nocase:location.state['heroName']?location.state['heroName'].toLowerCase():'',
            clientName:location.state['clientName']?location.state['clientName']:clientNameInput,
            clientName_nocase:location.state['clientName']?location.state['clientName'].toLowerCase():clientNameInput.toLowerCase(),
            heroNumber: -1,
            clientNumber: location.state['idx']?location.state['idx']:-1,
            photoURL: location.state['photoURL']?location.state['photoURL']:'',
            birthday: birthday,
            complete: true,
            dateJoined: firebase.firestore.Timestamp.fromDate(new Date()),
            characterName:location.state['clientName']?location.state['clientName']:clientNameInput,
            characterName_nocase:location.state['clientName']?location.state['clientName'].toLowerCase():clientNameInput.toLowerCase(),
        }

        // registerUser(email, username, password, location.state['client'], location.state['role'], location.state['profileVideoUrl'])
        registerUser(registerClientProfileData)
            .then(newUser=>{
          console.log("newUSER", newUser)
                sendVerificationEmail(email, registerClientProfileData.characterName);

                // debugger;
                window.location.href = "/welcomeWurld";
                // window.location.href = "/premiumMissions";

            }).catch(err=>{
          console.log("error registering new user")
          console.dir(err)
          setError(err)
          if (err.code === "auth/network-request-failed")
              toast("There's been an network error. Please check your connection.");
          else if (err.code === "auth/email-already-in-use")
              toast("The email address is already in use by another account.");
          else
              toast("There's been an error registering a new user.", err.message);

      });
          
    }

    async function cancelRegisterClient() {
        setShowStopClient(false);

        setEmail(email);
        setClientNameInput(clientNameInput);

        let registerClientProfileData = {
            email:email,
            password:password,
            heroId:'',
            clientId:location.state['client'],
            role:location.state['role'],
            profileVideo:location.state['profileVideoUrl']?location.state['profileVideoUrl']:'',
            heroName:location.state['heroName']?location.state['heroName']:'',
            heroName_nocase:location.state['heroName']?location.state['heroName'].toLowerCase():'',
            clientName:location.state['clientName']?location.state['clientName']:clientNameInput,
            clientName_nocase:location.state['clientName']?location.state['clientName'].toLowerCase():clientNameInput.toLowerCase(),
            heroNumber: -1,
            clientNumber: location.state['idx']?location.state['idx']:-1,
            photoURL: location.state['photoURL']?location.state['photoURL']:'',
            birthday: birthday,
            complete:false,
            dateJoined: firebase.firestore.Timestamp.fromDate(new Date()),
            characterName: location.state['clientName'] ? location.state['clientName'] : clientNameInput,
            characterName_nocase: location.state['clientName'] ? location.state['clientName'].toLowerCase() : clientNameInput.toLowerCase(),
        }

        // registerUser(email, username, password, location.state['client'], location.state['role'], location.state['profileVideoUrl'])
        registerUser(registerClientProfileData)
            .then(newUser=>{
                //toast('Sorry to see you go. We hope you will join the JFH Cinematic Universe soon.');
                //console.log("new user email", newUser.email);
                sendCompleteProfile(registerClientProfileData['email'], registerClientProfileData['characterName']);
                setShowLogin(true);
                // console.log("newUSER", newUser)
                // // debugger;
                // window.location.href = "/premiumMissions";
            }).catch(err=>{
            console.log("error registering new user")
            console.dir(err)
            setError(err)
            if (err.code === "auth/network-request-failed")
                toast("There's been an network error. Please check your connection.");
            else if (err.code === "auth/email-already-in-use")
                toast("The email address is already in use by another account.");
            else
                toast("There's been an error registering a new user.", err.message);

        });

    }

    let confirmAndRegisterClient = () => {
        let validationAnswer = validateClientAttributes().then(answerClientValidation => {
            console.log("answer", answerClientValidation);
            if (answerClientValidation !== undefined && answerClientValidation) {
                setShowStopClient(true);
            }
        });
    }

  return (
    <IonPage>
      <IonContent className="loginPage ion-text-center">
          {/*<div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>*/}

          <video autoPlay loop muted className="loginPage__video"  controls={false}>
          <source src={backgroundVideoUrl} type="video/mp4" />
        </video>
          <IonModal isOpen={showStopClient}
                    cssClass='stop-modal'
                    onDidDismiss={() => setShowStopClient(false)}>

              <IonContent className="stop-modal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>


                  <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'center'}}>
                      <IonThumbnail>
                          <IonImg src={'/assets/img/stopSign.png'}></IonImg>
                      </IonThumbnail>
                  </div>

                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                          To build a Cinematic Universe takes COMMITMENT. <span className="bolder">We’re going to make movies and shows together for years to come, all because of the stories we tell together on this app.</span> When you join JFH, other characters build on your story. Contributing to a crowdsourced cinematic universe is like placing a brick in a bridge. We all rely on each other’s contributions, especially as our universe grows. Therefore, <span className="italic">once you create a profile, you won’t be able to delete it </span>(you’ll be able to make changes, though). Your contributions to the story through your character and content will live on whether or not you continue to create new content in JFH. If your content is ever used in a production that makes money, you will share in the profits. This is not your typical social network. If you’re ready to be a part of a cinematic universe, then we look forward to seeing you inside.  </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={cancelRegisterClient} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Cancel
                          </IonLabel>
                      </IonButton>
                      <IonButton onClick={registerClient} style={{'--background':'none'}} className="center">
                          <IonLabel className="italic bolder">

                              Agree & Finish

                          </IonLabel>
                      </IonButton>
                  </IonRow>
              </IonFooter>
          </IonModal>
          <IonModal isOpen={showAlready}
                    cssClass='duplicateModal'
                    onDidDismiss={() => setShowAlready(false)}>

              <IonContent className="duplicateModal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>



                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                          A character with that name already exists. Use a different hero name. If this is your account Login or access from your email later.
                      </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={() => window.location.href='/login'} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Login
                          </IonLabel>
                      </IonButton>

                  </IonRow>
              </IonFooter>
          </IonModal>

          <IonModal isOpen={showLogin}
                    cssClass='duplicateModal'
                    onDidDismiss={() => setShowLogin(false)}>

              <IonContent className="duplicateModal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>



                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                          Login or access from your email later.
                      </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={() => window.location.href='/login'} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Login
                          </IonLabel>
                      </IonButton>

                  </IonRow>
              </IonFooter>
          </IonModal>

      <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <IonCard className="center">
         <IonCardHeader>
            <IonRow className="ion-justify-content-center">
                <div style={{marginBottom: 0}}>
                    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFH_LOGIN_PAGE.png?alt=media"} className="login-box-image" style={{marginBottom: 0, marginTop: 10}}></IonImg>
                </div>
                <div style={{marginBottom: 0}}>
                    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Ftagline_login.png?alt=media"} className="login-box-image" style={{marginBottom: 0, marginTop: 10}}></IonImg>
                </div>
            </IonRow>
         </IonCardHeader>
         <IonCardContent className="login-box">

             {location.state && !location.state['clientName'] && <IonInput autocapitalize="on" className="appText" color="light" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Client Name" onIonChange={(e: any) => setClientNameInput (e.target
            .value)}></IonInput>}

         <IonInput  style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Email Address" onIonChange={(e: any) => setEmail (e.target
            .value)}></IonInput>
         <IonInput  style={{'background':'#ffffff', 'color':'#005494'}} type="password" placeholder="Password" onIonChange={(e: any) => setPassword (e.target
            .value)}></IonInput>        
          <IonInput  style={{'background':'#ffffff', 'color':'#005494'}} type="password" placeholder="Confirm Password" onIonChange={(e: any) => setCPassword (e.target
            .value)}></IonInput>
          <IonDatetime style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Your REAL Birthday" displayFormat="MM/DD/YYYY" max={getDefaultDOB()} value={birthday} onIonChange={e => setBirthday(e.detail.value!)}></IonDatetime>
         </IonCardContent>
         <IonGrid>
         <IonRow>       
             <IonCol><IonButton onClick={confirmAndRegisterClient} className="appBtn">Register</IonButton></IonCol>
         </IonRow>
         <IonRow className="ion-justify-content-center">
           <IonText> By clicking Register you are accepting the <IonRouterLink href="/pages/termsPage" color="light" style={{'textDecoration': 'underline'}}> Terms of Use</IonRouterLink> and <IonRouterLink href="/privacyPolicy" color="light" style={{'textDecoration': 'underline'}}>  Privacy Policy </IonRouterLink></IonText>
         </IonRow>
         <IonRow className="ion-justify-content-center">     
             <IonText className="appText"> Already Have An Account? <IonRouterLink href="/login" color="secondary"> Login</IonRouterLink></IonText> 
         </IonRow>
        
         </IonGrid>

            <IonFooter>

                <IonGrid>
                    <IonRow className="footerText">
                        <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                            <div>
                                <IonText >Powered by ReelwUrld™. Patent pending. <br/>  All Rights reserved. <br/></IonText>
                            </div>
                        </div>
                    </IonRow>

                    <IonRow className="appText">
                        <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                            {/*&#169; Creative Impulse® 2020. All Rights reserved.*/}
                        </div>
                    </IonRow>
                </IonGrid>

            </IonFooter>
         </IonCard>
      </div>
      </IonContent>
    </IonPage>
    
  );
};

export default RegisterClient;

import React, { useState, useRef } from 'react';
import {IonIcon, IonRow} from "@ionic/react";
import {playOutline} from "ionicons/icons";
import { storageRef } from '../firebaseConfig';

const MissionsExplainerVideo: React.FC = () => {

    const [contentUrl, setContentUrl] = useState('');
    const [posterContentUrl, setPosterContentUrl] = useState('');

    const videoRef = useRef(null);

    let assetName="brand/Missions_Explainer_Heroes.mp4";
    let posterAssetName="Missions/thumbnails/missions_page_explainer.jpg";

    storageRef.child(assetName).getDownloadURL().then(function(url) {
        setContentUrl(url);
        let img = document.getElementById('missionVideo');
        img.setAttribute("src", url);
    }).catch(function(error) {
        // Handle any errors
    });

    if (posterAssetName && posterAssetName!=='') {
        storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
            setPosterContentUrl(posterUrl);
            let img = document.getElementById('missionVideo');
            img.setAttribute("poster", posterUrl);
        }).catch(function(error) {
            // Handle any errors
        });
    }


    function onVideoClick(e) {
        let video  = document.getElementById('missionVideoExplainerDiv') as HTMLVideoElement;
        video.play().then(() => {console.log('video played')});

    }
    return (

        <div className="video-wrapper" >
            <div className="overlay"
                 onClick={onVideoClick}
            >

                <div style={{margin: 5}} className="left deliveryModuleTitle bolder">

                    <IonRow>
                        <IonIcon style={{padding: 2}} item-end icon={playOutline}/>

                    </IonRow>
                </div>

            </div>

            <video id="missionVideoExplainerDiv" poster={posterContentUrl} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>
                <source src={contentUrl} type="video/mp4" />
            </video>

            <div className="playpause"></div>

        </div>
    );
};

export default MissionsExplainerVideo;


import React from 'react';
import {IonContent, IonText, IonPage, IonRouterLink, IonLabel, IonRow} from '@ionic/react';
import Header from '../../components/Header';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <IonPage>
       <Header noChat={true}/>
       <IonContent className="ion-padding ion-text-justify" scrollEvents={true}
                   onIonScrollStart={() => {
                   }}
                   onIonScroll={() => {
                   }}
                   onIonScrollEnd={() => {
                   }} scrollX scrollY>
           <div style={{
               height: '100%',
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-between",
               padding: 10,
           }}>

               <IonRow className="ion-align-items-center">
                   <IonText className="bolder"> Privacy Policy </IonText>
               </IonRow>

               <IonRow>
                   <div style={{flex: 1, display: 'flex'}}>
                       <IonText className="bolder">
                           Your privacy is important to us. The Company’s Privacy Policy is hereby incorporated into these <IonRouterLink href="pages/termsPage"><IonLabel class="bolder italic">Terms</IonLabel></IonRouterLink> by reference. Please read the Privacy Policy carefully for information relating to the Company’s collection, use, and disclosure of your personal information. By agreeing to these <IonRouterLink href="/pages/termsPage"> <IonLabel class="bolder italic">Terms</IonLabel> </IonRouterLink>, you are agreeing to the Company’s Privacy Policy.
                       </IonText>
                   </div>
               </IonRow>

               <IonRow>
                   <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                       <IonText> <IonRouterLink href="/pages/termsPage"> <IonLabel className="bolder italic">Read Terms here.</IonLabel></IonRouterLink></IonText>
                   </div>
               </IonRow>

           </div>
       </IonContent>
      {/*<Footer />*/}
    </IonPage>
  );
};

export default PrivacyPolicy;

import React, { useState } from 'react';
import { storageRef } from '../../firebaseConfig';
import {IonIcon, IonRow} from "@ionic/react";
import {playOutline} from "ionicons/icons";

const MissionVideoExampleContainer: React.FC = () => {

const [contentUrl, setContentUrl] = useState('');
const [posterContentUrl, setPosterContentUrl] = useState('');

let assetName="app/walkthrough/Mission-Return-a-Stolen-Phone-CTA-v2.mp4";
let posterAssetName="app/walkthrough/returnastolenphone.jpg";

storageRef.child(assetName).getDownloadURL().then(function(url) {
    setContentUrl(url);
    let img = document.getElementById('missionVideo');
    img.setAttribute("src", url);
    }).catch(function(error) {
    // Handle any errors
    });

    storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
        setPosterContentUrl(posterUrl);
        let img = document.getElementById('missionVideo');
        img.setAttribute("poster", posterUrl);
    }).catch(function(error) {
        // Handle any errors
    });

    function onVideoClick(e) {
        let video  = document.getElementById('missionVideo') as HTMLVideoElement;
        video.play().then(() => {console.log('video played')});

    }
  return (

      <div className="video-wrapper" >
          <div className="overlay"
               onClick={onVideoClick}
          >

              <div style={{margin: 5}} className="left deliveryModuleTitle bolder">

                  <IonRow>
                      <IonIcon style={{padding: 2}} item-end icon={playOutline}/>

                  </IonRow>
              </div>

          </div>

          <video id="missionVideo" poster={posterContentUrl} controlsList="nodownload">
              <source src={contentUrl} type="video/mp4" />
          </video>

          <div className="playpause"></div>

      </div>
  );
};

export default MissionVideoExampleContainer;


import React, { useState, useEffect } from 'react';

import Footer from '../../components/Footer';

import { firestore} from '../../firebaseConfig';
import { IonPage, IonRow, IonImg, IonContent, IonLabel, IonText, IonAvatar, IonButton, IonList, IonItem, IonRouterLink, IonListHeader, IonFabButton, IonSearchbar, IonIcon } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import {ally} from "./allyrival";
import Header from "../../components/Header";
import { personAddOutline } from 'ionicons/icons';
import { downloadPictureContent } from '../../util/avatarUtils';
import { createRelationship, removeAllyRelationship } from './relationships';

interface AlliesPageProps extends RouteComponentProps<{
    userId: string,
   }> {}


async function getAllyRelationshipArray(userId) {
    let alliesOut = firestore.collection('relationshipRequests').where('senderId', '==', userId).get();
    let alliesIn = firestore.collection('relationshipRequests').where('rivalId', '==', userId).get();

    const [alliesOutSnapshot, alliesInSnapshot] = await Promise.all([
        alliesOut,
        alliesIn
    ]);


    const alliesOutArray = alliesOutSnapshot.docs;
    const alliesInArray = alliesInSnapshot.docs;

    const alliesArray = alliesOutArray.concat(alliesInArray);

    return alliesArray;
}
const Allies: React.FC<AlliesPageProps> = ({match}) => {

    const [alliesList, setAlliesList] = useState<any>(['']);
    const [rivalsList, setRivalsList] = useState<any>(['']);
    const [suggestionList, setSuggestionList] = useState<any>(['']);
    const [senderInfo, setSenderInfo] = useState({});
    const [searchText, setSearchText] = useState('');

    const {userId} = match.params;

    useEffect(() => {


        let userDocRef = firestore.collection('users').doc(userId);

        userDocRef.get().then((doc) => {
            const rivalsListRef = {id: doc.id, ...doc.data()};

            //console.log("rivals:", rivalsListRef['rivals']);
            //rivalsList = rivalsListRef['rivals'];
            setSenderInfo(doc.data());

            getAllyRelationshipArray(userId).then(snapshotRelationships => {
                let rivalsList = [];
                let alliesList = [];
                snapshotRelationships.forEach(snapshotRelationship => {
                    if (snapshotRelationship.data()['relationshipType'] === 'rival') {
                        rivalsList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id})
                    }
                    else if (snapshotRelationship.data()['relationshipType'] === 'ally') {
                        alliesList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id});
                    }
                    // if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {


                });

                if (searchText !== '') {
                    if (rivalsList !== undefined) {
                        rivalsList = rivalsList.filter(rivalItem => rivalItem.rivalName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                    }
                }
                setRivalsList(rivalsList);
                setAlliesList(alliesList);


                let rivalSuggestionRef = firestore.collection('users').where("complete", "==", true);
                let query = searchText.toLowerCase()
                let stringBound = query.slice(0, query.length-1)
                    + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)

                if (query!='' && query.length >=1) {
                    // console.log("suggestions", suggestionsRef);
                    rivalSuggestionRef.where('characterName_nocase','>=', query)
                        .where('characterName_nocase','<', stringBound)
                        .where('characterName_nocase','!=', '')

                }
                rivalSuggestionRef = rivalSuggestionRef.orderBy("characterName_nocase", "asc");

                rivalSuggestionRef.onSnapshot(snapshot => {
                    let suggestionList = [];

                    snapshot.forEach(suggestionRivalDoc => {
                        if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {
                            suggestionList.push({...suggestionRivalDoc.data(), uid: suggestionRivalDoc.id})
                        }
                    });
                    suggestionList = suggestionList.filter(item => item.uid !== userId);

                    if (rivalsList && rivalsList.length >= 1) {
                        for (let i = 0; i < rivalsList.length; i++) {
                            let rivalId = rivalsList[i]['rivalId'];
                            let senderId = rivalsList[i]['senderId'];
                            console.log("rival id", rivalId);
                            console.log("sender", rivalId);
                            suggestionList = suggestionList.filter(item => item.uid !== rivalId);
                            suggestionList = suggestionList.filter(item => item.uid !== senderId);
                            //console.log(suggestionList);
                        }
                    }

                    if (alliesList && alliesList.length >= 1) {
                        for (let i = 0; i < alliesList.length; i++) {
                            let allyId = alliesList[i]['rivalId'];
                            let senderId = alliesList[i]['senderId'];
                            console.log("ally ", allyId);
                            console.log("sender ", allyId);
                            suggestionList = suggestionList.filter(item => item.uid !== allyId);
                            suggestionList = suggestionList.filter(item => item.uid !== senderId);
                            //console.log(suggestionList);
                        }
                    }


                    if (searchText !== '') {
                        suggestionList = suggestionList.filter(item => item.characterName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                    }
                    setSuggestionList(suggestionList);
                });
            });
        });
     }, [userId, searchText]);



     function followAsAlly(allyId, allyHeroId, allyName, allyPhotoUrl) {
         console.log("Ally info:", allyId + allyName + +"photo" + allyPhotoUrl + '-' +"userId:" + userId);


         let allyRelationshipRequest = {
             senderId: userId,
             senderName: senderInfo['characterName'],
             senderPhotoURL: senderInfo['photoURL'],
             rivalId: allyId,
             rivalPhotoUrl: allyPhotoUrl,
             rivalName: allyName,
             status: 'approved',
             relationshipType: 'ally',
         }

         createRelationship(allyRelationshipRequest);

    }


    function removeAlly(allyRelationship) {
        //console.log("Ally info:", allyId + allyName + allyPhotoUrl+"userId:" + userId);
        //notification('ello'+ allyRelationship.uid);
        removeAllyRelationship(allyRelationship.uid);
     }

    function viewProfile(profileId, relationship)  {
        if (profileId!=undefined) {
            window.location.href=`/viewAssociation/${profileId}`;
        }
    }

            return (
                <IonPage>
                    <Header fromPage={'profile'}/>

                    <IonContent className="ion-padding-bottom" scrollX scrollY>

                        { (alliesList === undefined || alliesList?.length < 1) &&
                        <IonFabButton disabled style={{
                            '--background': '#ffffff', "margin": "20px auto 20px auto", 'minHeight': '111px', 'width':'111px'
                        }}>
                            <IonIcon icon={personAddOutline} style={{'color': '#005494'}}/>
                        </IonFabButton>
                        }
                        { (alliesList === undefined || alliesList?.length < 1) &&
                        <IonRow className="ion-justify-content-center">

                            <IonLabel className="sectionTitle"
                                      style={{"margin": "20px 20px 20px 20px", 'color': '#005494'}}>
                                <IonText className="ion-text-center"> Your Allies </IonText>
                            </IonLabel>
                            <IonLabel
                                      style={{'margin': '10px 10px 10px 10px', 'fontSize':'10pt','color': '#005494'}}>
                                <IonText className="ion-text-center"> Once you ally other heroes, you'll see them here. </IonText>
                            </IonLabel>
                        </IonRow>}
                        <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} debounce={0}></IonSearchbar>

                        {alliesList && alliesList.length >= 1 &&
                        <IonList>
                            <IonListHeader>
                                <IonLabel className="sectionTitle"
                                          style={{'align': 'center', 'color': '#005494'}}>Allies
                                </IonLabel>
                            </IonListHeader>
                            {alliesList && alliesList.map((ally, index) => {
                                return (
                                    <IonItem key={index} lines="none">
                                        <IonAvatar slot="start">
                                            {ally?.senderId === userId ?
                                                <IonImg id={'allyProfile'+ally.rivalId} src={ally.rivalPhotoUrl ? downloadPictureContent(ally.rivalPhotoUrl,ally.rivalId, 'allyProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                               onClick={() => viewProfile(ally.rivalId, 'ally')}/>
                                               :<IonImg id={'allyProfile'+ally.senderId} src={ally.senderPhotoURL ? downloadPictureContent(ally.senderPhotoURL,ally.senderId, 'allyProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                                         onClick={() => viewProfile(ally.senderId, 'ally')}/>
                                            }

                                        </IonAvatar>

                                        <IonLabel>
                                                {ally?.senderId === userId
                                                    ? <IonRouterLink href={`/viewAssociation/${ally?.rivalId}/${'ally'}`}>{ally?.rivalName}</IonRouterLink>
                                                    : <IonRouterLink href={`/viewAssociation/${ally?.senderId}/${'ally'}`}>{ally?.senderName}</IonRouterLink>}
                                                {/*{ally?.rivalName} | {ally?.senderName}| {ally?.rivalId} |{ally?.senderId}| {userId}*/}
                                        </IonLabel>
                                        <IonButton className="alliesBtn" onClick={() => removeAlly(ally)} > Remove  </IonButton>
                                        <div style={{
                                            marginLeft: -17,
                                            marginTop: 0,
                                            marginRight: 6,
                                            width: 27, height: 42,
                                            transform: "skew(20deg)",
                                            backgroundColor: 'white',
                                            borderWidth: 2, borderStyle: 'solid', borderColor: '#005494',
                                            boxShadow: "0px 4px 4px -4px dimgray",
                                        }}/>

                                    </IonItem>
                                );
                            })}
                        </IonList>
                        }
                        <IonRow>
                            <IonLabel className="sectionTitle"
                                      style={{'paddingLeft': '20px', 'color': '#005494'}}>
                                Suggestions
                            </IonLabel>
                        </IonRow>
                        <IonList>
                        {suggestionList && suggestionList.map((suggestion, index) => {
                            return (

                               <IonItem key={index} lines="none">

                                  <IonAvatar slot="start">
                                      <IonImg id={'allySuggestionProfile'+suggestion.uid} src={suggestion.photoURL ? downloadPictureContent(suggestion.photoURL,suggestion.uid, 'allySuggestionProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                              onClick={() => viewProfile(suggestion?.uid, '')}/>
                                  </IonAvatar>

                                   <IonLabel>
                                   <IonRouterLink href={`/viewAssociation/${suggestion?.uid}`}>
                                       {suggestion?.characterName !== '' ? suggestion?.characterName: (suggestion?.heroName ? suggestion?.heroName : suggestion?.clientName)}
                                   </IonRouterLink>
                                   </IonLabel>
                                  <IonButton className="alliesBtn" onClick={() => followAsAlly(suggestion.uid, suggestion.heroId, suggestion.characterName ? suggestion.characterName : suggestion.displayName, suggestion.photoURL !== undefined ? suggestion.photoURL : ' ') }> Ally  </IonButton>
                                  <div style={{
                                        marginLeft: -17,
                                        marginTop: 0,
                                        marginRight: 6,
                                        width: 25, height: 41,
                                        transform: "skew(20deg)",
                                        backgroundColor: 'white',
                                        borderWidth: 2, borderStyle: 'solid', borderColor: '#005494',
                                        boxShadow: "0px 4px 4px -4px dimgray",
                                    }}/>

                               </IonItem>
                            );
                        })}
                        </IonList>
                    </IonContent>
                    <Footer/>
                </IonPage>
            )

    }

 export default Allies;

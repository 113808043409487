import React from 'react';

import './Challenges.css';

import { firestore } from '../../firebaseConfig';
import { Challenge } from '../../models/Challenge';
import authWrapper from '../../components/authWrapper';
import ChallengeListItem from "./ChallengeListItem";

interface UGCDevelopmentListPageState {
    loading: boolean;
    ugcDevelopmentList: Challenge[];
    user: any;
}
class UGCDevelopmentListPage extends React.Component<{}, UGCDevelopmentListPageState>  {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ugcDevelopmentList: [],
            user: props.user,

        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        firestore.collection('challenges').where("type", "==", 'development').where("ugc", "==", true).orderBy('index','asc')
            .onSnapshot(snapshot => {
                let ugcDevelopmentList = [];

                snapshot.forEach(doc =>
                    ugcDevelopmentList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    ugcDevelopmentList,
                    loading: false,
                });
            });
    }



    render() {
        const { ugcDevelopmentList, loading, user} = this.state;

        const hide  = true;

        return (

            <>

                {ugcDevelopmentList.map((training, index) => {
                    return (
                        <ChallengeListItem key={index} challenge={training} index={index} showButtons={true} user={user}/>

                    );
                })}


            </>

        );
    };
}

export default authWrapper(UGCDevelopmentListPage);

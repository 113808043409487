import React  from 'react';
import { IonRow,  IonLabel, IonAvatar, IonImg} from '@ionic/react';
import {downloadPictureContent, getPictureAddress, viewProfileAssociation} from "../util/avatarUtils";

interface UserAvatarProps {
    userPhotoURL: string;
    userId: string;
    userHeroName: string;
    userRole: string;
    showMoreInfo: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {

    return (
        <>
        <IonRow className="ion-justify-content-center">
            <IonAvatar>
                <IonImg id={'avatar'+props.userId} src={props.userPhotoURL ? getPictureAddress(props.userPhotoURL,props.userId, 'avatar') : "https://www.gravatar.com/avatar?d=mm"}
                        onClick={() => viewProfileAssociation(props.userId)}/>
            </IonAvatar>
        </IonRow>
            {props.showMoreInfo &&
            <IonRow className="ion-justify-content-center">
                <IonLabel onClick={() => viewProfileAssociation(props.userId)}>{props.userHeroName} </IonLabel>
            </IonRow>
               }
            {props.showMoreInfo &&
            <IonRow className="ion-justify-content-center">
                <IonLabel>{props.userRole?.toUpperCase()}</IonLabel>
            </IonRow>}
        </>
    );
};

export default UserAvatar;


import React, { useState } from 'react';
import { storageRef } from '../../firebaseConfig';
import {IonAvatar, IonIcon, IonRow} from "@ionic/react";
import {playOutline} from "ionicons/icons";



const DevelopmentVideoExampleContainer: React.FC = () => {

    const [contentUrl, setContentUrl] = useState('');
    const [posterContentUrl, setPosterContentUrl] = useState('');
    let assetName="app/walkthrough/Development-Behind_the_Scenes_CTA.mp4";
    let posterAssetName="app/walkthrough/development_cover-BTS)JFH_EP02.jpg";

    storageRef.child(assetName).getDownloadURL().then(function(url) {
        setContentUrl(url);
        let img = document.getElementById('developmentVideo');
        img.setAttribute("src", url);
    }).catch(function(error) {
        // Handle any errors
    });

    storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
        setPosterContentUrl(posterUrl);
        let img = document.getElementById('developmentVideo');
        img.setAttribute("poster", posterUrl);
    }).catch(function(error) {
        // Handle any errors
    });

    function onVideoClick(e) {
        let videoEl = document.getElementById('developmentVideo') as HTMLVideoElement;
        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    }
    return (

        <div className="video-wrapper">

            <div className="overlay"
                 onClick={onVideoClick}
            >
                <div style={{margin: 5}} className="left deliveryModuleTitle bolder">

                    <IonRow>
                        <IonIcon style={{padding: 2}} item-end icon={playOutline}/>
                    </IonRow>
                </div>

            </div>
            <video id="developmentVideo" poster={posterContentUrl} controlsList="nodownload">
                <source src={contentUrl} type="video/mp4" />
            </video>


        </div>
    );
};

export default DevelopmentVideoExampleContainer;


import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonText,
    IonHeader,
    IonRow,
    IonCol,
    IonLabel,
    IonFabButton,
    IonIcon,
    IonModal,
    IonItem,
    IonCard,
    IonImg,
    IonFooter,
    IonThumbnail,
    IonGrid
} from '@ionic/react';

import {personAddOutline, play} from "ionicons/icons";
import UserAvatar from "./UserAvatar";
import {firestore} from "../firebaseConfig";
import {toast} from "../toast";
import { downloadPictureContent, viewAssociation, viewProfileAssociation } from '../util/avatarUtils';


interface CompletedByGridProps {
    ctaObjectId: string;
    fromPage: string;
    deliveryModule: any;
    objectType: string;
    showSection: boolean;
    loggedInUser: any;
}
const CompletedByGrid: React.FC<CompletedByGridProps> = (props) => {


    const [completedByUserList, setCompletedByUserList] = useState<any>([{}]);

    useEffect( () => {
        let isSubscribed = true;

        let query;

        //console.log("proops object type", props);
        if (props.deliveryModule.contentType !== 'mission') {
            query = firestore.collection('content').where('publicContent','==',true).where('objectRefId', '==', props.deliveryModule.objectRefId).orderBy("timeCreated", "desc");
        }
        else {
            query = firestore.collection('content').where('publicContent','==',true).where('objectRefId', '==', props.ctaObjectId).orderBy("timeCreated", "desc");
        }


        query.get()
            .then(snapshot => {
                let contentList = [];

                snapshot.forEach(doc =>
                    contentList.push({ ...doc.data(), uid: doc.id }),
                );

                console.log("contentList", contentList);
                setCompletedByUserList(contentList);
            }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
        return () => {isSubscribed = false}
    }, [props.ctaObjectId]);

    function playContent(contentItem)  {
       // toast("content item content url"+ contentItem.contentVideo);
    }
    return (


            <IonContent
                        scrollEvents={true}
                        onIonScrollStart={() => {
                        }}
                        onIonScroll={() => {
                        }}
                        onIonScrollEnd={() => {
                        }} scrollX scrollY style={props.showSection ? {'display':'block'}: {'display':'none'}}>
                <IonRow>
                    <IonLabel className="sectionTitle"
                              style={{'paddingLeft': '20px', 'paddingTop':'10px', 'color': '#005494'}}>
                        <IonText> {props.objectType.toUpperCase()} was completed by</IonText>
                    </IonLabel>
                </IonRow>
                { (completedByUserList === undefined || completedByUserList.empty ) &&
                <IonFabButton style={{
                    '--background': '#ffffff', "margin": "20px auto 20px auto", 'minHeight': '111px', 'width':'111px'
                }}>
                    <IonIcon icon={personAddOutline} style={{'color': '#005494'}}/>
                </IonFabButton>
                }
                { (completedByUserList === undefined || completedByUserList.empty) &&
                <IonRow style={{'textAlign':'center'}}>

                    <IonLabel className="sectionTitle"
                              style={{"margin": "20px auto 20px auto", 'color': '#005494'}}>
                        <IonText>
                            This {props.deliveryModule.contentType} was not completed by anyone yet. <br/>Be the first to complete it by pressing +
                        </IonText>
                    </IonLabel>

                </IonRow>}
                {completedByUserList && !completedByUserList.empty
                && completedByUserList.map((completedItem, idx)=> {
                    return (
                        <IonCard key={idx} className="child4" style={completedItem.brandSelected === true ? {'border': '2px solid #005494'
                           }:{}}>
                            <IonThumbnail style={{'height':'80%', 'width':'100%'}}>
                            <IonImg id={'contentGenerated'+idx+completedItem.completedByUserId} src={completedItem.completedByPhotoUrl ? downloadPictureContent(completedItem.completedByPhotoUrl,completedItem.completedByUserId, 'contentGenerated'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    onClick={() => viewAssociation(completedItem.completedByUserId, 'none', props.loggedInUser)}/>
                            </IonThumbnail>
                            <IonGrid>
                                <IonRow>
                                    <IonText className="brandText bolder" style={{'fontSize':'7px', 'marginLeft':'2px'}}>
                                        {completedItem.completedByHeroName}
                                    </IonText>
                                </IonRow>
                            </IonGrid>

                        </IonCard>
                    );
                })}
<IonRow>

</IonRow>
       </IonContent>

    );
}

export default CompletedByGrid;

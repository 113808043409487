import React, {useState} from 'react';
import {
        IonSlide,
        IonText,
        IonRow,
        IonCol,
        IonGrid,
        IonRouterLink,
        IonImg,
        IonModal,
        IonHeader,
        IonContent, IonLabel, IonFooter, IonButton
} from '@ionic/react';
import TrainingVideoExampleContainer from "../../components/Walkthrough/TrainingVideoExampleContainer";
import {sendJoinTestTeamEmail} from "../../util/sendEmail";


interface ApprovedUserScreen42Props {
        user:any;
}
const ApprovedUserScreen42: React.FC<ApprovedUserScreen42Props> = (props) => {
        const [showJoin, setShowJoin] = useState(false);

        const joinTestTeam = () => {
                console.log("props", props);
                sendJoinTestTeamEmail(props.user);
                setShowJoin(false);
        }
        return (
   
        <IonSlide>
            <IonModal isOpen={showJoin}
                      cssClass='join-custom-class'
                      onDidDismiss={() => setShowJoin(false)}>
                <IonHeader>
                    <IonRow style={{padding: 10}}>
                        <IonText className="appTextMain center">
                            {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                        </IonText>
                    </IonRow>
                </IonHeader>
                <IonContent className='join-custom-class'
                            scrollEvents={true}
                            onIonScrollStart={() => {
                            }}
                            onIonScroll={() => {
                            }}
                            onIonScrollEnd={() => {
                            }} scrollX scrollY>

                    {props.user.testTeamMember
                        ?<><IonRow><IonCol></IonCol></IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                            <IonRow>
                                <IonText className="appTextMain center">Check your email for the invite! </IonText>
                            </IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                        </>
                        :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                </IonContent>
                <IonFooter>
                    <IonRow className="ion-justify-content-center">

                        {props.user.testTeamMember === undefined  &&
                        <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                            <IonLabel className="italic bolder">
                                Join
                            </IonLabel>
                        </IonButton>}
                    </IonRow>
                </IonFooter>
            </IonModal>
          <IonGrid className="stepScreen">
                  <IonRow>
                          <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                                  <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>

                                  </div>

                                  <IonRouterLink href="/challenges">
                                  {/*<IonRouterLink onClick={() => setShowJoin(true)}>*/}
                                          <IonImg style={{'width':'50px'}} src={"assets/img/super.png"} />
                                  </IonRouterLink>
                                  <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>

                                  </div>
                          </div>
                  </IonRow>
                  <IonRow><IonCol></IonCol></IonRow>

                  <IonRow>
          <IonCol>
          <IonText className="appTextMain ion-text-justify" color="light">
                  Training Challenges build your character.
          </IonText>
          </IonCol>       
          </IonRow>         
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow>    
                
          <IonCol>
          <IonText className="appTextMainItalic ion-text-center" color="light">
             Example: Special training with pro-athletes.
          </IonText>    
          </IonCol>
          
          </IonRow>
          <IonRow>
          
          <IonCol>
          <IonText className="appTextMain ion-text-center" color="light">
          </IonText>
    
          </IonCol>
         
          </IonRow>
          <IonRow>
          
          <IonCol>
         
          </IonCol>
          
          </IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow>
            <IonCol>
              <TrainingVideoExampleContainer />
            </IonCol>
          </IonRow>
          </IonGrid>
        </IonSlide>
  );
};


export default ApprovedUserScreen42;



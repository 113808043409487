import React from 'react';
import { IonCard, IonContent, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonButtons, IonButton,  IonImg, IonLabel, IonRow, IonText, IonCol,  IonThumbnail, IonRouterLink, IonIcon } from '@ionic/react';

import './Challenges.css';
//import * as CapacitorVPPlugin from 'capacitor-video-player';

import { playOutline } from 'ionicons/icons';
import { Device } from '@capacitor/core';
import { firestore } from '../../firebaseConfig';
import { Challenge } from '../../models/Challenge';
import VideoList from '../../components/VideoList';
import authWrapper from '../../components/authWrapper';
import ChallengeListItem from "./ChallengeListItem";
import PremiumChallenges from './PremiumChallenges';

interface TrainingListPageState {
  loading: boolean;
  trainingList: Challenge[];
  user: any;
  // userId: string;
  // heroName: string;
  // heroId: string;
  // userPhotoURL: string;
}

class TrainingListPage extends React.Component<{}, TrainingListPageState>  {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      trainingList: [],
      user: props.user,
      // userId: props.user.uid,
      // heroName: props.user.heroName,
      // heroId: props.user.heroId,
      // userPhotoURL: props.user.photoURL,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    firestore.collection('challenges').where("type", "==", 'training').where("premium", "==", false).orderBy('index','asc')
    .onSnapshot(snapshot => {
      let trainingList = [];

      snapshot.forEach(doc =>
        trainingList.push({ ...doc.data(), uid: doc.id }),
      );

      this.setState({
        trainingList,
        loading: false,
      });
    });
  }



  render() {
    const { trainingList, loading, user} = this.state;

    const hide  = true;

  return (

<>

            {trainingList.map((training, index) => {
              return (
                  <ChallengeListItem key={index} challenge={training} index={index} showButtons={true} user={user}/>

              );
            })}


  </>

  );
};
}

export default authWrapper(TrainingListPage);

import React from 'react';
import {
    IonContent,
    IonRow,
    IonLabel,
    IonPage,
    IonSpinner,
    IonCardHeader,
    IonCard,
} from '@ionic/react';
import './Missions.css';
import MissionDeliveryComponent from '../../components/MissionDeliveryComponent';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Mission } from '../../models/Mission';
import { firestore} from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import Hire from '../Hire';
import ClientDelivery from '../ClientDelivery';

interface PremiumMissionsState {
    loading: boolean,
    missionsList: Mission[],
    loggedInUser: any,
}

class PremiumMissions extends React.Component<{}, PremiumMissionsState>  {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            missionsList: [],
            loggedInUser: props.user,
        };
    }

    componentDidMount() {

        firestore.collection('missions').where('premium', '==', true).orderBy("timeCreated", "desc")
            .onSnapshot(snapshot => {
                let missionsList = [];

                snapshot.forEach(doc =>
                    missionsList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    missionsList,
                    loading: false,
                });
            });
    }

    render() {
        const { missionsList, loading, loggedInUser } = this.state;
        if (loading)
            return <IonSpinner/>
        else
            return (
                <IonPage>
                    <Header menu={true} user={loggedInUser}/>
                    <IonContent className="ion-padding-bottom" scrollX scrollY>
                        <IonRow> <IonLabel className="sectionTitle"
                                           style={{'paddingTop':'20px','paddingLeft': '20px', 'color': '#005494'}}>

                        </IonLabel> </IonRow>
                        {missionsList.map((mission, index) => {
                            return (

                                <MissionDeliveryComponent
                                    key={index}
                                    mission={mission}
                                    index={index}
                                    showButtons={true}
                                    loggedInUser={loggedInUser}
                                    fromPage="premiumMissions"
                                    showCompletedSection={true}
                                />

                            );
                        })}
                    </IonContent>
                    <Footer/>
                </IonPage>
            )


    }
}
export default authWrapper(PremiumMissions);

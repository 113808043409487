import React, {useEffect, useRef, useState} from 'react';

import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel, IonList,
    IonRouterLink,
    IonSearchbar, IonRow, IonFooter, IonToolbar, IonCol, IonButtons, IonToggle, IonCheckbox, IonInput, IonHeader, IonText, IonModal, IonImg
} from "@ionic/react";
import {firestore} from "../../firebaseConfig";
import {closeCircleOutline, sendOutline} from "ionicons/icons";
import UserAvatar from "../../components/UserAvatar";
import firebase from "firebase";
import {toast} from "../../toast";
import {sendJoinTestTeamEmail} from "../../util/sendEmail";
import { findOrCreateConvoWithMessage } from './chat';
import {useHistory} from "react-router";

interface SendContentPopoverProps{
    sender:any;
    contentId: string;
    fromPage: string;
    isOpen: boolean;
    sentObjectType: string;
}

const SendContentPopover: React.FC<SendContentPopoverProps> = (props) => {

    const [searchReceiver, setSearchReceiver] = useState('');
    const [receiversLocked, setReceiversLocked] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const [checked, setChecked] = useState(true);
    const [suggestionList, setSuggestionList] = useState<any>(['']);
    const [receivers, setReceivers] = useState<any>([]);
    const [sentObjectType, setSentObjectType] = useState('');
    const [showJoin, setShowJoin] = useState(false);

    const history = useHistory();

    const drawerRef = useRef(null);

    const customAlertOptions = {
        header: 'Select Receivers'
    };

    const joinTestTeam = () => {
        console.log("send email props ", props);
        sendJoinTestTeamEmail(props.sender);
        setShowJoin(false);
    }

    useEffect(() => {
         console.log("run useEffect", props);
         let query = searchReceiver.toLowerCase()
         let stringBound = query.slice(0, query.length-1)
                        + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (query!='' && query.length >=1) {
           // console.log("query", query);
            const receiverRef = firestore.collection('users')
                .where('complete', '==', true)
                 .where('characterName_nocase','>=', query)
                 .where('characterName_nocase','<', stringBound)
                .where('characterName_nocase','!=', '')
                .orderBy('characterName_nocase', 'asc')
            receiverRef.onSnapshot(snapshot => {
                setSuggestionList(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));
                console.log("receiversLocked in useEffect", receiversLocked.toString())
                //setReceiversLocked(false)
            });
        }
        else {
            const receiverRef = firestore.collection('users')
                .where('complete', '==', true)
                // .where('heroName_nocase','>=', query)
                // .where('heroName_nocase','<', stringBound)
                .where('characterName_nocase','!=', '')
                .orderBy('characterName_nocase', 'asc')

            receiverRef.onSnapshot(snapshot => {
                setSuggestionList(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));
                console.log("receiversLocked in useEffect", receiversLocked.toString())
                //setReceiversLocked(false)
            });
        }
        
    }, [searchReceiver]);




    function addToReceivers(receiver, checked) {
        let receiverIdx = receivers.findIndex(r => r.uid === receiver.uid)
        let receiverWasIncluded = receiverIdx > -1
        if (checked && !receiverWasIncluded) {
            setReceivers([...receivers, receiver])
        } else if (!checked && receiverWasIncluded) {
            let newReceivers = [...receivers]
            newReceivers.splice(receiverIdx, 1)
            setReceivers(newReceivers)
        }
    }

    const createMessages = (sender, receiversLocked, contentId, messageContent, checked, sentObjectType) => {
        let popoverContainer = document.getElementById(contentId + 'popover');
        console.log("create individual messages", sender);
        console.log("create individual messages props", props);
        //todo if thread exists, add message if not create new convo
        //todo redirect to wurld after creation
        console.log("receivers", receivers);
        for (let destination of receivers) {
            //create messages to add to conversation
            let messageData = {
                messageType: 'outgoing',
                checked: checked,
                sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
                answerContent: messageContent,
                contentId: contentId,
                sentObjectType: sentObjectType,
                messageFrom: sender.uid,
            };
            let messageList = [messageData];
            console.log("message list", messageList);
            console.log("destination", destination);
            console.log("sender send content", sender);

            findOrCreateConvoWithMessage(sender, destination, messageData, history);
            // const conversation = firestore.collection('conversation').add({
            //     senderId: sender.uid,
            //     senderHeroName: sender.heroName,
            //     senderPhotoURL: sender.photoURL,
            //     receiverId: destination.uid,
            //     receiverHeroName: destination.heroName ? destination.heroName : destination.clientName,
            //     receiverPhotoURL: destination?.photoURL,
            //     sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
            //     messages: messageList,
            // }).then(refConvo => {
            //     console.log("created convo");
            //
            // }).catch(err => console.log('error', err));
        }
        toast('Messages successfully sent', 2000);
        popoverContainer.setAttribute("isOpen", 'false');
    }

    const closePopover = () => {
        drawerRef.current.dismiss().then(() => console.log('modal close'));
    }


    return (

        <IonModal
            id={props.contentId + 'popover'}
            cssClass='menu sharePopover'
            isOpen={props.isOpen}
            backdropDismiss={true}
            showBackdrop={false}
            swipeToClose={true}
            animated={false}
            ref={drawerRef}
        >
                <IonHeader>
                    <IonToolbar>
                        <IonRow>
                        <IonCol size="11">
                            <IonText className="brandText"> Send Message To </IonText>
                        </IonCol>
                        <IonCol>
                            <IonIcon icon={closeCircleOutline} style={{color:'#005494'}} size="medium" onClick={() => closePopover()}/>
                        </IonCol>
                        </IonRow>
                    </IonToolbar>
                </IonHeader>
                <IonSearchbar
                   value={searchReceiver}
                   placeholder="Search ... "
                   onIonChange={e => {
                       console.log("receiversLocked in searchBar change before set true", receiversLocked.toString(), e);
                       setReceiversLocked(true);
                       setTimeout(()=> setReceiversLocked(false), 200) // unacceptable solution
                       setSearchReceiver(e.detail.value!)
                   }}
                   debounce={0}
                />
              <IonContent scrollEvents={true} scrollX>
                  <IonModal isOpen={showJoin}
                            cssClass='join-custom-class'
                            onDidDismiss={() => setShowJoin(false)}>
                      <IonHeader>
                          <IonRow style={{padding: 10}}>
                              <IonText className="appTextMain center">
                                  To gain access to this section, join the JFH App Test Team.

                              </IonText>
                          </IonRow>
                      </IonHeader>
                      <IonContent className="my-custom-class"
                                  scrollEvents={true}
                                  onIonScrollStart={() => {
                                  }}
                                  onIonScroll={() => {
                                  }}
                                  onIonScrollEnd={() => {
                                  }} scrollX scrollY>
                          <IonImg src={"/assets/img/JoinTestTeam.png"} style={{height:'150px', width:'300'}}></IonImg>
                      </IonContent>
                      <IonFooter>
                          <IonRow className="ion-justify-content-center">

                              <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="appBtn center">
                                  <IonLabel className="italic bolder">
                                      Join
                                  </IonLabel>
                              </IonButton>
                          </IonRow>
                      </IonFooter>
                  </IonModal>
                <div style={{'overflowX':'scroll'}}>
                    <IonList>
                        {suggestionList && suggestionList.map((receiver, idx) => {
                            //console.log("extremely inefficient") // this runs 611 times over 3 minutes upon reloading wurld. This is unacceptable
                            if (props.sender?.uid == receiver?.uid) return null // remove when useEffect is updated after @google-cloud/firestore firebase-admin gets updated to latest version - see https://stackoverflow.com/questions/64595002/firestore-query-error-argument-of-type-is-not-assignable-to-parameter
                            else return (
                                <IonItem key={idx} lines="none">
                                    <UserAvatar userPhotoURL={receiver.photoURL} userId={receiver.uid} userHeroName={receiver.hero? receiver.heroName : receiver.clientName} userRole={'role'} showMoreInfo={false}/>

                                    <IonLabel>
                                        <IonRouterLink href={`/viewAssociation/${receiver.uid}`}>
                                            {receiver?.characterName ? receiver.characterName : receiver.username}
                                        </IonRouterLink>
                                    </IonLabel>
                                    <IonCheckbox
                                        checked={receivers.some(r => r.uid == receiver.uid)}
                                        className="checkbox-send"
                                        onIonChange={ e => {
                                            console.log("receiversLocked in checkbox ionChange", receiversLocked.toString());
                                            !receiversLocked && addToReceivers(receiver, e.detail.checked)
                                        }}
                                    />
                                </IonItem>
                            );
                        })}
                    </IonList>
                </div>
            </IonContent>


                <div className="messageBox">
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between',background: 'rgba(180,180,180,0.3)'}}>
                        <UserAvatar userPhotoURL={props.sender.photoURL}
                                    userId={props.sender.uid}
                        userHeroName={props.sender.characterName? props.sender.characterName : props.sender.username}
                        userRole={props.sender.role}
                        showMoreInfo={false}
                        />
                        <IonCol size="6">
                            <IonInput
                                value={messageContent}
                                autofocus
                                onIonChange={e => {e.preventDefault();setMessageContent(e.detail.value);}}
                                type="text"
                                spellcheck={true}
                                autocorrect="on"
                                placeholder={"Write messages here ... "}
                                className="input ion-padding-horizontal"
                                style={{'fontSize':'12px'}}
                            />
                        </IonCol>
                        <IonButtons slot="end">
                            <IonCol>
                                <IonRow>
                                    <IonLabel style={{'fontSize':'8px'}}>{checked ? `In-Character`: ''}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                                </IonRow>
                            </IonCol>
                            <IonCol>
                                {/*<IonButton onClick={() => {setShowJoin(true); closePopover();}}>*/}
                                <IonButton onClick={() => {createMessages(props.sender, receiversLocked, props.contentId, messageContent, checked, props.sentObjectType); closePopover();}}>
                                    <IonIcon icon={sendOutline}/>
                                </IonButton>
                            </IonCol>
                        </IonButtons>
                    </div>
                </div>

        </IonModal>

    );
};

export default SendContentPopover;


import { Device } from '@capacitor/core';
import React, {useEffect, useRef, useState} from 'react';
import { firestore, storageRef } from '../firebaseConfig';
import { getPictureAddress } from '../util/avatarUtils';
import { openFullScreen } from '../util/videoUtils';
//import { Player } from 'video-react';

interface VideoContainerNoControlsProps {
   item: any;

}

const VideoListNoControls: React.FC<VideoContainerNoControlsProps> = ({ item}) => {

    const [contentUrl, setContentUrl] = useState(item.ctaVideoUrl);
    const [contentType, setContentType] = useState('');
    const [platformInfoName, setPlatformInfoName] = useState('web');

    const [posterContentUrl, setPosterContentUrl]=useState(item.posterUrl);
    const videoRef = useRef(null);
    // console.log("in video list", locationAttribute);
    // console.log("container uid", uid);

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            // if (info.platform === 'web' || info.platform ==='android') {
            //
            // }
            setPlatformInfoName(info.operatingSystem);
        } );

    }, [item]);

    // if (locationAttribute && locationAttribute!=''  && locationAttribute!==undefined) {
    //     //console.log("Location", locationAttribute);
    //     storageRef.child(locationAttribute).getDownloadURL().then(function (videoUrl) {
    //         let img = document.getElementById(uid);
    //         img.setAttribute("src", videoUrl);
    //         setContentUrl(videoUrl);
    //     }).catch(function (error) {
    //         // Handle any errors
    //     });
    // }
    //
    // if(posterUrl && posterUrl!=='' && posterUrl!==undefined) {
    //     //console.log("poster", posterUrl);
    //     storageRef.child(posterUrl).getDownloadURL().then(function (contentUrl) {
    //
    //         let img = document.getElementById(uid);
    //         img.setAttribute("poster", contentUrl);
    //         setPosterContentUrl(contentUrl);
    //     }).catch(function (error) {
    //         // Handle any errors
    //     });
    // }
    // else {
    //     //setPosterContentUrl(contentUrl+'#t=0.0.1');
    // }

    function onVideoClick(e) {
        //let videoEl = document.getElementById(item.code) as HTMLVideoElement;

        let videoEl = videoRef.current;

        // let playBtn = document.getElementById('play'+item.code);
        // let stopBtn = document.getElementById('stop'+item.code);

        if (platformInfoName ==='android') {
            videoEl.onfullscreenchange = (e=> {
                if (!document.fullscreenElement)
                    videoEl.pause();
            })
        }

        if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
            //hide play. show stop
            // playBtn.style.display='block';
            // stopBtn.style.display='none';

            videoEl.pause();
        }
        else if (videoEl.paused || videoEl.ended) {
            // videoEl.webkitEnterFullScreen();
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (platformInfoName ==='android') {
                        openFullScreen(videoEl);
                    }
                    console.log("resume video");
                    console.log("video play time", videoEl.currentTime);
                }
            ).catch(() => console.log("error playing video"));
        }
        else {
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (platformInfoName ==='android') {
                        openFullScreen(videoEl);
                    }
                }
            ).catch(() => console.log("error playing video"));
        }
    }
    return (

        <div className="video-wrapper">
            <div className="overlay"
                 onClick={onVideoClick}
            >
                <video id={item.uid+item.type} poster={getPictureAddress(item.posterUrl, item.uid,item.uid+item.type)} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>
                    <source src={getPictureAddress(item.ctaVideoUrl, item.uid,item.uid+item.type)} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default VideoListNoControls;


import React from 'react';
import {IonContent, IonText, IonPage
} from '@ionic/react';

import './Rules.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from "../../components/authWrapper";

const StorySoFar: React.FC = () => {
    return (
        <IonPage>
            <Header menu={true}/>


            <IonContent className="ion-padding">


                   <ul>
                        <IonText style={{fontStyle:'bold', fontSize:21}}> JFH - The Story So Far… </IonText>
                    </ul>

                  <ul>
                      <p>
                          JFH aka Justice For Hire is an app that’s just for heroes and just for help.  It unites heroes with people who need them - hire a hero or become one and get paid.  The JFH App has ignited a global Heroes Culture movement.  Here’s how it all began and where our community is going with it.
                      </p>
                  </ul>


                  <ul>
                      <p>
                          JFH started in New York with Hero #0 aka <a href="viewAssociation/jkcaYJTrjpZDecf8wOBdYYf0uRq1/none/AaQJb3iqc1gA3S5WLY64sS92wpy2">Jan</a>, a former vigilante that gave up that life due to burnout.  But when Jan witnesses a domestic violence incident that police can’t respond to, he realizes the only way to truly make a safer world is for everyone to be empowered as a hero.
                      </p>
                  </ul>

                  <ul>
                      <p>
                          Jan’s intervention turns to a battle with a man named Franco (more on him later), and inspires Franco’s ex <a href="/viewAssociation/rStlJxCS9Bb3dvT8apSuqsnfDV02/none/pLfr7dFM8ZeG5YXe4PZSz62GOgO2">Justine</a> to step up and train herself as JFH Hero #1.
                      </p>
                  </ul>
                  <ul>
                      <p>
                          The beginnings of the JFH App are humble.  Jan begins hosting Hero Meetups at his martial arts school.  People from all walks of life come to discuss how to make the world a better place <strong>with our own hands</strong>.
                      </p>
                  </ul>

                   <ul>
                       <p>
                           Real change happens by taking action.
                       </p>
                   </ul>

                   <ul>
                       <p>
                           Jan starts finding people who need help.  Folks that can’t get that help from the police or any other organization our society has.  He starts sharing those needs at the Hero Meetups, and heroes step up.
                       </p>
                   </ul>

                    <ul>
                        <p>

                            The movement grows.  Fighters, mediators, helpers, hackers, guards, good samaritans.  People helping people.

                        </p>
                    </ul>
                    <ul>
                        <p>
                            The JFH App gets built by Jan’s college buddy <a href="/viewAssociation/e1FkrhCVMbY65JpivRzsc9LQcJi1/none/AaQJb3iqc1gA3S5WLY64sS92wpy2">Tommy</a> and expert developer <a href="/viewAssociation/pLfr7dFM8ZeG5YXe4PZSz62GOgO2/none/AaQJb3iqc1gA3S5WLY64sS92wpy2">Andra</a>.  It’s a bit buggy in the beginning, but it’s a start - enough to get financial support from one-man startup accelerator <a href="/viewAssociation/LzTO0H89awPoFI1HZP0GHqyb5QX2/none/AaQJb3iqc1gA3S5WLY64sS92wpy2" target="_blank">Brian Frumberg</a>.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            We grow more.  JFH nationwide.  Worldwide.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            But an international network of heroes redefining justice doesn’t go unnoticed by authorities, or by the business world.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            Turns out Franco’s a high ranking cop, and he’s pissed that JFH exists.  He calls up detective <a href="/viewAssociation/O8gCQbni8TPxmbeRiwxegqqLa4C2/none/AaQJb3iqc1gA3S5WLY64sS92wpy2" target="">Louisa Mendez</a> to get as much dirt on JFH as she can.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            Meanwhile, notorious tech bro Damien Lord loves what he sees with JFH.  He sends out hired muscle to test how good the JFH community is - a fighter named <a href="/missions" target="_blank">Cowboy</a>, who targets JFH Heroes, and shuts down their missions.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            This of course is problematic for business.  JFH already deals with Villains posing as Heroes on the app.  But Cowboy’s efforts are more strategic.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            His boss Damien wants to buy JFH.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            Jan says no.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            That pisses Damien off.  He’s left with no choice other than to find someone within JFH to help him build his own rival app.  One that won’t be so focused on the principles behind being a hero.  An app focused on kicking ass and getting paid.

                        </p>
                    </ul>
                    <ul>
                        <p>
                            He recruits our Hero #1, Justine, who never felt at home at JFH, or anywhere else for that matter.

                        </p>
                    </ul>
                    <ul>
                        <p>
                            Cowboy and Justine are fond of one another, but Justine has her own plans for him and Damien and this new rival JFH app.  She just needs an X factor to raise the stakes.

                        </p>
                    </ul>
                    <ul>
                        <p>
                            She gets her wish.

                        </p>
                    </ul>
                    <ul>
                        <p>
                            Like a sudden storm, Ninjas cause incidents across the US.  They appear and disappear, with attacks on people, police, and JFH Heroes alike.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            Cops, cowboys, ninjas, bad press, business moguls trying to take down what we built as a community.  This is where we are with JFH.
                        </p>
                    </ul>
                    <ul>
                        <p>
                            Our only choice is to stay the course.  Keep helping clients with our abilities through the JFH App.  And gather as much info as we can on anyone trying to stop our Heroes Culture movement.
                        </p>
                    </ul>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

export default authWrapper(StorySoFar);

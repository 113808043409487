 import React from 'react';
import {
    IonContent,
    IonSpinner,   IonList,  IonRow, IonText, IonCard, IonIcon
} from '@ionic/react';


import { firestore} from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';

import { Conversation } from '../../models/Conversation';
import ConversationItem from './ConversationItem';
import { createOutline } from 'ionicons/icons';

interface ConversationFeedState {
    loading: boolean,
    conversations: Conversation[],
    user: string;
}


async function getConvosArray(userName) {
    const conversationsRef = firestore.collection("conversation").orderBy("lastUpdated", "desc");
    
    const sentConvos = conversationsRef.where('senderHeroName', "==", userName).get();
    const receivedConvos = conversationsRef.where('receiverHeroName', "==", userName).get();

    const [sentConvosSnapshot, receivedConvosSnapshot] = await Promise.all([
        sentConvos,
        receivedConvos
    ]);

    const sentConvosArray = sentConvosSnapshot.docs;
    const receivedConvosArray = receivedConvosSnapshot.docs;

   // const convosArray = sentConvosArray;

    const convosArray = sentConvosArray.concat(receivedConvosArray);

    return convosArray;
}

class Conversations extends React.Component<{}, ConversationFeedState>  {

    timer: any;
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            conversations: [],
            user: props.user,
        };
    }


    componentDidMount() {

        let characterName = this.state.user['characterName']? this.state.user['characterName']:
            (this.state['heroName']? this.state['heroName']:this.state.user['clientName']);
        console.log("character name"+ characterName);
        //We call the asychronous function
        this.timer = setInterval(() => {
           // console.log("characterName", characterName);

            getConvosArray(characterName).then(result => {
                let conversations = [];

                result.forEach(docSnapshot => {
                    conversations.push({...docSnapshot.data(), uid: docSnapshot.id});
                });

                this.setState({
                    conversations,
                    loading: false,
                });
            });
        }, 1000);
    }


    render() {
        const { conversations, loading, user } = this.state;

        if (loading)
            return <IonSpinner/>
        else
            return (

                <IonContent className="ion-padding-bottom" style={{'--padding-start':'5px'}} scrollX>
                    {conversations.length === 0 &&
                    <IonCard className="ion-justify-content-center" style={{'backgroundOpacity':'80%'}}>
                        <IonRow style={{'textAlign':'center'}}><IonText className="brandText">Your Conversations with other Cast Members will appear here.</IonText></IonRow>
                        <IonRow style={{'textAlign':'center'}}><IonText className="brandText" style={{'marginLeft':'20%'}}> Press <IonIcon icon={createOutline} onClick={()=> {window.location.href='/startConversation';}}/> to start a conversation.</IonText></IonRow>
                    </IonCard>}
                    <IonList lines="none">
                        {conversations && conversations.map((conversation, idxs) => {
                            return (
                                <div key={idxs}>
                                    {conversation && conversation.messages !== undefined && <ConversationItem conversation={conversation} user={user} index={idxs}/>}
                                </div>
                                );
                        })}
                    </IonList>
                </IonContent>
            )

    }
}
export default authWrapper(Conversations);

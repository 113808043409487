import React, {useState} from 'react';
import {
    IonContent,
    IonText,
    IonPage,
    IonRouterLink,
    IonLabel,
    IonRow,
    IonGrid,
    IonModal,
    IonHeader, IonButton, IonIcon
} from '@ionic/react';
import {closeOutline} from "ionicons/icons";


const ChatWelcomePopup: React.FC = () => {
    const [showWelcome, setShowWelcome] = useState(true);


    return (
        <IonModal isOpen={showWelcome} cssClass='chat-welcome-class'>
            <IonHeader>
                <IonRow className="ion-justify-content-center" style={{padding: 10}}>
                    <IonText className="appTextMain">
                        Welcome to the JFH Cast Chat!
                    </IonText>
                </IonRow>
            </IonHeader>
            <IonContent className="chat-welcome-class"
                        scrollEvents={true}
                        onIonScrollStart={() => {
                        }}
                        onIonScroll={() => {
                        }}
                        onIonScrollEnd={() => {
                        }} scrollX scrollY>

                <div style={{
                    height: '100%',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: 10,
                }}>
                    <IonRow className="ion-align-items-center">
                            <IonText className="appTextMain" style={{lineHeight: 2}}>
                                Get to know all the Heroes, Villains and Clients on the app.  <br/> <br/>
                                Please note the toggle to chat In-Character (your alter-ego) and Out-of-Character (yourself).
                            </IonText>
                    </IonRow>


                    <IonRow className="ion-justify-content-end">
                        <IonButton onClick={() => setShowWelcome(false)} style={{'--background': 'none'}}>
                            <IonIcon icon={closeOutline}/>
                        </IonButton>
                    </IonRow>
                </div>

            </IonContent>
        </IonModal>
    );
};

export default ChatWelcomePopup;

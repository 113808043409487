import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel

} from '@ionic/react';
import Header from '../../components/Header';
import './Challenges.css';
import Footer from '../../components/Footer';

import JourneyListPage from './JourneyListPage';
import TrainingListPage from './TrainingListPage';
import DevelopmentListPage from './DevelopmentListPage';

import authWrapper from '../../components/authWrapper';
import ChallengesFeedPopup from "./ChallengesFeedPopup";
import {firestore} from "../../firebaseConfig";
import firebase from "firebase";
import PremiumChallenges from './PremiumChallenges';
import PremiumDevelopmentChallenges from "./PremiumDevelopmentChallenges";
import UGCTrainingListPage from "./UGCTrainingListPage";
import UGCDevelopmentListPage from "./UGCDevelopmentListPage";
import CreateChallenge from "./CreateChallenge";
import CreateDevChallenge from "./CreateDevChallenge";
import CreateTrainingChallenge from "./CreateChallenge";

interface TrainingChallengesScreen {
    showModal: any;
}

const TrainingChallenges: React.FC<TrainingChallengesScreen> = (props) => {
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    const {user:{pages, uid}} = props as any;

    const [selectedTab, setSelectedTab] = useState('training');

    useEffect(() => {
        if (pages !== undefined ) {
            console.log("pages ", pages);
            let pagesList = pages.filter(item => item.challenges === true);
            if (pagesList && pagesList.length===1) {
                setShowTutorialModal(false);
            }
        }
        else {
            setShowTutorialModal(true);

            let userDocRef = firestore.collection('users').doc(uid);
            let pagesData = {
                challenges: true,
            }
            let arrUnion = userDocRef.update({
                pages: firebase.firestore.FieldValue.arrayUnion(pagesData)
            }).then(() =>
                console.log("add challenges page to pages visited"))
                .catch((err) => console.log(err))
        }
    }, [uid]);


    function changeSegmentContent(selectedSegment) {
        let contentContainer = document.getElementById(selectedSegment+'Feed');
        contentContainer.style.display= 'block';
        let journeyFeedContainer = document.getElementById('journeyFeed');
        let trainingFeedContainer = document.getElementById('trainingFeed');
        let developmentFeedContainer = document.getElementById('developmentFeed');
        let challengesFeedContainer = document.getElementById('challengesFeed');
        if (selectedSegment === 'journey') {
            journeyFeedContainer.style.display= 'block';
            trainingFeedContainer.style.display= 'none';
            developmentFeedContainer.style.display = 'none';
            challengesFeedContainer.style.display = 'none';

        }
        else if (selectedSegment === 'development'){
            journeyFeedContainer.style.display= 'none';
            trainingFeedContainer.style.display= 'none';
            developmentFeedContainer.style.display = 'block';
            challengesFeedContainer.style.display = 'none';
        }
        else {
            journeyFeedContainer.style.display= 'none';
            trainingFeedContainer.style.display= 'block';
            developmentFeedContainer.style.display = 'none';
            challengesFeedContainer.style.display = 'none';
        }
    }

    const hide  = true;

    return (
        <IonPage>
            <Header menu={true} />
            <IonContent>

                {showTutorialModal && <ChallengesFeedPopup />}

                <IonSegment value={selectedTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)}>
                    <IonSegmentButton value="journey">
                        <IonLabel className="labelText">Journey</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="training">
                        <IonLabel className="labelText">Training</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="development">
                        <IonLabel className="labelText">Development</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonContent id="journeyFeed" style={hide ? { display: 'none' } : {}}>
                    <JourneyListPage/>
                </IonContent>
                <IonContent id="trainingFeed">
                    <CreateTrainingChallenge/>
                    <PremiumChallenges/>
                    <TrainingListPage/>
                    <UGCTrainingListPage/>
                </IonContent>
                <IonContent style={hide ? { display: 'none' } : {}} id="developmentFeed">
                    <CreateDevChallenge/>
                    <PremiumDevelopmentChallenges/>
                    <DevelopmentListPage/>
                    <UGCDevelopmentListPage/>
                </IonContent>
                <IonContent id="challengesFeed">
                    <JourneyListPage/>
                </IonContent>
            </IonContent>
            <Footer />
        </IonPage>

    );
};

export default authWrapper(TrainingChallenges);

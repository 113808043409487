import React, {useEffect, useRef, useState} from 'react';
import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonAlert,

    IonImg,
    IonText,

    IonRow,
    IonFabButton,
    IonLabel,
    IonCol,
    IonSegment, IonSegmentButton
} from '@ionic/react';

import { RouteComponentProps } from 'react-router';

import './Account.css';
import Footer from '../../components/Footer';
import authWrapper from '../../components/authWrapper';
import {firestore, storageRef} from '../../firebaseConfig';
import { useHistory } from 'react-router';

import Header from "../../components/Header";
import { toast } from '../../toast';
import {playVideoEl} from "../../util/videoUtils";
import {Device} from "@capacitor/core";
import ProfileVideoAvatar from "../../components/ProfileVideoAvatar";
import Moment from 'react-moment';

interface OwnProps extends RouteComponentProps { defaultTab?: string;}

interface StateProps {
    username?: string;
}

interface UserProps {
    photoUrl: string,
    user: any;
    role: string;
    selectedTab: string;
}


const AccountEdit: React.FC<UserProps> = (props) => {

    const history = useHistory();

    const [showHeroNameAlert, setShowHeroNameAlert] = useState(false);
    const [showClientNameAlert, setShowClientNameAlert] = useState(false);

    const [showOneLinerAlert, setShowOneLinerAlert] = useState(false);
    const [showAboutAlert, setShowAboutAlert]= useState(false);
    const [showTeamAlert, setShowTeamAlert]= useState(false);
    const [showBackstoryAlert, setShowBackstoryAlert] = useState(false);
    const [changeVideoOptions, setChangeVideoOptions] = useState(false);
    const [changeContactEmail, setChangeContactEmail] = useState(false);
    const [showChangeIG, setShowChangeIG] = useState(false);
    const [showChangeTikTok, setShowChangeTikTok] = useState(false);

    const [showRealName, setShowRealName] = useState(false);

    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [showBirthday, setShowBirthday] = useState(false);

    const [pictureContentUrl, setPictureContentUrl] = useState(props.user.photoUrl);

    //const [selectedTab, setSelectedTab] = useState(history.location ? history.location);

    const [selectedTab, setSelectedTab] = useState('inCharacter');


    const [platformInfoName, setPlatformInfoName] = useState('web');

    console.log("history location", history.location.state);

    useEffect(() => {
        //console.log("poster url", props.user.photoURL);
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            // if (info.platform === 'web' || info.platform ==='android') {
            //
            // }
            setPlatformInfoName(info.operatingSystem);
        } );

        if (props.user.photoURL !== undefined && props.user.photoURL !== '' && props.user.photoURL !== null) {
            //console.log("profile picture", props.user.photoURL);
            let profilePictureContentRef = storageRef.child(props.user.photoURL);
            //console.log("content ref", profilePictureContentRef);
            profilePictureContentRef.getDownloadURL().then(function(contentDownloadUrl) {
                // Insert url into an <img> tag to "download"
                let xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function(event) {
                    let blob = xhr.response;
                };
                xhr.open('GET', contentDownloadUrl);
                xhr.send();
                // Or inserted into an <img> element:
                let imgC = document.getElementById(props.user.uid+'profilePhoto');
                //contentDownloadUrl= contentDownloadUrl;
                //console.log("contentDownloadUrl", contentDownloadUrl);
                imgC.setAttribute("src", contentDownloadUrl);
                setPictureContentUrl(contentDownloadUrl);

            }).catch(function(error) {

                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;

                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;


                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
            });
        }
    }, [props.user.uid]);

    function changePassword() {
        window.location.href='/forgotPassword';
    }

    function changeHeroName(uid) {
        setShowHeroNameAlert(true);
        //console.log("uid:", uid);
    }

    function changeClientName(uid) {
        setShowClientNameAlert(true);
    }

    function changeOneLiner(uid) {
        setShowOneLinerAlert(true);
    }

    function changeAbout(uid) {
        setShowAboutAlert(true);
    }

    function changeTeamName(uid) {
        setShowTeamAlert(true);
    }

    function changeBackstory(uid) {
        setShowBackstoryAlert(true);
    }

    function changeIG(uid) {
        setShowChangeIG(true);
    }

    function changeTikTok(uid) {
        setShowChangeTikTok(true);
    }

    function changeEmail(uid) {
        setChangeContactEmail(true);
    }

    function changeRealName(uid) {
        setShowRealName(true);
    }

    function changePhoneNumber(uid) {
        setShowPhoneNumber(true);
    }

    function changeBirthday(uid) {
        setShowBirthday(true);
    }


    function showHideVideo(e, container, platformInfoName) {
        console.log("container", container);
        let divContainerEl  = document.getElementById(container);
        divContainerEl.style.display = 'block';
        // let videoEl=document.getElementById('profileVideo') as HTMLVideoElement;
        // // let videoEl = videoContainer as HTMLVideoElement;
        // console.log("Video el id:",videoEl);

        // videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
        playVideoEl(e, platformInfoName, props);
    }


    function updateInCharacter() {
        window.location.reload(false);
        this.setState({
            selectedTab:'inCharacter'
        });
    }

    function updateOutOfCharacter() {
        window.location.reload(false);
        setSelectedTab('outOfCharacter');
    }

    function changeProfileVideo() {
        history.push({
            pathname: '/profileVideoItem',
            state: {user:props.user, fromPage: 'pages/account'},
        });
    }

    function changeProfilePicture() {
        history.push({
            pathname: '/profileVideoItem',
            state: {user:props.user, fromPage: 'pages/account'},
        });
    }

    function changeSegmentContent(selectedSegment) {
        console.log("sleected segment", selectedSegment);
        let contentContainer = document.getElementById(selectedSegment + 'Tab');

        contentContainer.style.display = 'block';

        let outOfCharacterTabContainer= document.getElementById('outOfCharacterTab');
        let inCharacterTabContainer = document.getElementById('inCharacterTab');
        if (selectedSegment === 'outOfCharacter') {
            setSelectedTab('outOfCharacter');
            outOfCharacterTabContainer.style.display = 'block';
            inCharacterTabContainer.style.display='none';

        } else if (selectedSegment === 'inCharacter') {
            setSelectedTab('inCharacter');
            outOfCharacterTabContainer.style.display = 'none';
            inCharacterTabContainer.style.display='block';
        }
    }


    const onUploadImage = (e: any) => {

        let posterFile = e.target.files[0];
        let posterFileUrl = URL.createObjectURL(posterFile);
        let posterFileExtension = posterFile.type.split("/").pop();
        let posterFileName = props.user.heroName.replace(/ /g, "_") + Date.now()+"."+posterFileExtension;

        // let videoProfileEl = document.getElementById('challengeVideoPlayer') as HTMLVideoElement;
        // videoProfileEl.setAttribute('poster', posterFileUrl);
        let profileThumbnailRef = storageRef.child('profiles/thumbnails/'+posterFileName);
        profileThumbnailRef.put(posterFile).then(function(snapshot) {
            //console.log('Uploaded the thumbnail for profile');

            firestore.doc(`users/${props.user.uid}`).update({
                photoURL: profileThumbnailRef.fullPath,
            }).then(function() {
                window.location.reload(false);
            });
        }).catch((err)=>toast("error uploading thumbnail"+err));
        //console.log("photo url", profileThumbnailRef.fullPath);
        //update props.user photoURL

        setPictureContentUrl(profileThumbnailRef.fullPath);

    }

    async function clickUploadImage(e) {
        document.getElementById('uploadImageBtn').click();
    }

    let setRealName = (newRealName) => {
        {props.user?.realName &&

        firestore.doc(`users/${props.user.uid}`).update({
            realName: newRealName,
        }).then(function() {
            //window.location.reload(false);
        });

        }

    }

    return (
        <IonPage id="account-page">
            <Header fromPage={'profile'}/>

            <IonContent className="ion-padding-top">
                <IonFabButton style={{ '--background': '#005494', "margin": "auto", 'minHeight': '111px', 'width':'111px'}}>
                    {props.user.profileVideo !== '' && <ProfileVideoAvatar videoPath={props.user.profileVideo} userId={props.user.uid} photoUrl={props.user.photoURL} role={props.user.role} tab={'account'} index={props.user.heroNumber ? props.user.heroNumber : props.user.clientNumber } />}
                    {(props.user.profileVideo === 'undefined' || props.user.profileVideo === '') && <IonImg  src={"https://www.gravatar.com/avatar?d=mm&s=140"}  />}
                </IonFabButton>
                {/*<IonFabButton style={{*/}
                {/*    '--background': '#005494', "margin": "auto", 'minHeight': '111px', 'width':'111px'*/}
                {/*}} onClick={(e)=>showHideVideo(e,props.user.uid+'accountProfileVideo', platformInfoName)}>*/}
                {/*    <IonImg id={props.user.uid+'profilePhoto'} src={pictureContentUrl ? pictureContentUrl : "https://www.gravatar.com/avatar?d=mm&s=140"}  onClick={() => setChangeVideoOptions(true)} ></IonImg>*/}
                {/*</IonFabButton>*/}
                {/*<IonRow className="ion-justify-content-center">*/}

                {/*    {props.user.profileVideo && <IonBadge style={{'background':'#005494'}}>*/}
                {/*        <IonIcon icon={playCircleOutline} onClick={(e)=>showHideVideo(e,props.user.uid+'accountProfileVideo', platformInfoName)}></IonIcon>*/}
                {/*    </IonBadge> }*/}
                {/*    {!props.user.profileVideo && <IonBadge style={{'background':'#005494'}}>*/}
                {/*        <IonIcon icon={cloudUploadOutline} onClick={changeProfileVideo}/>*/}
                {/*    </IonBadge>}*/}
                {/*</IonRow>*/}
                <IonRow>
                    <IonCol style={{textAlign:'right'}}>
                        <IonText  onClick={changeProfileVideo} className="chooseButtonText"> Change Video </IonText>
                    </IonCol>
                    <IonCol style={{textAlign:'left'}}>

                        <IonLabel className="chooseButtonText" onClick={(e) => {
                            clickUploadImage(e)
                        }}>
                            Change Image </IonLabel>
                        <input type="file" accept="image/*" className="uploadVideoBtn"
                               id="uploadImageBtn"
                               onChange={onUploadImage}
                        />
                        {/*<IonText  onClick={changeProfilePicture} className="chooseButtonText"> Change Image </IonText>*/}
                    </IonCol>
                </IonRow>

                <IonSegment value={selectedTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)}>
                    <IonSegmentButton value="outOfCharacter">
                        <IonLabel className="labelText">Out-of-Character </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="inCharacter">
                        <IonLabel className="labelText">In-Character</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonContent id="outOfCharacterTab" style={selectedTab === 'outOfCharacter' ? {} :{display:'none'}}className="ion-padding-bottom">
                    <IonList>
                        <IonItem onClick={() => changeRealName(props.user)}>
                            <IonCol>
                                <IonLabel>Real Name </IonLabel>
                            </IonCol>
                            <IonCol>
                                {/*<IonInput id="realNameLbl" className={props.user.realName ? '': 'placeholder'}>{props.user.realName ? props.user.realName : 'So we can credit you properly'} </IonInput>*/}
                                {/*<IonInput  value={props.user.realName} placeholder="So we can credit you properly" onIonChange={e => setRealName(e.detail.value)}> </IonInput>*/}
                                <IonText id="realNameLbl" style={props.user.realName  ? {}: {opacity:'.5'}}>{props.user.realName ? props.user.realName: 'So we can credit you'}</IonText>
                            </IonCol>
                        </IonItem>
                        <IonItem onClick={() => changeEmail(props.user)}>
                            <IonCol><IonLabel>Contact Email </IonLabel> </IonCol>
                            <IonCol><IonText id="contactEmailValue"> {props.user.contactEmail ? props.user.contactEmail : props.user.email}</IonText></IonCol>
                        </IonItem>
                        <IonItem onClick={() => changePhoneNumber(props.user)}>
                            <IonCol><IonLabel> Phone Number </IonLabel></IonCol>
                            <IonCol><IonText id="phoneNumberLbl"> {props.user.phoneNumber}</IonText></IonCol>
                        </IonItem>
                        <IonItem onClick={() => changeBirthday(props.user)}>
                            <IonCol> <IonLabel> Birthday </IonLabel></IonCol>
                            <IonCol> <IonLabel id="birthdayLbl">

                                <Moment format='MMMM Do YYYY'>{props.user.birthday}</Moment>

                            </IonLabel></IonCol>
                        </IonItem>

                        <IonItem onClick={() => changeIG(props.user)}>
                            <IonCol><IonLabel> Instagram Handle </IonLabel></IonCol>
                            <IonCol><IonLabel id="igHandleLbl"> {props.user.igHandle}</IonLabel></IonCol>
                        </IonItem>

                        <IonItem onClick={() => changeTikTok(props.user)}>
                            <IonCol><IonLabel> Tik Tok Handle </IonLabel></IonCol>
                            <IonCol><IonLabel id="tikTokHandleLbl"> {props.user.tikTokHandle}</IonLabel></IonCol>
                        </IonItem>

                        <IonItem onClick={() => changePassword()}> <IonCol>Password</IonCol></IonItem>
                        <IonItem routerLink="/pages/Support"><IonCol>Support</IonCol></IonItem>
                    </IonList>
                </IonContent>

                <IonContent id="inCharacterTab" style={selectedTab === 'inCharacter'? {}:{display: 'none'}} className="ion-padding-bottom">
                    <IonList>
                        {props.user.heroId &&
                        <IonItem  onClick={() => changeHeroName(props.user)}>
                            <IonLabel>Hero Name </IonLabel>
                            <IonLabel id="heroNameLbl">{props.user.heroName} </IonLabel>
                        </IonItem>}
                        {props.user.clientId &&
                        <IonItem onClick={() => changeClientName(props.user)}>
                            <IonLabel>Client Name </IonLabel>
                            <IonLabel style={{'textAlign':'left'}} id="clientNameLbl">{props.user.clientName} </IonLabel>
                        </IonItem>}
                        {props.user.heroId &&
                        <IonItem onClick={() => changeOneLiner(props.user)}>
                            <IonLabel>One Liner </IonLabel>
                            <IonLabel id="oneLinerValue"> {props.user.oneLiner} </IonLabel>
                        </IonItem>}
                        {props.user.clientId &&
                        <IonItem onClick={() => changeAbout(props.user)}>
                            <IonLabel> About</IonLabel>
                            <IonLabel id="aboutLbl"> {props.user.about} </IonLabel>

                        </IonItem>}

                        {props.user.heroId &&
                        <IonItem onClick={() => changeTeamName(props.user)}>
                            <IonLabel> Team Name </IonLabel>
                            <IonLabel id="teamNameLbl"> {props.user.teamName}</IonLabel>
                        </IonItem>}

                        <IonItem onClick={() => changeBackstory(props.user)}>

                            <IonCol style={{'paddingLeft':'0px'}}>
                                <IonLabel>Backstory </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText id="backstoryLbl"> {props.user.backstory} </IonText>
                            </IonCol>

                        </IonItem>
                    </IonList>
                </IonContent>

            </IonContent>
            <IonAlert
                isOpen={showRealName}
                header="Change Real Name"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('realNameLbl').innerHTML=data['realName'];
                            {props.user.realName !== undefined &&
                            firestore.doc(`users/${props.user.uid}`).update({
                                realName: data['realName'],
                            }).then(function() {
                                console.log(" new real name", data['realName']);
                                //window.location.reload(false);
                            });

                            }
                            {props.user.realName === undefined &&

                            firestore.doc(`users/${props.user.uid}`).set({
                                realName: data['realName'],
                            }, {merge: true}).then(function() {
                                console.log(" new real name", data['realName']);
                                //window.location.reload(false);
                            });
                            }

                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'realName',
                        value: (`${props.user.realName}` !== 'undefined' && `${props.user.realName}` !== '') ? `${props.user.realName}`: ``,
                        placeholder: (`${props.user.realName}` !== '' && `${props.user.realName}` !== 'undefined')  ? `${props.user.realName}`: `So We Can Credit You Properly`
                    }
                ]}
                onDidDismiss={() => setShowRealName(false)}
            />
            <IonAlert
                isOpen={showPhoneNumber}
                header="Change Phone Number"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('phoneNumberLbl').innerHTML=data['phoneNumber'];

                            firestore.doc(`users/${props.user.uid}`).set({
                                phoneNumber: data['phoneNumber'],
                            }, {merge:true}).then(() => console.log("updated"));
                        }
                    }
                ]}
                inputs={[
                    {
                        type: "tel",
                        name: 'phoneNumber',
                        value: (`${props.user.phoneNumber}` !== '' && `${props.user.phoneNumber}` !== 'undefined') ? `${props.user.phoneNumber}`: ``,
                        placeholder: `${props.user.phoneNumber}` !== '' ? `${props.user.phoneNumber}`: `Please fill in your Phone Number`
                    }
                ]}
                onDidDismiss={() => setShowPhoneNumber(false)}
            />
            <IonAlert
                isOpen={showBirthday}
                header="Change Birthday"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('birthdayLbl').innerHTML=data['birthday'];

                            firestore.doc(`users/${props.user.uid}`).set({
                                birthday: data['birthday'],
                            }, {merge:true}).then(() => console.log("updated"));
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'date',
                        min: '1904-03-14',
                        max: '2008-01-01',
                        name: 'birthday',
                        value: (`${props.user.birthday}` !== 'undefined' && `${props.user.birthday}` !== '') ? `${props.user.birthday}`: ``,
                        placeholder: `${props.user.birthday}` !== '' ? `${props.user.birthday}`: `Please fill in your birthday`
                    }
                ]}
                onDidDismiss={() => setShowBirthday(false)}
            />
            <IonAlert
                isOpen={showOneLinerAlert}
                header="One Liner"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            console.log(data);
                            document.getElementById('oneLinerValue').innerHTML=data['oneLiner'];

                            firestore.doc(`users/${props.user.uid}`).set({
                                oneLiner: data['oneLiner'],
                            }, {merge:true}).then(function() {
                                console.log("Document successfully written!");
                                //window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'oneLiner',
                        value:  (`${props.user.oneLiner}` !== '' &&  `${props.user.oneLiner}` !== 'undefined')  ? `${props.user.oneLiner}`: ``,
                        placeholder: (`${props.user.oneLiner}` !== '' &&  `${props.user.oneLiner}` !== 'undefined')  ? `${props.user.oneLiner}`: `Please fill in your One Liner`
                    }
                ]}
                onDidDismiss={() => setShowOneLinerAlert(false)}
            />
            <IonAlert
                isOpen={showHeroNameAlert}
                header="Change Hero Name"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('heroNameLbl').innerHTML=data['heroName'];

                            firestore.doc(`users/${props.user.uid}`).set({
                                characterName: data['heroName'],
                                heroName: data['heroName'],
                            }, {merge:true}).then(function() {
                                //window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'heroName',
                        value: (`${props.user.heroName}` !== '' && `${props.user.heroName}` !== 'undefined') ? `${props.user.heroName}`: `Please fill in your Hero Name`,
                        placeholder: (`${props.user.heroName}` !== '' && `${props.user.heroName}` !== 'undefined') ? `${props.user.heroName}`: `Please fill in your Hero Name`
                    }
                ]}
                onDidDismiss={() => setShowHeroNameAlert(false)}
            />
            <IonAlert
                isOpen={showClientNameAlert}
                header="Change Client Name"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            console.log(data);
                            document.getElementById('clientNameLbl').innerHTML=data['clientName'];

                            firestore.doc(`users/${props.user.uid}`).set({
                                characterName: data['clientName'],
                                clientName: data['clientName'],
                            }, {merge:true}).then(function() {
                                //window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'clientName',
                        value: (`${props.user.clientName}` !== '' && `${props.user.clientName}` !== 'undefined') ? `${props.user.clientName}`: ``,
                        placeholder: (`${props.user.clientName}` !== '' && `${props.user.clientName}` !== 'undefined') ? `${props.user.clientName}`: `Please fill in your Client Name`
                    }
                ]}
                onDidDismiss={() => setShowClientNameAlert(false)}
            />
            <IonAlert
                isOpen={changeContactEmail}
                header="Contact Email"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            console.log(data);
                            document.getElementById('contactEmailValue').innerHTML=data['contactEmail'];

                            firestore.doc(`users/${props.user.uid}`).set({
                                contactEmail: data['contactEmail'],
                            }, {merge:true}).then(function() {
                                console.log("Document successfully written!");
                                //window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'email',
                        name: 'contactEmail',
                        value:  (`${props.user.contactEmail}` !== '' &&  `${props.user.contactEmail}` !== 'undefined')  ? `${props.user.contactEmail}`: `${props.user.email}`,
                        placeholder: (`${props.user.contactEmail}` !== '' &&  `${props.user.contactEmail}` !== 'undefined')  ? `${props.user.contactEmail}`:  `${props.user.email}`
                    }
                ]}
                onDidDismiss={() => setChangeContactEmail(false)}
            />
            <IonAlert
                isOpen={showAboutAlert}
                header="Change About"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('aboutLbl').innerHTML=data['about'];

                            firestore.doc(`users/${props.user.uid}`).update({
                                about: data['about']
                            }).then(function() {
                                // console.log("Document successfully written!");
                                window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'about',
                        value:  (`${props.user.about}` !== '' &&  `${props.user.about}` !== 'undefined')  ? `${props.user.about}`: ``,
                        placeholder:  (`${props.user.about}` !== '' &&  `${props.user.about}` !== 'undefined') ? `${props.user.about}`: `Please fill in About`
                    }
                ]}
                onDidDismiss={() => setShowAboutAlert(false)}
            />
            <IonAlert
                isOpen={showTeamAlert}
                header="Change Team Name"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('teamNameLbl').innerHTML=data['teamName'];
                            firestore.doc(`users/${props.user.uid}`).update({
                                teamName: data['teamName']
                            }).then(function() {
                                //console.log("Document successfully updated!");
                                window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'teamName',
                        value: (`${props.user.teamName}` !== '' && `${props.user.teamName}` !== 'undefined') ? `${props.user.teamName}`: ``,
                        placeholder: (`${props.user.teamName}` !== '' && `${props.user.teamName}` !== 'undefined') ? `${props.user.teamName}`: `Please fill in your Team Name`
                    }
                ]}
                onDidDismiss={() => setShowTeamAlert(false)}
            />
            <IonAlert
                isOpen={showBackstoryAlert}
                header="Backstory"
                cssClass='backstory-class'
                buttons={[
                    'Cancel',
                    {
                        text: 'Save',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('backstoryLbl').innerHTML=data['backstory'];
                            firestore.doc(`users/${props.user.uid}`).update({
                                backstory: data['backstory'],
                            }).then(() => console.log("updated"));
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'textarea',
                        name: 'backstory',
                        // value: document.getElementById('backstoryLbl').i,
                        value:  (`${props.user.backstory}` !== '' && `${props.user.backstory}` !=='undefined') ? `${props.user.backstory}`: ``,
                        placeholder: (`${props.user.backstory}` !== '' && `${props.user.backstory}` !=='undefined') ? `${props.user.backstory}`: `Please fill in your Backstory`
                    }
                ]}
                onDidDismiss={() => setShowBackstoryAlert(false)}
            />
            <IonAlert
                isOpen={showChangeIG}
                header="Instagram Handle"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('igHandleLbl').innerHTML=data['igHandle'];

                            firestore.doc(`users/${props.user.uid}`).update({
                                igHandle: data['igHandle'],
                            }).then(function() {
                                //console.log("Document successfully updated!");
                                window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'igHandle',
                        value:  (`${props.user.igHandle}` !== 'undefined' && `${props.user.igHandle}` !=='') ? `${props.user.igHandle}`: ``,
                        placeholder: (`${props.user.igHandle}` !== 'undefined' && `${props.user.igHandle}` !== '') ? `${props.user.igHandle}`: `Please fill in your Instagram Handle`
                    }
                ]}
                onDidDismiss={() => setShowChangeIG(false)}
            />
            <IonAlert
                isOpen={showChangeTikTok}
                header="Tik Tok Handle"
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (data) => {
                            //console.log(data);
                            document.getElementById('tikTokHandleLbl').innerHTML=data['tikTokHandle'];

                            firestore.doc(`users/${props.user.uid}`).update({
                                tikTokHandle: data['tikTokHandle'],
                            }).then(function() {
                                console.log("Document successfully updated!");
                                window.location.reload(false);
                            });
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'text',
                        name: 'tikTokHandle',
                        value:  (`${props.user.tikTokHandle}` !== '' && `${props.user.tikTokHandle}` !=='undefined') ? `${props.user.tikTokHandle}`: ``,
                        placeholder: (`${props.user.tikTokHandle}` !== 'undefined' && `${props.user.tikTokHandle}` !== '') ? `${props.user.igHandle}`: `Please fill in your Tik Tok Handle`
                    }
                ]}
                onDidDismiss={() => setShowChangeTikTok(false)}
            />
            <Footer />
        </IonPage>
    );
};

export default authWrapper(AccountEdit);

import {firestore} from "../firebaseConfig";

import { message } from "./notification";

export function sendJoinTestTeamEmail(user) {
    // add record to test user table

    //console.log("user", user);
    if (user?.testTeamMember) {
        message('You can access this section in the next app test session. Check your email for the invite!');
    }
    else {
        let userDocRef = firestore.collection('users').doc(user.uid);

        userDocRef.get().then(function(doc) {
            if (doc.exists) {
                let isTestTeamMember = doc.data()['testTeamMember'];

                if (isTestTeamMember == undefined) {
                    // rivalDocOps = rivalDocRef.set({
                    //     rivals: rivalDocs
                    // }, {merge: true});
                    let addToTestTeam = userDocRef.set({
                        testTeamMember: true,
                    }, {merge:true});
                    addToTestTeam.then(() => {
                        //send email
                        sentTestTeamInvitationEmail(user);
                       // window.location.reload(false);
                        message('Please check your email for the invite to the JFH App Test Team.');
                    });
                }

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

}

export function sendSubscribeNotification(user, email) {
    // add record to test user table

    //console.log("user", user);
    if (user?.subscribed) {
        message('You are part of the JFH audience.');
    }
    else {
        let userDocRef = firestore.collection('users').doc(user.uid);

        userDocRef.get().then(function(doc) {
            if (doc.exists) {
                let isSubscribedToNewsletter = doc.data()['subscribed'];

                if (isSubscribedToNewsletter == undefined) {
                    // rivalDocOps = rivalDocRef.set({
                    //     rivals: rivalDocs
                    // }, {merge: true});
                    let addToSubscribeList = userDocRef.set({
                        subscribed: true,
                    }, {merge:true});
                    addToSubscribeList.then(() => {
                        //send email
                        sendSubscribeEmail(user, email);
                        // window.location.reload(false);
                        message('Success. You have been added to the JFH audience.');
                    });
                }

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

}

function sendSubscribeEmail(user, email) {
    firestore.collection("mail")
        .add({
            to: email,
            cc: 'autumn@justiceforhire.com',
            bcc: "jan@justiceforhire.com, andra@reelwurld.com",
            message: {
                subject: 'Welcome to JFH App Test Team',
                html:
                    " Hello " + user.characterName + ", <br/> <br/>" +
                    " You have been added to the JFH audience" + "<br/> <br/>" +
                     "The JFH community is creating content every day. Don't miss anything. Plus, there's events, panels, trainings, meetups, workshops, and more. " + "<br/> <br/>" +
                    " With love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Subscribe Confirmation  Notification email for delivery!", user.email));
}

function sentTestTeamInvitationEmail(user) {
    firestore.collection("mail")
        .add({
            to: user.email,
            cc: 'andra@reelwurld.com',
            bcc: "jan@justiceforhire.com",
            message: {
                subject: 'Welcome to JFH App Test Team',
                html:
                    " Hello " + user.characterName + ", <br/> <br/>" +
                    " Building a cinematic universe with a community means building the future of filmmaking on your phone ---. We’ve been producing our series and making our app from the ground up completely independent of the Hollywood system, and now we’re assembling heroes from across JFH that want to be the first to experience the app that will change audience participation in movies and shows forever -. The JFH App injects you into the heart of our cinematic universe, allowing you to take on Missions that influence the collective story, or focus on Challenges that help you tell your character’s solo story. You’ll find other Heroes, Clients, and Villains to link up with along the way. " + "<br/> <br/>" +
                    " Register in advance for this meeting <code><a href='https://us02web.zoom.us/meeting/register/tZ0kdOGhpz4vGtUO4ttOt6hSy5zyk_tlaj_t' target='_blank' style='font-family: arial;'> here</a></code>."  + "<br/> <br/>" +
                    " See you Wednesday on Zoom," + "<br/> <br/>" +
                    " With love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Join Test Team Notification email for delivery!", user.email));
}


export function findUserAndSendAssetsEmail(userId) {
    let userDocRef = firestore.collection('users').doc(userId);

    userDocRef.get().then(function(doc) {
        if (doc.exists) {
            let sendToEmail = doc.data()['contactEmail'] ? doc.data()['contactEmail']:doc.data()['email'];

            if (sendToEmail) {
                sendAssetsEmailTo(sendToEmail, doc.data()['characterName']);
            }

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
}

export function sendAssetsEmail(user) {

    firestore.collection("mail")
        .add({
            to: user.email,
            cc: 'jan@justiceforhire.com',
            bcc: "andra@reelwurld.com",
            message: {
                subject: 'JFH Brand Assets',
                html:
                    " Hello " + user.characterName + ", <br/> <br/>" +
                    " Thanks for getting in touch with us!" + "<br/> <br/>" +
                    " Download the JFH Brand Assets for your own mini-movie <code><a href='https://drive.google.com/drive/folders/1rKZoVsNth_FsiXrI1-Yq1IFwX-0uMcpk?usp=sharing' target='_blank' style='font-family: arial;'> here</a></code>."  + "<br/> <br/>" +
                    " With love," + "<br/> " +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>" + "<br/> <br/>" +
                    "* By downloading the JFH Brand Assets you're agreeing to the terms and conditions  of the Justice for Hire universe. " + "<br/>" +
                    "All JFH brand assets are copyright of ReelwUrld, Inc. All rights reserved.",
            },
            secure:true,
        })
        .then(() => console.log("Assets Download email for delivery!", user.email));


}


export function sendAssetsEmailTo(email, characterName) {

    firestore.collection("mail")
        .add({
            to: email,
            cc: 'jan@justiceforhire.com',
            bcc: "andra@reelwurld.com",
            message: {
                subject: 'JFH Brand Assets',
                html:
                    " Hello " + characterName + ", <br/> <br/>" +
                    " Thanks for getting in touch with us!" + "<br/> <br/>" +
                    " Download the JFH Brand Assets for your own mini-movie <code><a href='https://drive.google.com/drive/folders/1rKZoVsNth_FsiXrI1-Yq1IFwX-0uMcpk?usp=sharing' target='_blank' style='font-family: arial;'> here</a></code>."  + "<br/> <br/>" +
                    " For best viewing on the app, please upload videos in the following format:" + "<br/> " +
                    "  - 1080p resolution" + "<br/> " +
                    "  - MP4 file type" + "<br/>" +
                    " We’re working hard to optimize all file types and formats." + "<br/> <br/>" +
                    " With love," + "<br/> " +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>" + "<br/> <br/>" +
                    "* By downloading the JFH Brand Assets you're agreeing to the terms and conditions  of the Justice for Hire universe. " + "<br/>" +
                    "All JFH brand assets are copyright of ReelwUrld, Inc. All rights reserved.",
            },
            secure:true,
        })
        .then(() => console.log("Assets Download email for delivery!", email));


}


export function sendGetComicBook(user) {
    firestore.collection("mail")
        .add({
            to: 'andra@reelwurld.com',
            message: {
                subject: 'Welcome to JFH App Test Team',
                html:
                    " Hello,  " + user.characterName + " got the JFH Comic Book Issue 0  "+ ", <br/> <br/>" +
                    " With love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Subscribe Confirmation  Notification email for delivery!", user.email));
}


export function sendDevCallRequest(user) {
    firestore.collection("mail")
        .add({
            to: 'jan@justiceforhire.com',
            bcc: 'andra@reelwurld.com',
            message: {
                subject: user.characterName + ' Development Call Request',
                html:
                    " Hello,  " + user.characterName + " requested a development call  "+ ", <br/> <br/>" +
                    " With love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Development Call  Notification email for delivery!", user.email));
}

export function sendCompleteProfile(userEmail, characterName) {
    firestore.collection("mail")
        .add({
            to: userEmail,
            bcc: 'andra@reelwurld.com',
            message: {
                subject: characterName + ' Complete Profile',
                html:
                    " Hello,  " + characterName+", <br/> <br/>" +
                    " To build a Cinematic Universe takes COMMITMENT. "+ "<br/> <br/>" +
                    " We’re going to make movies and shows together for years to come, all because of the stories we tell together on this app. " + "<br/> <br/>" +
                    " When you join JFH, other characters build on your story. Contributing to a crowdsourced cinematic universe is like placing a brick in a bridge. We all rely on each other’s contributions, especially as our universe grows. Therefore, <span className=\"italic\">once you create a profile, you won’t be able to delete it </span>(you’ll be able to make changes, though). Your contributions to the story through your character and content will live on whether or not you continue to create new content in JFH. If your content is ever used in a production that makes money, you will share in the profits. This is not your typical social network. If you’re ready to be a part of a cinematic universe, then we look forward to seeing you inside. "+ ", <br/> <br/>" +
                    " Click <code><a href='https://justiceforhire.app/login' target='_blank'><p style='font-family: arial, helvetica'>this</p></a></code> to login and agree to the terms above" + "<br/> <br/>" +
                    " With love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Complete Profile  Notification email for delivery!", userEmail));
}


export function sendEmailToUserById(userId, emailSubject, emailContent) {

    let emailDestinationQuery = firestore.doc(`users/${userId}`);

    let emailDestinationQueryRef = emailDestinationQuery.get().then(docDB => {
        if (docDB) {
            //console.log("docDB", docDB);
            if (docDB.data() && docDB.data()['email'] !== undefined) {
                //console.log("email address", docDB.data()['email']);
                    firestore.collection("mail")
                        .add({
                            to: docDB.data()['email'],
                            message: {
                                subject: emailSubject,
                                html:
                                    " Hello " + docDB.data()['characterName']+ ", <br/>" +
                                    emailContent + "<br/> <br/>" +
                                    "   With Love," + "<br/> <br/>" +
                                    "   Justice F. Hire" + "<br/> <br/>" +
                                    "#HeroesUnitingWorldwide" + " <br/>" +
                                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",

                            },
                            secure:true,
                        })
                        .then(() => console.log(emailSubject + "email for delivery!"))
                        .catch(error => console.log("error sending email"));
                }

            }

    }).catch(error => console.log('error sending email'));


}

export function sendApprovalPendingEmail(userId, requestType) {

    let emailSubject = requestType + ' Approval Pending' ;

    let emailContent = 'Your ' + requestType +' Change Request has been received and is pending Brand Admin Approval. ';

    sendEmailToUserById(userId,emailSubject, emailContent);
}
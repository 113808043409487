import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonText,
    IonHeader,
    IonRow,
    IonCol,
    IonLabel,
    IonFabButton,
    IonIcon,
    IonModal,
    IonItem,
    IonCard,
    IonThumbnail,
    IonImg,
    IonGrid
} from '@ionic/react';

import {personAddOutline, play} from "ionicons/icons";
import UserAvatar from "./UserAvatar";
import {firestore} from "../firebaseConfig";
import {toast} from "../toast";
import {downloadPictureContent, viewAssociation, viewProfileAssociation } from '../util/avatarUtils';


interface CompletedByListProps {
    showModalWindow: boolean;
    ctaObjectId: string;
    fromPage: string;
    deliveryModule: any;
    objectType: string;
    loggedInUser: any;
}


const CompletedByList: React.FC<CompletedByListProps> = (props) => {


    const [completedByUserList, setCompletedByUserList] = useState<any>([{}]);

    const [myMap, setMyMap] = useState(new Map());

    const updateMap = (k,v) => {
        setMyMap(new Map(myMap.set(k,v)));
    }

    useEffect( () => {
        let isSubscribed = true;

        let query;

        console.log("proops object type", props);
        if (props.deliveryModule.contentType !== 'mission') {
            query = firestore.collection('content').where('publicContent','==',true).where('objectRefId', '==', props.deliveryModule.objectRefId).orderBy("timeCreated", "desc");
        }
        else {
            query = firestore.collection('content').where('publicContent','==',true).where('objectRefId', '==', props.ctaObjectId).orderBy("timeCreated", "desc");
        }

        query.get()
            .then(snapshot => {
                    let contentList = [];

                    let contentMap = new Map();

                    //create a map
                    snapshot.forEach(doc => {
                        let docData = { ...doc.data(), uid: doc.id }
                        contentMap.set(doc.data()['completedByHeroId'], docData)
                        contentList.push(docData)
                    });

                    console.log("contentList", contentList);
                    setCompletedByUserList(contentList);
                    setMyMap(contentMap);

             }).catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        return () => {isSubscribed = false}
    }, [props.ctaObjectId]);

    function playContent(contentItem)  {
        toast("content item content url"+ contentItem.contentVideo);
    }
    return (

        <IonModal isOpen={props.showModalWindow} cssClass='completed-by-modal'>
            <IonHeader> <IonRow className="ion-justify-content-center" style={{padding: 10}}>
                <IonText className="appTextMain">  {props.deliveryModule? props.deliveryModule.contentType.toUpperCase(): 'MISSION'}<br/> {props.deliveryModule.title} <br/> was completed by: </IonText>
            </IonRow></IonHeader>
            <IonContent className="completed-by-modal"
                        scrollEvents={true}
                        onIonScrollStart={() => {
                        }}
                        onIonScroll={() => {
                        }}
                        onIonScrollEnd={() => {
                        }} scrollX scrollY>

                <IonRow>
                    <IonLabel className="sectionTitle"
                              style={{'paddingLeft': '20px', 'color': '#005494'}}>
                    </IonLabel>
                </IonRow>
                { (completedByUserList === undefined || completedByUserList.empty ) &&
                <IonFabButton style={{
                    '--background': '#ffffff', "margin": "20px auto 20px auto", 'minHeight': '111px', 'width':'111px'
                }}>
                    <IonIcon icon={personAddOutline} style={{'color': '#005494'}}/>
                </IonFabButton>
                }
                { (completedByUserList === undefined || completedByUserList.empty) &&
                <IonRow style={{'textAlign':'center'}}>

                    <IonLabel className="sectionTitle"
                              style={{"margin": "20px auto 20px auto", 'color': '#005494'}}>
                        <IonText>
                            This {props.deliveryModule.contentType} was not completed by anyone yet. <br/>Be the first to complete it by pressing +
                        </IonText>
                    </IonLabel>

                </IonRow>}
                {completedByUserList && !completedByUserList.empty
                && completedByUserList.map((completedItem, idx)=> {
                    return (
                        <IonCard key={idx} className="child4" style={completedItem.brandSelected === true ? {'border': '2px solid #005494'
                        }:{}}>
                            <IonThumbnail style={{'height':'80%', 'width':'100%'}}>
                                <IonImg id={'completedByList'+idx+completedItem.completedByUserId} src={completedItem.posterUrl ? downloadPictureContent(completedItem.posterUrl,completedItem.completedByUserId, 'completedByList'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                                        onClick={() => viewAssociation(completedItem.completedByUserId, 'none', props)}/>
                            </IonThumbnail>

                            <IonGrid style={{padding:'1px', textAlign:'center'}}>
                                <IonRow>
                                    <IonText className="brandText bolder" style={{'fontSize':'8px'}}>
                                        {completedItem.completedByHeroName}
                                    </IonText>
                                </IonRow>
                            </IonGrid>

                        </IonCard>
                        // <IonItem key={idx} lines="none" style={{'--background':'#000000'}}>
                        //     <UserAvatar userPhotoURL={completedItem.completedByPhotoUrl} userId={completedItem.completedByUserId} userHeroName={completedItem.completedByHeroName} userRole={'hero'} showMoreInfo={false}/>
                        //     <IonCol size="4">
                        //         <IonText style={{'color':'#ffffff'}}> {completedItem.completedByHeroName}</IonText>
                        //     </IonCol>
                        //     <IonCol>
                        //         <IonIcon icon={play} style={{'color':'#ffffff'}} onClick={()=>playContent(completedItem)}></IonIcon>
                        //     </IonCol>
                        // </IonItem>
                    );
                })};

            </IonContent>

        </IonModal>
    );
}

export default CompletedByList;

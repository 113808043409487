import React, {useEffect, useState, useRef} from 'react';
import { storageRef } from '../../firebaseConfig';
import {PremiumContent} from "../../models/PremiumContent";
import {IonAvatar, IonIcon} from "@ionic/react";
import {pauseOutline, playOutline, stop} from "ionicons/icons";
import {toast} from "../../toast";
import {Device} from "@capacitor/core";
import {openFullScreen} from "../../util/videoUtils";
import { getPictureAddress } from '../../util/avatarUtils';


interface PremiumContentPlayerProps {
    item: PremiumContent;
}

const PremiumContentPlayer: React.FC<PremiumContentPlayerProps> = ({ item }) => {

    // init = async (fullscreen: boolean) => {
    //     let videoPlayer: any;
    //     const info = await Device.getInfo();
    //     if (info.platform === "ios" || info.platform === "android") {
    //         videoPlayer = CapacitorVideoPlayer;
    //     } else {
    //         videoPlayer = CapacitorVPPlugin.CapacitorVideoPlayer;
    //     }
    //
    // }

    const [pictureContentUrl, setPictureContentUrl] = useState(item.contentUrl);
    // const [clickedToPlay, setClickedToPlay] = useState(false);
    // const [showActionSheet, setShowActionSheet] = useState(false);

    const [platformInfoName, setPlatformInfoName] = useState('web');
    const videoRef = useRef(null);
    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            // if (info.platform === 'web' || info.platform ==='android') {
            //
            // }
            setPlatformInfoName(info.operatingSystem);
        } );
        // if(item.posterUrl && item.posterUrl!=='' && item.posterUrl!==undefined) {
        //     storageRef.child(item.posterUrl).getDownloadURL().then(function (thumbnailContent) {
        //         //console.log("htumbanil"+thumbnailContent);
        //         let img = document.getElementById(item.code);
        //         //contentUrl = contentUrl.replace("%2F","/");
        //         img.setAttribute("poster", thumbnailContent);
        //     }).catch(function (error) {
        //         toast("error"+error);
        //     });
        // }
        //
        // if (item.contentUrl!=='' && item.contentUrl!== undefined) {
        //     //console.log("content video", item.contentUrl.toString());
        //     let pictureContentRef = storageRef.child(item.contentUrl);
        //     //console.log("content ref", pictureContentRef);
        //     pictureContentRef.getDownloadURL().then(function(contentDownloadUrl) {
        //
        //
        //         let imgC = document.getElementById(item.code);
        //
        //         imgC.setAttribute("src", contentDownloadUrl);
        //         setPictureContentUrl(contentDownloadUrl);
        //
        //     }).catch(function(error) {
        //
        //         switch (error.code) {
        //             case 'storage/object-not-found':
        //                 // File doesn't exist
        //                 break;
        //
        //             case 'storage/unauthorized':
        //                 // User doesn't have permission to access the object
        //                 break;
        //
        //             case 'storage/canceled':
        //                 // User canceled the upload
        //                 break;
        //
        //
        //             case 'storage/unknown':
        //                 // Unknown error occurred, inspect the server response
        //                 break;
        //         }
        //     });
        // }
    }, [item]);




  function onVideoClick(e) {
      //let videoEl = document.getElementById(item.code) as HTMLVideoElement;

      let videoEl = videoRef.current;

      let playBtn = document.getElementById('play'+item.code);
      let stopBtn = document.getElementById('stop'+item.code);

      if (platformInfoName ==='android') {
          videoEl.onfullscreenchange = (e=> {
            if (!document.fullscreenElement)
              videoEl.pause();
          })
      }

      if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
          //hide play. show stop
          playBtn.style.display='block';
          stopBtn.style.display='none';

          videoEl.pause();
      }
      else if (videoEl.paused || videoEl.ended) {
          // videoEl.webkitEnterFullScreen();
          videoEl.play().then(
              () => {
                  playBtn.style.display='none';
                  stopBtn.style.display='block';
                  if (platformInfoName ==='android') {
                      openFullScreen(videoEl);
                  }
                  console.log("resume video");
                  console.log("video play time", videoEl.currentTime);
              }
          ).catch(() => console.log("error playing video"));
      }
      else {
              videoEl.play().then(
                  () => {
                      playBtn.style.display='none';
                      stopBtn.style.display='block';
                      if (platformInfoName ==='android') {
                          openFullScreen(videoEl);
                      }
                  }
              ).catch(() => console.log("error playing video"));
          }
      }


    return (
        <div className="video-wrapper">
            <div className="overlay"
              onClick={onVideoClick}
            >
                {
                    item.contentUrl && item.contentUrl != '' &&

                <IonAvatar className="right playBtn" id={'play'+item.code}>
                        <IonIcon className="highContrast" style={{padding: 3}} icon={playOutline}/>
                </IonAvatar>}
                {
                    item.contentUrl && item.contentUrl != '' &&
                <IonAvatar id={'stop'+item.code} style={{display: 'none'}} className="right playBtn">
                    <IonIcon className="highContrast" style={{padding: 3}} icon={pauseOutline} />

                </IonAvatar>}

            </div>


            <video id={item.uid+item.contentType} poster={getPictureAddress(item.posterUrl, item.uid,item.uid+item.contentType)} controlsList="nodownload"
                   ref={videoRef}
                   onEnded={e=> videoRef.current.load()}
                   onPause={e=> {videoRef.current.load(); videoRef.current.controls=false; }}
            >
                <source src={getPictureAddress(item.contentUrl, item.uid,item.uid+item.contentType)}
                        type="video/mp4"/>
                Your browser does not support the video tag.
            </video>

            {/* <video id={item.code} poster={item.posterUrl} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>*/}
            {/*    <source src={pictureContentUrl} type={item.videoContentType}  />*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}
        </div>
    );
};

export default PremiumContentPlayer;


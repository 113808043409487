import React, { useState, useEffect } from 'react';

import Footer from '../../components/Footer';

import { firestore, storageRef} from '../../firebaseConfig';
import {
    IonPage,
    IonRow,
    IonImg,
    IonContent,
    IonLabel,
    IonText,
    IonList,
    IonItem,
    IonRouterLink,
    IonListHeader,
    IonFabButton,
    IonSearchbar,
    IonIcon,
    IonAvatar, IonButton
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import {ally} from "./allyrival";
import Header from "../../components/Header";
import { personAddOutline } from 'ionicons/icons';
import {createRelationship, removeAllyRelationship} from './relationships';
import {downloadPictureContent} from "../../util/avatarUtils";

interface ViewAlliesPageProps extends RouteComponentProps<{
    userId: string,
    currentUserId: string,
}> {}

async function getRelationshipAllyArray(userId) {
    let relOut = firestore.collection('relationshipRequests').where('senderId', '==', userId).get();
    let relIn = firestore.collection('relationshipRequests').where('rivalId', '==', userId).get();

    const [relOutSnapshot, relInSnapshot] = await Promise.all([
        relOut,
        relIn
    ]);


    const relOutArray = relOutSnapshot.docs;
    const relInArray = relInSnapshot.docs;

    const relArray = relOutArray.concat(relInArray);

    return relArray;
}

const ViewAllies: React.FC<ViewAlliesPageProps> = ({match}) => {

    const [alliesList, setAlliesList] = useState<any>(['']);
    const [rivalsList, setRivalsList] = useState<any>(['']);
    const [suggestionList, setSuggestionList] = useState<any>(['']);
    const [searchText, setSearchText] = useState('');
    const [isAlly, setIsAlly] = useState(true);
    const [isRival, setIsRival] = useState(true);
    const [senderInfo, setSenderInfo] = useState({});

    const {userId, currentUserId} = match.params;

    useEffect(() => {

        let userDocRef = firestore.collection('users').doc(userId);

        userDocRef.get().then((doc)=> {
            const rivalsListRef = {id: doc.id, ...doc.data()};


            getRelationshipAllyArray(userId).then(snapshotRelationships => {
                let rivalsList = [];
                let alliesList = [];
                snapshotRelationships.forEach(snapshotRelationship => {

                    if (snapshotRelationship.data()['relationshipType'] === 'rival') {
                        rivalsList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id})
                    }
                    else if (snapshotRelationship.data()['relationshipType'] === 'ally') {
                        alliesList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id});
                    }
                    // if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {

                });

                if (searchText !== '') {
                    if (alliesList !== undefined) {
                        alliesList = alliesList.filter(rivalItem => rivalItem.rivalName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                    }
                }
                setRivalsList(rivalsList);
                setAlliesList(alliesList);


                let rivalSuggestionRef = firestore.collection('users').where("complete", "==", true);
                let query = searchText.toLowerCase()
                let stringBound = query.slice(0, query.length-1)
                    + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)

                if (query!='' && query.length >=1) {
                    // console.log("suggestions", suggestionsRef);
                    rivalSuggestionRef.where('characterName_nocase','>=', query)
                        .where('characterName_nocase','<', stringBound)
                        .where('characterName_nocase','!=', '')

                }
                rivalSuggestionRef = rivalSuggestionRef.orderBy("characterName_nocase", "asc");

                rivalSuggestionRef.onSnapshot(snapshot => {
                    let suggestionList = [];

                    snapshot.forEach(suggestionRivalDoc => {
                        if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {
                            suggestionList.push({...suggestionRivalDoc.data(), uid: suggestionRivalDoc.id})
                        }
                    });
                    suggestionList = suggestionList.filter(item => item.uid !== userId);

                    if (rivalsList && rivalsList.length >= 1) {
                        for (let i = 0; i < rivalsList.length; i++) {
                            let rivalId = rivalsList[i]['rivalId'];
                            let senderId = rivalsList[i]['senderId'];
                            console.log("rival id", rivalId);
                            suggestionList = suggestionList.filter(item => item.uid !== rivalId);
                            suggestionList = suggestionList.filter(item => item.uid !== senderId);
                            //console.log(suggestionList);
                        }
                    }

                    if (alliesList && alliesList.length >= 1) {
                        for (let i = 0; i < alliesList.length; i++) {
                            let allyId = alliesList[i]['rivalId'];
                            let senderId = alliesList[i]['senderId'];
                            console.log("ally ", allyId);
                            suggestionList = suggestionList.filter(item => item.uid !== allyId);
                            suggestionList = suggestionList.filter(item => item.uid !== senderId);
                            //console.log(suggestionList);
                        }
                    }


                    if (searchText !== '') {
                        suggestionList = suggestionList.filter(item => item.characterName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                    }
                    setSuggestionList(suggestionList);
                });
            });
        });


    }, [userId, searchText]);


    function followAsAlly(allyId, allyHeroId, allyName, allyPhotoUrl) {
        console.log("Ally info:", allyId + allyName + +"photo" + allyPhotoUrl + '-' +"userId:" + userId);


        let allyRelationshipRequest = {
            senderId: userId,
            senderName: senderInfo['characterName'],
            senderPhotoURL: senderInfo['photoURL'],
            rivalId: allyId,
            rivalPhotoUrl: allyPhotoUrl,
            rivalName: allyName,
            status: 'approved',
            relationshipType: 'ally',
        }

        createRelationship(allyRelationshipRequest);
    }

    function removeAlly(allyDoc) {
        console.log("ally doc", JSON.stringify(allyDoc));
        removeAllyRelationship(allyDoc.uid);
    }

    // function downloadPictureContent(photoPath, id) {
    //
    //     if(photoPath !== '' && photoPath !== undefined) {
    //         storageRef.child(photoPath).getDownloadURL().then(function (pathUrl) {
    //             let img = document.getElementById('profile'+id);
    //             img.setAttribute('src', pathUrl);
    //         }).catch(function (error) {
    //             // Handle any errors
    //         });
    //     }
    //
    //     return '';
    // }

    // function viewProfile(profileId)  {
    //     if (profileId!=undefined) {
    //         window.location.href=`/viewAssociation/${profileId}`;
    //     }
    // }
    function viewProfile(profileId, relationship)  {
        if (profileId!=undefined) {
            window.location.href=`/viewAssociation/${profileId}/${relationship}`;
        }
    }

    return (
        <IonPage>
            <Header/>

            <IonContent className="ion-padding-bottom" scrollX scrollY>

                { (alliesList === undefined || alliesList?.length < 1) &&
                <IonFabButton disabled style={{
                    '--background': '#ffffff', "margin": "20px auto 20px auto", 'minHeight': '111px', 'width':'111px'
                }}>
                    <IonIcon icon={personAddOutline} style={{'color': '#005494'}}/>
                </IonFabButton>
                }
                { (alliesList === undefined || alliesList?.length < 1) &&
                <IonRow className="ion-justify-content-center">

                    <IonLabel className="sectionTitle"
                              style={{"margin": "20px 20px 20px 20px", 'color': '#005494'}}>
                        <IonText className="ion-text-center">  Allies </IonText>
                    </IonLabel>
                    <IonLabel
                        style={{'margin': '10px 10px 10px 10px', 'fontSize':'10pt','color': '#005494'}}>
                        <IonText className="ion-text-center"> Once they ally other heroes, you'll see them here. </IonText>
                    </IonLabel>
                </IonRow>}
                <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} debounce={0}></IonSearchbar>

                {alliesList && alliesList.length >= 1 &&
                <IonList>
                    <IonListHeader>
                        <IonLabel className="sectionTitle"
                                  style={{'align': 'center', 'color': '#005494'}}>Allies
                        </IonLabel>
                    </IonListHeader>
                    {alliesList && alliesList.map((ally, index) => {
                        return (
                            <IonItem key={index} lines="none">


                                            {ally?.senderId === userId ?
                                                <IonAvatar slot="start">
                                                    <IonImg id={'viewAllyProfile'+ally.rivalId} src={ally.rivalPhotoUrl ? downloadPictureContent(ally.rivalPhotoUrl,ally.rivalId,'viewAllyProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                        onClick={() => viewProfile(ally.rivalId, 'ally')}/>
                                                </IonAvatar>
                                                :<IonAvatar slot="start">
                                                     <IonImg id={'viewAllyProfile'+ally.senderId} src={ally.senderPhotoURL ? downloadPictureContent(ally.senderPhotoURL,ally.senderId,'viewAllyProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                        onClick={() => viewProfile(ally.senderId, 'ally')}/>
                                                </IonAvatar>
                                            }



                                        <IonLabel>
                                            {ally?.senderId === userId
                                                ? <IonRouterLink href={`/viewAssociation/${ally?.rivalId}`}>{ally?.rivalName}</IonRouterLink>
                                                : <IonRouterLink href={`/viewAssociation/${ally?.senderId}`}>{ally?.senderName}</IonRouterLink>}
                                            {/*{ally?.rivalName} | {ally?.senderName}| {ally?.rivalId} |{ally?.senderId}| {userId}*/}
                                        </IonLabel>

                                        { (ally?.senderId === currentUserId || ally?.rivalId === currentUserId) ?
                                            <>
                                            <IonButton className="alliesBtn" onClick={() => removeAlly(ally)} > Remove  </IonButton>
                                            <div style={{
                                                marginLeft: -17,
                                                marginTop: 0,
                                                marginRight: 6,
                                                width: 27, height: 42,
                                                transform: "skew(20deg)",
                                                backgroundColor: 'white',
                                                borderWidth: 2, borderStyle: 'solid', borderColor: '#005494',
                                                boxShadow: "0px 4px 4px -4px dimgray",
                                            }}/></>
                                            :<></>}

                            </IonItem>
                        );
                    })}
                </IonList>
                }

            </IonContent>
            <Footer/>
        </IonPage>
    )

}

export default ViewAllies;

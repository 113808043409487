import React from 'react';
import '../Challenges/Challenges.css';

import {IonCard,  IonSlide, IonSlides} from "@ionic/react";
import './Sliders.css';




class MoreResourcesFeed extends React.Component<{}>  {

    render() {


        const slideOptsIRL = {
            effect: 'cube',
            zoom: false
        };

        return (

            <IonSlides pager options={slideOptsIRL}>

                <IonSlide key="updatesIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2Fjfh_updates_watch_page.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UMl6vJ7Hq70bi7NQIeJCP0e","_blank")}/>
                    </IonCard>
                </IonSlide>
                <IonSlide key="eventsIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2Fevents_watch_page.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UO3zLY7yCVyDucqYc03b6Pr","_blank")}/>
                    </IonCard>
                </IonSlide>
                <IonSlide key="developmentCallsIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2FDevelopment_Calls.png?alt=media"} onClick={()=> window.open(" https://youtube.com/playlist?list=PLXA2PxwVM_UOIyujliyzpAqrj8cwXMqi4","_blank")}/>
                    </IonCard>
                </IonSlide>


                      <IonSlide key="heroTrainingIDx">
                            <IonCard style={{"--background":"none"}}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fwatch%2FHeroTraining2.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UNN57Zk2RKf-FfmErktrg-J","_blank")}/>
                            </IonCard>
                        </IonSlide>
                <IonSlide key="heroMeetupsIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2Fhero_meetups_watch_page.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UP-C7tnGrzQm9EPVsoAbfOH","_blank")}/>
                    </IonCard>
                </IonSlide>
                <IonSlide key="heroesCultureIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2FHeroes_Culture.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UO4EcWyegNMuQBC2Xnoyana","_blank")}/>
                    </IonCard>
                </IonSlide>
                <IonSlide key="appTestsTDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2Fapp_test_watch_page.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UNE_-VKo2GgPrVEvM3F1jol","_blank")}/>
                    </IonCard>
                </IonSlide>
                <IonSlide key="liveEditingIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2Flive_editing.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UPsdSA3KZzzAIhVDwEjWO51","_blank")}/>
                    </IonCard>
                </IonSlide>
                <IonSlide key="reelwurldIDx">
                    <IonCard style={{"--background":"none"}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2FMoreResources%2FReelwurld_watch%20page_alt.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UMs4VrB9KYrkD-bHucnoXEO","_blank")}/>
                    </IonCard>
                </IonSlide>
            </IonSlides>
        );
    };
}
export default MoreResourcesFeed;

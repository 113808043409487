import React, { useState } from 'react';
import {
    IonInput,
    IonRow,
    IonContent,
    IonButton,
    IonPage,
    IonRouterLink,
    IonCol,
    IonCard,
    IonCardContent,
    IonGrid,
    IonCardHeader,
    IonThumbnail,
    IonImg,
    IonText,
    IonFooter,
    IonNote,
    IonIcon,
    IonLabel,
    IonToolbar
} from '@ionic/react';

import './Login.css';
import {loginUser} from '../firebaseConfig';
import { toast } from '../toast';
import { NavLink } from 'react-router-dom';
import { arrowDown, share } from 'ionicons/icons';


const LoginHero: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [approved, setApproved] = useState(false)
    const [error, setError] = useState(null);

    const backgroundVideoUrl = "https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFHAppBGSignupVideo.mp4?alt=media&token=80dc4d83-fd1c-46c0-8b15-206ea03a0d9a";


    const BackgroundVideo = () => {
        return (
            <video  autoPlay playsInline src={backgroundVideoUrl} className="loginPage__video" controls={false}  />
        );
    };

    let videoPlayerContainer = document.getElementById('backgroundVideo') as HTMLVideoElement;
    if (videoPlayerContainer !== null) {
        videoPlayerContainer.setAttribute("autoplay","true");
        //videoPlayerContainer.play().then(()=> console.log("play video"));
    }

    async function login() {

        if (email.trim() === '') {
            toast('Please type in your hero/client name.');
        }
            // else if (email.trim() === '') {
            //     toast('Please use a valid hero/client name.');
            // }
            // else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
            //  {
            //   // toast('Please use a valid email address.');
            //      setEmail(email+'@jfh.com');
            //      toast('email'+email+'@jfh.com');
        // }
        else if(password.trim() === '') {
            toast('Please type in your password.');
        }
        else {
            if (email.replace(/ /g, "").toLowerCase()==='jan'){
                loginUser(email+'@reelwurld.com', password).catch(err=>{
                    toast("There's been an error at login.", err.message);
                });
            }
            else {
                loginUser(email.replace(/ /g, "").toLowerCase()+'@jfh.com', password).catch(err=>{
                    console.log("login hero", email);
                    toast("There's been an error at login.", err.message);
                });
            }

        }
    }

    return (
        <IonPage>
            <IonContent className="loginPage">
                <video autoPlay playsInline src={backgroundVideoUrl} muted loop className="loginPage__video" controls={false} />
                {/*<video id="backgroundVideo" autoPlay loop className="loginPage__video" controls={false}>*/}
                {/*  <source src={backgroundVideoUrl} type="video/mp4" />*/}
                {/*</video>*/}
                <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <IonCard className="ion-justify-content-center center">
                        <div style={{marginBottom: 0}}>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFH_App_logo_WHITE.png?alt=media&token=356ffa2e-785d-4cdb-9e4e-9e71e3543abb"} className="login-box-image" style={{marginBottom: 0, marginTop: 10, height: 100}}></IonImg>
                        </div>
                        <IonCardContent className="login-box">
                            <IonInput  className="appText" color="light" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Hero/Client Name" onIonChange={(e: any) => setEmail (e.target
                                .value)}></IonInput>
                            <IonInput className="appText" type="password" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Password"
                                      onIonChange={(e: any) => setPassword (e.target.value)}
                                      onKeyPress={e => {if(e.keyCode == 13 || e.which == 13) login()} }
                            />
                            <NavLink to="forgotPassword">
                                <IonRow>
                                    <IonCol>
                                        <IonText className="ion-text-right" ><h6 className="smallSignin ion-no-margin ion-text-end" style={{'color':'#ffffff'}}>Forgot Password?</h6></IonText>
                                    </IonCol>
                                </IonRow>
                            </NavLink>
                            <IonGrid>
                                <IonRow>
                                    <IonCol><IonButton className="appBtn" onClick={login}><IonLabel className="loginBtnLabel">LOGIN</IonLabel></IonButton></IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol className="appText">
                                        <IonButton className="appBtn" href="/registration"><IonText className="registerBtnLabel ion-text-capitalize">New Here? Register</IonText></IonButton>
                                        {/*New Here? <IonRouterLink href="/preRegistration1" color="secondary"> Register</IonRouterLink>*/}
                                    </IonCol>
                                </IonRow>

                            </IonGrid>
                        </IonCardContent>

                        <IonFooter>

                            <IonGrid>
                                <IonRow className="footerText">
                                    <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                                        <div>
                                            <IonText >Powered by ReelwUrld™. Patent pending. <br/> All Rights reserved. <br/> </IonText>
                                        </div>
                                    </div>
                                </IonRow>

                                {/*<IonRow className="appText">*/}
                                {/*    <IonCol size="2">*/}
                                {/*        <IonImg src={"https://jfh-app-assets.s3.amazonaws.com/brand/JFH+ONLY+App+logo+WHITE.png"} className="login-box-image" style={{marginBottom: 0, marginTop: 10, height: 50}}></IonImg>*/}
                                {/*    </IonCol>*/}
                                {/*    <IonCol>*/}
                                {/*        <IonRow>*/}
                                {/*            <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>*/}
                                {/*                <IonText> <br/>Click  <IonIcon icon={share}/> below to add JFH to your home screen.</IonText>*/}
                                {/*            </div>*/}
                                {/*        </IonRow>*/}
                                {/*    </IonCol>*/}
                                {/*</IonRow>*/}
                            </IonGrid>
                        </IonFooter>
                    </IonCard>

                </div>
                {/*<IonFooter>*/}
                {/*   */}
                {/*</IonFooter>*/}
            </IonContent>


        </IonPage>

    );
};

export default LoginHero;
import React, { Component } from 'react';
import {fetchUserDocumentData, getCurrentUser} from '../firebaseConfig'
import { IonSpinner } from '@ionic/react';
import { withRouter } from 'react-router-dom';

const centeringContainerStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}

export default (ScreenComponent) => {
  class AuthenticatedComponent extends Component{//<{}, { fetching: boolean, authenticated: boolean, user: any, }> {
      constructor(props) {
          super(props);
          this.state = {
              fetching: true,
              user: null
          }
      }

      componentDidMount() {

        getCurrentUser()
        .then(authUser => {
           //console.log("AuthUser:: ", authUser)
            if (!authUser) {
              // debugger;
              this.props.history.push({pathname: '/login'});
            }else{
              return fetchUserDocumentData(authUser)
                    .then(user => {
                      //console.log("Firestore User:: ", user)
                      //console.log("and AuthUser:: ", authUser)
                      if (!user) {
                        // debugger;
                        this.props.history.push({pathname: '/login'});
                      }else{
                        this.setState({
                          fetching: false,
                          user: {...user, uid: authUser.uid}
                        })
                      }
                    }).catch(err => {
                      console.log("Failed to retrieve Firestore user with error:")
                      console.dir(err)
                    })
            }
          }
        ).catch(err => {
          console.log("Failed to fetch AuthUseer from firebase with error:")
          console.dir(err)
        })
      }

      render() {
          let {fetching, user} = this.state
          if (fetching)
              return <div style={centeringContainerStyle}>
                  <IonSpinner/>
              </div>
          else
              return <ScreenComponent user={user}/>
      }
  }
  return withRouter(AuthenticatedComponent)
}


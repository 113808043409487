import firebase from "firebase";
import { firestore } from "../../firebaseConfig";

import { notification } from "../../util/notification";
import { sendWelcomeDMEmail } from "../../util/email";



export const findConvo = async (sender, receiver) => {
    // console.log("sender id", sender.uid !== undefined ? sender.uid : sender.id);
    // console.log("receiver id", receiver.uid !== undefined ? receiver.uid : receiver.id);

    let senderId = sender.uid !== undefined ? sender.uid : sender.id;
    let receiverId = receiver.uid !== undefined ? receiver.uid : receiver.id;

    const existingSentConvoQuery = firestore.collection('conversation').where('senderId', '==', senderId).where('receiverId','==',receiverId).get();

    const existingReceivedConvoQuery = firestore.collection('conversation').where('senderId', '==', receiverId).where('receiverId','==',senderId).get();

    const [existingSentConvosSnapshot, existingReceivedConvosSnapshot] = await Promise.all([
        existingSentConvoQuery,
        existingReceivedConvoQuery
    ]);

    const existingSentConvosArray = existingSentConvosSnapshot.docs;
    const existingReceivedConvosArray = existingReceivedConvosSnapshot.docs;

    const existingConvosArray = existingSentConvosArray.concat(existingReceivedConvosArray);

    return existingConvosArray;
}

export const createConvo = (sender, receiver, history, redirect) => {

    console.log("receiver", receiver);
    console.log("sender", sender);
    let senderId = sender?.uid !== undefined ? sender?.uid : sender?.id;
    let receiverId = receiver?.uid !== undefined ? receiver?.uid : receiver?.id;

    const conversation = firestore.collection('conversation').add({
        senderId: senderId,
        senderHeroName: sender.characterName ? sender.characterName: (sender.heroName ? sender.heroName: sender.clientName),
        senderPhotoURL: sender.photoURL ? sender.photoURL: 'brand/noProfilePicture.png',
        receiverId: receiverId,
        receiverHeroName: receiver.characterName ? receiver.characterName : (receiver.heroName ? receiver.heroName: receiver.clientName),
        receiverPhotoURL: receiver?.photoURL ? receiver.photoURL: 'brand/noProfilePicture.png',
        sendTime: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),

}).then(refConvo => {
        console.log("created convo", refConvo.id);

        if(redirect) {
            history.push({
                pathname: "/conversationThread",
                state: {conversationId: refConvo.id, sender:sender, receiver: {uid: receiverId, photoURL: receiver?.photoURL, heroName: receiver.characterName, role:receiver.role}, messages: [''], user: sender, fromPage: 'chat'},
            });
        }

    });
}

export const createConvoWithMessage = (sender, receiver, history, messageData, redirect, redirectTo) => {

    // console.log("add conent to message and do not redirect !!!!",messageData);
    // console.log("sender  !!!!",sender);
    // console.log("receiver  !!!!",receiver);
    // console.log("messageData  !!!!",messageData);
    // console.log("redirectTo  !!!!",redirectTo);
    // console.log("redirect  !!!!",redirectTo);

    let receiverRefId = receiver.uid ? receiver.uid : receiver.id;


    const conversation = firestore.collection('conversation').add({
        senderId: sender.uid,
        senderHeroName: sender.heroName ? sender.heroName : (sender.senderHeroName ? sender.senderHeroName: sender.characterName),
        senderPhotoURL: sender.photoURL ? sender.photoURL : (sender.senderPhotoURL ? sender.senderPhotoURL : 'brand/noProfilePicture.png'),
        receiverId: receiver.uid,
        receiverHeroName: receiver.characterName ? receiver.characterName : receiver.heroName,
        receiverPhotoURL: receiver?.photoURL ? receiver.photoURL: 'brand/noProfilePicture.png',
        sendTime: firebase.firestore.FieldValue.serverTimestamp(),
        messages: [messageData],
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        receiverRole: receiver.role,
    }).then(refConvo => {
        console.log("created convo", refConvo.id);

        console.log('receiver uid', receiver.uid);
        console.log('receiver id', receiver.id);

        console.log('receiver id', receiverRefId);
        let receiverRef = firestore.collection('users').doc(receiverRefId);

        let receiverDMNotificationOps = receiverRef.set({
            unread: true
        }, {merge: true})
        receiverDMNotificationOps.then(() => {
            notification('Message sent successfully');
        });

        //send dm email


        if(redirect) {
            console.log("redirect", redirect);
            console.log("redirectTo", redirectTo);

            if (redirectTo) {
                window.location.href=redirectTo;
            }
            else {
                history.push({
                    pathname: "/conversationThread",
                    state: {conversationId: refConvo.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.characterName, role: receiver.role}, messages: [messageData], user: sender, fromPage: 'chat'},
                });
            }

        }

    });
}

export const createConvoWithMessageList = (sender, receiver, history, messageData, redirect, redirectTo) => {

    // console.log("add content to message and do not redirect !!!!",messageData);
    // console.log("sender  !!!!",sender);
    // console.log("receiver  !!!!",receiver);
    // console.log("messageData  !!!!",messageData);
    // console.log("redirectTo  !!!!",redirectTo);
    // console.log("redirect  !!!!",redirectTo);

    let receiverRefId = receiver.uid ? receiver.uid : receiver.id;


    const conversation = firestore.collection('conversation').add({
        senderId: sender.uid,
        senderHeroName: sender.heroName ? sender.heroName : (sender.senderHeroName ? sender.senderHeroName: sender.characterName),
        senderPhotoURL: sender.photoURL ? sender.photoURL : (sender.senderPhotoURL ? sender.senderPhotoURL : 'brand/noProfilePicture.png'),
        receiverId: receiver.uid,
        receiverHeroName: receiver.characterName ? receiver.characterName : receiver.heroName,
        receiverPhotoURL: receiver?.photoURL ? receiver.photoURL: 'brand/noProfilePicture.png',
        sendTime: firebase.firestore.FieldValue.serverTimestamp(),
        messages: messageData,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        receiverRole: receiver.role,
    }).then(refConvo => {
        console.log("created convo", refConvo.id);

        console.log('receiver uid', receiver.uid);
        console.log('receiver id', receiver.id);

        console.log('receiver id', receiverRefId);
        let receiverRef = firestore.collection('users').doc(receiverRefId);

        let receiverDMNotificationOps = receiverRef.set({
            unread: true
        }, {merge: true})
        receiverDMNotificationOps.then(() => {
            notification('Message sent successfully');
        });

        //send dm email


        if(redirect) {
            console.log("redirect", redirect);
            console.log("redirectTo", redirectTo);

            if (redirectTo) {
                window.location.href=redirectTo;
            }
            else {
                history.push({
                    pathname: "/conversationThread",
                    state: {conversationId: refConvo.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.characterName, role: receiver.role}, messages: messageData, user: sender, fromPage: 'chat'},
                });
            }

        }

    });
}

export const findOrCreateConvo =  (sender, receiver, history, fromPage) => {
    //check if conversation exists between sender and receiver

    // existingConvosArray.then(result => {
    //     console.log(result.empty, result.docs);
    // })
    // console.log("sender in chat ts", sender);
    // console.log("receiver in chat ts", receiver);
    console.log("receiver in chat ts", fromPage);
    console.log("receiver in chat role", receiver.role);


    findConvo(sender, receiver).then(result => {
            if (result.length > 0) {
                result.forEach(docSnapshot => {
                    console.log("found", { ...docSnapshot.data(), uid: docSnapshot.id });
                    history.push({
                        pathname: "/conversationThread",
                        state: {conversationId: docSnapshot.id, sender:sender, receiver: {uid: receiver.uid? receiver.uid: receiver.id, photoURL: receiver?.photoURL, heroName: receiver.characterName, role:receiver.role}, user: sender, fromPage: fromPage},
                    });
                });
            }
            else {
                //create
                console.log("create convo");
                createConvo(sender, receiver, history, true);
            }

        }
    );


}


export const findOrCreateConvoWithMessage =  (sender, receiver, messageData, history) => {
    //check if conversation exists between sender and receiver

    // existingConvosArray.then(result => {
    //     console.log(result.empty, result.docs);
    // })

    // console.log("sender...", sender);
    //
    // console.log("contentToSend...", messageData);
    //
    // console.log("destination...", receiver);


    findConvo(sender, receiver).then(result => {
            if (result.length > 0) {
                result.forEach(docSnapshot => {
                    console.log("found", { ...docSnapshot.data(), uid: docSnapshot.id });
                    // history.push({
                    //     pathname: "/conversationThread",
                    //     state: {conversationId: docSnapshot.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.heroName, role:'hero'}, user: sender, fromPage: 'startConversation'},
                    // });
                    //update convo in background
                    let convoToUpdateRef = firestore.collection('conversation').doc(docSnapshot.id);
                    console.log("messageData", messageData);
                    let convoOps = convoToUpdateRef.update({
                        messages: firebase.firestore.FieldValue.arrayUnion(messageData),
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    convoOps.then(() => {
                        console.log("added content to message thread and rdirect");

                    });
                });
            }
            else {
                //create
                console.log("create convo");
                createConvoWithMessage(sender, receiver, history, messageData, false, null);
            }

        }
    );

}


export const findOrCreateConvoWithMessageRedirect =  (sender, receiver, messageData, history, redirect, redirectTo) => {
    //check if conversation exists between sender and receiver

    // existingConvosArray.then(result => {
    //     console.log(result.empty, result.docs);
    // })

    // console.log("sender...", sender);
    //
    // console.log("contentToSend...", messageData);
    //
    // console.log("destination...", receiver.uid? receiver.uid: receiver.id)
    //
    console.log(" redirect to", redirectTo);
    console.log(" redirect ", redirect);


    findConvo(sender, receiver).then(result => {
            if (result.length > 0) {
                result.forEach(docSnapshot => {
                    console.log("found", { ...docSnapshot.data(), uid: docSnapshot.id });
                    // history.push({
                    //     pathname: "/conversationThread",
                    //     state: {conversationId: docSnapshot.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.heroName, role:'hero'}, user: sender, fromPage: 'startConversation'},
                    // });
                    //update convo in background
                    let convoToUpdateRef = firestore.collection('conversation').doc(docSnapshot.id);
                    console.log("messageData", messageData);
                    let convoOps = convoToUpdateRef.update({
                        messages: firebase.firestore.FieldValue.arrayUnion(messageData),
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    convoOps.then(() => {
                        console.log("added content to message thread and redirect", redirect);
                        if (redirect) {
                            if (redirectTo) {
                                window.location.href=redirectTo;
                            }
                            else {
                                history.push({
                                    pathname: "/conversationThread",
                                    state: {conversationId: docSnapshot.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.heroName, role: receiver.role}, messages: [messageData], user: sender, fromPage: 'chat'},
                                });
                            }
                        }
                    });
                });
            }
            else {
                //create
                console.log("create convo");

                createConvoWithMessage(sender, receiver, history, messageData, true, redirectTo);

            }

        }
    );

}

export const findOrCreateConvoWithMessageListRedirect =  (sender, receiver, messageData, history, redirect, redirectTo) => {
    //check if conversation exists between sender and receiver

    // existingConvosArray.then(result => {
    //     console.log(result.empty, result.docs);
    // })

    // console.log("sender...", sender);
    //
     console.log("contentToSend...", messageData);
    //
    // console.log("destination...", receiver.uid? receiver.uid: receiver.id)
    //
    // console.log(" redirect to", redirectTo);
    // console.log(" redirect ", redirect);


    findConvo(sender, receiver).then(result => {
            if (result.length > 0) {
                result.forEach(docSnapshot => {
                    console.log("found", { ...docSnapshot.data(), uid: docSnapshot.id });
                    // history.push({
                    //     pathname: "/conversationThread",
                    //     state: {conversationId: docSnapshot.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.heroName, role:'hero'}, user: sender, fromPage: 'startConversation'},
                    // });
                    //update convo in background
                    let convoToUpdateRef = firestore.collection('conversation').doc(docSnapshot.id);
                    console.log("messageData", messageData);
                    let convoOps = convoToUpdateRef.update({
                        messages: firebase.firestore.FieldValue.arrayUnion(...messageData),
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    convoOps.then(() => {
                        console.log("added content to message thread and redirect", redirect);
                        if (redirect) {
                            if (redirectTo) {
                                console.log("added content to message thread and redirect", redirectTo);
                                window.location.href=redirectTo;
                            }
                            else {
                                console.log("added content to message thread and redirect 367");
                                history.push({
                                    pathname: "/conversationThread",
                                    state: {conversationId: docSnapshot.id, sender:sender, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.heroName, role: receiver.role}, messages: messageData, user: sender, fromPage: 'chat'},
                                });
                            }
                        }
                    });
                });
            }
            else {
                //create
                console.log("create convo");

                createConvoWithMessageList(sender, receiver, history, messageData, true, redirectTo);

            }

        }
    );

}

export const sendWelcomeDM = (receiver, history) => {

    let messageData = {
        messageType: 'outgoing',
        checked: true,
        sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
        messageFrom: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
        messageTo: receiver.uid,
        answerContent: 'Hey, this is your first time in JFH Chat. Welcome! Important note: You’ll see an “in character” and “out of character” toggle in Cast Chat that’s super important. When you want to talk to other heroes as if you’re playing your character on a movie set and its dialogue in the movie, choose “in-character.” If you want to chat as your true identity (ie. your real name), “out-of-character” is best. ',
    };
    //console.log("add content to message and do not redirect !!!!",messageData);

    let sender = {
        uid: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
        characterName: 'Justice F. Hire',
        photoURL: 'profiles/thumbnails/1618265515397.jpeg'
    }

    findOrCreateConvoWithMessage(sender, receiver, messageData, history);
    sendWelcomeDMEmail(receiver.characterName, receiver.email);


    // const conversation = firestore.collection('conversation').add({
    //     senderId: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
    //     senderHeroName: 'Justice F. Hire',
    //     senderPhotoURL: 'profiles/thumbnails/1618265515397.jpeg',
    //     receiverId: receiver.uid,
    //     receiverHeroName: receiver.characterName ? receiver.characterName : (receiver.heroName ? receiver.heroName : receiver.clientName),
    //     receiverPhotoURL: receiver?.photoURL,
    //     sendTime: firebase.firestore.FieldValue.serverTimestamp(),
    //     messages: [messageData],
    //     lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    //     receiverRole: receiver.role,
    // }).then(refConvo => {
    //     //mark unread
    //     let receiverRef = firestore.collection('users').doc(receiver.uid);
    //     let receiverDMNotificationOps = receiverRef.set({
    //         unread: true
    //     }, {merge: true})
    //     receiverDMNotificationOps.then(() => {
    //         notification('Hey, this is your first time in JFH Chat. Welcome! Important note: You’ll see an “in character” and “out of character” toggle in Cast Chat that’s super important. When you want to talk to other heroes as if you’re playing your character on a movie set and its dialogue in the movie, choose “in-character.” If you want to chat as your true identity (ie. your real name), “out-of-character” is best. ');
    //         sendWelcomeDMEmail(receiver.characterName, receiver.email);
    //     });
    //
    //     //console.log("created convo", refConvo.id);
    // });
}

import React, {useEffect, useState } from 'react';
import { IonCard,  IonRow, IonCol, IonButtons, IonButton,  IonIcon, IonLabel, IonText, IonGrid } from '@ionic/react';

import {people, playOutline} from 'ionicons/icons';
import { useHistory} from "react-router-dom";
import {Challenge} from "../../models/Challenge";
import VideoList from "../../components/VideoList";
import { firestore } from '../../firebaseConfig';
import { toast } from '../../toast';
import {message, notification } from '../../util/notification';
import VideoListNoControls from '../../components/VideoListNoControls';
import { Device } from '@capacitor/core';
import { openFullScreen } from '../../util/videoUtils';
import ChallengePromptVideo from './ChallengePromptVideo';

interface ChallengePromptModuleProps {
    challengeId: string;
    showButtons: boolean;
    user: any;
    // userId: string;
    // heroName: string;
    // heroId: string;
    // userPhotoURL: string;
}



const ChallengePromptModule: React.FC<ChallengePromptModuleProps> = ({challengeId, showButtons, user}) => {

    const [showPeopleList, setShowPeopleList] = useState(false);
    const [actionName, setActionName] = useState('accept');
    const [divisionFactor, setDivisionFactor] = useState(1);
    const [platformInfoName, setPlatformInfoName] = useState('web');
    const [challengeDetails, setChallengeDetails] = useState<any>({});
    const history = useHistory();

    useEffect(() => {
        let isSubscribed = true;

        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            setPlatformInfoName(info.operatingSystem);
        } );

        let query = firestore.doc(`challenges/${challengeId}`);

        query.get()
            .then(function (doc) {
                if (isSubscribed) {
                    setChallengeDetails({ ...doc.data(), uid: doc.id });
                    console.log("challenge Details:" , doc.data());
                    console.log("user", user);
                }

            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
        return () => {isSubscribed = false}
    }, [challengeId]);


    let acceptChallenge = (challenge) => {
        if (user.role === 'client') {
            message('Only Heroes or Villains can take on Challenges');
        }
        else {
            history.push({
                pathname: '/challengeListItemDetail',
                state: {challengeId:challenge.uid, challengeTitle:challenge.title, challengeType: challenge.type, heroId: user.uid, heroName: user.characterName, userId: user.uid, userPhotoURL: user.photoURL, fromPage: 'watch'},
            });
        }
    }


    return (
        <IonCard style={{margin: '0'}}>
            <div>
                <ChallengePromptVideo tab={'watch'} platformName={platformInfoName}/>
            </div>

            <IonRow style={{paddingTop:'0px',paddingBottom:'10px', paddingLeft:'5px', paddingRight:'5px'}}>
                    <IonText class="contentTextSubtitle bolder" color="primary"> {challengeDetails.title} </IonText>
            </IonRow>
            <IonRow style={{paddingTop:'0px',paddingBottom:'10px', paddingLeft:'5px', paddingRight:'5px'}}>
                <IonText className="ion-text-justify" color="primary">
                    {challengeDetails.description}
                </IonText>
            </IonRow>
            <IonRow style={{'float':'right'}}>
                <IonCol>
                        <IonButton shape="round"  size="default" className={(actionName ==='accept')? 'acceptBtn bolder ion-text-right': 'completeBtn ion-text-right'}   onClick={() => acceptChallenge(challengeDetails)}> {actionName.toUpperCase()} </IonButton>
                </IonCol>
            </IonRow>
        </IonCard>
    );
};

export default ChallengePromptModule;


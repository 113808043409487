
import React, {useState, useEffect, useRef} from 'react';
import {firestore, storageRef} from '../firebaseConfig';

import './VideoListItem.css';
import {IonIcon, IonLabel, IonButton, IonActionSheet, IonImg, IonRow, IonCol, IonAlert} from '@ionic/react';
import {
    playOutline,
    mail,
    closeOutline,
    lockClosedOutline,
    lockOpenOutline,
    imageOutline,
    pencilOutline
} from 'ionicons/icons';
import { ContentItem } from '../models/ContentItem';
import { toast } from '../toast';
import { playVideoFromRef} from "../util/videoUtils";
import {getPictureAddress} from "../util/avatarUtils";
import {Device} from "@capacitor/core";
import firebase from "firebase";
import { sendApprovalPendingEmail } from '../util/sendEmail';


interface VideoContainerProps {
    contentItem: ContentItem;
    tab: string;
    enableTogglePrivate: boolean;
}

const VideoListItem: React.FC<VideoContainerProps> = (props) => {

    const { contentItem, tab, enableTogglePrivate } = props as any;

    const [showActionSheet, setShowActionSheet] = useState(false);

    const [pictureContentUrl, setPictureContentUrl] = useState(contentItem.contentVideo);
    const videoListItemRef = useRef(null);

    const [showUpdateDescriptionPopup, setShowUpdateDescriptionPopup] = useState(false);

    const [platformInfoName, setPlatformInfoName] = useState('web');


    useEffect(() => {
        //console.log("poster url", contentItem.posterUrl);

        let isSubscribed = true;

        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);

            setPlatformInfoName(info.operatingSystem);
        } );

        return () => {isSubscribed = false}
    }, [contentItem, tab]);


    function playVideoListItem(e) {

        let videoListItemEl  = videoListItemRef.current;

        playVideoFromRef(videoListItemEl, platformInfoName);

    }


    async function clickUploadVideoListItemImage() {
        document.getElementById('uploadVideoListItemImageBtn'+contentItem.uid+tab).click();
    }

    function onUploadCoverImageVL(e, contentItem) {
        console.log("video list item");

        let coverImageFile = e.target.files[0];
        let coverImageFileUrl = URL.createObjectURL(coverImageFile);

        let posterFileExtension = coverImageFile.type.split("/").pop();
        let posterFileName =  contentItem.title.replace(/ /g, "_") + contentItem.completedByHeroName.replace(/ /g, "_") + Date.now()+"."+posterFileExtension;
        console.log('poster file video list!!!!!!!!!!!!!!!!!!!!'+ posterFileName);

        videoListItemRef.current.setAttribute('poster', coverImageFileUrl);


        let videoListItemStorageRef = storageRef.child('content/thumbnails/'+posterFileName);

        videoListItemStorageRef.put(coverImageFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail for delivery module!!!!!!!!!!!!!!!!!!');

            firestore.doc(`content/${contentItem.uid}`).update({
                posterUrl: videoListItemStorageRef.fullPath,
            }).then(function() {
                console.log("updated cover photo");
                //window.location.reload(false);
            });
        }).catch((err)=>toast("error uploading thumbnail"+err));
      // let coverImageRef = uploadDeliveryModuleCover(e, videoListItemRef, null, contentItem);
        //console.log("poster url", coverImageRef.fullPath);

    }


  return (
    <div className="video-wrapper">
        <div className="overlay"
             onClick={playVideoListItem}
        >

            <div style={{margin: 5, maxWidth: '80%'}} className="left deliveryModuleTitle bolder">

                    <IonRow>
                        <IonIcon style={{padding: 2, display: 'inline-block'}} item-end icon={playOutline}/>
                        <IonLabel style={{display: 'flex !important', justifyContent: 'center'}}>
                            {contentItem.title}
                        </IonLabel>
                    </IonRow>

            </div>

            <div className="right reportBtn highContrast">
                <IonLabel style={{padding: 20, paddingTop: 5, cursor: 'pointer'}}
                          onClick={(e) => {setShowActionSheet(true);e.stopPropagation();}}
                >
                    ...
                </IonLabel>
                {enableTogglePrivate && contentItem.publicContent &&
                <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    backdropDismiss={false}
                    cssClass='my-custom-class'
                    buttons={[ {
                        text: 'Report a problem',
                        icon: mail,
                        handler: () => {
                            window.location.href='/reportRequest';
                        }
                    }, {
                        text: 'Make Private',
                        icon: lockClosedOutline,
                        handler: () => {
                            firestore.doc(`content/${contentItem.uid}`).update({
                                publicContent: false,
                            }).then(function() {
                                window.location.reload(false);
                            });
                        }
                    }, {
                        text: 'Change Cover Photo',
                        icon: imageOutline,
                        handler: () => {
                            // firestore.doc(`content/${contentItem.uid}`).update({
                            //     publicContent: true,
                            // }).then(function() {
                            //     window.location.reload(false);
                            // });
                            clickUploadVideoListItemImage();
                        }
                    },{
                        text: 'Edit  Description',
                        icon: pencilOutline,
                        handler: () => {
                            // firestore.doc(`content/${contentItem.uid}`).update({
                            //     publicContent: true,
                            // }).then(function() {
                            //     window.location.reload(false);
                            // });
                            //clickUploadCoverImage();
                            setShowUpdateDescriptionPopup(true);
                        }
                    }
                        , {
                            text: 'Cancel',
                            icon: closeOutline,
                            role: 'cancel',
                            handler: () => {
                                console.log('Cancel clicked');
                            }
                        }]}
                >
                </IonActionSheet>
                }
                {enableTogglePrivate && !contentItem.publicContent &&
                <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    backdropDismiss={false}
                    cssClass='my-custom-class'
                    buttons={[ {
                        text: 'Report a problem',
                        icon: mail,
                        handler: () => {
                            window.location.href='/reportRequest';
                        }
                    }, {
                        text: 'Make Public',
                        icon: lockOpenOutline,
                        handler: () => {
                            firestore.doc(`content/${contentItem.uid}`).update({
                                publicContent: true,
                            }).then(function() {
                                window.location.reload(false);
                            });
                        }
                    }, {
                        text: 'Change Cover Photo',
                        icon: imageOutline,
                        handler: () => {
                            // firestore.doc(`content/${contentItem.uid}`).update({
                            //     publicContent: true,
                            // }).then(function() {
                            //     window.location.reload(false);
                            // });
                            clickUploadVideoListItemImage();
                        }
                    },{
                        text: 'Edit Description',
                        icon: pencilOutline,
                        handler: () => {
                            // firestore.doc(`content/${contentItem.uid}`).update({
                            //     publicContent: true,
                            // }).then(function() {
                            //     window.location.reload(false);
                            // });
                            //clickUploadCoverImage();
                            setShowUpdateDescriptionPopup(true);
                        }
                    }
                        , {
                            text: 'Cancel',
                            icon: closeOutline,
                            role: 'cancel',
                            handler: () => {
                                console.log('Cancel clicked');
                            },

                        }]}
                >
                </IonActionSheet>
                }
                {!enableTogglePrivate &&
                <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    cssClass='my-custom-class'
                    buttons={[ {
                        text: 'Report a problem',
                        icon: mail,
                        handler: () => {
                            window.location.href='/reportRequest';
                        }
                    }
                        , {
                            text: 'Cancel',
                            icon: closeOutline,
                            role: 'cancel',
                            handler: () => {
                                console.log('Cancel clicked');
                            }
                        }]}
                >
                </IonActionSheet>

                }
            </div>

        </div>
        {enableTogglePrivate &&
            <input type="file" accept="image/*" className="uploadVideoBtn"
                   id={'uploadVideoListItemImageBtn'+contentItem.uid+tab}
                   onChange={(e) => onUploadCoverImageVL(e, contentItem)}
            />}
        {enableTogglePrivate &&
        <IonAlert
            isOpen={showUpdateDescriptionPopup}
            header="These changes affect the entire universe."
            buttons={[
                'Cancel',
                {
                    text: 'Request',
                    handler: (data) => {

                        if (contentItem.contentDescription !== data['contentDescription']) {
                            let contentUpdateRequest = {
                                message: 'Content Description Change Request',
                                originalDescription: contentItem.contentDescription,
                                requestedById: contentItem.completedByUserId,
                                requestedByCharacter: contentItem.completedByHeroName,
                                requestedChangeDescription: data['contentDescription'],
                                timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
                                posterUrl: contentItem.posterUrl,
                                contentVideo: contentItem.contentVideo,
                                contentType: contentItem.contentType,
                                contentId: contentItem.uid,
                                status: 'pending',
                            }
                            let contentUpdateRef = firestore.collection('contentUpdateRequest').add(contentUpdateRequest).then(ref => {
                                toast('Approval Pending');
                                sendApprovalPendingEmail(contentItem.completedByUserId, 'Content Produced Description ('+ contentItem.contentType+') ');
                                console.log('Added content update request  with ID: ', ref.id);
                            });
                        }
                        else {
                            toast('No change detected');
                        }

                    }
                }
            ]}
            inputs={[

                {
                    type: "text",
                    name: 'contentDescription',
                    id: 'contentDescription',
                    value: (`${contentItem.contentDescription}` !== '' && `${contentItem.contentDescription}` !== 'undefined') ? `${contentItem.contentDescription}`: ``,
                    placeholder: `${contentItem.contentDescription}` !== '' ? `${contentItem.contentDescription}`: `Please fill in the content description`
                }
            ]}
            onDidDismiss={() => setShowUpdateDescriptionPopup(false)}
        />}
        <video id={contentItem.uid+tab} poster={getPictureAddress(contentItem.posterUrl, contentItem.uid,contentItem.uid+tab)} ref={videoListItemRef}
               onEnded={e=> videoListItemRef.current.load()}
               onPause={e=> {videoListItemRef.current.load(); videoListItemRef.current.controls=false; }}>
        >
           <source src={getPictureAddress(contentItem.contentVideo, contentItem.uid,contentItem.uid+tab)} type="video/mp4"  />
            Your browser does not support the video tag.
        </video>
    </div>

  );
};

export default VideoListItem;


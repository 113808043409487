import React, { Component } from 'react';
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonCol,
    IonRow,
    IonInput,
    IonText,
    IonImg,
} from '@ionic/react';
import { NavLink } from 'react-router-dom';
import JFH from '../../assets/icon/logo-blue.png';
import './ForgotPasswordStyles.css';
import { auth } from '../../firebaseConfig';
import Header from '../../components/Header';

const INITIAL_STATE = {
    email: ' ',
};

class ForgotPassword extends Component {
    state: any = {};
    props: any = {};
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    onChange = (event: any) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    onSubmit = (event: any) => {

        const { email } = this.state;

        auth.sendPasswordResetEmail(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch((error: any) => {
                this.setState({ error });
            });
        event.preventDefault();
    }
    render() {
        const { email, error } = this.state;
        const isInvalid = email === '';
        return (
            <IonPage>
                <Header noChat={true}/>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle slot="start">Forgot Password</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent className='ion-padding'>
                    <IonRow className="row">
                        <div className="FireLogoPAssword">
                             <IonImg src={JFH} className="img-logo" alt="JFH" />
                            <IonText><p className="TextPassDesc ion-no-margin ion-margin-vertical">Enter Your mail here and we will send you a email with password reset link</p></IonText>
                        </div>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonInput
                                name="email"
                                value={email}
                                onIonChange={this.onChange}
                                clearInput
                                type="email"
                                placeholder="Email"
                                className="pwdInput"
                                clear-input="true"></IonInput>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton disabled={isInvalid} onClick={this.onSubmit} type="submit" expand="block" className="btn-transition"><strong className="white">Submit</strong></IonButton>
                        </IonCol>
                    </IonRow>
                    {error && <p>{error.message}</p>}
                    <NavLink to="login">
                        <IonRow>
                            <IonCol>
                                <IonButton fill="outline" color="undefined" className="ButtonColorForgot"><strong><strong>HAVE AN ACCOUNT ? SIGN IN</strong></strong></IonButton>
                            </IonCol>
                        </IonRow>
                    </NavLink>
                </IonContent >
            </IonPage >
        );
    }
}

export default ForgotPassword;

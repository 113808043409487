import React, {useState} from 'react';
import {
    IonContent,
    IonText,
    IonPage,
    IonRouterLink,
    IonLabel,
    IonRow,
    IonGrid,
    IonModal,
    IonHeader, IonButton, IonIcon
} from '@ionic/react';
import {closeOutline} from "ionicons/icons";


const ChallengesFeedPopup: React.FC = () => {
    const [showTutorialModal, setShowTutorialModal] = useState(true);


    return (
        <IonModal isOpen={showTutorialModal} cssClass='my-custom-class'>
            <IonHeader>
                <IonRow className="ion-justify-content-center" style={{padding: 10}}>
                    <IonText className="appTextMain">
                        CHALLENGES build your character.
                    </IonText>
                </IonRow>
            </IonHeader>
            <IonContent className="my-custom-class"
                        scrollEvents={true}
                        onIonScrollStart={() => {
                        }}
                        onIonScroll={() => {
                        }}
                        onIonScrollEnd={() => {
                        }} scrollX scrollY>

                <div style={{
                    height: '100%',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: 10,
                }}>
                    <IonRow className="ion-align-items-center">
                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                             <IonText className="appTextMain"> JOURNEY </IonText>
                        </div>
                    </IonRow>
                    <IonText className="appTextMain">Tell your origin story as a mini movie.
                    </IonText>


                    <IonRow>
                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                            <IonText className="appTextMain"> TRAINING </IonText>
                        </div>
                    </IonRow>
                    <IonText className="appTextMain">Physical and mental exercises to strengthen you.

                    </IonText>

                    <IonRow>
                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                            <IonText className="appTextMain"> DEVELOPMENT </IonText>
                        </div>
                    </IonRow>
                    <IonText className="appTextMain">Share ideas and plan your creativity.

                    </IonText>

                    <IonRow className="ion-justify-content-end">
                        <IonButton onClick={() => setShowTutorialModal(false)} style={{'--background': 'none'}}>
                            <IonIcon icon={closeOutline}/>
                        </IonButton>
                    </IonRow>
                </div>

            </IonContent>
        </IonModal>
    );
};

export default ChallengesFeedPopup;

import React from 'react';
import { IonCard, IonContent, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonButtons, IonButton,  IonImg, IonLabel, IonRow, IonText, IonCol,  IonThumbnail, IonRouterLink, IonIcon } from '@ionic/react';

import './Challenges.css';


import { firestore } from '../../firebaseConfig';
import { Challenge } from '../../models/Challenge';
import authWrapper from '../../components/authWrapper';
import PremiumDevelopmentListItem from "./PremiumDevelopmentListItem";


interface PremiumDevelopmentChallengesListState {
    loading: boolean;
    challengesList: Challenge[];
    user: any;
    // userId: string;
    // heroName: string;
    // heroId: string;
    // userPhotoURL: string;
}

class PremiumDevelopmentChallengesItems extends React.Component<{}, PremiumDevelopmentChallengesListState>  {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            challengesList: [],
            user: props.user,
            // userId: props.user.uid,
            // heroName: props.user.characterName ? props.user.characterName: props.user.heroName,
            // heroId: props.user.heroId,
            // userPhotoURL: props.user.photoURL,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        firestore.collection('challenges').where("premium", "==", true).where("type","==", 'development').where("publicContent", "==", true)
            .onSnapshot(snapshot => {
                let challengesList = [];

                snapshot.forEach(doc =>
                    challengesList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    challengesList,
                    loading: false,
                });
            });
    }


    render() {
        const { challengesList, loading, user } = this.state;

        const hide  = true;

        return (

            <>
                {challengesList.map((trainingChallenge, index) => {
                    return (
                        <PremiumDevelopmentListItem key={index} challenge={trainingChallenge} index={index} showButtons={true} user={user}/>

                    );
                })}

            </>

        );
    };
}

export default authWrapper(PremiumDevelopmentChallengesItems);

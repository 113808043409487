import React, { useState } from 'react';
import {
    IonInput,
    IonRow,
    IonContent,
    IonButton,
    IonPage,

    IonCol,
    IonCard,
    IonCardContent,
    IonGrid,

    IonImg,
    IonText,
    IonFooter,

    IonLabel,
    IonModal,  IonHeader,  IonIcon, IonToolbar
} from '@ionic/react';

import './Login.css';
import {loginUser} from '../firebaseConfig';
import { toast } from '../toast';
import { NavLink } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {closeOutline, volumeHighOutline, volumeMuteOutline} from "ionicons/icons";


const Login: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showDesktopExp, setShowDesktopExp] = useState(!isMobile);

    const [showWelcomeVideo, setShowWelcomeVideo] = useState(isMobile);
    const [videoMuted, setVideoMuted] = useState(true);
    const [platformInfoName, setPlatformInfoName] = useState('web');
    const backgroundVideoUrl = "https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFHAppBGSignupVideo.mp4?alt=media&token=80dc4d83-fd1c-46c0-8b15-206ea03a0d9a";


    const BackgroundVideo = () => {
        return (
            <video  autoPlay playsInline src={backgroundVideoUrl} className="loginPage__video" controls={false}  />
        );
    };

    let videoPlayerContainer = document.getElementById('backgroundVideo') as HTMLVideoElement;
    if (videoPlayerContainer !== null) {
        videoPlayerContainer.setAttribute("autoplay","true");
    }

    let unmuteVideo = () => {
        let welcomeVideoRefId = document.getElementById('welcomeVideoRef') as HTMLVideoElement;
        welcomeVideoRefId.muted = false;
        setVideoMuted(false);
        // if (welcomeVideoRefId) {
        //     setTimeout(function() {
        //
        //         welcomeVideoRefId.play();
        //     }, 5000);
        // }
    }

    let muteVideo = () => {
        let welcomeVideoRefId = document.getElementById('welcomeVideoRef') as HTMLVideoElement;
        welcomeVideoRefId.muted = true;
        setVideoMuted(true);
    }
    async function login() {

      if (email.trim() === '') {
        toast('Please type in your email address.');
      }
      else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
       {
        toast('Please use a valid email address.');
      }
      else if(password.trim() === '') {
        toast('Please type in your password.');
      }
      else if (!(/^.{6,}$/).test(password)) {
          toast('Password must be at least 6 characters long.');
      }
          else {
        loginUser(email, password).catch(err=>{
            toast("There's been an error at login.", err.message);
        });
      }
    }

  return (
    <IonPage>
        {!isMobile &&
        <IonHeader>
            <IonToolbar color="dark">
                <IonRow style={{justifyContent:'center'}}>
                    <img  style={{height:'200px',objectFit:'cover'}} src="https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fdesktop_app.png?alt=media"
                          onClick={() => {window.open('https://bit.ly/jfh-app','_blank')}}/>
                </IonRow>
            </IonToolbar>
        </IonHeader>
        // <IonHeader>
        //     <IonRow>
        //         <IonImg src={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fdesktop_app.png?alt=media'} style={{objectFit:'cover'}} onClick={()=> window.location.href="www.wefunder.com/reelwurld"}></IonImg>
        //     </IonRow>
        // </IonHeader>
        }
        {/*<IonModal isOpen={showDesktopExp}*/}
        {/*          onDidDismiss={() => setShowDesktopExp(false)}*/}
        {/*          swipeToClose={true}*/}
        {/*          cssClass="destopBanner">*/}
        {/*    <IonContent*/}
        {/*                scrollEvents={true}*/}
        {/*                onIonScrollStart={() => {*/}
        {/*                }}*/}
        {/*                onIonScroll={() => {*/}
        {/*                }}*/}
        {/*                onIonScrollEnd={() => {*/}
        {/*                }} scrollX scrollY*/}
        {/*                className="destopBanner">*/}


        {/*        /!*<div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'center', objectFit:'cover'}}>*!/*/}
        {/*        /!*    <div className="overlay">*!/*/}
        {/*        /!*        <IonFab vertical="top" horizontal="end" slot="fixed">*!/*/}
        {/*        /!*            <IonFabButton style={{'--background':'none', '--border-radius':0, '--box-shadow':'none'}} onClick={() => setShowDesktopExp(false)}>*!/*/}
        {/*        /!*                <IonButton onClick={() => setShowDesktopExp(false)} style={{}} > X </IonButton>*!/*/}
        {/*        /!*            </IonFabButton>*!/*/}
        {/*        /!*        </IonFab>*!/*/}

        {/*        /!*        <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{}}>*!/*/}
        {/*        /!*            <IonFabButton style={{'--background':'none', '--border-radius':0,'--box-shadow':'none', 'width':'200px'}} onClick={() => setShowDesktopExp(false)}>*!/*/}
        {/*        /!*                <IonButton onClick={() => setShowDesktopExp(false)}> Continue to JFH App </IonButton>*!/*/}
        {/*        /!*            </IonFabButton>*!/*/}
        {/*        /!*        </IonFab>*!/*/}

        {/*        /!*    </div>*!/*/}
        {/*        /!*    <IonImg src={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FjfhAppDesktop.png?alt=media'} style={{objectFit:'cover'}} onClick={()=> window.location.href='www.wefunder.com/reelwurld'}></IonImg>*!/*/}

        {/*        /!*    /!*<IonImg src={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FjfhAppDesktop.png?alt=media'} style={{height:'80%'}}></IonImg>*!/*!/*/}

        {/*        /!*</div>*!/*/}

        {/*    </IonContent>*/}

        {/*</IonModal>*/}
      <IonContent className="loginPage">
          <video autoPlay playsInline src={backgroundVideoUrl} muted loop className="loginPage__video" controls={false} />

          <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <IonCard className="ion-justify-content-center center">
                <div style={{marginBottom: 0}}>
                      <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFH_LOGIN_PAGE.png?alt=media"} className="login-box-image" style={{marginBottom: 0, marginTop: 10}}></IonImg>
                </div>
                <div style={{marginBottom: 0}}>
                    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Ftagline_with%20period.png?alt=media"}></IonImg>
                </div>
             <IonCardContent className="login-box">
                 <IonInput  className="appText " color="light" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Email" onIonChange={(e: any) => setEmail (e.target
                    .value)}/>
             <IonInput className="appText" type="password" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Password"
                onIonChange={(e: any) => setPassword (e.target.value)}
                onKeyPress={e => {if(e.keyCode == 13 || e.which == 13) login()} }
             />
              <NavLink to="forgotPassword">
             <IonRow>
                  <IonCol>
                      <IonText className="ion-text-right" ><h6 className="smallSignin ion-no-margin ion-text-end" style={{'color':'#ffffff'}}>Forgot Password?</h6></IonText>
                  </IonCol>
              </IonRow>
              </NavLink>
             <IonGrid>
             <IonRow>
                 <IonCol>
                     <IonButton className="appBtn" onClick={login}><IonLabel className="loginBtnLabel">LOGIN</IonLabel></IonButton>
                 </IonCol>
             </IonRow>

             <IonRow>
                 <IonCol>
                     <IonButton className="appBtn" href="/registration"><IonLabel className="registerBtnLabel ion-text-capitalize">New Here? Register</IonLabel></IonButton>
                 </IonCol>
             </IonRow>

             </IonGrid>
             </IonCardContent>

             <IonFooter>

                 <IonGrid>
                     <IonRow className="footerText">
                         <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                                <div>
                                    <IonText >Powered by ReelwUrld™. Patent pending. <br/> All Rights reserved.  </IonText>
                                </div>
                         </div>
                     </IonRow>

                     {/*<IonRow className="appText">*/}
                     {/*    <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>*/}
                     {/*       &#169; Creative Impulse® 2020. All Rights reserved.*/}
                     {/*    </div>*/}
                     {/*</IonRow>*/}
              </IonGrid>

                 {/*<IonRow className="footerText ion-text-justify">*/}
                 {/*    <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>*/}

                 {/*            <IonImg src={"/assets/icon/icons-192x192.png"} style={{'width':'42px', 'height':'42px'}}/>*/}
                 {/*            <IonText style={{color:'#ffffff', padding: '10px', fontSize: '12px'}}> Click <IonIcon icon={shareOutline}/> to add the JFH App to your homescreen. </IonText>*/}

                 {/*    </div>*/}

                 {/*</IonRow>*/}
             </IonFooter>

             </IonCard>

        </div>
      </IonContent>

        <IonModal isOpen={showWelcomeVideo} cssClass='my-custom-class'>
            <IonHeader>
                <IonRow>
                    <IonCol>
                        <IonIcon onClick={() => setShowWelcomeVideo(false)} icon={closeOutline} color="light"/>
                    </IonCol>
                    <IonCol size="9" className="ion-text-center">
                        <IonText className="appTextMain"> Welcome to JFH</IonText>
                    </IonCol>
                    <IonCol>
                        {videoMuted ?
                            <IonIcon onClick={unmuteVideo} color="light" icon={volumeMuteOutline}/>
                            :<IonIcon onClick={muteVideo} color="light" icon={volumeHighOutline}/>
                        }
                    </IonCol>
                </IonRow>

            </IonHeader>
            <IonContent className="my-custom-class no-scroll"
            >

                <div style={{
                    height: '100%',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    padding: 20,
                    overflowY: "hidden",
                }}>

                    <video id='welcomeVideoRef'
                           autoPlay
                           playsInline
                           src={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFH_App_Welcome_Video-1080p_122021.mp4?alt=media'}
                           poster={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fwelcome_to_the_jfh_app.png?alt=media'}
                           muted={videoMuted}
                           onEnded={() => setShowWelcomeVideo(false)}
                    />


                </div>
            </IonContent>

        </IonModal>

    </IonPage>

  );
};

export default Login;

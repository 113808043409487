import React, { useState } from 'react';
import {
    IonContent,
    IonPage,
    IonTitle,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonIcon,
    IonList,
    IonItem,
    IonAvatar,
    IonImg,
    IonText,
    IonBadge,
    IonFooter,
    IonRouterLink,
    IonCard,
    IonThumbnail,
    IonGrid,
    IonButton,
    IonLabel,
    IonModal,
    IonInput,
    IonSlide
} from '@ionic/react';

import Moment from "react-moment";
import Header from './Header';
import Footer from './Footer';
import authWrapper from './authWrapper';
import { notification } from '../util/notification';
import { toast } from '../toast';
import { sendSubscribeNotification } from '../util/sendEmail';

interface EventScreen {
    loading: boolean;
    user: any;
    showOptIn:boolean;
    email: any;
}

const subscribeToNewsletter = (user, email) => {
    if (email.trim() === '') {
      return toast("Please Input A Valid Contact Email Address");
    }
    sendSubscribeNotification(user, email);
   // toast('Success! You have been added to the audience.');
}

class Events extends React.Component<{}, EventScreen> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user: props.user,
            showOptIn: false,
            email: props.user.contactEmail,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
    }


    render() {

        const { user, loading, showOptIn, email } = this.state;

        return (

            <IonPage>
                <Header />
                <IonContent>

                    <IonModal isOpen={showOptIn}
                              cssClass='subscribe-modal'
                              onDidDismiss={() => {this.setState({showOptIn: false})}}>
                        <IonHeader>
                            <IonRow style={{padding: 10}}>
                                <IonText className="appTextMain center">
                                    Stay updated
                                </IonText>
                            </IonRow>
                        </IonHeader>
                        <IonContent className='subscribe-modal'
                                    scrollEvents={true}
                                    onIonScrollStart={() => {
                                    }}
                                    onIonScroll={() => {
                                    }}
                                    onIonScrollEnd={() => {
                                    }} scrollX scrollY>

                            <IonRow><IonCol></IonCol></IonRow>
                                    <IonRow><IonCol></IonCol></IonRow>
                            <IonRow className="ion-text-justify">
                                <IonCol>
                                    <IonText color="light">
                                        The JFH community is creating content every day. Don't miss anything. Plus, there's events, panels, trainings, meetups, workshops, and more.
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                                    <IonRow>
                                        <IonInput  className="appText"  color="light" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Your Contact Email" onIonChange={(e: any) => {this.setState({email:e.target.value})}}></IonInput>
                                    </IonRow>
                                    <IonRow><IonCol></IonCol></IonRow>


                        </IonContent>
                        <IonFooter>
                            <IonRow className="ion-justify-content-center">

                                <IonButton  style={{'--background':'#005494'}} className="center" onClick={() => subscribeToNewsletter(user, email)}>
                                    <IonLabel className="italic bolder">
                                        Subscribe
                                    </IonLabel>
                                </IonButton>
                            </IonRow>
                        </IonFooter>
                    </IonModal>

                    {/*<IonCard>*/}

                    {/*    <IonImg src={"/assets/img/meetup_xxii_banner.png"} />*/}

                    {/*    <div>*/}
                    {/*        <IonGrid>*/}
                    {/*            <IonRow className="ion-float-right">*/}
                    {/*                <IonButton onClick={() => {window.location.href='https://www.justiceforhire.com/event-details/hero-meetup-xxii'}}>RSVP</IonButton>*/}
                    {/*            </IonRow>*/}

                    {/*        </IonGrid>*/}
                    {/*    </div>*/}
                    {/*</IonCard>*/}

                    <IonCard>
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fhero_meetup_xxiii_banner.jpg?alt=media"} onClick={() => {window.location.href='https://www.justiceforhire.com/event-details/hero-meetup-xxiii'}}/>
                        </IonSlide>


                        <div>
                            <IonGrid>
                                <IonRow className="ion-float-right">
                                    <IonButton onClick={() => {window.open('https://www.justiceforhire.com/event-details/hero-meetup-xxiii', '_blank')}}>RSVP</IonButton>
                                </IonRow>

                            </IonGrid>
                        </div>
                    </IonCard>
                    {/*<IonCard>*/}

                    {/*    <IonImg src={"/assets/img/hero_meetup_XX_BANNER.png"} />*/}

                    {/*    <div>*/}
                    {/*        <IonGrid>*/}
                    {/*            <IonRow className="ion-float-right">*/}
                    {/*                <IonButton onClick={() => {window.location.href='https://www.justiceforhire.com/event-details/hero-meetup-xx_blank'}}>RSVP</IonButton>*/}
                    {/*            </IonRow>*/}

                    {/*        </IonGrid>*/}
                    {/*    </div>*/}
                    {/*</IonCard>*/}

                    {/*<IonCard>*/}

                    {/*    <IonImg src={"/assets/img/hero_meetup_XIX_banner.jpg"} />*/}

                    {/*    <div>*/}
                    {/*        <IonGrid>*/}
                    {/*            <IonRow className="ion-float-right">*/}
                    {/*                <IonButton onClick={() => {window.location.href='https://www.justiceforhire.com/event-details/hero-meetup-xix'}}>RSVP</IonButton>*/}
                    {/*            </IonRow>*/}

                    {/*        </IonGrid>*/}
                    {/*    </div>*/}
                    {/*</IonCard>*/}

                    <IonCard>

                        <IonImg src={"/assets/img/tai_chi_to_the_people.png"} />

                        <div>
                            <IonGrid>
                                <IonRow className="ion-float-right">
                                    <IonButton onClick={() => {window.location.href='https://bit.ly/TaiChiApp'}}>RSVP</IonButton>
                                </IonRow>

                            </IonGrid>
                        </div>
                    </IonCard>

                    <IonCard>

                                <IonImg src={"/assets/img/Banner_App_June 2021.jpg"} />

                                <div>
                                    <IonGrid>
                                        <IonRow className="ion-float-right">
                                         <IonButton onClick={() => {this.setState({showOptIn: true})}}>Stay Updated</IonButton>
                                        </IonRow>

                                    </IonGrid>
                                </div>
                            </IonCard>


                </IonContent>

                <Footer/>
            </IonPage>

        );
    }
}
export default authWrapper(Events);

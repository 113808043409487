import React, {useState} from 'react';
import {
    IonSlide,
    IonText,
    IonRow,
    IonCol,
    IonGrid,
    IonImg,
    IonButton,
   IonRouterLink, IonModal, IonHeader, IonContent, IonLabel, IonFooter
} from '@ionic/react';
import MissionVideoExampleContainer from '../../components/Walkthrough/MissionVideoExampleContainer';
import {sendJoinTestTeamEmail} from "../../util/sendEmail";

interface ApprovedUserScreen3Props {
    user:any;
}
const ApprovedUserScreen3: React.FC<ApprovedUserScreen3Props> = (props) => {

    const [showJoin, setShowJoin] = useState(false);

    const joinTestTeam = () => {
        console.log("props", props);
        sendJoinTestTeamEmail(props.user);
        setShowJoin(false);
    }
    return (
          <IonSlide>
              <IonModal isOpen={showJoin}
                        cssClass='join-custom-class'
                        onDidDismiss={() => setShowJoin(false)}>
                  <IonHeader>
                      <IonRow style={{padding: 10}}>
                          <IonText className="appTextMain center">
                              {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                          </IonText>
                      </IonRow>
                  </IonHeader>
                  <IonContent className='join-custom-class'
                              scrollEvents={true}
                              onIonScrollStart={() => {
                              }}
                              onIonScroll={() => {
                              }}
                              onIonScrollEnd={() => {
                              }} scrollX scrollY>

                      {props.user.testTeamMember
                          ?<><IonRow><IonCol></IonCol></IonRow>
                              <IonRow><IonCol></IonCol></IonRow>
                              <IonRow>
                                  <IonText className="appTextMain center">Check your email for the invite! </IonText>
                              </IonRow>
                              <IonRow><IonCol></IonCol></IonRow>
                          </>
                          :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                  </IonContent>
                  <IonFooter>
                      <IonRow className="ion-justify-content-center">

                          {props.user.testTeamMember === undefined  &&
                          <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                              <IonLabel className="italic bolder">
                                  Join
                              </IonLabel>
                          </IonButton>}
                      </IonRow>
                  </IonFooter>
              </IonModal>
          <IonGrid className="stepScreen">
              <IonRow>
              <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                  <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>

                  </div>
                  {/*<IonRouterLink onClick={() => setShowJoin(true)}>*/}

                  <IonRouterLink href="/missions">

                          <IonImg  style={{'width':'50px'}} src={"assets/img/harmony_white.svg"} className="menuButton"/>

                  </IonRouterLink>
                  <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>

                  </div>
              </div>
              </IonRow>
            <IonRow><IonCol></IonCol></IonRow>


              <IonRow>
          <IonCol>
          <IonText className="appTextMain" color="light">
          Missions are created by clients. 
          Heroes complete them. 
          </IonText>
          </IonCol>       
          </IonRow>     
          <IonRow></IonRow>    
          <IonRow>
           <IonCol>
             <IonText className="appTextMainItalic">Example: A client reports a stolen phone. Go find it. </IonText>
           </IonCol>
          </IonRow>

          <IonRow>
          
          <IonCol>
             <MissionVideoExampleContainer  />

  
          </IonCol>
          
          </IonRow>
          </IonGrid>
          </IonSlide>
         
  );
};


export default ApprovedUserScreen3;



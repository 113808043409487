import React from 'react';
import {
    IonContent,
    IonSpinner, IonItem,  IonList, IonGrid
} from '@ionic/react';


import { firestore} from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import {Message} from "../../models/Message";

import MessageItem from "./MessageItem";

interface SentFeedState {
    loading: boolean,
    sentList: Message[],
    user: string;
}

class SentFeed extends React.Component<{}, SentFeedState>  {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            sentList: [],
            user: props.user,
        };
    }

    componentDidMount() {
            firestore.collection('directMessage').where("senderId", "==", this.state.user['uid']).orderBy("sendTime", "desc")
                .onSnapshot(snapshot => {
                    let sentList = [];

                    snapshot.forEach(doc =>
                        sentList.push({ ...doc.data(), uid: doc.id }),
                    );

                    this.setState({
                        sentList,
                        loading: false,
                    });
                });
        }


    render() {
        const { sentList, loading, user } = this.state;

        if (loading)
            return <IonSpinner/>
        else
        return (

            <IonContent className="ion-padding-bottom" scrollX>
                <IonList>
                    {sentList && sentList.map((sentMessage, idxs) => {
                        return (
                            <IonItem key={idxs} lines="none">
                                <IonGrid>
                                    <MessageItem message={sentMessage} user={user}/>
                                </IonGrid>
                            </IonItem>
                        );
                    })}
                </IonList>
            </IonContent>
        )

    }
}
export default authWrapper(SentFeed);

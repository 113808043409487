import React from 'react';
import '../Challenges/Challenges.css';

import { firestore } from '../../firebaseConfig';
import {PremiumContent} from "../../models/PremiumContent";
import PremiumContentPlayer from "./PremiumContentPlayer";
import {IonCard, IonSlide, IonSlides} from "@ionic/react";
import './Sliders.css';

interface CollectiveJourneyFeedContent {
    premiumContentList: PremiumContent[],
}


class CollectiveJourneyFeed extends React.Component<{},CollectiveJourneyFeedContent>  {

    constructor(props) {
        super(props);

        this.state = {
            premiumContentList: [],
        };
    }

    componentDidMount() {

        firestore.collection('premiumContent').where('contentType','==','collectiveJourney').orderBy('date','asc')
            .onSnapshot(snapshot => {
                let premiumContentList= [];
                snapshot.forEach(doc =>
                    premiumContentList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    premiumContentList,
                });
            });

    }



    playCJContent = (item) => {
        //toast('click slide', idx);
        let videoEl  = document.getElementById(item.code) as HTMLMediaElement;
        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    }

    render() {

        const { premiumContentList} = this.state;

        const slideOpts = {
            effect: 'cube',
            zoom: false
        };

        return (
            <IonSlides pager options={slideOpts}>

            {premiumContentList.map((contentItem, idx) => {
                    return (
                        <IonSlide key={idx}>
                            <IonCard className='widthFullCard'>
                                <PremiumContentPlayer item={contentItem}/>
                            </IonCard>
                        </IonSlide>
                    )
                })}
            </IonSlides>
        );
    };
}
export default CollectiveJourneyFeed;

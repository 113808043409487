import React, {useEffect, useState} from 'react';
import authWrapper from "../../components/authWrapper";
import {
    IonAvatar,
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonPage,
    IonRouterLink,
    IonSearchbar
} from "@ionic/react";

import {firestore, storageRef} from "../../firebaseConfig";
import {sendOutline} from "ionicons/icons";
import firebase from "firebase";
import Header from "../../components/Header";

import { useHistory } from 'react-router';

import { getPictureAddress } from '../../util/avatarUtils';
import { findOrCreateConvo } from './chat';

interface MessageContainerProps {
    user: any;
}

const StartConversation: React.FC<MessageContainerProps> = (props) => {

    const [searchReceiver, setSearchReceiver] = useState('');
    const [messageContent, setMessageContent] = useState('');
    const [checked, setChecked] = useState(true);
    const [suggestionList, setSuggestionList] = useState<any>(['']);

    const history = useHistory();

    useEffect(() => {

        let query = searchReceiver.toLowerCase()
        let stringBound = query.slice(0, query.length-1)
            + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (query!='' && query.length >=1) {
            // console.log("query", query);
            const receiverRef = firestore.collection('users')
                .where('characterName_nocase','>=', query)
                .where('characterName_nocase','<', stringBound)
                .where('characterName_nocase','!=', '')
                .where('complete', '==', true)
                .orderBy('characterName_nocase', 'asc')
                .orderBy('dateJoined', 'desc')
            receiverRef.onSnapshot(snapshot => {
                setSuggestionList(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));

            });
        }
        else {
            const receiverRef = firestore.collection('users')
                .where('characterName_nocase','!=', '')
                .where('complete', '==', true)
                .orderBy('characterName_nocase', 'asc')
                .orderBy('dateJoined', 'desc')

            receiverRef.onSnapshot(snapshot => {
                setSuggestionList(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));
            });
        }


    }, [searchReceiver]);


    let viewProfile = (profileId) => {
        if (profileId!=undefined) {
            window.location.href=`/viewAssociation/${profileId}`;
        }
    }

    return (

        <IonPage>
            <IonHeader>
                <Header noChat={true} fromPage="chat"/>
            </IonHeader>
            <IonContent>
                <IonItemDivider>
                    <IonLabel>To
                    </IonLabel>
                </IonItemDivider>
               <IonList>

                    <IonSearchbar value={searchReceiver} onIonChange={e => setSearchReceiver(e.detail.value!)} placeholder="Search ... " debounce={0}></IonSearchbar>

                        {suggestionList && suggestionList.map((receiver, idx) => {
                            if (props.user.uid == receiver.uid) return null
                            return (
                                <IonItem key={idx} lines="none">
                                    <IonAvatar slot="start">
                                        <IonAvatar>
                                            <IonImg id={'profile'+receiver.uid} src={receiver.photoURL ? getPictureAddress(receiver.photoURL,receiver.uid, 'startConvo') : "https://www.gravatar.com/avatar?d=mm"}
                                                    onClick={() => viewProfile(receiver.uid)}/>
                                        </IonAvatar>
                                    </IonAvatar>

                                    <IonLabel style={{paddingLeft: '10px'}}>
                                        <IonRouterLink href={`/viewAssociation/${receiver.uid}`}>
                                            {receiver?.characterName ? receiver.characterName : receiver.username}
                                        </IonRouterLink>
                                    </IonLabel>
                                    <IonButton className="menuButton" onClick={() => findOrCreateConvo(props.user, receiver, history, 'chat')}>
                                       <IonIcon icon={sendOutline}/>
                                    </IonButton>
                                </IonItem>                                // <IonSelectOption key={index} value={receiver.id}>{receiver.heroName ? receiver.heroName : receiver.username}</IonSelectOption>
                            );
                        })}
               </IonList>

            </IonContent>

        </IonPage>
    );
};

export default authWrapper(StartConversation);

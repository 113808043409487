import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonText,
    IonHeader,
    IonRow,
    IonCol,
    IonImg,
    IonItem,
    IonLabel,
    IonThumbnail,
    IonPage,
    IonFab,
    IonFabButton,
    IonGrid,
    IonList,
    IonToolbar,
    IonBackButton,
    IonRouterLink,
    IonButtons,
    IonButton,
    IonIcon,
    IonCheckbox,
    IonFooter,
    IonAvatar,
    IonInput, IonToggle, IonCardContent
} from '@ionic/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { RouteComponentProps, withRouter} from 'react-router';
import {useHistory} from "react-router-dom";

import { firestore, storageRef } from '../../firebaseConfig';
import CheckboxNote from "../../components/ChecboxNote";
import CheckboxBtn from "../../components/CheckboxBtn";
import authWrapper from '../../components/authWrapper';
import {sendOutline} from "ionicons/icons";
import {toast} from "../../toast";
import firebase from "firebase";


interface MessageDetailProps {
    location: any;
    history: any;
}
const MessageDetail: React.FC<MessageDetailProps> = (props) => {

    const [checked, setChecked] = useState(false);
    const [answerContent, setAnswerContent] = useState();

    if (!props.location.state)
        return null
    const {message, user, fromPage} = props?.location.state as any;

    function sendReply(answerContent,checked) {
        // console.log("answerContent", answerContent);
        // console.log("checked", checked);
        // console.log("from", user);
        // console.log("to", message.receiver);
        if (answerContent == '') {
            return toast('Enter a message before sending');
        }

        const chatFeedItemRef = firestore.collection('directMessage').add({
            senderId: message.senderId,
            messageContent: answerContent,
            inCharacter: checked,
            senderImage: '',
            sendTime: firebase.firestore.FieldValue.serverTimestamp(),
            senderHeroName: user.heroName,
            receiver: message.receiver,
        }).then(ref => {
            //
            // console.log('Added message with ID: ', ref.id);
            // window.location.reload(false);

        });
    }

    return (

        <IonPage>
            <Header fromPage={fromPage} noChat={true}/>

            <IonContent className="ion-padding" scrollX scrollY>
                <IonRow>
                    <IonText>{message.messageContent}</IonText>
                </IonRow>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <IonButtons slot="start">
                            <IonAvatar>
                                <IonImg src={"https://www.gravatar.com/avatar?d=mm"}/>
                            </IonAvatar>
                        </IonButtons>

                        <IonInput
                            value={answerContent}
                            onIonChange={(e: any) => setAnswerContent(e.target.value)}
                            clearInput
                            type="text"
                            placeholder={"Write Message here ... "}
                            className="input ion-padding-horizontal"
                        ></IonInput>

                        <IonButtons slot="end">

                            <IonCol>
                                <IonRow>
                                    <IonLabel style={{'fontSize':'7px', minWidth: 64}}>{checked ? `In-Character`: 'Out-Of-Character'}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} />

                                </IonRow>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={() => sendReply(answerContent,checked)}>
                                    <IonIcon icon={sendOutline}/>
                                </IonButton>
                            </IonCol>
                        </IonButtons>
                    </div>
                </IonToolbar>

            </IonFooter>
            <Footer />
        </IonPage>
    );
};

export default authWrapper(withRouter(MessageDetail));

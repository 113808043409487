
import React  from 'react';
import './ExploreContainer.css';

import { IonCard } from '@ionic/react';

import VideoListItem from './VideoListItem';
import { ContentItem } from '../models/ContentItem';
import DeliveryModuleBtnContainer from '../pages/Wurld/DeliveryModuleBtnContainer';
import DeliveryModuleVideo from "./DeliveryModuleVideo";


interface WurldDeliveryModuleScreenProps {
    deliveryModule: ContentItem,
    showButtons: boolean,
    loggedInUser: any,
    tab: string,
    showPopup: boolean,
}


const WurldDeliveryModuleScreen: React.FC<WurldDeliveryModuleScreenProps> = ({ deliveryModule, showButtons, loggedInUser, tab, showPopup }) => {

    return (
        <IonCard className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'}>
            <DeliveryModuleVideo contentItem={deliveryModule} tab={tab} enableMakePrivate={deliveryModule.completedByUserId === loggedInUser.uid}/>
            <DeliveryModuleBtnContainer deliveryModule={deliveryModule} loggedInUser={loggedInUser} tab={tab} showPopup={showPopup}/>
        </IonCard>
    );
};

export default WurldDeliveryModuleScreen;


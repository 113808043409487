import React, {useState} from 'react';
import {
    IonContent,
    IonPage,
    IonTitle,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonIcon,
    IonList,
    IonItem,
    IonAvatar,
    IonImg,
    IonText,
    IonBadge,
    IonSearchbar,
    IonLabel,
    IonButton,
    IonItemDivider,
    IonTextarea,
    IonToggle,
    IonFooter,
    IonInput,
    IonRouterLink,
    IonThumbnail, IonGrid, IonSegment, IonSegmentButton
} from '@ionic/react';
import { firestore } from '../../firebaseConfig';
import {
    ellipsisVertical,
    add,
    people,
    mic,
    createOutline,
    chatbubbles,
    sendOutline,
    send,
    mailUnreadOutline
} from 'ionicons/icons';
import { Message } from '../../models/Message';
import {toast} from "../../toast";
import {withRouter} from "react-router";
import authWrapper from "../../components/authWrapper";
import firebase from "firebase";
import DeliveryModuleScreen from "../../components/DeliveryModuleScreen";
import ChatSubHeader from "../../components/ChatSubHeader";
import Header from '../../components/Header';
import Footer from "../../components/Footer";
import WurldFeed from "../Wurld/WurldFeed";
import MissionsFeed from "../Wurld/MissionsFeed";
import SentFeed from "./SentFeed";
import ReceivedFeed from "./ReceivedFeed";

interface InboxState {
    loading: boolean,
    sentList: Message[],
    receivedList: Message[],
    searchText: string,
    messageContent: string,
    checked: boolean,
    user: any,
}

class Inbox extends React.Component<{}, InboxState> {

    state: any;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sentList: [],
            receivedList: [],
            searchText: '',
            checked: false,
            messageContent: '',
            user: props.user,
        };
    }


    toggleMessageType = () => {
        this.setState({
            checked: !this.state.checked
        })

    }

    setMessageContent = (event: any) => {
        this.setState({ messageContent: event.target.value });
    };



    render() {

        function changeSegmentContent(selectedSegment) {
            let contentContainer = document.getElementById(selectedSegment+'Feed');
            contentContainer.style.display= 'block';
            let sentFeedContainer = document.getElementById('sentFeed');
            let receivedFeedContainer = document.getElementById('receivedFeed');
            if (selectedSegment === 'sent') {
                receivedFeedContainer.style.display= 'none';
            }
            else if (selectedSegment === 'received'){
                sentFeedContainer.style.display= 'none';
            }
        }

        return (

                <IonPage>
                    <Header/>
                    <ChatSubHeader/>

                    <IonContent>
                    <IonSegment value="sent" scrollable onIonChange={(e) => changeSegmentContent(e.detail.value as any)}>
                        <IonSegmentButton value="sent">
                            <IonLabel className="labelText">Sent</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="received">
                            <IonLabel className="labelText">Received</IonLabel>
                        </IonSegmentButton>

                    </IonSegment>
                    <IonContent id="sentFeed" scrollX scrollY>
                        <SentFeed/>
                    </IonContent>
                    <IonContent id="receivedFeed" style={ {display: 'none'}} scrollX scrollY>
                        <ReceivedFeed/>
                    </IonContent>
                    </IonContent>
                    <Footer/>
                </IonPage>

        );
    }
}
export default authWrapper(withRouter(Inbox));


import React, {useEffect, useState} from 'react';
import { IonMenuButton, IonButtons,  IonIcon, IonHeader, IonToolbar, IonButton, IonImg,  IonThumbnail, IonRouterLink, IonModal, IonRow, IonText, IonContent, IonLabel, IonFooter} from '@ionic/react';
import {chatbubbles, chevronBackOutline} from 'ionicons/icons';
import './Header.css';
import { sendJoinTestTeamEmail } from '../util/sendEmail';
import {firestore, getCurrentUser } from '../firebaseConfig';
import {isMobile} from 'react-device-detect';

import { useHistory } from 'react-router-dom';

interface HeaderProps {
  menu?: boolean;
  noChat?: boolean;
  fromPage?: string;
  user?: any;
  selectedTab?: any;
}

const Header: React.FC<HeaderProps> = (props) => {

    const [showJoinTest, setShowJoinTest] = useState(false);

    const [showUnread,setShowUnread] = useState(false);

    const history = useHistory();
    
    useEffect(() => {
        setInterval(() => {
            let currentUser = getCurrentUser().then((doc) => {
                if (doc) {
                    let userRef = firestore.doc(`users/${doc['uid']}`);

                    let currentUserFromDB = userRef.get().then(docDB => {
                        if (docDB) {
                            //console.log("docDB", docDB);
                            if (docDB.data() && docDB.data()['unread'] !== undefined) {
                                setShowUnread(docDB.data()['unread']);

                            }

                            //console.log("check unread", docDB.data() && docDB.data()['unread']);
                        }

                    });
                }

            });
        }, 2000);
        // if (showUnread) {
        //     notification('You have new messages');
        // }
    });

    function goBack(fromPage) {
        //console.log('fromPage', fromPage);
        fromPage ? window.location.href="/"+fromPage : window.history.back();
    }

    function goBackToTab(fromPage, selectedTab) {

        //console.log("selectedTab", selectedTab)
        window.history.back();
        // history.pushState({selectedTab: selectedTab}, 'chat', '/chat');

        // if (selectedTab && selectedTab=== 'dm') {
        //     history.push({
        //         pathname: '/chat',
        //         state: {selectedTab: selectedTab}
        //     });
        // }
        // else {
        //     fromPage ? window.location.href="/"+fromPage : window.history.back();
        //
        // }
    }

    const joinTestTeam = () => {
        //console.log("send email ", props.user);
        sendJoinTestTeamEmail(props.user);
        setShowJoinTest(false);
    }


    return (
        <IonHeader className="no-border" style={{'--border-width':'0 0 0 0'}}>
            <IonModal isOpen={showJoinTest}
                      cssClass='join-custom-class'
                      onDidDismiss={() => setShowJoinTest(false)}>
                <IonHeader>
                    <IonRow style={{padding: 10}}>
                        <IonText className="appTextMain center">
                            To gain access to this section, join the JFH App Test Team.

                        </IonText>
                    </IonRow>
                </IonHeader>
                <IonContent className="join-custom-class"
                            scrollEvents={true}
                            onIonScrollStart={() => {
                            }}
                            onIonScroll={() => {
                            }}
                            onIonScrollEnd={() => {
                            }} scrollX scrollY>

                    <IonImg src={"/assets/img/JoinTestTeam.png"} style={{height:'150px', width:'300'}}></IonImg>

                </IonContent>
                <IonFooter>
                    <IonRow className="ion-justify-content-center">

                        <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                            <IonLabel className="italic bolder">
                                Join
                            </IonLabel>
                        </IonButton>
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonToolbar className="app" style={{'--border-width':'0 0 0 0'}}>
                {!isMobile &&
                    <IonRow style={{justifyContent:'center'}}>
                        <img  style={{height:'200px',objectFit:'cover'}} src="https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fdesktop_app.png?alt=media"
                              onClick={() => {window.open('https://bit.ly/jfh-app','_blank')}}/>
                    </IonRow>
                }
                <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                        <IonButtons>
                            {props.menu ?
                                <IonMenuButton autoHide={true} class="menuButton"/>
                                :
                                <IonButton style={{'--color':'#ffffff', 'marginTop':'10px'}} onClick={() => {props.selectedTab ? goBackToTab(props.fromPage, props.selectedTab) :goBack(props.fromPage)}}> <IonIcon icon={chevronBackOutline}> </IonIcon> {props.fromPage==='premiumMissions' ? 'Missions': 'Back'} </IonButton>
                            }
                        </IonButtons>
                    </div>

                    <IonRouterLink href="/wurld">
                        <IonThumbnail>
                            <IonImg src={"/assets/icon/JFH_App_logo_WHITE.png"}></IonImg>
                        </IonThumbnail>
                    </IonRouterLink>

                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>
                        {props.noChat ? null :
                            <IonButtons>
                                {/*<IonButton onClick={() => setShowJoinTest(true)} className="menuButton" style={{'--color':'#ffffff'}}>*/}

                                    <IonButton href="/chat" className="menuButton" style={{'--color':'#ffffff'}}>

                                        {showUnread ?

                                        <sub
                                                style={
                                                    {color:'red',
                                                        height:'10px',
                                                        width:'10px',
                                                        backgroundColor: 'red',
                                                        borderRadius: '50%'}
                                                }/>
                                                : ''}

                                    <IonIcon slot="icon-only" icon={chatbubbles}>
                                        {/*{(props.user.unread !== undefined && props.user.unread) ? <sub>.</sub>: ''}*/}
                                    </IonIcon>

                                    </IonButton>
                            </IonButtons>
                        }
                    </div>
                </div>
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;




import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonPage,
    IonSegmentButton, IonLabel, IonSegment
} from '@ionic/react';
import User from '../../models/User';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from '../../components/authWrapper';


import ViewContent from './ViewContent';
import ViewChangeRequests from "./ViewChangeRequests";


interface BrandAdminState {
    loading: boolean;
    users: User[];
    user: any;
}

const BrandAdmin:React.FC<BrandAdminState> = (props) => {

    const [currentTab, setCurrentTab]= useState('content');
    const [searchUser, setSearchUser]= useState('');

    useEffect(() => {

        // let queryProfile = searchUser.toLowerCase()
        // let stringBoundProfile = queryProfile.slice(0, queryProfile.length-1)
        //     + String.fromCharCode(queryProfile.slice(-1).charCodeAt(0) + 1)
        // //console.log("search receiver", searchReceiver);
        //
        // if (queryProfile!='' && queryProfile.length >=1) {
        //     // console.log("query", query);
        //     const searchCastRef = firestore.collection('users').where('complete', '==', true)
        //         .where('characterName_nocase','>=', queryProfile)
        //         .where('characterName_nocase','<', stringBoundProfile)
        //         .where('characterName_nocase','!=', '')
        //         .orderBy('characterName_nocase', 'asc')
        //         .orderBy('dateJoined', 'desc')
        //
        //     searchCastRef.onSnapshot(snapshot => {
        //         setUsers(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));
        //
        //     });
        //
        // }
        // else {
        //
        //     let castRef = firestore.collection('users').where('complete', '==', true).orderBy('dateJoined', 'desc')
        //         .onSnapshot(snapshot => {
        //
        //             let users = [];
        //
        //             snapshot.forEach(castDoc =>
        //                 users.push({...castDoc.data(), uid: castDoc.id}),
        //             );
        //
        //             setUsers(users);
        //         });
        //
        //
        // }


    }, [searchUser]);

    function changeSegmentContent(selectedSegment) {

        let contentContainer = document.getElementById(selectedSegment + 'Feed');
        contentContainer.style.display = 'block';
        let contentFeedContainer = document.getElementById('contentFeed');
        let pendingChangesContainer = document.getElementById('pendingChangesFeed');

        if (selectedSegment === 'content') {
            pendingChangesContainer.style.display='none';
            contentFeedContainer.style.display='block';
            setCurrentTab('content');
        } else if (selectedSegment === 'pendingChanges') {
            // setShowJoin(true);
            contentFeedContainer.style.display='none';
            pendingChangesContainer.style.display='block';
            setCurrentTab('pendingChanges');
        }
    }
    return (

        <IonPage>


            <Header />


            <IonContent>


                <IonSegment value={currentTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)} >
                    <IonSegmentButton value="content">
                        <IonLabel className="labelText">Content</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="pendingChanges">
                        <IonLabel className="labelText"> Change Requests </IonLabel>
                    </IonSegmentButton>
                </IonSegment>


                <IonContent id="contentFeed" scrollX scrollY>
                    { currentTab === 'content' &&
                        <ViewContent />}
                </IonContent>
                <IonContent id="pendingChangesFeed" style={{display: 'none'}}  scrollX scrollY>

                    { currentTab === 'pendingChanges' &&
                    <ViewChangeRequests />}
                </IonContent>

            </IonContent>
            <Footer/>
        </IonPage>

    );
}

export default authWrapper(BrandAdmin);

import React from 'react';
import {
    IonText,
    IonRow,
    IonCol,

    IonRouterLink,
    IonPage,
    IonContent,

    IonFabButton,
    IonLabel,

    IonFab
} from '@ionic/react';
import {  } from 'ionicons/icons';
import Header from "../../components/Header";
import {useHistory} from "react-router";

const PreRegistration2: React.FC = () => {

    const history = useHistory();

    function createVillain() {
        //window.location.href="/hero";
        history.push({pathname: '/hero', state: {villain:true}});
    }

    function createHero() {
       // window.location.href="/hero";
        history.push({pathname: '/hero', state: {villain:false}});
    }

    function createClient() {
       // window.location.href="/client";
        history.push({pathname: '/client'});
    }
  return (
    <IonPage>
        <Header noChat={true} fromPage="registration"/>
        <IonContent className="app ion-padding">
            <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'left'}}>
                  <IonRow>
                      <IonCol style={{'textAlign':'center'}}>
                          <IonText className="appTextMain">
                                Tap Your Role:
                          </IonText>
                      </IonCol>
                  </IonRow>
                  <IonRow>
                      <IonCol>

                          <IonFab onClick={createHero}>
                              <IonFabButton style={{ '--background': '#ffffff', 'minHeight': '100px', 'width':'100px', '--border-color':'#FF0000' }} >
                                                     <IonLabel className="btnTitle" style={{'color':'#005494'}}>HERO</IonLabel>
                              </IonFabButton>
                          </IonFab>

                         <br/><br/>
                         <IonText className="app bolder" style={{'paddingLeft':'32%', 'color':'#ffffff'}}> Save people and get paid.</IonText>
                      </IonCol>

                  </IonRow>
                  <IonRow style={{'paddingTop':'10%'}}>

                      <IonCol>

                      <IonFab onClick={createClient}>
                          <IonFabButton style={{ '--background': '#ffffff', 'minHeight': '100px', 'width':'100px', '--border-color':'#FF0000' }} >
                                                 <IonLabel className="btnTitle" style={{'color':'#005494'}}>CLIENT</IonLabel>
                          </IonFabButton>
                      </IonFab>
                      {/*</IonCol>*/}
                      {/*    <IonCol>*/}
                      <br/><br/>
                      <IonText className="app bolder" style={{'paddingLeft':'32%', 'marginTop':'20%', 'color':'#ffffff'}}> Hire a hero to help out.</IonText>
                      </IonCol>
                  {/*</IonItem>*/}
                  {/*</IonRouterLink>*/}

                  </IonRow>

                  <IonRow style={{'paddingTop':'10%'}}>

                  <IonCol>
                  {/*<IonRouterLink href="/hero">*/}
                  {/*  <IonItem lines="none" className="app">*/}
                  <IonFab onClick={createVillain}>
                    <IonFabButton style={{ '--background': '#ffffff', 'minHeight': '100px', 'width':'100px', '--border-color':'#FF0000' }} >
                                         <IonLabel className="btnTitle" style={{'color':'#005494'}}>VILLAIN</IonLabel>
                    </IonFabButton></IonFab>
                  {/*</IonCol>*/}
                      {/*    <IonCol>*/}
                      <br/><br/>
                      <IonText  className="app  bolder" style={{'paddingLeft':'32%', 'color':'#ffffff'}}>
                          <IonLabel className="ion-padding-top">Pose as a hero (for now).</IonLabel>
                      </IonText>

                    {/*</IonItem>*/}
                    {/*</IonRouterLink>*/}
                  </IonCol>

                  </IonRow>

                  <IonRow>
                     <IonText className="app ion-text-center bolder" style={{'paddingTop':'20%', 'color':'#ffffff'}}>

                         <li className="bolder" style={{color:'#ffffff', listStyle: 'none'}}>
                             If you want to just watch as an audience member, <IonRouterLink href="/client" style={{fontStyle:'italic', color: '#ffffff'}}>CLIENT</IonRouterLink> is the best choice. </li>
                     </IonText>
                 </IonRow>

            </div>

        </IonContent>

    </IonPage>

  );
};


export default PreRegistration2;



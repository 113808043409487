import React, {useEffect, useState } from 'react';
import {IonCard, IonContent, IonGrid, IonIcon, IonImg, IonRow, IonSearchbar, IonThumbnail} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import { ContentItem } from '../../models/ContentItem';
import DeliveryModuleScreen from '../../components/DeliveryModuleScreen';
import WurldDeliveryModuleScreen from '../../components/WurldDeliveryModuleScreen';
import {downloadContent, downloadPictureContent} from "../../util/avatarUtils";
import {cloudDownload} from "ionicons/icons";


interface AdminDevFeedProps {

}


const AdminDevFeed: React.FC<AdminDevFeedProps> = (props) => {

    const [devList, setDevList] = useState([]);
    const [searchDev, setSearchDev] = useState('');

    useEffect(() => {
        let queryDevFeed = searchDev
        let stringBoundDev = queryDevFeed.slice(0, queryDevFeed.length-1)
            + String.fromCharCode(queryDevFeed.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (queryDevFeed!='' && queryDevFeed.length >=1) {
            // console.log("query", query);
            const searchDevRef = firestore.collection('content').where('contentType', '==', 'development')
                .where('objectRefTitle','>=', queryDevFeed)
                .where('objectRefTitle','!=', '')
                .orderBy('objectRefTitle', 'asc')
                .orderBy('timeCreated', 'desc')

            searchDevRef.onSnapshot(snapshot => {
                setDevList(snapshot.docs.map(devDoc => ({...devDoc.data(), uid: devDoc.id })));

            });

        }
        else {

            let DevRef =  firestore.collection('content').where('contentType', '==', 'development').orderBy("timeCreated", "desc")
                .onSnapshot(snapshot => {

                    let devDocList = [];

                    snapshot.forEach(devDoc =>
                        devDocList.push({...devDoc.data(), uid: devDoc.id}),
                    );

                    setDevList(devDocList);
                });


        }
    }, [searchDev]);

    return (
        <>
            <IonSearchbar value={searchDev} onIonChange={e => setSearchDev(e.detail.value!)} placeholder="Search by prompt name ... " debounce={1000}></IonSearchbar>

            {devList.map((devItem, idxM) => {
                return (
                    <IonCard key={idxM} className="child2">
                        <IonThumbnail style={{'height':'70%', 'width':'100%'}}>
                            <IonImg id={'contentItemAdmin'+idxM+devItem.uid} src={devItem.posterUrl ? downloadPictureContent(devItem.posterUrl,devItem.uid, 'contentItemAdmin'+`${idxM}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    alt={"Content Image"}  />
                        </IonThumbnail>

                        <div>
                            <IonGrid>
                                <IonRow>
                                    {devItem.objectRefTitle}_{devItem.completedByHeroName}
                                </IonRow>
                                <IonRow>
                                    <a href={downloadContent(devItem.contentVideo)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCard>
                )
            })}
        </>
    );


}
export default AdminDevFeed;


import React from 'react';
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonButton, IonImg
} from '@ionic/react';

const CreateTrainingChallenge: React.FC = () => {

    return (
        <IonHeader class="ion-no-border">
            <IonToolbar>
                <IonButtons slot="end" className="ion-padding-start">
                    <IonButton className="completeBtn bolder italic" href="/createTrainingChallengeItem" style={{height:'fit-content', background:'#005494', color:'#ffffff', fontStyle:'italic'}}>
                        CREATE <IonImg style={{'width':'25px', background:'#005494'}} src={"assets/img/super.png"} /><sup style={{color:'#ffffff'}}>+</sup>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default CreateTrainingChallenge;

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonImg,
  IonFooter,
  IonNote
} from '@ionic/react';

import React, {useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  archiveSharp,
  documentTextOutline,
  person,
  help,
  informationCircleOutline,
  bookOutline,

  people,
  calendarOutline,
  downloadOutline,
  hammerOutline, cloudDownload, shirt, pencilOutline, logOut
} from 'ionicons/icons';

import './Menu.css';
import {getCurrentUser, logoutUser} from '../firebaseConfig';


interface AppPage {
  url: string;
  iosIcon: any;
  mdIcon: any;
  title: string;
}



const Menu: React.FC = () => {
  const location = useLocation();
  const logo = require('../assets/logo/jfh.png');

    const [showAdmin, setShowAdmin] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState('');

    useEffect(() => {
        let currentUser = getCurrentUser().then((doc)=> {
          //setUserLoggedIn(doc);
          if (doc && doc['email'] === 'jan@justiceforhire.com') {
            setShowAdmin(true);
          }
          // console.log("doc ", doc);
          // getUserDocument(doc['uid']).then(currentUserDoc => {
          //       //console.log("current user data", currentUser.data());
          //       //let currentUserLogged = {...currentUser, uid: currentUser.uid}
          //       //console.log("current user logged", currentUser);
          //       setCurrentUserRole(currentUserDoc['role']);
          //     }
          // );
        });
    }, []);

  const appPages: AppPage[] = [

    {
      title: 'The Story So Far ...',
      url: '/storySoFar',
      iosIcon: 'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Ffairytale.png?alt=media',
      mdIcon: 'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Ffairytale.png?alt=media'
    },
    {
      title: 'Walkthrough',
      url: '/Walkthrough',
      iosIcon: bookOutline,
      mdIcon: bookOutline
    },
    {
      title: 'Rules',
      url: '/pages/Rules',
      iosIcon: archiveSharp,
      mdIcon: archiveSharp
    },
    {
      title: 'Cast',
      url: '/cast',
      iosIcon: people,
      mdIcon: people
    },
    {
      title: 'Events',
      url: '/events',
      iosIcon: calendarOutline,
      mdIcon: calendarOutline
    },
    {
      title: 'Assets',
      url: '/assets',
      iosIcon: downloadOutline,
      mdIcon: downloadOutline
    },
    {
      title: 'Merch',
      url:'/merch',
      iosIcon: shirt,
      mdIcon: shirt
    },
    {
      title: 'About',
      url: '/pages/About',
      iosIcon: informationCircleOutline,
      mdIcon: informationCircleOutline
    },
    {
      title: 'Account',
      url: '/pages/Account',
      iosIcon: person,
      mdIcon: person
    },
    {
      title: showAdmin ? 'Brand Admin': 'Change Requests',
      url: showAdmin ? '/brandAdmin' : '/userAdmin',
      iosIcon: showAdmin ? cloudDownload : pencilOutline,
      mdIcon: showAdmin ? cloudDownload : pencilOutline
    },
    {
      title: 'Support',
      url: '/pages/Support',
      iosIcon: help,
      mdIcon: help
    },
    {
      title: 'Join Test Team',
      url: '/pages/joinTestTeam',
      iosIcon: hammerOutline,
      mdIcon: hammerOutline
    },
    {
      title: 'Terms',
      url: '/pages/TermsPage',
      iosIcon: documentTextOutline,
      mdIcon: documentTextOutline
    }
  ];

    return (
    <IonMenu contentId="main" type="overlay">
       <IonHeader className="head">
                    <div className="jfh-header">
                        <div className="logoImage">
                            <IonImg src={logo}></IonImg>
                        </div>
                        <div className="titleHeader">Justice For Hire</div>
                    </div>
                </IonHeader>
      <IonContent>
      
        <IonList className="ion-margin-vertical">

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                {appPage.url === '/storySoFar' ?
                    <IonItem style={{}} className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonImg slot="start" src={"assets/img/fairytale.png"}  className="menuButton"/>
                      <IonLabel style={{'--overflow': 'visible !important'}} slot="start">{appPage.title}</IonLabel>
                    </IonItem>
                :<IonItem style={{}} className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" icon={appPage.iosIcon} />
                      <IonLabel style={{'--overflow': 'visible !important'}} slot="start">{appPage.title}</IonLabel>
                    </IonItem>}

              </IonMenuToggle>
            );
          })}


          {/*{showAdmin &&*/}
          {/*<IonMenuToggle  autoHide={false}>*/}
          {/*  <IonItem className={location.pathname === '/brandAdmin' ? 'selected' : ''} routerLink={'brandAdmin'} routerDirection="none" lines="none" detail={false}>*/}
          {/*    <IonIcon slot="start" icon={cloudDownload} />*/}
          {/*    <IonLabel slot="start">Brand Admin</IonLabel>*/}
          {/*  </IonItem>*/}
          {/*</IonMenuToggle>*/}
          {/*}*/}

          {/*{!showAdmin &&*/}
          {/*<IonMenuToggle  autoHide={false}>*/}
          {/*  <IonItem className={location.pathname === '/changeRequests' ? 'selected' : ''} routerLink={'changeRequests'} routerDirection="none" lines="none" detail={false}>*/}
          {/*    <IonIcon slot="start" icon={pencilOutline} />*/}
          {/*    <IonLabel slot="start">Change Requests</IonLabel>*/}
          {/*  </IonItem>*/}
          {/*</IonMenuToggle>*/}
          {/*}*/}
            <IonMenuToggle autoHide={false}>
                <IonItem lines="none" button onClick={logoutUser} detail={false}>
                    <IonIcon slot="start" icon={logOut} />
                    <IonLabel>Logout</IonLabel>
                </IonItem>
            </IonMenuToggle>
        </IonList>

      </IonContent>
        <IonFooter style={{'marginBottom':'4px'}}>
            <IonItem lines="none">
                <IonNote className="ion-text-wrap ion-text-center"> <br/>Platform Powered by ReelwUrld™. Patent Pending. All rights reserved. <br/></IonNote>
            </IonItem>
        </IonFooter>
    </IonMenu>
  );
};

export default Menu;

import {storageBucketAddress, storageRef} from "../firebaseConfig";
import { toast } from "../toast";

export function downloadPictureContent(photoPath, id, containerId) {
    // console.log("photoPath", photoPath);
    // console.log("id", id);
    // console.log("containerId", containerId);

    if(photoPath !== '' && photoPath !== undefined) {
        storageRef.child(photoPath).getDownloadURL().then(function (pathUrl) {
            let img = document.getElementById(containerId+id);
            img.setAttribute('src', pathUrl);
        }).catch(function (error) {
            // Handle any errors
        });
    }

    return '';
}

export function downloadCoverPhoto(photoPath, id, containerId) {
    // console.log("photoPath", photoPath);
    // console.log("id", id);
    // console.log("containerId", containerId);

    if(photoPath !== '' && photoPath !== undefined) {
        storageRef.child(photoPath).getDownloadURL().then(function (pathUrl) {
            let img = document.getElementById(containerId);
            console.log("img container", img);
            img.setAttribute('src', pathUrl);
        }).catch(function (error) {
            // Handle any errors
        });
    }

    return '';
}

export function viewProfileAssociation(id) {
    if (id != undefined) {
        window.location.href='/viewAssociation/'+id;
    }
}

export function viewAssociation(id, relationship, loggedInUser) {


    if (id !== undefined && loggedInUser !== undefined) {
        window.location.href='/viewAssociation/'+id +  "/" + relationship + "/" + loggedInUser;
    }
    else {
        toast("an error occured. Please contact Support");
    }
}


export function getPictureAddress(photoPath, id, containerId) {
    return storageBucketAddress+`${encodeURIComponent(photoPath)}`+"?alt=media";
}

export function getAssetFullAddress(assetPath) {
    return storageBucketAddress+`${encodeURIComponent(assetPath)}`+"?alt=media";
}

export function getThumbnailAddress(photoPath, id, containerId) {

    return storageBucketAddress+`${encodeURIComponent(photoPath)}`+"?alt=media";
}

export function getVideoAddress(photoPath, id, containerId) {
    // console.log("photo path", photoPath);
    // console.log("photo id", id);
    // console.log("container id", containerId);
    // https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fwatch%2FSERIES012021.mp4?alt=media&token=
    return storageBucketAddress+`${encodeURIComponent(photoPath)}`+"?alt=media&token=e9e6160d-b962-40c7-975e-9cbc94b90e68";

}


export function downloadContent(imgAddress)  {

    return storageBucketAddress+`${encodeURIComponent(imgAddress)}`+"?alt=media&token=e9e6160d-b962-40c7-975e-9cbc94b90e68";
}


export function viewCharacterProfileDetails(profileId) {
    if (profileId!=undefined) {
        window.location.href=`/viewAssociation/${profileId}`;
    }
}
import React, {useEffect, useState} from 'react';

import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,

    IonLabel, IonList,
    IonPage, IonRouterLink,
    IonSearchbar, IonRow, IonFooter, IonToolbar, IonCol, IonButtons, IonToggle, IonCheckbox, IonInput
} from "@ionic/react";
import {firestore} from "../../firebaseConfig";
import {sendOutline} from "ionicons/icons";
import Header from "../../components/Header";
import UserAvatar from "../../components/UserAvatar";
import firebase from "firebase";
import {toast} from "../../toast";

interface SendContentProps{
    location: any;
    history: any;
}

const SendContent: React.FC<SendContentProps> = (props) => {

    const [searchReceiver, setSearchReceiver] = useState('');
     const [messageContent, setMessageContent] = useState('');
    const [checked, setChecked] = useState(true);
    const [suggestionList, setSuggestionList] = useState<any>(['']);
    const [receivers, setReceivers] = useState<any>([]);

    console.log('props send content',props);


    const {sender, contentId, fromPage} = props?.location?.state as any;

    const customAlertOptions = {
        header: 'Select Receivers'
    };

    useEffect(() => {

        const receiverRef = firestore.collection('users').where('heroName','>', '')

        receiverRef.onSnapshot(snapshot => {
            let suggestionList = [];

            snapshot.forEach(doc =>
                suggestionList.push({ ...doc.data(), uid: doc.id }),
            );
            suggestionList = suggestionList.filter(item => item.uid !== sender.uid);

            if (searchReceiver !== '') {
                suggestionList = suggestionList.filter(item => item.heroName.trim().toLowerCase().startsWith(searchReceiver.toLowerCase()));
            }
            setSuggestionList(suggestionList);
        });

    }, [searchReceiver]);




    function addToReceivers(receiver, checked) {

        if (checked) {
            receivers.push(receiver);
        }
        else {
            let receiverIdx = receivers.findIndex(d => d.uid === receiver.uid);
            receivers.splice(receiverIdx);
        }
        setReceivers(receivers);
        //console.log("receivers list", receivers);

    }

    function createMessages(sender, receivers, contentId, messageContent, checked) {
        console.log("create individual messages", receivers);
        //todo if thread exists, add message if not create new convo
        //todo redirect to wurld after creation
        for (let destination of receivers) {
            //create messages to add to conversation
            let messageData = {messageType: 'outgoing', checked: checked, sendTime: firebase.firestore.Timestamp.fromDate(new Date()), answerContent: messageContent, contentId: contentId};
            let messageList = [messageData];
            console.log("message list", messageList);
            const conversation = firestore.collection('conversation').add({
                senderId: sender.uid,
                senderHeroName: sender.heroName,
                senderPhotoURL: sender.photoURL,
                receiverId: destination.uid,
                receiverHeroName: destination.heroName ? destination.heroName : destination.clientName,
                receiverPhotoURL: destination?.photoURL,
                sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
                messages: messageList,
            }).then(refConvo => {
                console.log("created convo");

                // console.log("created convo", refConvo.id);
                // history.push({
                //     pathname: "/conversationThread",
                //     state: {conversationId: refConvo.id, receiver: {uid: receiver.uid, photoURL: receiver?.photoURL, heroName: receiver.heroName, role:'hero'}, messages: [''], user: sender, fromPage: 'chat'},
                // });
            }).catch(err => console.log('error', err));
        }
        toast('Messages successfully sent', 2000);
       // window.location.href='wurld';

    }

    return (

        <IonPage>

            <Header noChat={true} fromPage={fromPage}/>

            <IonContent>

                <IonSearchbar value={searchReceiver} onIonChange={e => setSearchReceiver(e.detail.value!)} placeholder="Search ... " debounce={0}></IonSearchbar>

               <IonList>

                         {suggestionList && suggestionList.map((receiver, idx) => {
                             return (
                                 <IonItem key={idx} lines="none">
                                     <UserAvatar userPhotoURL={receiver.photoURL} userId={receiver.uid} userHeroName={receiver.hero? receiver.heroName : receiver.clientName} userRole={'role'} showMoreInfo={false}/>

                                     <IonLabel>
                                         <IonRouterLink href={`/viewAssociation/${receiver.uid}`}>
                                             {receiver?.heroName ? receiver.heroName : receiver.username}
                                         </IonRouterLink>
                                     </IonLabel>
                                     <IonCheckbox checked={receivers.indexOf(receiver)!=-1} className="checkbox-send" onIonChange={e => addToReceivers(receiver, e.detail.checked)} />
                                 </IonItem>
                             );
                         })}
                </IonList>

            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <IonCol size="8">
                            <IonInput
                                value={messageContent}
                                onIonChange={e => {e.preventDefault();setReceivers(receivers);setMessageContent(e.detail.value);}}
                                autofocus
                                type="text"
                                placeholder={"Write messages here ... "}
                                className="input ion-padding-horizontal"
                                style={{'fontSize':'12px'}}
                            />
                        </IonCol>
                        <IonButtons slot="end">
                            <IonCol>
                                <IonRow>
                                    <IonLabel style={{'fontSize':'8px'}}>{checked ? `In-Character`: ''}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                                </IonRow>
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={() => createMessages(sender, receivers, contentId, messageContent, checked)}>
                                    <IonIcon icon={sendOutline}/>
                                </IonButton>
                            </IonCol>
                        </IonButtons>
                    </div>
                </IonToolbar>
            </IonFooter>


        </IonPage>
    );
};

export default SendContent;


import React from 'react';
import { IonContent, IonPage,  IonRow, IonCol, IonGrid, IonThumbnail, IonImg, IonHeader,  IonLabel,  IonButton, IonFab, IonFabButton} from '@ionic/react';

import Header from '../components/Header';

const HeroDelivery: React.FC = () => {

 var recorder = document.getElementById('recorder');
 //var player = document.getElementById('player');


 if (recorder != null) {
    recorder.addEventListener('change', function(e:any) {
        let file = e.target.files[0];
        //create url
        let fileURL = URL.createObjectURL(file);
        // Do something with the video file.
        window.location.href='/heroDeliveryReview';
        //send it to the review window

        //player.setAttribute('src', URL.createObjectURL(file));
        //console.log(file);
    });
}

async function clickUpload(e) {
    document.getElementById('uploadVideo').click();
}

async function recordVideo() {
    document.getElementById('recorder').click();

}


  return (

    <IonPage>
        <IonContent className="app" scrollX scrollY>
        <Header menu={true}/>
        <IonHeader>
            <IonRow>
                <IonCol>
                    <IonButton id="uploadVideoImgBtn" style={{'background':'none'}} onClick={(e) => {clickUpload(e)}}>
                        <IonThumbnail>
                        <IonImg src={"https://jfh-app-assets.s3.amazonaws.com/CHALLENGES_4_ICONS/Copy+of+upload.svg"} className="footerIcon"/>
                        <input type="file" accept="video/*" className="uploadVideoBtn" id="uploadVideo"/>
                        </IonThumbnail>
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonHeader>
        <IonGrid>

        <IonRow><IonCol></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow>

        <IonCol className="app ion-text-center">
                    <IonLabel className="appText" style={{'paddingBottom':'10px', 'color':'#ffffff'}}>  Introduce yourself and reveal your hero name to the world.
 </IonLabel>


        </IonCol>
         </IonRow>
         <IonRow></IonRow>
         <IonRow></IonRow>
         <IonRow></IonRow>
         <IonRow>
         <IonCol className="app ion-text-center">
             <IonLabel  color="light" className="appText" style={{'paddingBottom':'10px'}}> Why are you the hero to hire?
</IonLabel>
          </IonCol>

        </IonRow>


        <IonRow><IonCol></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow>

           <IonCol className="app ion-text-center">

            <IonFab style={{'left':'35%','borderStyle':'solid', 'borderRadius': '50%','borderWidth': '3px','borderColor': 'red'}}>

                    <IonFabButton style={{ '--background': '#ffffff', 'minHeight': '100px', 'width':'100px', '--border-color':'red' }} onClick={() => recordVideo()}>
                        <IonLabel className="appTitle">RECORD</IonLabel>
                    </IonFabButton>
                    <input type="file" accept="video/*" capture="camera" id="recorder" className="uploadVideoBtn"/>

                 </IonFab>
            </IonCol>
         </IonRow>
         <IonRow>
         </IonRow>
          </IonGrid>
      </IonContent>

    </IonPage>
  );
};

export default HeroDelivery;

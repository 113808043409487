import {firestore, storageRef} from "../firebaseConfig";
import {toast} from "../toast";
import {Device} from "@capacitor/core";
import firebase from "firebase";

export function extractFramesFromVideo(videoPlayerId, videoUrl, fps=25, objectType, objectTitle, heroName) {

    //let videoPlayerEl = document.getElementById('challengeVideoPlayer') as HTMLVideoElement;
    let videoPlayerEl = document.getElementById(videoPlayerId) as HTMLVideoElement;

    let seekResolve;
    videoPlayerEl.addEventListener('seeked', async function() {
        if(seekResolve) seekResolve();
    });


    let duration = videoPlayerEl.duration;
    console.log('duration', videoPlayerEl.duration);

    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let [w, h] = [videoPlayerEl.videoWidth, videoPlayerEl.videoHeight]
    canvas.width =  w;
    canvas.height = h;

    let frames = [];
    let interval = 1 / fps;
    let currentTime = 0.1;
    videoPlayerEl.currentTime = currentTime;
    context.drawImage(videoPlayerEl, 0, 0, w, h);
    let thumbnail =  canvas.toDataURL('image/jpeg', 1.0);
    console.log('base64ImageData', thumbnail);
    videoPlayerEl.style.display='none';
    videoPlayerEl.pause();
    let imgEl = document.getElementById('posterGenerated');
    imgEl.setAttribute('src', thumbnail);
    imgEl.style.display='block';
    let thumbnailFileName = objectTitle.replace(/ /g, "_") + "_"+heroName.replace(/ /g, "_") + Date.now()+ '.jpeg';
    let thumbnailStorageRef = storageRef.child('content/'+objectType+"/thumbnails/"+thumbnailFileName);

    canvas.toBlob((blob) => {
        let thumbnailFile = new File([blob], thumbnailFileName, { type: "image/jpeg" })
        //console.log("file", thumbnailFile);
        //upload
        thumbnailStorageRef.put(thumbnailFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail');
        }).catch((err)=>toast("error uploading thumbnail"+err));
    }, 'image/jpeg');
    return thumbnailStorageRef.fullPath;
}

export function toggleFullscreen() {
    let elem = document.querySelector("video");

    if (!document.fullscreenElement) {
        elem.requestFullscreen().catch(err => {
            alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else {
        document.exitFullscreen().then(() => {
            console.log("exit fullscreen")});
    }
}
export function openFullScreen(videoEl) {
    if (!document.fullscreenElement) {
        videoEl.requestFullscreen().catch(err => {
            alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else {
        // document.exitFullscreen().then(() => console.log('stop video play'));
        videoEl.pause();
        console.log("exit fullscreen");
    }
}

export function checkDuration(videoContainerName, fileURL) {
    let videoContainer  = document.getElementById(videoContainerName) as HTMLVideoElement;
    console.log("video info", videoContainer.duration);

    if (videoContainer.duration > 60) {
        URL.revokeObjectURL(fileURL);
        return toast('Video exceeds the 1-minute limit. Please upload shorter video');
    }
}


export function onVideoClick(e, platformInfoName, props) {
    let videoEl = document.getElementById(props.index+props.tab) as HTMLVideoElement;

    let playBtn = document.getElementById('play'+props.index+props.tab);
    let stopBtn = document.getElementById('stop'+props.index+props.tab);


    if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
        //hide play. show stop
        playBtn.style.display='block';
        stopBtn.style.display='none';

        videoEl.pause();
    }
    else if (videoEl.paused || videoEl.ended) {
        // videoEl.webkitEnterFullScreen();
        videoEl.play().then(
            () => {
                playBtn.style.display='none';
                stopBtn.style.display='block';
                if (platformInfoName ==='android') {
                    openFullScreen(videoEl);

                    // let docElm = document.documentElement;
                    // if (docElm.requestFullscreen) {
                    //     docElm.requestFullscreen().then(()=> {console.log("played fullscreen")});
                    // }

                    // if (typeof(videoEl.webkitEnterFullscreen) != "undefined") {
                    //     // This is for Android Stock.
                    //     videoEl.webkitEnterFullscreen();
                    // }
                }
                console.log("resume video");
                console.log("video play time", videoEl.currentTime);
            }
        ).catch(() => console.log("error playing video"));
    }
    else {

        videoEl.play().then(
            () => {
                playBtn.style.display='none';
                stopBtn.style.display='block';
                if (platformInfoName ==='android' || platformInfoName === 'web') {
                    openFullScreen(videoEl);
                }
            }
        ).catch(() => console.log("error playing video"));
    }
}

export function playVideoRef(e, platformInfoName, props, videoRef) {

    let videoEl = videoRef != null ? videoRef.current :  document.getElementById('profileVideo'+props.index+props.tab) as HTMLVideoElement;

    let playBtn = document.getElementById('play'+props.index+props.tab);
    let stopBtn = document.getElementById('stop'+props.index+props.tab);

    if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
        //hide play. show stop
        playBtn.style.display = "flex";
        stopBtn.style.display = "none";

        videoEl.pause();
        videoEl.controls = false;
        videoEl.load();
    }
    else if (videoEl.paused || videoEl.ended) {
        // videoEl.webkitEnterFullScreen();
        videoEl.controls = false;
        playBtn.style.display = "flex";
        stopBtn.style.display = "none";

        videoEl.play().then(
            () => {
                stopBtn.style.display = "flex";
                playBtn.style.display = "none";
                if (platformInfoName ==='android' || platformInfoName === 'web') {
                    openFullScreen(videoEl);

                    // let docElm = document.documentElement;
                    // if (docElm.requestFullscreen) {
                    //     docElm.requestFullscreen().then(()=> {console.log("played fullscreen")});
                    // }

                    // if (typeof(videoEl.webkitEnterFullscreen) != "undefined") {
                    //     // This is for Android Stock.
                    //     videoEl.webkitEnterFullscreen();
                    // }
                }
                console.log("resume video");
                console.log("video play time", videoEl.currentTime);
            }
        ).catch(() => console.log("error playing video"));
    }
    else {

        videoEl.play().then(
            () => {
                stopBtn.style.display = "flex";
                playBtn.style.display = "none";
                if (platformInfoName ==='android' || platformInfoName === 'web') {
                    openFullScreen(videoEl);
                }
            }
        ).catch(() => console.log("error playing video"));
    }
}

export function playVideoEl(e, platformInfoName, props) {

    let videoEl =  document.getElementById('profileVideo'+props.index+props.tab) as HTMLVideoElement;

    console.log(videoEl+'videoEl');
    let playBtn = document.getElementById('play'+props.index+props.tab);
    let stopBtn = document.getElementById('stop'+props.index+props.tab);


    if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
        //hide play. show stop
        playBtn.style.display='block';
        stopBtn.style.display='none';

        videoEl.pause();
    }
    else if (videoEl.paused || videoEl.ended) {
        // videoEl.webkitEnterFullScreen();
        videoEl.play().then(
            () => {
                playBtn.style.display='none';
                stopBtn.style.display='block';
                if (platformInfoName ==='android') {
                    toggleFullscreen();

                    // let docElm = document.documentElement;
                    // if (docElm.requestFullscreen) {
                    //     docElm.requestFullscreen().then(()=> {console.log("played fullscreen")});
                    // }

                    // if (typeof(videoEl.webkitEnterFullscreen) != "undefined") {
                    //     // This is for Android Stock.
                    //     videoEl.webkitEnterFullscreen();
                    // }
                }
                console.log("resume video");
                console.log("video play time", videoEl.currentTime);
            }
        ).catch(() => console.log("error playing video"));
    }
    else {

        videoEl.play().then(
            () => {
                playBtn.style.display='none';
                stopBtn.style.display='block';
                if (platformInfoName ==='android') {
                    toggleFullscreen();
                }
            }
        ).catch(() => console.log("error playing video"));
    }
}

export function uploadDeliveryModuleCover(e, deliveryModuleRef, deliveryModuleCoverRef, contentItem) {
    let posterFile = e.target.files[0];
    let posterFileUrl = URL.createObjectURL(posterFile);

    let posterFileExtension = posterFile.type.split("/").pop();
    let posterFileName =  contentItem.title.replace(/ /g, "_") + contentItem.completedByHeroName.replace(/ /g, "_") + Date.now()+"."+posterFileExtension;
    console.log('poster file!!!!!!!!!!!!!!!!!!!!'+ posterFileName);

    deliveryModuleRef.current.setAttribute('poster', posterFileUrl);
    if (deliveryModuleCoverRef !== null) {
        deliveryModuleCoverRef.current.setAttribute('src', posterFileUrl);
    }

    let deliveryModuleCoverImageRef = storageRef.child('content/thumbnails/'+posterFileName);

    deliveryModuleCoverImageRef.put(posterFile).then(function(snapshot) {
        console.log('Uploaded the thumbnail for delivery module!!!!!!!!!!!!!!!!!!');

        firestore.doc(`content/${contentItem.uid}`).set({
            posterUrl: deliveryModuleCoverImageRef.fullPath,
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        }, {merge: true}).then(function() {
            console.log("updated cover photo");
            window.location.reload(false);
        });
    }).catch((err)=>toast("error uploading thumbnail"+err));
    return deliveryModuleCoverImageRef;
}


export function uploadCTAModuleCover(e, ctaModuleRef, ctaModule) {
    let ctaPosterFile = e.target.files[0];
    let posterFileUrl = URL.createObjectURL(ctaPosterFile);

    let posterFileExtension = ctaPosterFile.type.split("/").pop();
    let posterFileName =  ctaModule.title.replace(/ /g, "_") +ctaModule.clientName.replace(/ /g, "_") + Date.now()+"."+posterFileExtension;
    //toast('poster file'+ posterFileName);

    ctaModuleRef.setAttribute('poster', posterFileUrl);

    let ctaModuleCoverImageRef = storageRef.child('Missions/thumbnails/'+posterFileName);

    ctaModuleCoverImageRef.put(ctaPosterFile).then(function(snapshot) {
        //console.log('Uploaded the thumbnail for profile');

        firestore.doc(`missions/${ctaModule.uid}`).set({
            posterUrl: ctaModuleCoverImageRef.fullPath,
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        }, {merge:true}).then(function() {
            console.log("updated cover photo");
            //window.location.reload(true);
        });
    }).catch((err)=>toast("error uploading thumbnail"+err));
    return ctaModuleCoverImageRef;
}


export function playVideoFromRef(videoEl, platformInfoName) {
    videoEl.controls=true;
    videoEl.style.display='block';
    videoEl.controlsList = 'nodownload';

    if (platformInfoName ==='android') {
        videoEl.onfullscreenchange = (e=> {
            if (!document.fullscreenElement)
                videoEl.pause();
        })
    }

    videoEl.play().then(
        () => {
            console.log("played video");
            if (platformInfoName ==='android') {
                openFullScreen(videoEl);
            }
        }
    ).catch((err) => console.log("error playing video", err));
}
import React, {useEffect, useState } from 'react';
import {IonCard, IonContent, IonGrid, IonIcon, IonImg, IonRow, IonSearchbar, IonThumbnail} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import { ContentItem } from '../../models/ContentItem';
import DeliveryModuleScreen from '../../components/DeliveryModuleScreen';
import WurldDeliveryModuleScreen from '../../components/WurldDeliveryModuleScreen';
import {downloadContent, downloadPictureContent} from "../../util/avatarUtils";
import {cloudDownload, search} from "ionicons/icons";


interface AdminJourneyFeedProps {

}


const AdminJourneyFeed: React.FC<AdminJourneyFeedProps> = (props) => {

    const [journeyList, setJourneyList] = useState([]);
    const [searchJourney, setSearchJourney] = useState('');

    useEffect(() => {
        //console.log("props show", props);
        let queryJourneyFeed = searchJourney
        let stringBoundJourney = queryJourneyFeed.slice(0, queryJourneyFeed.length-1)
            + String.fromCharCode(search.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (queryJourneyFeed!='' && queryJourneyFeed.length >=1) {
            // console.log("query", query);
            const searchJourneyRef = firestore.collection('content').where('contentType', '==', 'journey')
                .where('objectRefTitle','>=', queryJourneyFeed)
                .where('objectRefTitle','!=', '')
                .orderBy('objectRefTitle', 'asc')
                .orderBy('timeCreated', 'desc')

            searchJourneyRef.onSnapshot(snapshot => {
                setJourneyList(snapshot.docs.map(journeyDoc => ({...journeyDoc.data(), uid: journeyDoc.id })));

            });

        }
        else {

            let journeyRef =  firestore.collection('content').where('contentType', '==', 'journey').orderBy("timeCreated", "desc")
                .onSnapshot(snapshot => {

                    let journeyDocList = [];

                    snapshot.forEach(journeyDoc =>
                        journeyDocList.push({...journeyDoc.data(), uid: journeyDoc.id}),
                    );

                    setJourneyList(journeyDocList);
                });


        }
    }, [searchJourney]);

    return (
        <>
            <IonSearchbar value={searchJourney} autoCapitalize="on" onIonChange={e => setSearchJourney(e.detail.value!)} placeholder="Search by prompt name ... " debounce={1000}></IonSearchbar>

            {journeyList.map((missionItem, idxM) => {
                return (
                    <IonCard key={idxM} className="child2">
                        <IonThumbnail style={{'height':'70%', 'width':'100%'}}>
                            <IonImg id={'contentItemAdmin'+idxM+missionItem.uid} src={missionItem.posterUrl ? downloadPictureContent(missionItem.posterUrl,missionItem.uid, 'contentItemAdmin'+`${idxM}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    alt={"Content Image"}  />
                        </IonThumbnail>

                        <div>
                            <IonGrid>
                                <IonRow>
                                    {missionItem.objectRefTitle}_{missionItem.completedByHeroName}
                                </IonRow>
                                <IonRow>
                                    <a href={downloadContent(missionItem.contentVideo)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCard>
                )
            })}
        </>
    );


}
export default AdminJourneyFeed;


import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';

import { firestore, storageRef} from '../../firebaseConfig';
import { IonPage, IonHeader, IonRow,  IonImg, IonContent, IonLabel,  IonAvatar, IonButton, IonList, IonItem,  IonFabButton, IonText, IonListHeader, IonRouterLink, IonSearchbar, IonIcon, IonCol } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import {removeRivalFromDb, removeRivalRef, rival} from "./allyrival";
import Header from "../../components/Header";
import { personAddOutline } from 'ionicons/icons';
import UserAvatar from '../../components/UserAvatar';
import {confirmRival, rejectWithdrawRival, updateNoPendingRequests} from "./relationships";
import {downloadPictureContent} from "../../util/avatarUtils";

interface ViewRivalsPageProps extends RouteComponentProps<{
    userId: string,
    currentUserId: string,
}> {}


async function getRivalsArray(userId) {
    let rivalsOut = firestore.collection('relationshipRequests').where('senderId', '==', userId).get();
    let rivalsIn = firestore.collection('relationshipRequests').where('rivalId', '==', userId).get();

    const [rivalsOutSnapshot, rivalsInSnapshot] = await Promise.all([
        rivalsOut,
        rivalsIn
    ]);


    const rivalsOutArray = rivalsOutSnapshot.docs;
    const rivalsInArray = rivalsInSnapshot.docs;

    const rivalsArray = rivalsOutArray.concat(rivalsInArray);

    return rivalsArray;
}

const ViewRivals: React.FC<ViewRivalsPageProps> = ({match}) => {

    const [rivalsList, setRivalsList] = useState<any>(['']);
    const [alliesList, setAlliesList] = useState<any>(['']);

    const [suggestionList, setSuggestionList] = useState<any>(['']);
    const [searchText, setSearchText] = useState('');


    const {userId, currentUserId} = match.params;

    useEffect(() => {

        // let isSubscribed = true;

        //let rivalsList = [];
        // setInterval(() => {


        let userDocRef = firestore.collection('users').doc(userId);

        userDocRef.get().then((doc)=> {
            const rivalsListRef = {id: doc.id, ...doc.data()};

            //console.log("rivals:", rivalsListRef['rivals']);
            //rivalsList = rivalsListRef['rivals'];


            getRivalsArray(userId).then(snapshotRelationships => {
                let rivalsList = [];
                let alliesList = [];
                snapshotRelationships.forEach(snapshotRelationship => {
                    if (snapshotRelationship.data()['relationshipType'] === 'rival') {
                        rivalsList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id})
                    }
                    else if (snapshotRelationship.data()['relationshipType'] === 'ally') {
                        alliesList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id});
                    }


                });

                if (searchText !== '') {
                    if (rivalsList !== undefined) {
                        rivalsList = rivalsList.filter(rivalItem => rivalItem.rivalName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                    }
                }

                // rivalsList = rivalsList.filter(rivalItem => rivalItem.senderId === userId);
                // if (rivalsList && rivalsList.length >= 1) {
                //     for (let i = 0; i < rivalsList.length; i++) {
                //         let rivalId = rivalsList[i]['rivalId'];
                //         let senderId = rivalsList[i]['senderId'];
                //         console.log("rival id", rivalId);
                //         // if (rivalId === userId) {
                //         //     rivalsList = rivalsList.filter(item => item.rivalId === rivalId);
                //         // }
                //         // else if (senderId === userId) {
                //         //     rivalsList = rivalsList.filter(item => item.senderId === senderId);
                //         // }
                //
                //     }
                // }

                setRivalsList(rivalsList);
                setAlliesList(alliesList);


                let rivalSuggestionRef = firestore.collection('users').where("complete", "==", true);
                let query = searchText.toLowerCase()
                let stringBound = query.slice(0, query.length-1)
                    + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)

                if (query!='' && query.length >=1) {
                    // console.log("suggestions", suggestionsRef);
                    rivalSuggestionRef.where('characterName_nocase','>=', query)
                        .where('characterName_nocase','<', stringBound)
                        .where('characterName_nocase','!=', '')

                }
                rivalSuggestionRef = rivalSuggestionRef.orderBy("characterName_nocase", "asc");

                rivalSuggestionRef.onSnapshot(snapshot => {
                    let suggestionList = [];

                    snapshot.forEach(suggestionRivalDoc => {
                        if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {
                            suggestionList.push({...suggestionRivalDoc.data(), uid: suggestionRivalDoc.id})
                        }
                    });
                    suggestionList = suggestionList.filter(item => item.uid !== userId);

                    if (rivalsList && rivalsList.length >= 1) {
                        for (let i = 0; i < rivalsList.length; i++) {
                            let rivalId = rivalsList[i]['rivalId'];
                            let senderId = rivalsList[i]['senderId'];
                            console.log("rival id", rivalId);
                            suggestionList = suggestionList.filter(item => item.uid !== rivalId);
                            suggestionList = suggestionList.filter(item => item.uid !== senderId);
                            //console.log(suggestionList);
                        }
                    }

                    if (alliesList && alliesList.length >= 1) {
                        for (let i = 0; i < alliesList.length; i++) {
                            let allyId = alliesList[i]['rivalId'];
                            let senderId = alliesList[i]['senderId'];
                            console.log("ally ", allyId);
                            suggestionList = suggestionList.filter(item => item.uid !== allyId);
                            //console.log(suggestionList);
                        }
                    }


                    if (searchText !== '') {
                        suggestionList = suggestionList.filter(item => item.characterName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                    }
                    setSuggestionList(suggestionList);
                });
            });
        });

        // }, 2000);
        // return () => {isSubscribed = false}

    }, [userId, searchText]);



    function removeRival(rivalId, rivalName, rivalPhotoUrl, rivalStatus) {
        //console.log("Ally info:", allyId + allyName + allyPhotoUrl+"userId:" + userId);

        console.log('rival status remove', rivalStatus);

        let rivalData = {
            rivalId:rivalId,
            rivalName:rivalName,
            rivalPhotoUrl:rivalPhotoUrl,
            status: rivalStatus,
        }

        removeRivalFromDb(userId, rivalData,rivalsList);
    }



    function confirmAndUpdatePending(rivalDoc, userId) {
        confirmRival(rivalDoc.uid);
        updateNoPendingRequests(userId);
        updateNoPendingRequests(rivalDoc.rivalId);
    }


    function rejectAndUpdatePending(rivalDoc, userId) {
        rejectWithdrawRival(rivalDoc.uid);
        updateNoPendingRequests(userId);
        updateNoPendingRequests(rivalDoc.rivalId);

    }

    function viewProfile(profileId, relationship)  {
        if (profileId!=undefined) {
            window.location.href=`/viewAssociation/${profileId}/${relationship}`;
        }
    }

    return (
        <IonPage>
            <Header/>
            <IonContent className="ion-padding-bottom" scrollX scrollY>
                { (rivalsList === undefined || rivalsList?.length < 1) &&
                <IonFabButton disabled style={{
                    '--background': '#ffffff', "margin": "20px auto 20px auto", 'minHeight': '111px', 'width':'111px'
                }}>
                    <IonIcon icon={personAddOutline} style={{'color': '#005494'}}/>
                </IonFabButton>
                }
                { (rivalsList === undefined || rivalsList?.length < 1) &&
                <IonRow className="ion-justify-content-center" style={{margin: "10px 10px 10px 10px", textAlign:'center'}}>

                    <IonLabel className="sectionTitle"
                              style={{ color: '#005494'}}>
                        <IonText style={{textAlign:'center'}}> Follow other Heroes as either Allies or Rivals on their Profile Page.</IonText>
                    </IonLabel>
                </IonRow>}

                <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} debounce={0}></IonSearchbar>

                {rivalsList && rivalsList.length >= 1 &&
                <IonList>
                    <IonListHeader>
                        <IonLabel className="sectionTitle"
                                  style={{'align': 'center', 'color': '#005494'}}> Rivals
                        </IonLabel>
                    </IonListHeader>
                    {rivalsList && rivalsList.map((rival, index) => {
                        return (


                            <IonItem key={index} lines="none">


                                    {rival?.senderId === userId ?
                                        <IonAvatar slot="start">
                                            <IonImg id={'viewRivalProfile'+rival.rivalId} src={rival.rivalPhotoUrl ? downloadPictureContent(rival.rivalPhotoUrl,rival.rivalId,'viewRivalProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                    onClick={() => viewProfile(rival.rivalId, 'rival')}/>
                                        </IonAvatar>
                                        :<IonAvatar slot="start">
                                            <IonImg id={'viewRivalProfile'+rival.senderId} src={rival.senderPhotoURL ? downloadPictureContent(rival.senderPhotoURL,rival.senderId,'viewRivalProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                    onClick={() => viewProfile(rival.senderId, 'rival')}/>
                                        </IonAvatar>
                                    }
                                    {/*<UserAvatar userId={rival.rivalId} userRole={'hero'} userHeroName={rival.rivalName} showMoreInfo={false} userPhotoURL={rival.rivalPhotoUrl}/>*/}



                                    <IonLabel>
                                        {rival?.senderId === userId
                                            ? <IonRouterLink href={`/viewAssociation/${rival?.rivalId}`}>{rival?.rivalName}</IonRouterLink>
                                            : <IonRouterLink href={`/viewAssociation/${rival?.senderId}`}>{rival?.senderName}</IonRouterLink>}
                                        {/*{ally?.rivalName} | {ally?.senderName}| {ally?.rivalId} |{ally?.senderId}| {userId}*/}
                                    </IonLabel>




                                {rival.status === 'pending' && rival.senderId === currentUserId &&
                                    <IonButton className="rivalsBtn" onClick={() => confirmAndUpdatePending(rival, userId)} >
                                       Awaiting/Confirm
                                    </IonButton>
                                }

                                {rival.status === 'pending' && rival.rivalId === currentUserId &&
                                <IonButton className="rivalsBtn" onClick={() => rejectAndUpdatePending(rival, userId)} >
                                    Pending/Withdraw
                                </IonButton>
                                }


                                {rival.status === 'approved' && (rival.senderId === currentUserId || rival.rivalId === currentUserId) &&
                                <>
                                    <IonButton className="rivalsBtn" onClick={() => rejectAndUpdatePending(rival, userId)} >
                                        Remove
                                    </IonButton>

                                </>

                                }
                            </IonItem>
                        );
                    })}
                </IonList>
                }

            </IonContent>
            <Footer/>
        </IonPage>
    )

}

export default ViewRivals;

import React, {useEffect, useState } from 'react';
import {

    IonCard,
    IonCol,

    IonRow,
    IonSearchbar,
    IonText,

    IonLabel
} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import VideoContentPlayer from "../../components/Player/VideoContentPlayer";
import ChangeRequestAction from "./ChangeRequestAction";
import Moment from 'react-moment';

interface MissionContentChangeRequestsProps {

}


const MissionContentChangeRequests: React.FC<MissionContentChangeRequestsProps> = (props) => {
    const [contentChangesList, setContentChangesList] = useState([]);

    const [searchContentByUser, setSearchContentByUser] = useState('');

    useEffect(() => {
        console.log("props show", props);
        let contentChangeRequestsRef =  firestore.collection('contentUpdateRequest').where('contentType','==','mission').orderBy("timeCreated", "desc")
            .onSnapshot(snapshot => {
                let changeRequestList = [];

                snapshot.forEach(doc =>

                    changeRequestList.push({ ...doc.data(), uid: doc.id }),
                );
                setContentChangesList(changeRequestList);

            });



    }, [props]);

    return (
        <>
            <IonSearchbar value={searchContentByUser} onIonChange={e => setSearchContentByUser(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}/>

            {contentChangesList.map((contentChangeItem, idxCt) => {
                return (
                    <div key={idxCt}>
                        <IonRow style={{fontSize:'8px', textAlign: 'center'}} className="ion-justify-content-center">

                            <Moment format='MMMM Do YYYY, h:mm a' style={{fontSize:'8px', opacity: '.5'}}>{contentChangeItem.timeCreated.toDate()}</Moment>

                        </IonRow>
                    <IonCard>
                        <VideoContentPlayer item={contentChangeItem} tab={'brandAdminContent'} index={idxCt}/>

                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Original Description </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText> {contentChangeItem.originalDescription} </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Proposed Description </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText> {contentChangeItem.requestedChangeDescription} </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Proposed By </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText> {contentChangeItem.requestedByCharacter}</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>

                            </IonCol>
                            <IonCol>
                                {contentChangeItem.status === 'pending' ?
                                    <ChangeRequestAction item={contentChangeItem} tab={'brandAdminContent'} dbTableName={'contentUpdateRequest'} index={idxCt}/>
                                    : <IonLabel>{contentChangeItem.status.toUpperCase()}</IonLabel>}
                            </IonCol>
                        </IonRow>

                    </IonCard>

                </div>
                )
            })}
        </>
    );

}
export default MissionContentChangeRequests;

import React from 'react';
import { IonContent, IonText, IonPage} from '@ionic/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './About.css';
import authWrapper from "../../components/authWrapper";

const About: React.FC = () => {
  return (
    <IonPage>
       <Header menu={true}/>
       <IonContent className="ion-padding ion-text-center">
        <IonText>

        <p className="c1"><span className="c2">JFH <br></br></span><span className="c0">is a mobile-1st, action movie series that calls upon
              our audience to unite in building a cinematic universe of heroes. &nbsp;Watch an episode from the larger
              storyline, then take on Challenges to make your own short movies on your phone. Tie-in characters that you
              create and portray back into the larger story, and meet up with other cast members to make cinematic
              adventures together (and make new friends in real life).</span></p>
      <p className="c1"><span className="c0">&nbsp;</span></p>
      <p className="c1"><span className="c0">We started JFH to fictionalize solutions to real world problems (kinda like how
              &quot;Star Trek&quot; is the reason we have cell phones). &nbsp;Join us in thinking about how we can take on
              issues in our daily lives, our neighborhoods, cities, towns, countries, and the world, and make a better
              place for us all to live - all while making movies &amp; having fun.</span></p>
      <p className="c1"><span className="c0">&#8203;</span></p>
      <p className="c1"><span className="c0">Inspired by the comic book series, &quot;JFH: Justice For Hire&quot;.</span></p>
      <p className="c3"><span className="c4"></span></p>
      </IonText>
        </IonContent>
      <Footer />
    </IonPage>
  );
};

export default authWrapper(About);


import React from 'react';
import { IonRow, IonCol, IonText, IonImg, IonAvatar } from '@ionic/react';


import { useHistory} from "react-router-dom";
import {Message} from "../../models/Message";
import Moment from 'react-moment';

interface MessageItemProps {
    message: Message;
    user: any;
}

const MessageItem: React.FC<MessageItemProps> = ({message, user}) => {

    const history = useHistory();


    function viewMessageDetail(message) {
        history.push({
            pathname: "/messageDetail",
            state: {message: message, user: user, fromPage: 'chat'},
        });
    }
    return (
        <IonRow onClick={()=> viewMessageDetail(message)}>
            <IonCol size="2">
                <IonAvatar>
                    <IonImg src={"https://www.gravatar.com/avatar?d=mm"} alt={message.uid}/>
                </IonAvatar>
            </IonCol>
            <IonCol size="6">
                <IonRow>
                    <IonText style={{'fontWeight':'bold'}}> {message.receiver} </IonText>
                </IonRow>
                <IonRow>
                    {message.inCharacter ?
                        <IonText>
                            {message.messageContent}
                        </IonText>
                        : <IonText style={{'color':'#005494'}}>
                            {message.messageContent}
                        </IonText>
                    }
                </IonRow>
                <IonRow>
                    <Moment format='MMMM Do YYYY, h:mm:ss a' style={{'fontSize':'8px'}}>{message.sendTime.toDate()}</Moment>
                </IonRow>
            </IonCol>
        </IonRow>
    );
};

export default MessageItem;


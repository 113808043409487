import React, {useEffect, useRef, useState} from 'react';
import './VideoListItem.css';
import {IonIcon, IonLabel, IonActionSheet, IonRow, IonCol, IonText, IonGrid, IonItem} from '@ionic/react';
import {playOutline, mail, closeOutline, lockClosedOutline, imageOutline} from 'ionicons/icons';
import { ContentItem } from '../models/ContentItem';
import {openFullScreen, playVideoFromRef, toggleFullscreen, uploadDeliveryModuleCover} from "../util/videoUtils";
import {firestore, storageRef} from "../firebaseConfig";
import {downloadContent, downloadCoverPhoto, downloadPictureContent, getPictureAddress} from '../util/avatarUtils';
import {toast} from "../toast";
import {Device} from "@capacitor/core";


interface DeliveryModuleVideoProps {
    contentItem: ContentItem;
    tab: string;
    enableMakePrivate: boolean;
}

const DeliveryModuleVideo: React.FC<DeliveryModuleVideoProps> = ({ contentItem, tab, enableMakePrivate }) => {

    const [showActionSheet, setShowActionSheet] = useState(false);

    const [platformInfoName, setPlatformInfoName] = useState('web');

    let deliveryModuleRef = useRef(null);
    let deliveryModuleCoverRef= useRef(null);
    let uploadCoverImageDeliveryModule  = useRef(null);

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);

            setPlatformInfoName(info.operatingSystem);
        } );
    }, [contentItem]);

    function playDeliveryModule() {
        let deliveryModuleEl  = deliveryModuleRef.current;

        playVideoFromRef(deliveryModuleEl, platformInfoName);

    }

    async function clickUploadCoverImage() {
        document.getElementById('uploadCoverImageDeliveryModule'+contentItem.uid+tab).click();
    }

    function onUploadCoverDeliveryModule(e) {
        console.log("do 1 delivery module", contentItem.title);
        let deliveryModuleCoverEl = uploadDeliveryModuleCover(e, deliveryModuleRef, deliveryModuleCoverRef, contentItem);
        //setCoverAddress(downloadContent(deliveryModuleCoverImageRef.fullPath));
        //setCoverImgAddress(deliveryModuleCoverImageRef.fullPath);
        console.log("cover image address", deliveryModuleCoverEl.fullPath);
    }



    return (
        <div className="video-wrapper">
            <div className="overlay"
                 onClick={playDeliveryModule}
            >

                <div style={{margin: 5, maxWidth: '80%'}} className="left deliveryModuleTitle bolder">
                    <IonItem style={{'--background':'#005494', '--color':'#ffffff', '--padding-start': '2px', '--min-height':'40'}} lines="none">

                                    <IonIcon style={{padding: 2}} item-end size="small" icon={playOutline}/>

                                   <IonText> {contentItem.title} </IonText>
                    </IonItem>
                </div>
                    {/*<IonRow>*/}
                    {/*    <IonIcon style={{padding: 2}} item-end icon={playOutline}/>*/}

                    {/*    <IonLabel style={{display: 'flex !important'}}>*/}
                    {/*         {contentItem.title}*/}
                    {/*    </IonLabel>*/}
                    {/*</IonRow>*/}
                    {/*<IonRow>*/}
                    {/*    <IonCol size="1">*/}
                    {/*        <IonIcon style={{padding: 2}} item-end icon={playOutline}/>*/}

                    {/*    </IonCol>*/}
                    {/*    <IonCol>*/}

                    {/*        <IonLabel style={{display: 'flex !important'}}>*/}
                    {/*            &nbsp;&nbsp;{contentItem.title}*/}
                    {/*        </IonLabel>*/}
                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}

                <div className="right reportBtn highContrast">
                    <IonLabel style={{padding: 20, paddingTop: 5, cursor: 'pointer'}}
                              onClick={(e) => {setShowActionSheet(true);e.stopPropagation();}}
                    >
                        ...
                    </IonLabel>
                    {enableMakePrivate &&
                    <IonActionSheet
                        isOpen={showActionSheet}
                        onDidDismiss={() => setShowActionSheet(false)}
                        backdropDismiss={false}
                        cssClass='my-custom-class'
                        buttons={[ {
                            text: 'Report a problem',
                            icon: mail,
                            handler: () => {
                                window.location.href='/reportRequest';
                            }
                        }, {
                            text: 'Make Private',
                            icon: lockClosedOutline,
                            handler: () => {
                                firestore.doc(`content/${contentItem.uid}`).update({
                                    publicContent: false,
                                }).then(function() {
                                    window.location.reload(false);
                                });
                            }
                        }, {
                            text: 'Change Cover Photo',
                            icon: imageOutline,
                            handler: () => {
                                // firestore.doc(`content/${contentItem.uid}`).update({
                                //     publicContent: true,
                                // }).then(function() {
                                //     window.location.reload(false);
                                // });
                                clickUploadCoverImage();
                            }
                        }
                            , {
                                text: 'Cancel',
                                icon: closeOutline,
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }]}
                    >
                    </IonActionSheet>
                    }
                    {!enableMakePrivate &&
                    <IonActionSheet
                        isOpen={showActionSheet}
                        onDidDismiss={() => setShowActionSheet(false)}
                        cssClass='my-custom-class'
                        buttons={[ {
                            text: 'Report a problem',
                            icon: mail,
                            handler: () => {
                                window.location.href='/reportRequest';
                            }
                        }
                            , {
                                text: 'Cancel',
                                icon: closeOutline,
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }]}
                    >
                    </IonActionSheet>
                    }
                </div>
            </div>
            {enableMakePrivate &&
            <input type="file" accept="image/*" className="uploadVideoBtn"
                   id={'uploadCoverImageDeliveryModule'+contentItem.uid+tab}
                   onChange={(e) => onUploadCoverDeliveryModule(e)}
            />}
            <img src={getPictureAddress(contentItem?.posterUrl, contentItem?.uid,contentItem?.uid+tab)} ref={deliveryModuleCoverRef}/>
            <video id={contentItem.uid+tab+"deliveryModule"}
                   poster={getPictureAddress(contentItem?.posterUrl, contentItem?.uid,contentItem?.uid+tab)}
                   ref={deliveryModuleRef} style={{'display':'none'}} controlsList="nodownload" preload="metadata"
                   onEnded={e=> {deliveryModuleRef.current.load(); }}
                   onPause={e=> {deliveryModuleRef.current.load(); deliveryModuleRef.current.controls=false; }}>
                <source src={getPictureAddress(contentItem.contentVideo, contentItem.uid,contentItem.uid+tab)} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

        </div>

    );
};

export default DeliveryModuleVideo;


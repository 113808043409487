import React, {useState} from 'react';
import {

    IonRow,
    IonCol,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonList,
    IonItem,
    IonAvatar,
    IonImg,
    IonText,
    IonLabel,
    IonButton,
    IonItemDivider,
    IonToggle,
    IonFooter,
    IonInput,
    IonRouterLink,
    IonThumbnail,
    IonGrid
} from '@ionic/react';
import { firestore } from '../../firebaseConfig';
import {sendOutline} from 'ionicons/icons';
import { Message } from '../../models/Message';
import {toast} from "../../toast";
import {withRouter} from "react-router";
import authWrapper from "../../components/authWrapper";
import firebase from "firebase";

import ChatWelcomePopup from "./ChatWelcomePopup";

interface CastChatState {
  loading: boolean,
  chats: Message[],
    searchText: string,
    messageContent: string,
    checked: boolean,
    user: any,
    showWelcomePopup: boolean,

}

class CastChat extends React.Component<{}, CastChatState> {

  state: any;


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      chats: [],
        searchText: '',
        checked: true,
        messageContent: '',
        user: props.user,
        showWelcomePopup: false,
        el: '',
    };
  }

  componentDidMount() {
    // this.setState({ loading: true, checked: true, messageContent: ''});


      if (this.state.user.pages !== undefined ) {
          console.log("pages ", this.state.user.pages);
          let pagesList = this.state.user.pages.filter(item => item.castChat === true);
          if (pagesList && pagesList.length === 1) {
              this.state.showWelcomePopup = false;
          }
           else {
              this.state.showWelcomePopup = true;

              let userDocRef = firestore.collection('users').doc(this.state.user.uid);
              let pagesData = {
                  castChat: true,
              }
              let arrUnion = userDocRef.update({
                  pages: firebase.firestore.FieldValue.arrayUnion(pagesData)
              }).then(() =>
                  console.log("add cast chat to pages visited"))
                  .catch((err) => console.log(err));

          // add message from Justice F Hire
              let botMessageContent = this.state.user.characterName ? this.state.user.characterName : (this.state.user.heroName ? this.state.user.heroName: this.state.user.clientName);
              const chatFeedItemRef = firestore.collection('chatFeedItem').add({
                  messageContent: botMessageContent+' has entered JFH cast chat for the first time. Say hi.',
                  inCharacter: true,
                  senderImage: '',
                  sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
                  senderHeroName: 'Justice F Hire',
              }).then(()=> console.log("bot message sent"));
         }
      }
      else {
          this.state.showWelcomePopup = true;

          let userDocRef = firestore.collection('users').doc(this.state.user.uid);
          let pagesData = {
              castChat: true,
          }
          let arrUnion = userDocRef.update({
              pages: firebase.firestore.FieldValue.arrayUnion(pagesData)
          }).then(() =>
              console.log("add cast chat to pages visited"))
              .catch((err) => console.log(err));

          // add message from Justice F Hire
          let botMessageContent = this.state.user.characterName ? this.state.user.characterName : (this.state.user.heroName ? this.state.user.heroName: this.state.user.clientName);

          const chatFeedItemRef = firestore.collection('chatFeedItem').add({
              messageContent: botMessageContent+' has entered JFH cast chat for the first time. Say hi.',
              inCharacter: true,
              senderImage: '',
              sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
              senderHeroName: 'Justice F Hire',
          }).then(()=> console.log("bot message sent"));
      }

    firestore.collection('chatFeedItem').orderBy("sendTime", "asc")
    .onSnapshot(snapshot => {
      let chats = [];

      snapshot.forEach(doc =>
        chats.push({ ...doc.data(), uid: doc.id }),
      );

      this.setState({
        chats,
        loading: false,
          searchText: '',
          messageContent: '',
      });
    });

      //this.scrollToBottom();

  }

    scrollToBottom = () => {
        const  messageList  = document.getElementById('messageList');
        messageList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    toggleMessageType = () => {
        this.setState({
            checked: !this.state.checked
        })

    }

    setMessageContent = (event: any) => {
        this.setState({ messageContent: event.target.value });
    };

     createChatFeedItem = (senderHeroName, senderId,photoURL,messageContent, checked) => {

         if (messageContent == '') {
             return toast('Enter a message before sending');
         }


         const chatFeedItemRef = firestore.collection('chatFeedItem').add({
             senderId: senderId,
             messageContent: messageContent,
             inCharacter: checked,
             senderImage: photoURL,
             sendTime: firebase.firestore.FieldValue.serverTimestamp(),
             senderHeroName: senderHeroName,
         }).then(ref => {
             //
            // console.log('Added message with ID: ', ref.id);
            // window.location.reload(false);

         });
     }


  render() {
      const { chats, loading, searchText, checked, messageContent, showWelcomePopup } = this.state;

      return (
      <>
                  {showWelcomePopup && <ChatWelcomePopup/>}

                  <IonList lines="none">
                      {chats.length === 0 &&
                      <IonItemDivider>Welcome To JFH CAST CHAT</IonItemDivider>}
                      {chats && chats.map((chatItem, chatIdx) => {
                          return (

                              <IonItem key={chatIdx} lines="none">
                                  <IonGrid>
                                  <IonRow>
                                  <IonCol size="2">
                                          <IonAvatar>
                                              <IonImg src={"https://www.gravatar.com/avatar?d=mm"}/>
                                          </IonAvatar>
                                  </IonCol>
                                      <IonCol size="8">

                                          <IonRow>
                                              <IonText style={{'fontWeight':'bold'}}>{chatItem.senderHeroName}</IonText>
                                          </IonRow>
                                          <IonRow>
                                              {chatItem.inCharacter ?
                                                  <IonText style={{'flexWrap':'noWrap'}}>
                                                      {chatItem.messageContent}
                                                  </IonText>
                                                  : <IonText style={{'color':'#005494'}}>
                                                      {chatItem.messageContent}
                                                  </IonText>
                                              }
                                          </IonRow>

                                      </IonCol>

                                  </IonRow>
                                  </IonGrid>
                              </IonItem>

                          );
                      })}

                  </IonList>
                  <IonFooter style={{'paddingBottom':'5px'}}>
                      <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>

                      <IonToolbar>
                             <IonButtons slot="start">
                                 <IonAvatar>
                                     <IonImg src={"https://www.gravatar.com/avatar?d=mm"}/>
                                 </IonAvatar>
                             </IonButtons>

                             <IonInput
                                 value={messageContent}
                                 onIonChange={this.setMessageContent}
                                 clearInput
                                 type="text"
                                 placeholder={"Write messages here ... "}
                                 className="input ion-padding-horizontal"
                             ></IonInput>

                             <IonButtons slot="end">

                                 <IonCol>
                                     <IonRow>
                                         <IonLabel style={{'fontSize':'7px', minWidth: 64}}>{checked ? `In-Character`: 'Out-Of-Character'}</IonLabel>
                                     </IonRow>
                                     <IonRow>
                                         <IonToggle checked={checked} onIonChange={this.toggleMessageType} />
                                     </IonRow>
                                 </IonCol>
                                 <IonCol>
                                     <IonButton onClick={() => this.createChatFeedItem(this.state.user.characterName, this.state.user.uid, this.state.user.photoURL, this.state.messageContent,checked)}>
                                         <IonIcon icon={sendOutline}/>
                                     </IonButton>
                                 </IonCol>
                             </IonButtons>

                         </IonToolbar>
                      </div>
                 </IonFooter>
       </>
  );
  }
}
export default authWrapper(withRouter(CastChat));

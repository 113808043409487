import React from 'react';
import {  IonContent} from '@ionic/react';

import { Challenge } from '../../models/Challenge';
import { firestore } from '../../firebaseConfig';

import './Challenges.css';
import authWrapper from '../../components/authWrapper';
import ChallengeListItem from "./ChallengeListItem";

interface JourneyListPageState {
  loading: boolean;
  journeyList: Challenge[];

    user: any;
}

class JourneyListPage extends React.Component<{}, JourneyListPageState>  {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      journeyList: [],
        // userId: props.user.uid,
        // heroName: props.user.characterName ? props.user.characterName : props.user.heroName,
        // heroId: props.user.heroId,
        // userPhotoURL: props.user.photoURL,
        user: props.user,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    firestore.collection('challenges').where("type", "==", "journey").orderBy('index','asc')
    .onSnapshot(snapshot => {
      let journeyList = [];

      snapshot.forEach(doc =>
        journeyList.push({ ...doc.data(), uid: doc.id }),
      );

      this.setState({
        journeyList,
        loading: false,
      });
    });
  }


  render() {

      const { journeyList, loading, user} = this.state;

      const hide  = true;


  return (

      <IonContent className="ion-padding-bottom" scrollX scrollY>

        {journeyList && journeyList.map((journey, idx) => {
            return (
                <ChallengeListItem key={idx} challenge={journey} index={idx} showButtons={true} user={user}/>
            );
          })}

      </IonContent>
  );
};
}
export default authWrapper(JourneyListPage);

import React from 'react';
import {  IonContent, IonPage, IonTitle, IonRow, IonCol, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonIcon, IonList, IonItem, IonAvatar, IonImg, IonText, IonBadge, IonFooter, IonRouterLink, IonCard, IonThumbnail, IonGrid, IonButton } from '@ionic/react';

import Moment from "react-moment";
import Header from './Header';
import Footer from './Footer';
import authWrapper from './authWrapper';
import { sendAssetsEmail } from '../util/sendEmail';
import { notification } from '../util/notification';


interface AssetsState {
    loading: boolean;
    user: any;
}
class Assets extends React.Component<{}, AssetsState> {

    [x: string]: any;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user:props.user,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

    }


    downloadAssets = (user) => {

        sendAssetsEmail(user);
        notification('Check Your Email For The Assets Link');
    }

    render() {
        const { user, loading } = this.state;

        return (

            <IonPage>
                <Header />
                <IonContent>

                    <IonCard>

                        <IonImg src="/assets/img/jfh_assets_banner_graphic.jpg"></IonImg>

                        <div>
                            <IonGrid>
                                <IonRow className="ion-float-right">
                                    <IonButton onClick={() => this.downloadAssets(user)}>Download</IonButton>
                                </IonRow>

                            </IonGrid>
                        </div>
                    </IonCard>


                </IonContent>

                <Footer/>
            </IonPage>

        );
    }
}
export default authWrapper(Assets);

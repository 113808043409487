//import * as firebase from 'firebase/firebase';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

import { toast } from './toast';
import {ProfileData} from "./models/ProfileData";
import { sendVerificationEmail } from './util/email';

const envConfig = {
    apiKey: "AIzaSyBwn4xtuJIqVPw70jtSpuaQ5wn_qMs6voY",
    authDomain: "myappjfh.firebaseapp.com",
    databaseURL: "https://myappjfh.firebaseio.com",
    projectId: "myappjfh",
    storageBucket: "myappjfh.appspot.com",
    messagingSenderId: "400209677747",
    appId: "1:400209677747:web:1a690154c385e3b4818fc6",
    measurementId: "G-RR0G1Z5ZDT"
  };

export const storageBucketAddress = "https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/";
const app = firebase.initializeApp(envConfig);

export const auth = app.auth();
export const firestore = app.firestore();
export const storage = app.storage();
export const storageRef = storage.ref();

export async function loginUser(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
    .then(result => {
        //console.log("login result uid:", result.user.uid)
        if (result != null) {
            //debugger;
            //find role
           //window.location.href='/premiumMissions';

           getUserDocument(result.user.uid).then(currentUser => {
                //console.log("current user data", currentUser.data());
                //let currentUserLogged = {...currentUser, uid: currentUser.uid}
               //console.log("current user logged", currentUser);
                if (currentUser['complete']) {
                    window.location.href='/welcomeWurld';
                }
                else {
                    window.location.href='/watch';
                }
                }
            );

        } else {
            toast('We are having trouble signing in this user. Please try again.');
        }
        return result
    }).catch(err => {
        console.log("login catch: ")
        console.dir(err)
        if (err.code === 'auth/user-not-found')
            toast("Email not registered yet. Please register first.");
        else if (err.code === 'auth/wrong-password')
            toast("Incorrect password. Please try again.");
        else if (err.code ===    'auth/network-request-failed')
            toast("Network error. Try checking your connection");
        else
            toast("Oops! Something went wrong.", err.code);
    })
}

export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    console.log("user, additionalData::", user, additionalData)

    const userRef = firestore.doc(`users/${user.uid}`);
    console.log("userRefa::", userRef)

    return userRef.get().then(shapshot=>{
        console.log("got snapshot:", shapshot)
        const { email, displayName, photoURL} = user;
        try {
            return userRef.set({
                displayName,
                email,
                photoURL,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    })
};


export const fetchUserDocumentData = async (user) => {
  if (!user) return;
  console.log("get user from db");
  let userRef = firestore.doc(`users/${user.uid}`);

   return userRef.get().then(snapshot => {
    if (snapshot.exists) {
      console.log("snapshot exists");
      return snapshot.data();
    }
   });
};


export function registerUser(profileData:ProfileData) {

    return auth.createUserWithEmailAndPassword(profileData.email,profileData.password)
        .then(newUser => {

            newUser.user.sendEmailVerification();
            console.log("new user user", newUser.user.email+profileData['characterName']);
            sendVerificationEmail(profileData['email'], profileData['characterName']);
            return generateUserDocument(newUser.user, profileData)
                   .then(user => user)
                   .catch(err => {throw(err)})
        }).catch(err => {
             console.log("there's been an error creating new user");
             console.log("error" + err);
             throw(err)
       });

    // let previousUser = auth.currentUser;
    // if (previousUser.isAnonymous) {
    //     console.log('Prev user is annon');
    //     return auth.createUserWithEmailAndPassword(profileData.email,profileData.password)
    //     .then(newUser => {
    //         return generateUserDocument(newUser.user, profileData)
    //                .then(user => user)
    //                .catch(err => {throw(err)})
    //     }).catch(err => {
    //          console.log("there's been an error creating new user");
    //          console.dir(err)
    //
    //          throw(err)
    //    });
    // } else {
    //     console.log("is not annon:", previousUser);
    //     toast("Still logged in as another user. Please log out first.")
    //     throw("NOT ANNON")
    // }
}



export function getCurrentUser() {
    return new Promise( (resolve, reject) => {
        try {
            firebase.auth().onAuthStateChanged(user => resolve(user))
        } catch(err) {
            reject(err)
        }
    })
}


export async function logoutUser(){
    firebase.auth().signOut().then(function() {
        toast('You have successfully signed out');
        window.location.href = "/login";
        toast('log out successfully')
      }).catch(function(error) {
        // An error happened.
        console.log('error logout', error);
      });

}

export const users = () => firestore.collection('users');


export async function existingHero(heroName:string) {
    console.log('hero name dup check', heroName);

    let heroNameCount =  await firestore.collection('users').where('heroName_nocase','==', heroName.toLowerCase()).get().then(function(querySnapshot) {
        console.log('heroNameCount',querySnapshot.size);
        return querySnapshot.size;
    });

    return heroNameCount;

}

export async function existingClient(clientName:string) {
    console.log('client name dup check', clientName);

    let clientNameCount =  await firestore.collection('users').where('clientName_nocase','==', clientName.toLowerCase()).get().then(function(querySnapshot) {
        console.log('client count',querySnapshot.size);
        return querySnapshot.size;
    });

    return clientNameCount;

}



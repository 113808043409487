import React, {useRef, useState} from 'react';
import { firestore, storageRef } from '../firebaseConfig';
//import { Player } from 'video-react';

interface VideoContainerProps {
    containerName: string;
    uid: string;
    locationAttribute: string;
    posterUrl: string;

}

const VideoList: React.FC<VideoContainerProps> = ({ uid, containerName, locationAttribute, posterUrl }) => {

const [contentUrl, setContentUrl] = useState('');
const [contentType, setContentType] = useState('');
const [posterContentUrl, setPosterContentUrl]=useState(posterUrl);
const videoRef = useRef(null);
    // console.log("in video list", locationAttribute);
    // console.log("container uid", uid);

    if (locationAttribute && locationAttribute!=''  && locationAttribute!==undefined) {
       //console.log("Location", locationAttribute);
       storageRef.child(locationAttribute).getDownloadURL().then(function (videoUrl) {
           let img = document.getElementById(uid);
           img.setAttribute("src", videoUrl);
           setContentUrl(videoUrl);
       }).catch(function (error) {
           // Handle any errors
       });
   }

    if(posterUrl && posterUrl!=='' && posterUrl!==undefined) {
        //console.log("poster", posterUrl);
        storageRef.child(posterUrl).getDownloadURL().then(function (contentUrl) {

            let img = document.getElementById(uid);
            img.setAttribute("poster", contentUrl);
            setPosterContentUrl(contentUrl);
        }).catch(function (error) {
            // Handle any errors
        });
    }
    else {
       //setPosterContentUrl(contentUrl+'#t=0.0.1');
    }
  return (

    <div className="video-wrapper">
        <video id={uid} controls poster={posterContentUrl} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>
           <source src={contentUrl} type={contentType} />
        </video>
    </div>
  );
};

export default VideoList;


import React, { constructor, Component } from 'react';
import { IonContent, IonPage, IonTitle, IonLabel, IonHeader, IonToolbar, IonItem, IonAvatar, IonList, IonImg, IonButtons, IonBackButton } from '@ionic/react';
import { firestore } from '../firebaseConfig';

class Contacts extends Component {


    state: any = {};
    props: any = {};

    contactList: any[];
    contacts: any[];
    
    async getContactList() {
        return firestore.collection('users').get();
      }

    getContact(contactId: string) {
      return firestore.collection('contacts').doc(contactId).get();
    }

    constructor(props: any) {
        super(props);
        console.log(this.getContactList());
      }
      
  render() {

  return (
    <IonPage>
           
    <IonHeader>
    <IonToolbar color="primary">
    <IonButtons slot="start">
      <IonBackButton color="light" defaultHref="wurld"></IonBackButton>
    </IonButtons>
        <IonTitle>
           Contacts
        </IonTitle>
    </IonToolbar>
    </IonHeader>

    <IonContent class="bg">
  
  <IonList lines="none" class="app-list">

  {this.contactList.map((contact, index) => {
            return (
                <IonItem key={index}>
                <IonAvatar slot="start">
                  <IonImg src="contact.photoURL"/>
                </IonAvatar>
                <IonLabel>
                  <h2>{ contact.username }</h2>
                  
                  <p item-end="true" className="text-ellipsis">{ contact.displayName }</p>
                </IonLabel>
              </IonItem>
            );
          })}
  </IonList>

</IonContent>
    </IonPage>
  );
};
}

export default Contacts;


import React, { useState, useRef } from 'react';
import {IonAvatar, IonIcon} from "@ionic/react";
import {playOutline} from "ionicons/icons";
import { storageRef } from '../../../firebaseConfig';


interface ProfileCallVideoProps {
    tab: string;
}
const ProfileCallVideo: React.FC<ProfileCallVideoProps> = (props) => {

    const [contentUrl, setContentUrl] = useState('');
    const [posterContentUrl, setPosterContentUrl] = useState('');
    const videoRef = useRef(null);
    let assetName="app/universe/CTA-ProfileVideos-UNIVERSE_TAB.mp4";
    let posterAssetName="app/universe/makeaprofilevideo_V2.jpg";

    storageRef.child(assetName).getDownloadURL().then(function(url) {
        setContentUrl(url);
        let img = document.getElementById('callVideo'+props.tab);
        img.setAttribute("src", url);
    }).catch(function(error) {
        // Handle any errors
    });

    if (posterAssetName!='') {
        storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
            setPosterContentUrl(posterUrl);
            let img = document.getElementById('callVideo');
            img.setAttribute("poster", posterUrl);
        }).catch(function(error) {
            // Handle any errors
        });
    }



    function onVideoClick(e) {
        let videoEl = document.getElementById('callVideo'+props.tab) as HTMLVideoElement;
        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    }
    return (

        <div className="video-wrapper">
            <div className="overlay"
                 onClick={onVideoClick}
            >
                <IonAvatar className="right playBtn">
                    <IonIcon className="highContrast" style={{padding: 2}} icon={playOutline}/>
                </IonAvatar>

            </div>
            <video id={'callVideo'+props.tab} poster={posterContentUrl} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>
                <source src={contentUrl} type="video/mp4" />
            </video>

        </div>
    );
};

export default ProfileCallVideo;



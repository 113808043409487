
import React, {useEffect, useRef, useState} from "react";
import './ExploreContainer.css';
import {
    IonImg,

    IonRow,
    IonText,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButton,
    IonModal,
    IonIcon,
    IonButtons,
    IonFooter,
    IonCard,
    IonGrid,
    IonCol,
    IonLabel
} from "@ionic/react";
import {ContentItem} from "../models/ContentItem";

import {firestore, storageRef} from "../firebaseConfig";

import {useHistory} from "react-router-dom";
import CTAVideoListItem from "./CTAVideoListItem";
import {close} from "ionicons/icons";



interface CTADeliveryProps {
   ctaModule: ContentItem;
   user: any;
   openModalWindow: boolean;
}


const CTADeliveryModule: React.FC<CTADeliveryProps> = (props) => {

   const [ctaContent, setCtaContent] = useState<any>();

    const history = useHistory();

    useEffect( () => {
        let isSubscribed = true;

        console.log("object cta type", props.ctaModule);

        let query;
        if (props.ctaModule.contentType === 'mission') {
             query = firestore.doc(`missions/${props.ctaModule.objectRefId}`)
        }
        else {
            query = firestore.doc(`challenges/${props.ctaModule.objectRefId}`)
        }

        query.get().then((doc)=> {
            let documentRef = {id: doc.id, ...doc.data()};
            console.log("documentRef:", documentRef);
            setCtaContent(documentRef);
        });

        return () => {isSubscribed = false}
    }, [props.ctaModule.uid]);

    function join(deliveryModule, user) {
        //dismiss modal
        let modalEl = document.getElementById(deliveryModule.uid+'modal') as HTMLIonModalElement;
        if (modalEl && modalEl!==undefined) {
            modalEl.dismiss().then(()=> console.log('modal dismissed'));
        }

        if (deliveryModule.contentType === 'mission') {
            history.push({
                pathname: '/missionDeliveryChecklist',
                state: {missionId:deliveryModule.objectRefId, index:deliveryModule.objectRefIdx, missionTitle:deliveryModule.title, heroId: user.heroId, heroName: user.heroName, userId: user.uid, userPhotoURL: user.photoURL, fromPage: 'wurld'}
            });
        }
        else {
            history.push({
                pathname: '/challengeListItemDetail',
                state: {challengeId:deliveryModule.objectRefId, challengeTitle:deliveryModule.title, challengeType: deliveryModule.contentType, heroId: user.heroId, heroName: user.heroName, userId: user.uid, userPhotoURL: user.photoURL, fromPage: 'wurld'},
            });
        }
    }

    function closeModal(id) {
        let modalContainer = document.getElementById(id+'modal');
        modalContainer.setAttribute("isOpen", 'false');
    }

    return (
        <IonModal id={props.ctaModule.uid+'modal'} isOpen={props.openModalWindow} cssClass='cta-modal' backdropDismiss={false} swipeToClose={true}>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonRow className="ion-justify-content-center">
                        {props.ctaModule.contentType === 'mission' &&
                        <IonText className="ion-text-center"> JOIN {props.ctaModule.contentType.toUpperCase()} </IonText>
                        }
                        {props.ctaModule.contentType !== 'mission' &&
                        <IonText className="ion-text-center"> JOIN {props.ctaModule.contentType.toUpperCase()} CHALLENGE</IonText>
                        }
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonText className="ion-text-center"> {props.ctaModule.title} </IonText>
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-text-center">

                <IonCard className={props.ctaModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'}>
                    {props.ctaModule.contentType === 'mission' &&
                    <CTAVideoListItem contentItem={ctaContent} tab={'join'+props.ctaModule.contentType} enableTogglePrivate={props.ctaModule.completedByUserId===props.user.uid}/>
                    }
                    {props.ctaModule.contentType !== 'mission' &&
                    <CTAVideoListItem contentItem={ctaContent} tab={'join'+props.ctaModule.contentType} enableTogglePrivate={props.ctaModule.completedByUserId===props.user.uid}/>}
                </IonCard>

            </IonContent>
            <IonFooter>
                <IonRow className="ion-justify-content-center">
                    {props.ctaModule.contentType === 'mission' &&
                    <IonButton onClick={() => join(props.ctaModule, props.user)} shape="round" fill="solid" style={{ '--background': '#66cc33', '--color':'#ffffff'}} className="btn-accept-mission bolder">
                        <IonText> Accept </IonText>
                    </IonButton>
                    }
                    {props.ctaModule.contentType !== 'mission' &&
                    <IonButton shape="round" fill="solid" size="large" style={{'--background':'yellow', '--color':'#005494'}} className="bolder" onClick={() => join(props.ctaModule, props.user)} >
                        <IonText> Accept </IonText>
                    </IonButton>}
                    <IonButton style={{'float':'right'}} onClick={() => closeModal(props.ctaModule.uid)}>
                        <IonIcon icon={close}/>
                    </IonButton>
                </IonRow>

            </IonFooter>
        </IonModal>

    );
};

export default CTADeliveryModule;


import React from 'react';
import { IonContent, IonPage, IonRow, IonCol,  IonButton, IonFooter, IonText, IonToolbar, IonImg} from '@ionic/react';

import { toast } from '../toast';



const slideOpts =  {
  initialSlide: 0,
  speed: 2000,
  slideShadows: true,
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  a11y: {
    prevSlideMessage: 'Previous slide',
    nextSlideMessage: 'Next slide',
  },
  preloadImages: true,
 };


function handleTouch() {
  toast("touch");
  //this.slider.slideTo(2, 500);
}

const Home: React.FC = () => {
  
   
  return (
    <IonPage className="app-page">
      <IonContent className="app home ion-padding ion-justify-content-center">
          <IonRow style={{display: 'fixed', flex: 1, flexDirection: 'row', alignContent: 'center', overflowX:'hidden'}}>
             <IonCol>
                 <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FTest_App_1200x720.png?alt=media&token=af184a5b-3521-4bf8-a2e7-c7ca2cb67d3c"}/>
             </IonCol>

          </IonRow> 

      </IonContent>
        <IonFooter>
            <IonToolbar className="app" style={{'--border-width':'0 0 0 0'}}>
            <IonRow className="ion-justify-content-center">

                    <IonButton href="https://us02web.zoom.us/meeting/register/tZ0kdOGhpz4vGtUO4ttOt6hSy5zyk_tlaj_t
                    "> <IonText style={{fontSize:'12px'}}>Register for the upcoming JFH APP session <span className="bolder italic">here</span> </IonText></IonButton>

            </IonRow>
            </IonToolbar>
        </IonFooter>
    </IonPage>
  );
};


export default Home;
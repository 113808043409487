import React, {useEffect, useRef, useState} from 'react';

import {Device} from "@capacitor/core";
import {playVideoFromRef} from "../../util/videoUtils";

import {getPictureAddress} from "../../util/avatarUtils";


interface VideoContentPlayerProps {
    item: any;
    tab: string;
    index: number;
}

const VideoContentPlayer: React.FC<VideoContentPlayerProps> = (props) => {


    let videoContentModuleRef = useRef(null);
    let videoContentCoverRef = useRef(null);

    const [platformInfoName, setPlatformInfoName] = useState('web');

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);

            setPlatformInfoName(info.operatingSystem);
        } );

    }, [props.item]);


    function playVideoContent() {
        let videoContentEl  = videoContentModuleRef.current;

        playVideoFromRef(videoContentEl, platformInfoName);

    }

    return (
        <div className="video-wrapper">
            <div className="overlay"
                 onClick={playVideoContent}
            >
            </div>

            <img src={getPictureAddress(props.item?.posterUrl, props.item?.uid,props.item?.uid+props.tab)} ref={videoContentCoverRef} />
            <video id={props.item.uid+props.tab+"videoContent"}
                   poster={getPictureAddress(props.item?.posterUrl, props.item?.uid,props.item?.uid+props.tab)}
                   ref={videoContentModuleRef} style={{'display':'none'}} controlsList="nodownload" preload="metadata"
                   onEnded={e=> {videoContentModuleRef.current.load(); }}
                   onPause={e=> {videoContentModuleRef.current.load(); videoContentModuleRef.current.controls=false; }}>
                <source src={getPictureAddress(props.item.contentVideo, props.item.uid,props.item.uid+props.tab)} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoContentPlayer;


import React, {useEffect, useState, useRef} from 'react';
import { storageRef } from '../../firebaseConfig';
import {IonAvatar, IonIcon, IonRow} from "@ionic/react";
import {pauseOutline, playOutline, stop} from "ionicons/icons";
import {Device} from "@capacitor/core";
import {onVideoClick, playVideoRef, toggleFullscreen} from "../../util/videoUtils";
import {toast} from "../../toast";
import {getPictureAddress} from "../../util/avatarUtils";


interface ChatDeliveryModuleVideoProps {
    item: any;
    tab: string;
    index: string;
}

const ChatDeliveryModuleVideo: React.FC<ChatDeliveryModuleVideoProps> = (props) => {


    const [videoContentUrl, setVideoContentUrl] = useState(props.item.contentVideo);


    const [platformInfoName, setPlatformInfoName] = useState('web');
    const chatVideoRef = useRef(null);

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            // if (info.platform === 'web' || info.platform ==='android') {
            //
            // }
            setPlatformInfoName(info.operatingSystem);
        } );
        // if(props.item.posterUrl && props.item.posterUrl!=='' && props.item.posterUrl!==undefined) {
        //     storageRef.child(props.item.posterUrl).getDownloadURL().then(function (thumbnailContent) {
        //         //console.log("htumbanil"+thumbnailContent);
        //         let img = document.getElementById(props.index+props.tab);
        //         //contentUrl = contentUrl.replace("%2F","/");
        //         img.setAttribute("poster", thumbnailContent);
        //     }).catch(function (error) {
        //         toast("error"+error);
        //     });
        // }
        //
        // if (props.item.contentVideo!=='' && props.item.contentVideo!== undefined) {
        //     //console.log("content video", item.contentUrl.toString());
        //     let pictureContentRef = storageRef.child(props.item.contentVideo);
        //     //console.log("content ref", pictureContentRef);
        //     pictureContentRef.getDownloadURL().then(function(contentDownloadUrl) {
        //
        //
        //         let imgC = document.getElementById(props.index+props.tab);
        //
        //         imgC.setAttribute("src", contentDownloadUrl);
        //         setVideoContentUrl(contentDownloadUrl);
        //
        //     }).catch(function(error) {
        //
        //         switch (error.code) {
        //             case 'storage/object-not-found':
        //                 // File doesn't exist
        //                 break;
        //
        //             case 'storage/unauthorized':
        //                 // User doesn't have permission to access the object
        //                 break;
        //
        //             case 'storage/canceled':
        //                 // User canceled the upload
        //                 break;
        //
        //
        //             case 'storage/unknown':
        //                 // Unknown error occurred, inspect the server response
        //                 break;
        //         }
        //     });
        // }
    }, [props.item]);

    return (
        <div className="video-wrapper">
            <div className="overlay"
                 onClick={(e) => playVideoRef(e, platformInfoName, props, chatVideoRef)}
            >

                <IonAvatar className="right videoPlayBtn" id={'play'+props.index+props.tab}>
                    <IonIcon className="highContrast" style={{padding: 2}} icon={playOutline}/>
                </IonAvatar>

                <IonAvatar id={'stop'+props.index+props.tab} style={{display: 'none'}} className="right videoPlayBtn">
                    <IonIcon className="highContrast" style={{padding: 2}} icon={pauseOutline} />

                </IonAvatar>

            </div>


            <video ref={chatVideoRef}
                   poster={getPictureAddress(props.item?.posterUrl, props.item?.uid,props.item?.uid+props.tab)}
                   onEnded={e=> chatVideoRef.current.load()}
                   onPause={e=> {chatVideoRef.current.load(); chatVideoRef.current.controls=false; }}
                   controlsList="nodownload">
                <source src={getPictureAddress(props.item?.contentVideo ? props.item?.contentVideo: props.item?.contentUrl, props.item?.uid,props.item?.uid+props.tab)} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/*<video id={props.index+props.tab} */}
            {/*       src={videoContentUrl} */}
            {/*       poster={props.item.posterUrl} */}
            {/*       controlsList="nodownload" */}
            {/*       ref={videoRef} */}
            {/*       onEnded={e=> videoRef.current.load()}>*/}
            {/*    <source src={videoContentUrl} type='video/mp4'  />*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}


        </div>
    );
};

export default ChatDeliveryModuleVideo;


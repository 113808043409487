import React, {useState} from 'react';
import {
    IonPage,
    IonFabButton,
    IonCol, IonRow, IonLabel, IonToggle, IonContent, IonButton,  IonSpinner, IonText
} from '@ionic/react';
import {useHistory, withRouter} from 'react-router-dom';

import { storageRef, firestore } from '../../firebaseConfig';
import Footer from '../../components/Footer';
import Header from "../../components/Header";
import {toast} from "../../toast";
import firebase from 'firebase';
import {extractFramesFromVideo} from "../../util/videoUtils";
import { sendNewMissionNotification } from '../../util/email';
import {uploadInProgressNotification} from "../../util/notification";


interface UGChallengeDeliveryReviewProps{
    location: any;
}
const UGChallengeDeliveryReview: React.FC<UGChallengeDeliveryReviewProps> = (props) => {

    const [publicContent, setPublicContent] = useState(true);
    const [ugPosterFileContent, setUgPosterFileContent] = useState('');
    const [showInProgress, setShowInProgress] = useState(false);

    const history = useHistory();

    //console.log("review location and props!", location, props)
    if (!props.location.state)
        return null
    //console.log("props", props.location);

    const {ugDeliveryFile, ugDeliveryFileType, ugDeliveryFileURL,title,description, createdByName, uid, type} = props.location.state as any; // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674
    // const {user:{displayName, uid}} = props as any;


    const onChooseImage = (e: any) => {

        let ugPosterFile = e.target.files[0];
        let createPosterFileUrl = URL.createObjectURL(ugPosterFile);
        let ugPosterFileExtension = ugPosterFile.type.split("/").pop();
        console.log('posterFile', ugPosterFile);
        let ugPosterFileName = title.replace(/ /g, "_") + "_"+createdByName.replace(/ /g, "_") + +Date.now()+"."+ugPosterFileExtension;
        console.log("se p", createPosterFileUrl);
        let videoProfileEl = document.getElementById('createVideoPlayer') as HTMLVideoElement;
        console.log("se p", videoProfileEl);
        // videoProfileEl.addAtt('poster', createPosterFileUrl);

        videoProfileEl.poster = createPosterFileUrl;
        let ugThumbnailRef = storageRef.child('content/challenges/thumbnails/'+ugPosterFileName);
        ugThumbnailRef.put(ugPosterFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail');
        }).catch((err)=>toast("error uploading thumbnail"+err));
        setUgPosterFileContent(ugThumbnailRef.fullPath);
        // if(createPosterFileUrl.startsWith('blob:')) {
        //     URL.revokeObjectURL(createPosterFileUrl);
        // }
    }

    async function clickChooseImage(e) {
        document.getElementById('uploadImageBtn').click();
    }

    function createChallenge(publicContent,title, description, createdByName, uid, ugPosterFileContent, type) {
        //console.log("save info to db", uid + "-"+ displayName+ "contnet tye"+ file.type);
        let ugTitle = title;
        let ugFileExtension = ugDeliveryFileType.split("/").pop();

        let videoName = ugTitle.replace(/ /g, "_")+Date.now()+ "."+ugFileExtension;
        //console.log("Public content", publicContent);
        let createBtnEl = document.getElementById('createUgBtn');
        createBtnEl.setAttribute('disabled', 'true');

        uploadInProgressNotification().then(() => console.log("notification in progess"));
        if (ugPosterFileContent === '') {
            let thumbnailRef = extractFramesFromVideo('createVideoPlayer',ugDeliveryFileURL, 1, 'challenge', ugTitle, createdByName);
            ugPosterFileContent = thumbnailRef;
            // console.log("thumbnail", thumbnailRef);
        }
        let ugVideoRef = storageRef.child('challenges/content/'+videoName);
        let ugData = {
            ctaVideoUrl: ugVideoRef.fullPath,
            contentUrl: ugVideoRef.fullPath,
            createdByName: createdByName,
            title: ugTitle,
            description: description,
            createdById: uid,
            contentType: ugDeliveryFile.type,
            publicContent: publicContent,
            posterUrl: ugPosterFileContent,
            type: type,
            ugc: true,
            index: Date.now(),
            timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date())

        };
        // let progressBarEl = document.getElementById('progressBarCDR');
        // progressBarEl.style.display='block';
        // progressBarEl.setAttribute('value', '0.5');
        // progressBarEl.setAttribute('buffer', '0.75');

        ugVideoRef.put(ugDeliveryFile).then(function() {
            console.log('Uploaded mission video file!');
            createChallengeInDB(ugData);
        });
    }

    //create mission

    function createChallengeInDB(ugData) {
        //  console.log('public content', missionData['publicContent']);
        let redirectTo='/profile';
        if (ugData['type'] === 'training') {
            redirectTo = '/trainingChallenges';
        }
        else if (ugData['type'] === 'development') {
            redirectTo = '/developmentChallenges';
        }
        const ugRef = firestore.collection('challenges').add(ugData).then(ref => {
            //sendNewChallengeNotification();
            window.location.replace(redirectTo);
            toast('Your Challenge was posted.');
            console.log('Added mission  with ID: ', ref.id);
        });
    }

    return (
        <IonPage>
            <Header/>

            <IonContent>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: 20, overflowY: 'auto'}}>

                    <div style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center', margin: 5}}>
                        <IonText style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center'}}> Review Your Mission </IonText>
                    </div>

                    <video style={{maxWidth: '100%'}} id="createVideoPlayer" controls src={ugDeliveryFileURL} autoPlay={true} muted>
                        <source src={ugDeliveryFileURL} type={ugDeliveryFile?.type}/>
                    </video>

                    <img id="posterGenerated" alt={'thumbnail'} style={{'display':'none'}}/>
                    <IonRow style={{paddingLeft: 10, paddingTop: 10, paddingBotton: 10}}>
                        <span style={{color: '#005494', fontWeight: 'bold', margin: 5}}>{createdByName}</span>
                        <span style={{margin: 5}}>{title}</span>
                    </IonRow>


                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                        <div style={{color: '#005494', fontWeight: 'bold'}}>
                            {/*<UploadImage/>*/}
                            <IonRow>
                                <IonCol>
                                    <IonButton id="uploadVideoImgBtn"
                                               className="editProfileButton"
                                               onClick={(e) => {
                                                   clickChooseImage(e)
                                               }}>
                                        <IonLabel className="editProfileButtonText"
                                        >
                                            Choose Cover Image </IonLabel>
                                        <input type="file" accept="image/*" className="uploadVideoBtn"
                                               id="uploadImageBtn"
                                               onChange={onChooseImage}
                                        />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </div>
                    <div style={{width: '80%'}}>
                        <IonCol>
                            <IonRow>
                                <IonLabel style={{'fontSize':'8px', 'marginLeft':'82%'}}>{publicContent ? `Public`: 'Private'}</IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonToggle style={{'marginLeft':'80%'}} checked={publicContent} onIonChange={e => setPublicContent(e.detail.checked)} />
                            </IonRow>
                        </IonCol>
                    </div>
                    {showInProgress ?
                        <IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '10px', marginBottom: '20px'}}>
                            <IonSpinner/>
                        </IonFabButton>
                        :  <IonFabButton id='createUgBtn' style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '10px', marginBottom: '20px'}} onClick={() => createChallenge(publicContent,title, description, createdByName, uid, ugPosterFileContent,type)}>
                            COMPLETE
                        </IonFabButton>
                    }


                    {/*<IonProgressBar id="progressBarCDR" type="determinate" className="progressBar" style={{'display':'none'}}></IonProgressBar>*/}

                </div>
            </IonContent>
            <Footer />
        </IonPage>
    )
}
export default withRouter(UGChallengeDeliveryReview);

import React from 'react';
import {
    IonContent,
    IonSpinner, IonItem,  IonList, IonGrid
} from '@ionic/react';


import { firestore} from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import {Message} from "../../models/Message";

import MessageItem from "./MessageItem";

interface ReceivedFeedState {
    loading: boolean,
    receivedList: Message[],
    user: any;
}

class ReceivedFeed extends React.Component<{}, ReceivedFeedState>  {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            receivedList: [],
            user: props.user,
        };
    }

    componentDidMount() {
        this.setState({ loading: true});


        firestore.collection('directMessage').where("receiver", "==", this.state.user.heroName).orderBy("sendTime", "desc")
            .onSnapshot(snapshot => {
                let receivedList = [];

                snapshot.forEach(doc =>
                    receivedList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    receivedList,
                    loading: false,
                });
            });
    }


    render() {
        const { receivedList, loading, user } = this.state;

        if (loading)
            return <IonSpinner/>
        else
            return (

                <IonContent className="ion-padding-bottom" scrollX>
                    <IonList>
                        {receivedList && receivedList.map((receivedMessage, idxr) => {
                            return (
                                <IonItem key={idxr} lines="none">
                                    <IonGrid>
                                        <MessageItem message={receivedMessage} user={user}/>
                                    </IonGrid>
                                </IonItem>
                            );
                        })}
                    </IonList>
                </IonContent>
            )

    }
}
export default authWrapper(ReceivedFeed);

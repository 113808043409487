import React, {useState,  useEffect} from 'react';
import {
    IonCol,
    IonRow,
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonText,
    IonButton,
    IonRouterLink,
    IonModal,
    IonGrid,
    IonImg,

    IonCard,
    IonHeader,
    IonFooter,
    IonIcon
} from '@ionic/react';
import Header from '../../components/Header';
import './Profile.css';
import Footer from '../../components/Footer';

import {  firestore} from '../../firebaseConfig';

import ProfileVideo from '../../components/ProfileVideo';

import authWrapper from '../../components/authWrapper'
import VideoListItem from '../../components/VideoListItem';
import DeliveryModuleScreen from '../../components/DeliveryModuleScreen';
import {selectSection, selectSegment} from "./profileSegment";
import DeliveryModuleBtnContainer from "../Wurld/DeliveryModuleBtnContainer";
import MissionDeliveryComponent from "../../components/MissionDeliveryComponent";
import { sendJoinTestTeamEmail } from '../../util/sendEmail';
import { getPictureAddress } from '../../util/avatarUtils';
import {Device} from "@capacitor/core";
import {useHistory} from "react-router";

interface UserProfile {
    missions: [],
    challenges: [],
    allies: [],
    rivals: [],
    user: any,
}



async function getRivalsArray(userId) {
    let rivalsOut = firestore.collection('relationshipRequests').where('senderId', '==', userId).get();
    let rivalsIn = firestore.collection('relationshipRequests').where('rivalId', '==', userId).get();

    const [rivalsOutSnapshot, rivalsInSnapshot] = await Promise.all([
        rivalsOut,
        rivalsIn
    ]);


    const rivalsOutArray = rivalsOutSnapshot.docs;
    const rivalsInArray = rivalsInSnapshot.docs;

    const rivalsArray = rivalsOutArray.concat(rivalsInArray);

    return rivalsArray;
}

const Profile: React.FC<UserProfile> = (props) => {

    const [showModal, setShowModal] = useState(props.user.profileVideo === '');

    const [missions, setMissions] = useState([]);
    const [missionsCreated, setMissionsCreated] = useState([]);
    const [challenges, setChallenges] = useState([]);

    const [showJoin, setShowJoin] = useState(false);

    const [rivalPendingCount, setRivalPendingCount] = useState<number>();
    const [rivalsApprovedCount, setRivalsApprovedCount] = useState<number>();
    const [alliesCount, setAlliesCount] =  useState<number>();

    const [platformInfoName, setPlatformInfoName] = useState('web');

    const history = useHistory();

    const joinTestTeam = () => {
        console.log("send email ");
        sendJoinTestTeamEmail(props.user);
        setShowJoin(false);
    }

    const goHire = () => {
        window.location.href="/hire";
        // window.location.href="/premiumMissions";
    }

    const goChallenges = () => {
        window.location.href="/challenges";
        // window.location.href="/premiumMissions";
    }

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            // if (info.platform === 'web' || info.platform ==='android') {
            //
            // }
            setPlatformInfoName(info.operatingSystem);
        } );


        getRivalsArray(props.user.uid).then(snapshotRivals => {
            let pendingNo = 0;
            let approvedNo = 0;
            let alliesNo = 0;

            snapshotRivals.forEach(snapshotRival => {
                // if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {
                //rivalsList.push({...snapshotRival.data(), uid: snapshotRival.id})
                if (snapshotRival.data()['status']==='pending' && snapshotRival.data()['relationshipType']==='rival') {
                    pendingNo = pendingNo+1;
                }
                else if (snapshotRival.data()['status']==='approved'  && snapshotRival.data()['relationshipType']==='rival') {
                    approvedNo = approvedNo+1;
                }
                else if (snapshotRival.data()['status']==='approved'  && snapshotRival.data()['relationshipType']==='ally') {
                    alliesNo = alliesNo+1;
                }

            });
            setRivalPendingCount(pendingNo);
            setRivalsApprovedCount(approvedNo);
            setAlliesCount(alliesNo);
        });

      //console.log("userId", props.user.uid);
      const userContentListRef = firestore.collection('content').where("completedByUserId", "==", props.user.uid).orderBy('timeCreated', 'desc');

      userContentListRef.onSnapshot(snapshot => {
         let userContentList = [];

          snapshot.forEach(doc =>
            userContentList.push({ ...doc.data(), uid: doc.id }),
          );

          let challengeList = userContentList.filter(item => item.contentType !== 'mission');
          let missionList = userContentList.filter(item => item.contentType === 'mission');

          setMissions(missionList);
          setChallenges(challengeList);
        });

      console.log("user ", props.user.uid);
      const missionsCreatedRef = firestore.collection('missions').where("submittedBy", "==", props.user.uid).orderBy('timeCreated', 'desc');
        missionsCreatedRef.onSnapshot(snapshot => {
            let missionsCreatedList = [];

            snapshot.forEach(doc =>
                missionsCreatedList.push({ ...doc.data(), uid: doc.id }),
            );

            setMissionsCreated(missionsCreatedList);
        });


   }, [props.user.heroId]);

    function changeSegmentContent(selectedSegment) {
       selectSegment(selectedSegment);
    }

    function changeSection(selectedSection) {
        selectSection(selectedSection);
    }

    function goInCharacterTab() {
      window.location.href='/account';
    }

    const hide=true;

   //console.log("props!!!", props.user);

    if (props.user === null)
      return

       else {
    return (

        <IonPage>
            <Header />
            <IonContent scrollX>

                <IonModal isOpen={showModal} cssClass='profile-custom-class'>
                    <IonHeader> <IonRow className="ion-justify-content-center" style={{padding: 10}}>
                        <IonText className="appTextMain"> Your Profile </IonText>
                    </IonRow></IonHeader>
                  <IonContent className="profile-custom-class"
                              scrollEvents={true}
                              onIonScrollStart={() => {}}
                              onIonScroll={() => {}}
                              onIonScrollEnd={() => {}} scrollX scrollY>

                    <IonGrid>
                      <IonRow className="ion-justify-content-center">
                          {props.user.role==='hero' &&
                        <IonText style={{'--color':'#ffffff', 'color':
                          '#ffffff'}}>
                            Add a Hero Profile Video and show the wUrld who you are.
                        </IonText>
                          }
                          {props.user.role==='client' &&
                          <IonText style={{'--color':'#ffffff', 'color':
                                  '#ffffff'}}>
                              Add a Client Profile Video and show the wUrld who you are.
                          </IonText>}
                       </IonRow>
                        <IonRow className="ion-justify-content-center"><IonCol className="editProfileButton"> <IonRouterLink href="/pages/Account" className="editProfileButtonText" color="primary"> UPDATE PROFILE </IonRouterLink></IonCol> </IonRow>
                        <IonRow className="ion-justify-content-end">
                      <IonButton onClick={ () => setShowModal(false)} style={{'--background':'none'}}>Skip for Now</IonButton>
                    </IonRow></IonGrid>

                  </IonContent>

                </IonModal>

                <IonModal isOpen={showJoin}
                          cssClass='join-custom-class'
                          onDidDismiss={() => setShowJoin(false)}>
                    <IonHeader>
                        <IonRow style={{padding: 10}}>
                            <IonText className="appTextMain center">
                                {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                            </IonText>
                        </IonRow>
                    </IonHeader>
                    <IonContent className='join-custom-class'
                                scrollEvents={true}
                                onIonScrollStart={() => {
                                }}
                                onIonScroll={() => {
                                }}
                                onIonScrollEnd={() => {
                                }} scrollX scrollY>

                        {props.user.testTeamMember
                            ?<><IonRow><IonCol></IonCol></IonRow>
                                <IonRow><IonCol></IonCol></IonRow>
                                <IonRow>
                                    <IonText className="appTextMain center">Check your email for the invite! </IonText>
                                </IonRow>
                                <IonRow><IonCol></IonCol></IonRow>
                            </>
                            :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                    </IonContent>
                    <IonFooter>
                        <IonRow className="ion-justify-content-center">

                            {props.user.testTeamMember === undefined  &&
                            <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                                <IonLabel className="italic bolder">
                                    Join
                                </IonLabel>
                            </IonButton>}
                        </IonRow>
                    </IonFooter>
                </IonModal>
                <IonRow>
                    <IonCol>
                        {props.user.photoURL === '' &&
                         
                              <IonImg src={"https://www.gravatar.com/avatar?d=mm&s=140"}/>

                        }

                        {
                            props.user.photoURL !== '' && props.user.profileVideo === '' &&
                            <IonImg id={'profile'+props.user.uid} src={props.user.photoURL ? getPictureAddress(props.user.photoURL,props.user.uid, 'profile') : "https://www.gravatar.com/avatar?d=mm"}
                                    />
                        }
                        {/*<IonImg id={'avatar'+props.user.uid} src={props.user.photoURL ? getPictureAddress(props.user.photoURL,props.user.uid, 'avatar') : "https://www.gravatar.com/avatar?d=mm"}/>*/}
                        {/*<UserAvatar userPhotoURL={props.user.photoURL} userId={props.user.uid} userHeroName={props.user.clientName} userRole={props.user.role} showMoreInfo={false}/>*/}
                        {props.user.profileVideo !== '' && <ProfileVideo videoPath={props.user.profileVideo} userId={props.user.uid} photoUrl={props.user.photoURL} role={props.user.role !== 'undefined' ? props.user.role: ''} tab={'profile'} index={props.user.role === 'hero' ? props.user.heroNumber : (props.user.clientNumber ? props.user.clientNumber: props.user.heroNumber) } />}
                    </IonCol>
                    {props.user.role === 'hero' &&<div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                      <IonRow ><IonText className="contentTextTitle" color="primary" >NAME: </IonText> <IonText className="contentText" color="primary"> {props.user.heroName}</IonText></IonRow>
                      <IonRow><IonCol></IonCol><IonCol></IonCol> </IonRow>
                      <IonRow><IonText className="contentTextTitle" color="primary"> HERO#: </IonText> <IonText className="contentText" color="primary"> {props.user.heroNumber}</IonText></IonRow>
                      <IonRow><IonCol></IonCol> </IonRow>
                      <IonRow> <IonText className="contentTextTitle" color="primary">TEAM: </IonText> <IonText className="contentText" color="primary"> {props.user.teamName}</IonText></IonRow>
                      <IonRow><IonCol className="editProfileButton"> <IonRouterLink href="/pages/Account" className="editProfileButtonText" color="primary"> EDIT PROFILE </IonRouterLink></IonCol> </IonRow>
                    </div> }
                    {props.user.role === 'villain' && <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <IonRow ><IonText className="contentTextTitle" color="primary" >NAME: </IonText> <IonText className="contentText" color="primary"> {props.user.heroName}</IonText></IonRow>
                        <IonRow><IonCol></IonCol><IonCol></IonCol> </IonRow>
                        <IonRow><IonText className="contentTextTitle" color="primary"> HERO#: </IonText> <IonText className="contentText" color="primary"> {props.user.heroNumber}</IonText></IonRow>
                        <IonRow><IonCol></IonCol> </IonRow>
                        <IonRow> <IonText className="contentTextTitle" color="primary">TEAM: </IonText> <IonText className="contentText" color="primary"> {props.user.teamName}</IonText></IonRow>
                        <IonRow><IonCol className="editProfileButton"> <IonRouterLink href="/pages/Account" className="editProfileButtonText" color="primary"> EDIT PROFILE </IonRouterLink></IonCol> </IonRow>
                    </div> }
                    {props.user.role === 'client' && <IonCol>
                      <IonRow ><IonText className="contentTextTitle" color="primary" >Name: </IonText> <IonText className="contentText" color="primary"> {props.user.clientName}</IonText></IonRow>
                      <IonRow><IonCol></IonCol><IonCol></IonCol> </IonRow>
                      <IonRow><IonText className="contentTextTitle" color="primary"> Client #: </IonText> <IonText className="contentText" color="primary"> {props.user.clientNumber}</IonText></IonRow>
                      <IonRow><IonCol></IonCol> </IonRow>
                      <IonRow> <IonText className="contentTextTitle" color="primary">Missions: </IonText> <IonText className="contentText" color="primary"> {missionsCreated.length}</IonText></IonRow>
                      <IonRow><IonCol className="editProfileButton"> <IonRouterLink href="/pages/Account" className="editProfileButtonText" color="primary"> EDIT PROFILE </IonRouterLink></IonCol> </IonRow>
                    </IonCol> }
                </IonRow>
                {props.user.role === 'hero' && <IonRow className="ion-justify-content-center" style={{flexWrap: 'nowrap'}}>
                    {/*<IonButton className="alliesBtn" onClick={() => setShowJoin(true)}> {props.user.allies? props.user.allies.length : 0} Allies  </IonButton>*/}
                  <IonButton className="alliesBtn" href={`/allies/${props.user.uid}`}> {alliesCount} Allies  </IonButton>
                    <div style={{
                        marginLeft: -17,
                        marginTop: 4,
                        marginRight: 4,
                        width: 25, height: 42,
                        transform: "skew(20deg)",
                        backgroundColor: 'white',
                        borderWidth: 2, borderStyle: 'solid', borderColor: '#005494',
                        boxShadow: "0px 4px 4px -4px dimgray",
                    }}/>
                    <div style={{
                        marginRight: -17,
                        marginTop: 4,
                        width: 25, height: 42,
                        transform: "skew(20deg)",
                        backgroundColor: '#005494',
                        boxShadow: "-4px 0px 4px -4px dimgray",

                    }}/>
                    {/*<IonButton className="rivalsBtn appBtn" onClick={()=> setShowJoin(true)}> {props.user.rivals? props.user.rivals.length: 0} Rivals  </IonButton>*/}

                    <IonButton className="rivalsBtn appBtn" href={`/rivals/${props.user.uid}`}>
                        {rivalPendingCount > 0
                            ?  <IonLabel> {rivalsApprovedCount} Rivals <sup>{rivalPendingCount}</sup></IonLabel>
                            :  (rivalsApprovedCount) + ' Rivals'
                        }
                    </IonButton>
                </IonRow>}
                {props.user.role === 'hero' &&
                    <IonRow style={{marginTop: 15, marginBottom: 10, marginLeft: 2}}>
                      <IonText className="contentTextTitle" color="primary"> ONE-LINER: </IonText>
                      <IonText className="contentText" color="primary" style={{marginLeft: 2}}> {props.user.oneLiner} </IonText>
                    </IonRow>
                }
                {props.user.role === 'villain' &&
                <IonRow style={{marginTop: 15, marginBottom: 10, marginLeft: 2}}>
                    <IonText className="contentTextTitle" color="primary"> ONE-LINER: </IonText>
                    <IonText className="contentText" color="primary" style={{marginLeft: 2}}> {props.user.oneLiner} </IonText>
                </IonRow>
                }

              {props.user.role === 'client' &&
                <IonRow style={{marginTop: 15, marginBottom: 10, marginLeft: 2}}>
                  <IonText className="contentTextTitle" color="primary"> ABOUT: </IonText>
                  <IonText className="contentText"> {props.user.about} </IonText>
                </IonRow>
                }
                {props.user.role && <IonSegment value="userMissions" scrollable onIonChange={(e) => changeSegmentContent(e.detail.value as any)}>
                  <IonSegmentButton value="userMissions">
                    <IonLabel className="labelText">Missions</IonLabel>
                  </IonSegmentButton>

                    {props.user.role === "hero" && <IonSegmentButton value="userChallenges">
                      <IonLabel className="labelText">Challenges</IonLabel>
                    </IonSegmentButton>}
                    {props.user.role === "villain" && <IonSegmentButton value="userChallenges">
                        <IonLabel className="labelText">Challenges</IonLabel>
                    </IonSegmentButton>}

                  <IonSegmentButton value="userBackstory">
                    <IonLabel className="labelText">Backstory</IonLabel>
                  </IonSegmentButton>
                </IonSegment>}
                <div id="userMissionsFeed">
                    {missionsCreated.length === 0  && missions.length === 0 &&
                        <>
                        <IonRow>
                            <IonCol style={{textAlign:'center'}}>
                            <IonText>
                                To create or complete Missions, press:
                            </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol style={{textAlign:'center'}}>
                             <IonIcon onClick={goHire} style={{padding: '5px', background:'#005494', width:'50px', height:'50px'}} src={"assets/img/harmony_white.svg"} className="menuButton"/>
                            </IonCol>
                        </IonRow>
                        </>
                    }
                    {/*{missionsCreated && missionsCreated.length > 0 && */}
                        {missionsCreated.map((missionCreated, mcIdx) => {
                            return (
                                <MissionDeliveryComponent key={mcIdx} mission={missionCreated} index={mcIdx} showButtons={true} loggedInUser={props.user} fromPage="profile" showCompletedSection={false}/>
                            );
                        })}

                        {missions.map((missionContent, idx) => {
                          return (
                            <DeliveryModuleScreen key={idx} deliveryModule={missionContent} showButtons={false} loggedInUser={props.user} tab="profile"/>
                          );
                        })}

                </div>
                {props.user.role === "hero"  && <div id="userChallengesFeed" style={{display: 'none' }} >
                    {challenges && challenges.length === 0 && <>
                        <IonRow>
                            <IonCol style={{textAlign:'center'}}>
                                <IonText>
                                    To take on Challenges, press:
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{textAlign:'center'}}>
                            <IonCol size="5"></IonCol>
                            <IonCol size="4">
                                <IonImg onClick={goChallenges} style={{padding: '5px',background:'#005494', width:'60px', height:'60px'}} src={"assets/img/super.png"} className="menuButton"/>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                    </>}
                {challenges && challenges.map((challenge, idx) => {
                  return (
                    <IonCard key={idx} className='deliveryModuleContainer'>
                        <VideoListItem contentItem={challenge} tab="challenge" enableTogglePrivate={true}/>
                        <DeliveryModuleBtnContainer deliveryModule={challenge} loggedInUser={props.user} tab="userChallenges"/>
                      {/*<VideoListItem  uid={challenge.contentTitle} contentUrl={challenge.contentVideo} posterUrl={challenge.posterUrl} contentType={challenge.contentType} contentTitle={challenge.title}/>*/}
                    </IonCard>
                  );
                })}

                    {/* <UserChallengesFeed userId={props.user.heroId}/> */}
                </div>}
                {props.user.role === "villain"  && <div id="userChallengesFeed" style={{display: 'none' }} >
                    {challenges && challenges.map((challenge, idx) => {
                        return (
                            <IonCard key={idx} className='deliveryModuleContainer'>
                                <VideoListItem contentItem={challenge} tab="challenge" enableTogglePrivate={true}/>
                                <DeliveryModuleBtnContainer deliveryModule={challenge} loggedInUser={props.user} tab="userChallenges"/>
                                {/*<VideoListItem  uid={challenge.contentTitle} contentUrl={challenge.contentVideo} posterUrl={challenge.posterUrl} contentType={challenge.contentType} contentTitle={challenge.title}/>*/}
                            </IonCard>
                        );
                    })}

                    {/* <UserChallengesFeed userId={props.user.heroId}/> */}
                </div>}
                <div id="userBackstoryFeed" style={hide ? { display: 'none' } : {}} className="backstoryFeed">
                    {props.user.backstory ?
                        <IonText>{props.user.backstory}</IonText>:
                        <>
                            <IonRow style={{textAlign:'center'}}><IonCol className="editProfileButton"> To write your Backstory here, press: </IonCol></IonRow>
                            <IonRow style={{textAlign:'center'}}><IonCol>
                                <IonButton onClick={goInCharacterTab} className="editProfileButtonText" color="primary"> EDIT PROFILE </IonButton></IonCol></IonRow>
                        </>
                        }

                  {/* <UserBackstoryFeed userId={props.user.uid}/> */}
                </div>

                {props.user.role === 'undefined' && <IonContent><IonText color="primary"> You don't have permission</IonText></IonContent>}
            </IonContent>
            <Footer />
        </IonPage>

    );
            }
};

export default authWrapper(Profile);

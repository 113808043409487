import React, { useState } from 'react';
import { storageRef } from '../../firebaseConfig';
import {IonIcon, IonLabel, IonRow} from "@ionic/react";
import {playOutline} from "ionicons/icons";



const ProfileVideoExampleContainer: React.FC = () => {

const [contentUrl, setContentUrl] = useState('');
const [posterContentUrl, setPosterContentUrl] = useState('');

let assetName="app/walkthrough/SlugHERO.mp4";

let posterAssetName="app/walkthrough/slug_heroprofile_cover.jpg";

// let posterAssetName="app/assets/walkthrough/postapproval/videos/hero/SlugHERO.mp4#t=0.1";
storageRef.child(assetName).getDownloadURL().then(function(url) {
    // `url` is the download URL for 'images/stars.jpg'
    
    // This can be downloaded directly:
    // var xhr = new XMLHttpRequest();
    // xhr.responseType = 'blob';
    // xhr.onload = function(event) {
    //     var blob = xhr.response;
    // };
    // xhr.open('GET', url);
    // xhr.send();
    setContentUrl(url);
    // Or inserted into an <img> element:
    let img = document.getElementById('profileVideo');
    img.setAttribute("src", url);
    }).catch(function(error) {
    // Handle any errors
    });

    storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        // var xhr = new XMLHttpRequest();
        // xhr.responseType = 'blob';
        // xhr.onload = function(event) {
        //     var blob = xhr.response;
        // };
        // xhr.open('GET', posterUrl);
        // xhr.send();
        setPosterContentUrl(posterUrl);
        // Or inserted into an <img> element:
        let img = document.getElementById('profileVideo');
        img.setAttribute("poster", posterUrl);
    }).catch(function(error) {
        // Handle any errors
    });

    function onVideoClick(e) {
        let video  = document.getElementById('profileVideo') as HTMLVideoElement;
        video.play().then(() => {console.log('video played')});
    }
  return (

    <div className="video-wrapper">

        <div className="overlay"
             onClick={onVideoClick}
        >

            <div style={{margin: 5}} className="left deliveryModuleTitle bolder">

                <IonRow>
                    <IonIcon style={{padding: 2}} item-end icon={playOutline}/>

                </IonRow>
            </div>

        </div>
        <video id="profileVideo" poster={posterContentUrl} controlsList="nodownload">
           <source src={contentUrl} type="video/mp4" />
        </video>
    </div>
  );
};

export default ProfileVideoExampleContainer;


import React from 'react';
import {
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonFooter,
    IonToolbar,
    IonText
} from '@ionic/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Terms from './Terms';
import Monetization from './Monetization';
import DMCA from './DMCA';

const TermsPage: React.FC = () => {
  function changeSegmentContent(selectedSegment) {
      let contentContainer = document.getElementById(selectedSegment+'Feed');
      contentContainer.style.display= 'block';
      let tosFeedContainer = document.getElementById('tosFeed');
      let monetizationFeedContainer = document.getElementById('monetizationFeed');
      let dmcaFeedContainer = document.getElementById('dmcaFeed');
      if (selectedSegment === 'tos') {
        monetizationFeedContainer.style.display= 'none';
        dmcaFeedContainer.style.display = 'none';
      }
      else if (selectedSegment === 'monetization'){
        tosFeedContainer.style.display= 'none';
        dmcaFeedContainer.style.display= 'none';
      }
      else if (selectedSegment === 'dmca'){
        tosFeedContainer.style.display= 'none';
        monetizationFeedContainer.style.display= 'none';
      }
  }

  const hide  = true;

  return (
    <IonPage>
      <Header noChat={true}/>
      <IonContent>

      <IonSegment value="tos"  onIonChange={(e) => changeSegmentContent(e.detail.value as any)}>
            <IonSegmentButton value="tos">
              <IonLabel className="labelText">Terms Of Service</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="monetization">
              <IonLabel className="labelText">Monetization</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="dmca">
              <IonLabel className="labelText">DMCA</IonLabel>
            </IonSegmentButton>
        </IonSegment>
          <IonContent id="tosFeed" className="ion-padding-bottom" scrollX scrollY>
            <Terms/>
          </IonContent>
          <IonContent style={hide ? { display: 'none' } : {}} id="monetizationFeed" className="ion-padding-bottom" scrollX scrollY>
            <Monetization />
          </IonContent>
          <IonContent style={hide ? { display: 'none' } : {}} id="dmcaFeed" className="ion-padding-bottom" scrollX scrollY>
            <DMCA />
          </IonContent>
        </IonContent>
      {/*<Footer />*/}
        <IonFooter>
            <IonToolbar color="primary" className="ion-text-center">
                <IonText className="footerText"> Powered by Reelwurld™. Patent pending. <br/>  All Rights reserved. </IonText>
            </IonToolbar>
        </IonFooter>
    </IonPage>
  );
};

export default TermsPage;

import React from 'react';
import {IonContent, IonPage, IonRow, IonCol, IonImg, IonButton, IonIcon} from '@ionic/react';
import Header from "../components/Header";
import {Footer} from "../components/Footer";
import authWrapper from "../components/authWrapper";
import {cart} from "ionicons/icons";

interface Store {

    user: any;

}

const Store: React.FC<Store> = (props) => {


    return (
        <IonPage className="app-page">
            <Header />
            <IonContent className="app home ion-padding ion-justify-content-center" style={{paddingTop:'10%'}}>

                <IonRow>
                    <IonCol>
                        <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fmerch%2FinCharacter.jpg?alt=media"}
                        />
                        <IonRow style={{float:'right'}}>
                            <IonButton onClick={() => {window.location.href="https://www.storenvy.com/products/33298606-in-character-t-shirt";}}><IonIcon icon={cart}/> In-Character T-Shirt </IonButton>
                        </IonRow>
                    </IonCol>
                    <IonCol>
                        <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fmerch%2Foutofcharacter.jpg?alt=media"}
                        />
                        <IonRow style={{float:'right'}}>
                            <IonButton onClick={() => {window.location.href="https://www.storenvy.com/products/33298561-out-of-character-t-shirt";}}><IonIcon icon={cart}/> Out-of-Character T-Shirt </IonButton>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fmerch%2Fjfh.jpg?alt=media"}
                        />
                        <IonRow style={{float:'right'}}>
                            <IonButton onClick={() => {window.location.href="https://www.storenvy.com/products/33298624-jfh-logo-t-shirt";}}><IonIcon icon={cart}/> JFH Logo T-Shirt </IonButton>
                        </IonRow>
                    </IonCol>
                    <IonCol>
                        <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fmerch%2Freelwurld.jpg?alt=media"}
                        />
                        <IonRow style={{float:'right'}}>
                            <IonButton onClick={() => {window.location.href="https://www.storenvy.com/products/33313120-reelwurld-logo-t-shirt";}}><IonIcon icon={cart}/> ReelwUrld Logo T-Shirt </IonButton>
                        </IonRow>
                    </IonCol>
                </IonRow>
                {/*<iframe src={"https://www.storenvy.com/stores/1445257-justice-for-hire"} style={{width: '100%', height: '100%'}}></iframe>*/}

            </IonContent>

            <Footer user={props.user}/>
        </IonPage>
    );
};


export default authWrapper(Store);
import React, {useState} from 'react';
import {
    IonPage,
    IonFabButton, IonLabel, IonButtons, IonCol, IonRow, IonToggle, IonInput, IonButton, IonContent, IonProgressBar, IonSpinner, IonText, IonFooter
} from '@ionic/react';
import { withRouter, useHistory} from "react-router-dom";
import {firestore, storageRef} from "../../firebaseConfig";
import Footer from "../../components/Footer";
import {toast} from "../../toast";
import Header from "../../components/Header";
import firebase from "firebase";
import {extractFramesFromVideo} from "../../util/videoUtils";
import { findOrCreateConvoWithMessage, findOrCreateConvoWithMessageRedirect } from '../Chat/chat';
import { sendEmail } from '../../util/email';
import {showNotification, uploadInProgressNotification} from "../../util/notification";


interface MissionDeliveryReviewProps{
   location: any;
}
const MissionDeliveryReview: React.FC<MissionDeliveryReviewProps> = (props) => {
    const history = useHistory();
    const [publicContent, setPublicContent] = useState(true);
    const [description, setDescription] = useState('');
    const [noteToClient, setNoteToClient] = useState('');

    const [missionPosterFileContent, setMissionPosterFileContent] = useState('');

    const [showProgress, setShowProgress] = useState(false);

    if (!props.location.state)
        return null
    const {missionFile, missionFileURL, missionTitle, missionId,heroName, heroId, userId, userPhotoURL, fromPage, clientDetails } = props.location.state as any; // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674
    //const {user:{heroName, heroId, uid, photoURL}} = props as any;


    const onUploadMissionImage = (e: any) => {

        let missionPosterFile = e.target.files[0];
        let missionPosterFileUrl = URL.createObjectURL(missionPosterFile);
        let missionPosterFileExtension = missionPosterFile.type.split("/").pop();
        console.log('posterFile', missionPosterFile);
        let posterFileName = missionTitle.replace(/ /g, "_") + "_"+heroName.replace(/ /g, "_") + Date.now() + "."+missionPosterFileExtension;
        let videoProfileEl = document.getElementById('missionVideoPlayer') as HTMLVideoElement;
        videoProfileEl.setAttribute('poster', missionPosterFileUrl);
        let missionThumbnailRef = storageRef.child('content/missions/thumbnails/'+posterFileName);
        missionThumbnailRef.put(missionPosterFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail for the mission');
        }).catch((err)=>toast("error uploading thumbnail"+err));
        setMissionPosterFileContent(missionThumbnailRef.fullPath);

    }

    async function clickUploadMissionImage(e) {
        document.getElementById('uploadImageBtn').click();
    }

    function completeMission(publicContent, missionTitle, description, heroName, heroId, missionPosterFileContent) {

        if (description === '') {
            return toast('See above to enter description. ');
        }

        showNotification().then(() => console.log('notification print'));

        let fileExtension = missionFile.type.split("/").pop();
        //console.log("file extension", fileExtension);
        let heroVideoName = heroName?.replace(/ /g, "_");
        let videoMissionTitle = missionTitle?.replace(/ /g, "_");
        let videoName = videoMissionTitle+"_"+heroVideoName+Date.now() +"."+fileExtension;
        console.log("video name", videoName);
        console.log("heroVideoName name", heroVideoName);

        let completeBtnEl = document.getElementById('completeMissionBtn');
        completeBtnEl.setAttribute('disabled', 'true');

        setShowProgress(true);

        if (missionPosterFileContent === '') {
            let thumbnailRef = extractFramesFromVideo('missionVideoPlayer',missionFileURL, 1, 'mission', missionTitle, heroVideoName);
            missionPosterFileContent = thumbnailRef;
            console.log("thumbnail", thumbnailRef);
        }

        let missionVideoRef = storageRef.child('content/missions/'+videoName);
        let missionContentData = {
          contentDescription: description,
          contentVideo: missionVideoRef.fullPath,
          contentType: 'mission',
          status: 'completed',
          completedByUserId: userId,
          completedByHeroId: heroId,
          completedByHeroName: heroName,
          completedByPhotoUrl: userPhotoURL,
          videoContentType: missionFile.type,
          timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
          objectRefTitle: missionTitle,
          objectRefId: missionId,
          title: missionTitle,
          publicContent: publicContent,
          posterUrl:missionPosterFileContent,
          liveContent: true,
        }
        missionVideoRef.put(missionFile).then(function() {
            console.log('Uploaded a blob or file!');
            createMissionContent(missionContentData);
        });


    }



     function createMissionContent(missionContentData) {
         // let progressBarEl = document.getElementById('progressBar');
         // progressBarEl.style.display='block';
         // progressBarEl.setAttribute('value', '0.5');
         // progressBarEl.setAttribute('buffer', '0.75');


         let redirectTo  = '/wurld';
         if (missionContentData['publicContent'] === false) {
             redirectTo = '/profile';
         }
        // let redirectAfterSuccess  = '/profile';

         if (noteToClient && noteToClient.trim() !== '') {
             //send note to client
             let sender = {uid: userId, senderPhotoURL: userPhotoURL, senderHeroName: heroName};
             console.log("submitted by", clientDetails);
             console.log("redirect to ", redirectTo);

             const missionDeliveryRef =  firestore.collection('content').add(missionContentData).then(ref => {
                 //console.log('Added mission content  with ID: ', ref.id);
                 sendNoteToClient(sender,clientDetails, noteToClient, ref.id, 'deliveryModule', redirectTo);
                 sendEmail("Note To Client", 'Your Mission Was Completed', clientDetails.email, missionTitle);
             });
             //export function {
         }
         else {
             //upload mission

             const deliverMissionRef =  firestore.collection('content').add(missionContentData).then(ref => {
                 //console.log('Added mission content  with ID: ', ref.id);
                 sendEmail("Note To Client", 'Your Mission Was Completed', clientDetails.email, missionTitle);
                 window.location.href=redirectTo;
             });
         }


     }

    function sendNoteToClient(sender, destination, noteToClient, contentId, sentObjectType, redirectTo) {
        let messageData = {
            messageType: 'outgoing',
            checked: true,
            sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
            answerContent: noteToClient,
            contentId: contentId,
            sentObjectType: sentObjectType,
            messageFrom: sender.uid,
        };
        let messageList = [messageData];

        console.log("destination", destination);
        
        // console.log("message list", messageList);
        // console.log("destination", destination);
        // console.log("sender send content", sender);

        // findOrCreateConvoWithMessage(sender, destination, messageData, history);
        findOrCreateConvoWithMessageRedirect(sender, destination, messageData, history, true, redirectTo)
    }


    return (
        <IonPage>
            <Header fromPage={fromPage}/>
            <IonContent>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: 20, overflowY: 'auto'}}>
                {/*<div style={{width: '80%', margin: 30}}>*/}
                {/*    <IonText style={{color: '#005494', fontWeight: 'bold', marginBottom: 8}}>Review Your Mission </IonText>*/}
                {/*</div>*/}
                <div style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center', margin: 5}}>
                    <IonText style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center'}}> Review Your Mission</IonText>
                </div>
                <div style={{}}>

                        <video style={{maxWidth: '100%'}} id="missionVideoPlayer" controls src={missionFileURL} autoPlay muted>
                            <source src={missionFileURL} type={missionFile?.type}/>
                        </video>
                        <img id="posterGenerated" alt={'thumbnail'} style={{'display':'none',maxWidth: '100%'}}/>

                    <div>
                        {/*<span style={{color: '#005494', fontWeight: 'bold', margin: 5}}>Description</span>*/}
                        <span style={{margin: 5}} >
                            <IonInput autocapitalize="on" className="inputBackground" value={description} placeholder="Enter Description" onIonChange={(e: any) => setDescription(e.target
                                .value)}></IonInput>
                          </span>
                    </div>
                </div>
                <div style={{width: '80%'}}>
                    <IonRow>
                        <IonCol>
                        </IonCol>
                        <IonCol>
                            <IonButton id="uploadVideoImgBtn"
                                       className="uploadLater"
                                       style={{ 'background':'none'}}
                                       onClick={(e) => {
                                           clickUploadMissionImage(e)
                                       }}>

                                Choose Cover Image
                                <input type="file" accept="image/*" className="uploadVideoBtn"
                                       id="uploadImageBtn"
                                       onChange={onUploadMissionImage}
                                />
                            </IonButton>
                          </IonCol>
                          <IonCol>
                          </IonCol>
                    </IonRow>

                    {/*<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} >*/}
                    {/*    <div style={{width: '100%', height: 100}}><ThumbnailChooseBar file={file}/></div>*/}
                    {/*</div>*/}
                </div>

                <div style={{width: '80%'}}>
                    <div>
                        {/*<span style={{color: '#005494', fontWeight: 'bold', margin: 5}}>Note to client</span>*/}
                        <span style={{margin: 5}}> <IonInput autocapitalize="on" className="inputBackground" value={noteToClient} placeholder={"Enter a note to client ..."} onIonChange={(e: any) => setNoteToClient(e.target
                                                                 .value)}></IonInput></span>
                    </div>
                </div>

                <div style={{width: '80%'}}>
                    <IonButtons slot="end" style={{float:'right'}}>
                        <IonCol>
                            <IonRow>
                                <IonLabel style={{'fontSize':'8px'}}>{publicContent ? `Public`: 'Private'}</IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonToggle checked={publicContent} onIonChange={e => setPublicContent(e.detail.checked)} />
                            </IonRow>
                        </IonCol>
                    </IonButtons>

                    {showProgress ?
                        <IonFabButton  style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px', marginLeft:'35%'}}>
                            <IonSpinner/>
                        </IonFabButton>
                        : <IonFabButton id="completeMissionBtn" style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px' , marginLeft:'35%'}} onClick={() => completeMission(publicContent,missionTitle,description,heroName,heroId,missionPosterFileContent)}>
                            COMPLETE
                        </IonFabButton>
                    }


                </div>

            </div>
            </IonContent>
            {/*{showProgress && <IonFooter> <IonText>Stay on this page. You will be automatically redirected once your upload is complete.</IonText></IonFooter>}*/}
            <Footer />

        </IonPage>
    )
}
export default withRouter(MissionDeliveryReview);

import React, {useEffect, useState} from 'react';
import {
    IonContent,

    IonSegmentButton, IonLabel, IonSegment
} from '@ionic/react';
import User from '../../models/User';


import authWrapper from '../../components/authWrapper';
import ContentChangeRequests from "./ContentChangeRequests";
import CharacterNameRequests from "./CharacterNameRequests";
import CTAChangeRequests from './CTAChangeRequests';
import UserContentChangeRequests from './UserContentChangeRequests';
import UserCharacterNameRequests from './UserCharacterNameRequests';
import UserCTAChangeRequests from './UserCTAChangeRequests';



interface UserChangeRequestsState {
    user: any;
}

const UserChangeRequests:React.FC<UserChangeRequestsState> = (props) => {

    const [searchContent, setSearchContent] = useState('');
    const [users, setUsers] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [currentTab, setCurrentTab]= useState('brandPrompts');
    const [searchUser, setSearchUser]= useState('');

    useEffect(() => {

        // let queryProfile = searchUser.toLowerCase()
        // let stringBoundProfile = queryProfile.slice(0, queryProfile.length-1)
        //     + String.fromCharCode(queryProfile.slice(-1).charCodeAt(0) + 1)
        // //console.log("search receiver", searchReceiver);
        //
        // if (queryProfile!='' && queryProfile.length >=1) {
        //     // console.log("query", query);
        //     const searchCastRef = firestore.collection('users').where('complete', '==', true)
        //         .where('characterName_nocase','>=', queryProfile)
        //         .where('characterName_nocase','<', stringBoundProfile)
        //         .where('characterName_nocase','!=', '')
        //         .orderBy('characterName_nocase', 'asc')
        //         .orderBy('dateJoined', 'desc')
        //
        //     searchCastRef.onSnapshot(snapshot => {
        //         setUsers(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));
        //
        //     });
        //
        // }
        // else {
        //
        //     let castRef = firestore.collection('users').where('complete', '==', true).orderBy('dateJoined', 'desc')
        //         .onSnapshot(snapshot => {
        //
        //             let users = [];
        //
        //             snapshot.forEach(castDoc =>
        //                 users.push({...castDoc.data(), uid: castDoc.id}),
        //             );
        //
        //             setUsers(users);
        //         });
        //
        //
        // }


    }, [searchUser]);

    function changeSegmentContent(selectedSegment) {

        let changesContentContainer = document.getElementById(selectedSegment + 'Feed');
        changesContentContainer.style.display = 'block';
        let characterNameContainer = document.getElementById('characterNamesFeed');
        let brandContentContainer = document.getElementById('brandPromptsFeed');
        let contentProducedContainer = document.getElementById('contentProducedFeed');

        if (selectedSegment === 'brandPrompts') {
            brandContentContainer.style.display='block';
            characterNameContainer.style.display='none';
            contentProducedContainer.style.display = 'none';
            setCurrentTab('brandPrompts');
        } else if (selectedSegment === 'characterNames') {
            brandContentContainer.style.display='none';
            characterNameContainer.style.display='block';
            contentProducedContainer.style.display = 'none';
            setCurrentTab('characterNames');
        } else if (selectedSegment === 'contentProduced') {
            // setShowJoin(true);
            brandContentContainer.style.display='none';
            contentProducedContainer.style.display = 'block';
            characterNameContainer.style.display = 'none';
            setCurrentTab('contentProduced');
        }
    }
    return (

        <IonContent className="ion-padding-bottom">


            <IonSegment value={currentTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)} >
                <IonSegmentButton value="brandPrompts">
                    <IonLabel className="labelText">CTA's</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="characterNames">
                    <IonLabel className="labelText">Character Name</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="contentProduced">
                    <IonLabel className="labelText">Content Produced </IonLabel>
                </IonSegmentButton>

            </IonSegment>

            <IonContent id="brandPromptsFeed" scrollX scrollY>
                {currentTab === 'brandPrompts' && <UserCTAChangeRequests user={props.user}/>}
            </IonContent>
            <IonContent id="characterNamesFeed" scrollX scrollY>
                {currentTab === 'characterNames' && <UserCharacterNameRequests user={props.user}/>}
            </IonContent>
            <IonContent id="contentProducedFeed" style={{display: 'none'}}  scrollX scrollY>
                { currentTab === 'contentProduced' &&
                <UserContentChangeRequests user={props.user}/>}
            </IonContent>

        </IonContent>


    );
}

export default authWrapper(UserChangeRequests);

import React from 'react';
import { IonText,IonRow, IonCol, IonGrid} from '@ionic/react';
import ProfileVideoExampleContainer from '../../components/Walkthrough/ProfileVideoExampleContainer';



const ApprovedUserScreen1: React.FC = () => {
  
 
  
  return (
   
        
        <IonGrid>

           <IonRow>        
          <IonCol>
          <IonText className="appTextMainItalic bolder ion-text-justify">
                Welcome to JFH       
          </IonText>
          </IonCol>       
          </IonRow>         
         
          <IonRow>    
                
          <IonCol>
          <IonText className="appTextMain ion-text-center">
              A Hero, Client, or Villain intro video is *strongly* recommended. 
          </IonText>    
          </IonCol>
          
          </IonRow>
          <IonRow>
          
          <IonCol>
            <ProfileVideoExampleContainer  />
    
          </IonCol>
         
          </IonRow>
          <IonRow>
          
          
          
          </IonRow>
         
         
          </IonGrid>

  );
};


export default ApprovedUserScreen1;



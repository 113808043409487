import React, {useState} from 'react';
import {
        IonSlide,
        IonText,
        IonRow,
        IonCol,
        IonGrid,
        IonRouterLink,
        IonImg,
        IonIcon,
        IonModal,
        IonHeader, IonContent, IonLabel, IonFooter, IonButton
} from '@ionic/react';
import { chatbubbles } from 'ionicons/icons';
import {sendJoinTestTeamEmail} from "../../util/sendEmail";

interface ApprovedUserScreen6Props {
        user:any;
}


const ApprovedUserScreen6: React.FC<ApprovedUserScreen6Props> = (props) => {
        const [showJoin, setShowJoin] = useState(false);

        const joinTestTeam = () => {
                console.log("props", props);
                sendJoinTestTeamEmail(props.user);
                setShowJoin(false);
        }
  return (

          <IonSlide>
                  <IonModal isOpen={showJoin}
                            cssClass='join-custom-class'
                            onDidDismiss={() => setShowJoin(false)}>
                          <IonHeader>
                                  <IonRow style={{padding: 10}}>
                                          <IonText className="appTextMain center">
                                                  To gain access to this section, join the JFH App Test Team.
                                          </IonText>
                                  </IonRow>
                          </IonHeader>
                          <IonContent className='join-custom-class'
                                      scrollEvents={true}
                                      onIonScrollStart={() => {
                                      }}
                                      onIonScroll={() => {
                                      }}
                                      onIonScrollEnd={() => {
                                      }} scrollX scrollY>

                                  <IonImg src={"/assets/img/JoinTestTeam.png"} style={{height:'150px', width:'300'}}></IonImg>

                          </IonContent>
                          <IonFooter>
                                  <IonRow className="ion-justify-content-center">

                                          <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                                                  <IonLabel className="italic bolder">
                                                          Join
                                                  </IonLabel>
                                          </IonButton>
                                  </IonRow>
                          </IonFooter>
                  </IonModal>
          <IonGrid className="stepScreen">
                  <IonRow>
                          <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                                  <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>

                                  </div>
                                  {/*<IonItem className="app menuButton" onClick={() => setShowJoin(true)}  lines="none">*/}

                                  {/*<IonRouterLink onClick={() => setShowJoin(true)}>*/}
                              <IonRouterLink href="/chat">
                                          <IonIcon style={{'width':'50px', 'height':'50px'}} icon={chatbubbles} className="menuButton"></IonIcon>
                                  </IonRouterLink>
                                  <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>

                                  </div>
                          </div>
                  </IonRow>
                  <IonRow><IonCol></IonCol></IonRow>

                  <IonRow>
          <IonCol>
          <IonText className="appTextMain ion-text-justify" color="light">
            Cast Chat is where Heroes, Clients and Villains share ideas, create teams, and get to know each other.
          </IonText>
          </IonCol>       
          </IonRow>         
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow>    
                
          <IonCol>
          <IonText className="appTextMainItalic ion-text-center bolder" color="light">
             Chat as BOTH of your identities:

          </IonText>    
          </IonCol>
          
          </IonRow>
          <IonRow>
          
          <IonCol>
          <IonText className="appTextMainItalic bolder ion-text-center" color="light">
                1. Alter ego (aka In-Character)
          </IonText>
    
          </IonCol>
         
          </IonRow>
          <IonRow>
          
          <IonCol>
          <IonText className="appTextMainItalic bolder ion-text-center" color="light">
                2. Yourself (aka Out-of-Character)
          </IonText>
    
          </IonCol>
         
          </IonRow>
          <IonRow>
          
          <IonCol>
         
          </IonCol>
          
          </IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          </IonGrid>
          </IonSlide>
         
  );
};


export default ApprovedUserScreen6;



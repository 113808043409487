
import React, {useEffect, useState } from 'react';
import {  IonRow, IonCol, IonButtons, IonButton,  IonIcon, IonLabel, IonText } from '@ionic/react';

import {caretDown, caretUp, playOutline} from 'ionicons/icons';
import { useHistory} from "react-router-dom";
import {Challenge} from "../../models/Challenge";
import { firestore } from '../../firebaseConfig';
import {message } from '../../util/notification';
import VideoListNoControls from '../../components/VideoListNoControls';
import { Device } from '@capacitor/core';
import { openFullScreen } from '../../util/videoUtils';

interface PremiumDevelopmentListItemProps {
    challenge: Challenge;
    index: number;
    showButtons: boolean;
    user: any;
    // userId: string;
    // heroName: string;
    // heroId: string;
    // userPhotoURL: string;
}



const PremiumDevelopmentListItem: React.FC<PremiumDevelopmentListItemProps> = ({challenge, index, showButtons, user}) => {

    const [showPeopleList, setShowPeopleList] = useState(false);
    const [actionName, setActionName] = useState('accept');
    const [divisionFactor, setDivisionFactor] = useState(1);
    const [platformInfoName, setPlatformInfoName] = useState('web');
    const [showDetails, setShowDetails] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let isSubscribed = true;
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            setPlatformInfoName(info.operatingSystem);
        } );

        let query = firestore.collection('content').where('objectRefId', '==', challenge.uid).where('completedByUserId', '==', user.uid);
        query.get()
            .then(function(snapshotContent)  {
                let isCompleted = false;
                console.log(snapshotContent.size);
                if (snapshotContent.size > 0) {
                    isCompleted = true;
                }
                let actionName = 'accept';
                if (challenge.episodic && isCompleted) {
                    actionName = 'add more';
                }
                else if (!challenge.episodic && isCompleted) {
                    actionName = 'completed';
                }
                setActionName(actionName);
            }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
        if (challenge.type === 'training') {
            setDivisionFactor(18);
        }
        if (challenge.type === 'development') {
            setDivisionFactor(14);
        }
        return () => {isSubscribed = false}
    }, [challenge]);

    function viewContent(containerId, videoContainerId) {
        //console.log("view id ", containerId + "video id", videoContainerId);
        let journeyEl=document.getElementById(containerId+videoContainerId);
        journeyEl.style.display = 'block';
        //console.log("Video container ID", videoContainerId);
        //let videoContainer= document.getElementById(videoContainerId);
        let videoEl = journeyEl as HTMLVideoElement;
        // console.log("Video el id:",videoEl);

        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));

        // let playBtn = document.getElementById('play'+item.code);
        // let stopBtn = document.getElementById('stop'+item.code);

        if (platformInfoName ==='android') {
            videoEl.onfullscreenchange = (e=> {
                if (!document.fullscreenElement)
                    videoEl.pause();
            })
        }

        if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
            //hide play. show stop
            // playBtn.style.display='block';
            // stopBtn.style.display='none';

            videoEl.pause();
        }
        else if (videoEl.paused || videoEl.ended) {
            // videoEl.webkitEnterFullScreen();
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (platformInfoName ==='android') {
                        openFullScreen(videoEl);
                    }
                    console.log("resume video");
                    console.log("video play time", videoEl.currentTime);
                }
            ).catch(() => console.log("error playing video"));
        }
        else {
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (platformInfoName ==='android') {
                        openFullScreen(videoEl);
                    }
                }
            ).catch(() => console.log("error playing video"));
        }
    }



    let acceptChallenge = (challenge) => {
        if (user.role === 'client') {
            message('Only Heroes or Villains can take on Challenges');
        }
        else {
            history.push({
                pathname: '/challengeListItemDetail',
                state: {challengeId:challenge.uid, challengeTitle:challenge.title, challengeType: challenge.type, heroId: user.uid, heroName: user.characterName, userId: user.uid, userPhotoURL: user.photoURL, fromPage: 'trainingChallenges'},
            });
        }
    }


    return (
        <>
            <div id={challenge.index}>
                <VideoListNoControls item={challenge}/>
            </div>

            <IonRow style={{'paddingTop':'10px', 'paddingLeft':'10px'}}>
                <IonCol size="7">
                    <IonText class="contentTextTitle" color="primary"> {challenge.title} </IonText>
                </IonCol>

                <IonCol size="2">
                    <IonButtons key={index}>
                        <IonButton shape="round"  size="default" className={(actionName ==='accept')? 'acceptBtn bolder': 'completeBtn'}  onClick={() => acceptChallenge(challenge)}> {actionName.toUpperCase()} </IonButton>

                        <IonButton  className="play-button-div" onClick={() => viewContent(challenge.uid, challenge.type)} style={{'color':'#005494'}}>
                            <IonIcon className="play-button" icon={playOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonCol>
            </IonRow>
            <IonRow style={{'paddingLeft':'14px','paddingBottom':'10px'}}>
                <IonText className="contentText bolder ion-text-justify" color="primary">
                    sponsored by Warner Media
                </IonText>
            </IonRow>
            <IonRow style={{'paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonCol size="4">
                    <IonLabel class="contentSubtitle" color="primary">

                        ({index+1}/{divisionFactor})

                    </IonLabel>
                </IonCol>
            </IonRow>

            <IonRow style={{'paddingTop':'0px','paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonText className="contentText ion-text-justify" color="primary">
                    {challenge.description}
               </IonText>
            </IonRow>

            <IonRow style={{'paddingTop':'0px','paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonText className="contentText ion-text-justify" color="primary">
                    <IonIcon icon={showDetails? caretUp: caretDown} onClick={() => setShowDetails(!showDetails)}></IonIcon> Here's the list of important things to know about the competition:
                </IonText>
            </IonRow>
            <div style={showDetails ? {'paddingTop':'0px','paddingLeft':'12px','paddingBottom':'10px'}: {'display':'none'}}>
                <IonText color="primary"> The biggest JFH event EVER is here, presented by Urban Action Showcase.  RECREATE a scene in your own way, playing YOUR OWN CHARACTER, using any of these films as inspiration: </IonText>
                <ul style={{listStyleType:'circle'}}>
                    <li> <IonText color="primary" className="bolder">
                        Avengers
                    </IonText></li>
                    <li> <IonText color="primary" className="bolder">
                        The Dark Knight Rises
                    </IonText></li>
                    <li> <IonText color="primary" className="bolder">
                        The Transporter
                    </IonText></li>
                    <li> <IonText color="primary" className="bolder">
                        Jackie Chan's Supercop
                    </IonText></li>
                    <li> <IonText color="primary" className="bolder">
                        The 5 Deadly Venoms
                    </IonText></li>
                    </ul>

                <IonText color="primary" className="bolder">
                    Here's how to enter:
                </IonText>
                <ul style={{listStyle:'none'}}> <IonText color="primary">
                    1. Press Accept on this Challenge.
                </IonText></ul>
                <ul style={{listStyle:'none'}}> <IonText color="primary">
                    2. Upload a video (max 3min in length - 1080p .mp4)
                </IonText></ul>

                <IonText color="primary" className="bolder">
                    The Finalists’ scenes will be screened at the Urban Action Showcase and Expo during the JFH Panel LIVE IN NEW YORK CITY in the Fall.  All scenes will be reviewed by our judges and Finalists will receive feedback from Hollywood Filmmakers and Martial Arts Action Icons. <br/> <br/>
                </IonText>

                <IonText color="primary">
                    Prizes
                </IonText>
                <ul style={{listStyleType:'circle'}}>
                    <li><IonText color="primary" className="bolder">
                     1st Place - A TROPHY, BluRays of all the films, and your own professionally produced scene in the JFH show.
                </IonText></li>
                <li> <IonText color="primary" className="bolder">
                     2nd Place - BluRays and swag.
                </IonText></li>
                <li> <IonText color="primary" className="bolder">
                    3rd Place - BluRay and swag.
                </IonText></li>
                </ul>

                <IonText color="primary" className="bolder">
                  Submission Deadlines: September 9, 2022  <br/>
                </IonText>
                <IonText color="primary">DISCLAIMER: You are solely responsible for the content you create.  Practice safety in all areas of your project.  NEVER USE REAL WEAPONS OR ANYTHING THAT RESEMBLES A REAL WEAPON, SHARP OBJECTS, OR ANYTHING THAT CAN SHOOT OR CUT YOU OR SOMEONE ELSE.  ALWAYS look for sharp corners in any area you plan to perform in so you can avoid those areas.  If you are not a professional stunt person, martial artist, or filmmaker, you need to do even more research than a professional for anything you wish to create on screen.  Check your local laws and regulations before you make any content.  Make sure if you record a video in public that anyone that can see you is aware that you are filming a scene BEFORE you perform any actions.  Action scenes can be dangerous, can cause injuries and more.  Do not do anything you are not comfortable with, and do not allow anyone to perform in your video if they are not comfortable with their actions and consent to those actions.  By submitting to this competition, you hereby indemnify and hold Justice For Hire, ReelwUrld, The Urban Action Showcase, and all affiliates harmless from any and all claims arising out of your participation in the competition.</IonText>
                <IonRow className="contentText ion-text-justify" color="primary">
                   <IonIcon icon={caretUp} onClick={() => setShowDetails(!showDetails)}></IonIcon>
                </IonRow>
            </div>


        </>
    );
};

export default PremiumDevelopmentListItem;


import React from 'react';
import {  IonSlide, IonText,IonRow, IonCol, IonGrid, IonButton, IonIcon} from '@ionic/react';
import {chevronForwardOutline, globeOutline} from 'ionicons/icons';

const ApprovedUserScreen7: React.FC = () => {

  function reachedEnd() {
     //window.location.href='/premiumMissions';
       window.location.href='/welcomeWurld';
  }

  function enterWurld() {
     //window.location.href='/premiumMissions';
    window.location.href='/welcomeWurld';
  }

  return (      
   
          <IonSlide onClick= {()=> reachedEnd()}>
          <IonGrid className="stepScreen">
           <IonRow>        
          <IonCol>
          <IonText className="appTextMain ion-text-justify">
            JFH is not a real vigilante app,
            but our vision is 100% real. We
            fictionalize solutions to real 
            world problems. 
          </IonText>
          </IonCol>       
          </IonRow>         
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow></IonRow>
          <IonRow>    
                
          <IonCol>
          <IonText className="appTextMain ion-text-center" color="light">
           
          </IonText>    
          </IonCol>
          
          </IonRow>
          <IonRow>
          
          <IonCol>
          <IonText className="appTextMain ion-text-center" color="light">
              
          </IonText>
    
          </IonCol>
         
          </IonRow>
          <IonRow>
          
          <IonCol>
          <IonText className="appTextMainItalic ion-text-center" color="light">
               Head inside. The rest is up to you.
          </IonText>

         
         
          </IonCol>
         
          </IonRow>
              <IonRow>
                  <IonCol>
                      <IonText className="appTextMainItalic ion-text-center" color="light">
                            Enter wUrld
                      </IonText>
                  </IonCol>
              </IonRow>
          <IonRow className="ion-justify-content-center">
              <IonCol>
                  <IonButton onClick={()=> enterWurld()} className="appBtn">
                      <IonIcon style={{width:'50px', height: '50px'}} icon={globeOutline}/>
                  </IonButton>
              </IonCol>
          </IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          <IonRow><IonCol></IonCol></IonRow>
          </IonGrid>
          </IonSlide>
         
  );
};


export default ApprovedUserScreen7;



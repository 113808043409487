import React from 'react';
import {IonContent, IonText, IonPage, IonTitle} from '@ionic/react';

import './Rules.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from "../../components/authWrapper";

const Rules: React.FC = () => {
  return (
    <IonPage>
       <Header menu={true}/>

        <IonContent className="ion-padding">

            <IonText>
                <ul style={{'textAlign':'center'}}> JFH Universe Rules for Participation in Canonical Content </ul>


              <ul>1. Communication.</ul>
              <li>Communication in JFH App happens either IN-CHARACTER (i.e. you speaking as the character you’ve created for yourself) or OUT-OF-CHARACTER (you speaking as yourself).</li>
              <li>In Chat, you can choose between the 2 by selecting the appropriate color text.</li>
          <li>Mission Videos should be submitted to the community and completed by the community In-Character.</li>
              <li>Challenge Videos may be submitted and completed either in In-Character or Out-of-Character.</li>
              <li>Training Videos may be submitted and completed either in In-Character or Out-of-Character.</li>

              <ul>2. You Own Your Character.</ul>
              <li>This means the characters you create and portray are your intellectual property.  If a major film studio wants to make a big movie based on your character, it's you who will deal with that opportunity.</li>
              <li>By participating in JFH, you are giving us the non-exclusive right to include your character in our larger narrative.</li>
              <li>Any content you create with your character becomes part of the JFH Cinematic Universe forever.  You can stop participating at any time, but since everyone who contributes to building the storyline is important, those building blocks you lay with your content must stay in place, even if you leave.</li>

          <ul>3. Character Types.</ul>
          <li>CLIENT - Clients share their problems and hire heroes. If you need a hero, know of a problem that requires heroes, or just want to vent and get some feedback about anything in your life, this is the character type for you.</li>
          <li>HERO - Heroes stand up and help people in need. If that’s who you are, join us.</li>
          <li>VILLAIN - Villains pose as heroes within the JFH app. No one will know that you're a villain unless you act like one.</li>

              <ul>4. Create Your Backstory.</ul>
                <li>Your character can be based on your own life, or can be completely fictionalized.  You decide.</li>
              <li>Do not create stories around real life people that you do not have permission from to do so.</li>

              <ul>5. No Superpowers.</ul>
                <li>JFH is reality-based action that takes place in our real world.  Please design your characters to be realistic.</li>

              <ul>6. Clarify Goals and Desires.</ul>
          What does your character want out of life?  This will inform your character’s decisions as we build the cinematic universe together.

              <ul>7. Missions.</ul>
          <li>JFH will regularly release Missions to the community during Meetups and online. Missions are issued and completed by making videos.
              Clients traditionally issue Missions.  However, Heroes can also issue Missions to the community.</li>
                <li>All Missions must be done In-Character.</li>
                <li>Missions should generally be about fictionalizing immediate solutions to real world problems.</li>

              <ul>8. Challenges.</ul>
                <li>JFH will regularly release Challenges to the community during Meetups and online. Challenges are completed by making videos. However, you can also issue Challenges to the community.</li>
                <li>Challenges can be done In-Character or Out-of-Character, depending on the context of the specific Challenge.</li>
                <li>Challenges should generally be about fun tasks that help develop your character, like creating your costume, designing your hero logo, sharing your hero training routine, etc.</li>

              <ul>9. Training.</ul>
                <li>JFH will regularly share Training routines with the community during Meetups and online.</li>
                <li>All Trainings are optional.  You should only participate in a routine after checking with your doctor and you are in good health.</li>
                <li>You can share your Training routines with the community.</li>
                <li>Training videos can be made In-Character or Out-of-Character, depending on the context of the specific Training.</li>

              <ul>10. Meetups.</ul>
                <li>Meetups bring together the JFH community in real life.</li>
                <li>Official JFH Meetups will always be announced by us on our website/apps.</li>
                <li>Anyone can officially host a JFH Meetup and share their Meetup with the community if they meet our criteria for hosting.</li>
                <li>If you want to officially host a JFH Meetup, please contact us.</li>

              <ul>11. Collaborate.</ul>
                <li>Ask other Cast Members and friends to join you in making videos.</li>
                <li>Always get permission from people and places before your record your videos.</li>
                <li>Every person who joins the story expands our cinematic universe.</li>

              <ul>12. Character Branch Narratives vs. Central Narrative.</ul>
                <li>We can only build a cinematic universe together if everyone has their own individual storylines. These should be short films/videos or mini-movies that we call Branch Narratives.  These videos are meant to be episodes that string together to tell your Character's personal story in relation to the larger Central Narrative of JFH (the storyline of building our hero app and community).</li>
                <li>A Hero or Villain can create their own Branch Narrative by accomplishing Missions issued by Clients.</li>
                <li>We want people to have fun and collaborate on videos, so separate Character's Branch Narratives are meant to Crossover (ahem... TEAM UP).</li>

              <ul>13. Always Ask Permission for Crossovers.</ul>
                <li>Everyone OWNS their own Character. Therefore, you must ask other Character Owners permission before including them in your story for any involvement other than Referencing.</li>
                <li>For example, you can mention another Character's actions if those actions have already appeared within a video released on their Branch Narrative.</li>

              <ul>14. Visual Consistency.</ul>
                <li>We recommend giving your Character some colors or clothing items, etc. that he or she will use regularly.  Just a tip to stay memorable.</li>

              <ul>15. No Nudity.</ul>
                <li>Please keep it clean people.</li>

         <ul> 16. No Real Weapons.</ul>
                <li> Please keep it safe.  You should never have real weapons - even something that looks remotely like a real weapon - near you when performing for your JFH videos.  That puts you and others in VERY REAL DANGER.  Even if it’s not real, you can still be in danger.  Don’t do it.</li>

          <ul>17. No Abuse.</ul>
          If we find any Cast Member (the real you) is being abusive to another person participating in this great experience, that abuser will be banned from the JFH Universe.

          <ul>18. All Branch Narratives are Canonical.</ul>
          If you follow the rules, every video you make will be considered part of the JFH Universe, and therefore will help shape our growing story world!

          <ul>19. Have Fun Changing the World.</ul>
          Serious fun.


      </IonText>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default authWrapper(Rules);

import React from 'react';
import {IonContent, IonFooter, IonItem, IonList, IonText, IonToolbar} from '@ionic/react';
import './Terms.css';


const DMCA: React.FC = () => {
  return (
    
      
      <IonContent className="ion-padding" scrollX scrollY>
        
      <IonText className="ion-text-center">
                <h2 className="ion-text-center">DMCA Guidelines</h2>
          <h3 className="ion-text-center">Notification of Infringement.</h3>
      </IonText>

          <IonList>
              <IonItem lines="none">
                  <IonText>
                      It is Justice For Hire’s (referred to hereafter as “JFH”) policy to promptly respond to clear notices of alleged copyright infringement that comply with the Digital Millennium Copyright Act. In addition, we will promptly terminate without notice the accounts of those determined by us to be “repeat infringers”. If you are a copyright owner or an agent thereof, and you believe that any content hosted on our web site and apps (collectively referred to as “web site”, found at www.JusticeForHire.com) infringes your copyrights, then you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing JFH's Designated Copyright Agent with the following information in writing (please consult your legal counsel or see 17 U.S.C. Section 512(c)(3) to confirm these requirements):

                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>

                  a. A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                  b. Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the JFH web site are covered by a single notification, a representative list of such works at the JFH web site.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
         c. Clear identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit JFH to locate the material. Providing URLs to the alleged infringing content in the body of an email is the best way to help us locate content quickly.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                      d. Information reasonably sufficient to permit JFH to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
          e. A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.
                  </IonText>
              </IonItem>

              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                    f.  A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText>
                      Please note that under Section 512(f) of the DMCA, any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability.
                  </IonText>
              </IonItem>
          </IonList>


                  <IonText>
                      <h3 className="ion-text-center"> Counter-Notification.</h3>
                  </IonText>
          <IonList>
              <IonItem lines="none">
                  <IonText>
          If you elect to send us a counter notice, to be effective it must be a written communication that includes the following (please consult your legal counsel or see 17 U.S.C. Section 512(g)(3) to confirm these requirements):
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      a. A physical or electronic signature of the user.
                      </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
          b. Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
          c. A statement under penalty of perjury that the subscriber has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                     d. The user’s name, address, and telephone number, and a statement that the user consents to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if the subscriber’s address is outside of the United States, for any judicial district in which JFH may be found, and that the subscriber will accept service of process from the person who provided notification under subsection (c)(1)(C) or an agent of such person.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText>
                      <span className="bolder">Please note that under Section 512(f) of the Copyright Act, any person who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability.</span>
                  </IonText>
              </IonItem>
          </IonList>

                    <IonText>
                        <h3 className="ion-text-center">Designated Copyright Agent:</h3>
                    </IonText>
          <IonList>
              <IonItem lines="none">
                  <IonText>
          JFH’s Designated Copyright Agent to receive notifications and counter-notifications of claimed infringement can be reached as follows:
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText>
                      Attention: Copyright Agent
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      Justice For Hire <br/>
                      Reelwurld, Inc.,<br/>
                      c/o Mark T. Hiraide | Partner, through his professional corporation<br/>
                      T: 310.312.3768 | mth@msk.com <br/>
                      Mitchell Silberberg & Knupp LLP | www.msk.com <br/>
                      2049 Century Park East, 18th Floor, Los Angeles, CA 90067 <br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText>
          Or by email at jfhjusticeforhire@gmail.com.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText>
                      For clarity, only DMCA notices should go to the JFH Designated Copyright Agent. Any other feedback, comments, requests for technical support or other communications should be directed to JFH customer service through jfhjusticeforhire@gmail.com. You acknowledge that if you fail to comply with all of the requirements of this section, your DMCA notice may not be valid.
                  </IonText>
              </IonItem>
          </IonList>
                    <IonText>
                        <h3>Report Copyright Infringement:</h3>
                    </IonText>
          <IonText>
              If someone has posted your copyrighted content or your personal information to JFH, let us know. You will receive a response promptly. Please contact jfhjusticeforhire@gmail.com with the subject line “DMCA”.

          </IonText>

      </IonContent>

  
  );
};

export default DMCA;

import React from 'react';
import {IonContent, IonItem, IonList, IonListHeader, IonRouterLink, IonText, IonTitle} from '@ionic/react';
import './Monetization.css';


const Monetization: React.FC = () => {
  return (
      <IonContent className="ion-padding" scrollX>

          <IonText>
              <h3 className="ion-text-center"> JFH Terms for Including Hero, Client, Character, Mission or Non-Mission Contribution for Monetized Final Products</h3>

          </IonText>
          <IonText>
              <h4> Last Modified: June 05, 2020</h4>
          </IonText>

          <IonText>
              All capitalized terms used but not otherwise defined in these Terms for Including Hero, Client, Character, Mission or Non-Mission Contributions for Monetized Final Products (“<span className="bolder">Monetized Final Product Terms</span>”) have the meanings ascribed to them in the Reelwurld (“<span className="bolder">Company</span>”) End User License Agreement and Terms of Service (“<span className="bolder">Terms</span>”).
          </IonText>

          <IonText>
              It is understood by You that there are currently no active monetization opportunities from the Company.  You may be notified when monetization opportunities become available.  Therefore, the following Terms are subject to change over time.
          </IonText>

          <IonList>
              <IonListHeader>
                  <IonText className="bolder"> 1. Notice of Selecting a Final Product and Grant in Contributing User’s Revenue Share.</IonText>
              </IonListHeader>
              <IonItem lines="none">
                    <IonText> a. <span className="underline">Process.</span> </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                      i. <span className="italic">Contributing Users Defined.</span> If you have Posted a Hero, Client, Character, Mission or Non-Mission Contribution to JFH and its is included in a Final Product to be commercialized in accordance with the rights granted in the Terms, then you will be deemed a “Contributing User” for that Final Product.  For avoidance of doubt, (1) the Company will have the right to modify and edit any Hero, Client, Character, Mission or Non-Mission Contribution, and it is only the final version of a Hero, Client, Character, Mission or Non-Mission Contribution that the Company designates and distributes as a “Final Product” that will be commercialized; and (2) only Contributing Users whose Hero, Client, Character, Mission or Non-Mission Contributions are part of a Final Product will be eligible, pursuant to these Monetized Final Product Terms, for compensation resulting from the commercial exploitation of that Final Product.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                      ii. <span className="italic">Contributing User Shares</span>. If any Hero, Client, Character, Mission or Non-Mission Contribution you have Posted or provided to JFH is part of a Final Product that has been distributed, commercialized, and generated Profits (or other tangible benefits), then the Company will notify you via electronic mail to the address associated with your account of the Contributing User’s Share (defined below) that has been conditionally granted to you following monetization. We say conditionally because you are not entitled to any allocation of profits unless you meet the requirements for a Participating Contributing User, as defined in Section 2.a.i below. The date on which the Company finalizes it’s determination for the Contributing Users’ Shares for any particular Final Product is the “<span className="bolder">Allocation Date</span>.”
                  </IonText>
              </IonItem>
          </IonList>
          <IonList>
              <IonListHeader>
                  <IonText className="bolder">
                      2. Allocating Contributing Users’ Revenue Share.
                  </IonText>
              </IonListHeader>
              <IonItem lines="none">
                  <IonText> a. <span className="underline">Eligibility.</span> </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                      i. Each Contributing User to a Final Product will be deemed a “<span className="bolder">Participating Contributing User</span>” and will share in an applicable Contributing Users’ Revenue Share provided he/she:
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                        1. has accepted the Terms;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                        2. complied with the Submission Guidelines;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      3. Posted a Hero, Client, Character, Mission or Non-Mission Contribution to JFH that is included in a Final Product; and
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      4. Provided sufficient identifying information to enable the Company to pay him or her before the close of the period specified in Section 1.a.ii, which at a minimum includes full legal name, valid physical address, and any tax information required by applicable law (“<span className="bolder">Requisite Payment Information</span>”).
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText className="underline">b. Overall Revenue Share Available to Contributing Users.</IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                      i. <span className="italic"> Contributing Users’ Revenue Share</span>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      1.  The “<span className="bolder"> Contributing Users’ Revenue Share</span>” from a Final Product will mean:
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                     a. fifty percent (50%) of the Advertising Revenue from a Final Product that has been distributed and commercialized, if any, and
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                      b. any amount the Company allocates to Contributing Users for:
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'45px'}}>
                      i. products sold or licensed, or tangible expressions that serve as an inspiration for the sale or licensing of a derivative work of a product to third parties for distribution (e.g. on any form of linear or on-demand television), or
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'45px'}}>
                      ii. amounts negotiated by the Company for allocation to Contributing Users as part of a line item in an approved budget for any usage of the Final Product.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'45px'}}>
                  iii. Subparts (i) and (ii) of this Section 2(b)(i)(1)(b) are the “<span className="bolder underline">Discretionary Revenue Share</span>”.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      2. <span className="italic">Retained Profits</span>. The Company will retain the remaining 50% of the Profits, if any, in the case of Section 2(b)(i)(1)(a), and any proceeds the Company receives from any third party, such as a television distribution partner, whether directly or indirect, minus the Discretionary Revenue Share, in the case of Section 2(b)(i)(1)(b) (the “<span className="bolder">Retained Profits</span>”). Because there are unlikely to be Profits for many distributed Final Products due to the high cost of production (e.g. for television), the Company will seek to obtain amounts for the Discretionary Revenue Share through negotiations with distribution partners. This may include specific, budgeted line item amounts that will be dedicated entirely to Contributing Users or specific fees for the Use (as defined below) of a Final Product, whether alone or as part of a larger work. These amounts are likely to vary over time and be subject to the vagaries of arm’s length, free market negotiations.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      3. <span className="italic">Identification of Discretionary Revenue Share</span>. The Company will identify the Discretionary Revenue Share, if any, for each Final Product on JFH.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText>
                      c. Allocations of Participation within Contributing Users’ Revenue Share.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      i. Participating Contributing Users for a Final Product will be entitled to a share of the Contributing Users’ Revenue Share.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      ii. Notwithstanding any other provision of these Monetization Terms, if one or more Contributing Users for a Final Product have failed to provide the Company the Requisite Payment Information by the Allocation Date (an “<span className="bolder">Unpayable Contributing User</span>”), then any conditional allocation made to those Unpayable Contributing User(s) will be set aside until the earlier of (i) the date on which he or she provides the Requisite Payment Information, or (ii) the third anniversary of the Allocation Date. If those Unpayable Contributing User(s) do not provide the Requisite Payment Information by the third anniversary of the Allocation Date, then any conditional allocation made to those Unpayable Contributing User(s) will be deemed null and void and the Company will retain that conditional amount for use in paying contributors in future community projects.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      iii. The share of the Contributing Users’ Revenue Share awarded to a Participating Contributing User will be the “<span className="bolder">Participating Contributing User’s Share</span>.” For purposes of illustration only, if there are 10 Users designated as Participating Contributing Users for a Final Product, then the Company will allocate 100% of the Contributing Users’ Revenue Share among those 10 Participating Contributing Users, which allocation could be made equally among all 10 Participating Contributing Users or in any other manner as the Company may select on a case-by-case and Final Product-by-Final Product basis in its sole discretion. Accordingly, if User A is awarded a Participating Contributing User’s Share of 10% and User B is awarded a Participating Contributing User’s Share of 25% and the applicable Contributing Users’ Revenue Share is $10,000, then User A would receive $1,000 and User B would receive $2,500, upon distribution of the Contributing Users’ Revenue Share. The dollar amount of the Contributing Users’ Revenue Share allocated to a Participating Contributing User for a Final Product is the “<span className="bolder">Distributable Contributing User Profits</span>.” The Distributable Contributing User Profits represent the full amount that is payable to a Participating Contributing User.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      iv. Subsequent to allocation and prior to distribution, any shares of the Contributing Users’ Revenue Share awarded to Participating Contributing Users who are JFH Personnel will be Reallocated back to the remaining eligible non-JFH Personnel Participating Contributing Users within that Contributing Users’ Revenue Share. For purposes of illustration only, if (1) a Contributing Users’ Revenue Share is $10,000, (2) Users A, B, C, D, and E are each allocated 20% or $2,000, and (3) User A is a Company employee, then User A’s $2,000 will be Reallocated back to the Contributing Users’ Revenue Share and Users B-E will each receive 25% or $2,500.
                      <br></br><br/>
                  </IonText>
              </IonItem>

              <IonItem lines="none">
                    <IonText>
                        d.<span className="underline">When Contributing Users’ Revenue Share is Set and Paid</span>.
                        <br></br><br/>
                    </IonText>
              </IonItem>

              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      i. The Contributing Users’ Revenue Share will be calculated semi-annually for each Final Product that is distributed and commercially exploited during that semi-annual period, and the Company will use commercially reasonable efforts to make available to each Participating Contributing User for that Final Product a statement (the “<span className="bolder">Contributing User Semi-Annual Statement</span>”) setting forth the calculation of the Contributing Users’ Revenue Share for that Final Product and his/her applicable Participating Contributing User’s Share, if any. The two semi-annual periods will be the calendar months (1) January through June; and (2) July through December (each a “<span className="bolder">Semi-Annual Period</span>”). Except as provided for below, a Contributing User Semi-Annual Statement will be made available to a Contributing User for a Final Product within one hundred eighty (180) days following the end of each Semi-Annual Period provided the Final Product was commercially exploited during that Semi-Annual Period.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      ii. <span className="italic">Public Disclosures of Contributing User Payments</span>. PLEASE NOTE THAT ALL CONTRIBUTING USER SEMI-ANNUAL STATEMENTS MAY BE MADE PUBLICLY AVAILABLE ON JFH. WE MAY MAKE ALL DISCLOSURES PUBLICLY AVAILABLE IN AN EFFORT TO PROVIDE FULL TRANSPARENCY TO ALL USERS, CONTRIBUTING USERS, PARTICIPATING CONTRIBUTING USERS, USERS, AND THE GENERAL PUBLIC.IF YOU DO NOT WISH TO HAVE YOUR USERNAME AND THE AMOUNTS YOU HAVE BEEN PAID BY THE COMPANY MADE PUBLIC, THEN YOU SHOULD NOT CONTRIBUTE A HERO, CLIENT, CHARACTER, MISSION, OR NON-MISSION CONTRIBUTION TO JFH.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      ii. Please see our <IonRouterLink href="/privacyPolicy">Privacy Policy</IonRouterLink> for more information about how information about you may be shared or disclosed to third parties.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      iv.<span className="italic"> Minimum Balance Requirements</span>. Distributions of a Contributing Users’ Revenue Share for a Final Product will not be payable until the minimum balance of the Contributing Users’ Revenue Share is equal to or greater than Five Hundred Dollars ($500.00) (the “<span className="bolder underline"> Minimum Revenue Share Balance</span>”). Once the Minimum Revenue Share Balance is achieved, all Participating Contributing Users for that Final Product will be eligible for a distribution equal to their Participating Contributing User’s Share; provided, however, that no Contributing User will be entitled to receive payment of his/her Participating Contributing User’s Share until he/she is owed at least Five Dollars ($5.00) in Distributable Contributing User Profits (the “<span className="bolder underline">Minimum Distribution Balance</span>”). We have to impose this limit so that the cost of distributing the Contributing User Revenue Share does not consume Distributable Contributing User Profits.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      v. <span className="italic">Payment Procedure</span>. Payments due Participating Contributing Users pursuant to this paragraph will, except as provided for below, be due within one hundred eighty (180) days following the end of each Semi-Annual Period in which such balance thresholds have been achieved. All payments will be made in U.S. currency unless the Company elects, in its sole discretion, to make payments in other currencies. If the Company elects to make payments in other currencies, then the Participating Contributing User to whom the non-U.S. currency payment is made will bear any and all currency conversion costs.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      vi. <span className="italic">Minimum Balance Carryforwards</span>. If a Minimum Revenue Share Balance for a Final Product or a Minimum Distribution Balance for a Participating Contributing User is not achieved for a Semi-Annual Period, then that Minimum Revenue Share Balance or Minimum Distribution Balance, as the case may be, will be carried over to successive Semi-Annual Periods until such Minimum Revenue Share Balance or Minimum Distribution Balance, as the case may be, is achieved.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      vii. <span className="italic">Discretion to Distribute Sub-Minimum Balances</span>. The Company reserves the right to distribute any amounts allocated to a Participating Contributing User even if the Minimum Distribution Balance for that Participating Contributing User has not been achieved. For example, the Company may choose to make a distribution to a Participating Contributing User who has had a balance under the Minimum Distribution Balance for a period of more than eight (8) calendar quarters. These payments, if made, are entirely discretionary and no Participating Contributing User will have a right to receive a distribution of Distributable Contributing User Profits until a Minimum Distribution Balance is achieved.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      viii. <span className="italic">Offsets and Reimbursements for Overpayments</span>. If the Company overpays a Participating Contributing User for any Final Product, then the Company may offset the amount of its overpayment from any future payments due to that Participating Contributing User, whether from the same Final Product or a different Final Product. If the Company overpays you by more than $1,000.00, then the Company has the right to demand a reimbursement from you, and you agree to reimburse the Company within thirty (30) days of receipt of the Company’s reimbursement request. The Company may make a request to you via electronic mail and you agree to accept communications via electronic mail. If an e-mail does not bounce back upon delivery, then you will be deemed to have received such e-mail one (1) business day following the transmission of that e-mail to you.
                      <br></br><br/>
                  </IonText>

              </IonItem>

              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      ix. <span className="italic">Preparation of Participating Contributing User Semi-Annual Statements; No Contest Rights</span>. The Company will prepare Participating Contributing User Semi-Annual Statements in good faith, and the calculation of the Contributing Users’ Revenue Share will be final, uncontestable, and apply to all Participating Contributing Users participating therein. You are not permitted to contest a Participating Contributing User Semi-Annual Statement or your Participating Contributing User’s Share, but you are always permitted to communicate with the Company during any comment period to suggest new allocations for the Contributing Users’ Revenue Share for a Final Product. You acknowledge and agree that the Company is only able to offer Participating Contributing Users participation in Distributable Contributing User Profits on these terms and conditions and that permitting Participating Contributing Users to contest allocations and payment amounts would be unduly burdensome and expensive for the Company.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      x. <span className="italic">Delays in Distributions</span>. Each Participating Contributing User acknowledges and agrees that the Company is a small, start-up company and that distributing Distributable Contributing User Profits to Participating Contributing Users can be labor intensive, time consuming, and expensive. We also want to make sure we get it right – which, in and of itself, is a difficult and time-consuming process. Each Participating Contributing User therefore agrees that the Company may suspend a distribution of Distributable Contributing User Profits for up to two Semi-Annual Periods if the Company is not reasonably capable, in light of its then-current resources, of making a timely distribution. A determination of reasonable capability will be made, if ever, in the sole discretion of the board of directors of the Company. <span className="bolder underline">Any temporary suspension of distributions pursuant to this Section 2(d)(x) will result simply in a temporary carryforward of any Distributable Contributing User Profits account balances, which will eventually be distributed to you in accordance with these Monetized Final Product Terms</span>. In the event the Company delays the distribution of Distributable Contributing User Profits pursuant to this Section 2(d)(x), the Company will notify the Participating Contributing Users allocated a portion of the Distributable Contributing User Profits subject to delay by electronic mail to the e-mail address associated with each such Contributing User’s account, and each Contributing User hereby consents to receive such notification via electronic mail. JFH may also provide notice by posting a message on JFH, such notice to be in addition to – and not a substitute for – notice by e-mail.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      xi. <span className="italic">Right to Donate</span>. The Company reserves the right, but is under no obligation, to enable Participating Contributing Users to donate payments that would otherwise be distributed to the Participating Contributing Users in part or in whole to a designated 501(c)(3) charitable organization (a “<span className="bolder underline">Designated Charity</span>”). If offered, in each instance the Participating Contributing User must affirmatively designate this choice via site pop-up or other selection tool, and in so doing waives any right to accept and receive Distributions related to periods for which such selection is made. After a Participating Contributing User elects to make a donation, the Company will pay donated amounts to the Designated Charity in lieu of making a payment to the Participating Contributing User, and any obligation to pay such amounts to the Participating Contributing User is extinguished. Participating Contributing Users in the U.S. who make the choice to donate will not receive an IRS form 1099 reflecting donated amounts. Although uncommon, it is the responsibility of any affected US taxpayer on an accrual basis (vs. the cash basis) to ensure any income is properly declared and offset. Non-US taxpayers retain any and all related obligations for tax impacts in their jurisdiction in regard to donations of a Participating Contributing User’s Share.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'15px'}}>
                      e. Definitions.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      i. “<span className="bolder underline">Adjusted Gross Revenues</span>” means Gross Receipts minus Permitted Deductions.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      ii. “<span className="bolder underline">Gross Receipts</span>” means all revenues actually received by the Company directly from the publication, distribution, transmission, performance, merchandising, and any other use (collectively “Use”) of a Final Product in any and all media, whether now known or hereafter created, throughout the world, excluding only amounts received by the Company that are otherwise accounted for as contributions to the Discretionary Revenue Share (e.g., a $50,000 per television episode budget line item allocable exclusively to Contributing Users). For the avoidance of doubt, a Discretionary Revenue Share amount will be allocated directly to Participating Contributing Users and will not be subject to a 50/50 split with the Company.
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      iii.  “<span className="bolder underline">Permitted Deductions</span>” will mean and include any and all costs incurred or borne by the Company or its permitted distributors (each, a “<span className="bolder">Distributor</span>” and, collectively, the “<span className="bolder">Distributors</span>” ) in connection with the Use of a Final Product, including, by way of example and not limitation, the following costs and expenses, whether incurred by the Company, the Distributor of a Final Product or any third party acting on behalf of the Company or a Distributor:
                      <br></br><br/>
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                    1. any and all customary distribution fees;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                    2. the direct costs and expenses of distribution and administration throughout the world (including, without limitation, customary costs such as prints, advertising, dubbing and subtitling, and dues and assessments), and all other costs and expenses which Distributor or any third party distributor may be authorized or permitted to deduct and retain under and pursuant to the terms of any distribution agreement with any Distributors of the Productions;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                     3. any production costs incurred by a Distributor to modify, edit, or complete a Final Product, which Distributor in its good faith judgment elects to incur in order to maximize the receipts, distribution, and Use of a Final Product;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                      4. with respect to any online advertising, any agency or sales commissions, refunds to advertisers, and fees charged by third party syndication partners;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                      5. all sums paid or payable to any actor, writer, producer, manufacturer of sound recording and producing equipment, composer, lyricist, musician, guild, union, labor organization or trustees of any thereof pursuant to the terms of any collective bargaining agreement or otherwise, or any collection society or organization;
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                      6. any and all other sums expended, or liabilities incurred, directly in connection with the exhibition, distribution, marketing, and Use of the Final Product, including, but not limited to, any use, re-use , sales, VAT or other taxes; and
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'35px'}}>
                      7. bad debts.
                  </IonText>
              </IonItem>

              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>

              Notwithstanding the foregoing examples listed in subsections (1) through (7) above of this Section 2.e.iv, the methodology of calculating and applying deductions is intended to permit the deduction of costs and expenses of distributing and exploiting Final Products only from corresponding gross revenues or, in the case of costs and expenditures allocable to more than one Final Product, in such manner as Company determines in its sole good faith discretion between or among Final Products and, accordingly, no “double deductions” will be taken; provided, however, that in the instance of sub-distribution, both the Distributor and sub-distributor will be entitled to take customary fees. For illustrative purposes only, if a third-party Distributor deducts costs of prints and advertising from the receipts that are tendered to the Company as Gross Receipts, then the Company will not separately deduct such costs and expenses in calculating Adjusted Gross Revenues.
                  </IonText>
              </IonItem>
              <IonItem lines="none">
                  <IonText style={{'paddingLeft':'25px'}}>
                      iv. "<span className="bolder underline">Profits</span>” will mean 100% of the Adjusted Gross Revenues.
                  </IonText>
              </IonItem>

          </IonList>

      </IonContent>

);
};

export default Monetization;

import React, {useState} from 'react';

import {IonAlert, IonButton} from "@ionic/react";
import {firestore} from "../../firebaseConfig";
import firebase from "firebase";
import {sendEmailToUserById} from "../../util/sendEmail";

interface ChangeRequestActionProps {
    item: any;
    tab: string;
    index: number;
    dbTableName: string;
}

const ChangeRequestAction: React.FC<ChangeRequestActionProps> = (props) => {

    const [showApproveDeny, setShowApproveDeny] = useState(false);

    function approveChangeRequest(item, collectionName, message) {
        console.log("approve"+collectionName);


        if (collectionName === 'missionUpdateRequests') {
            //update mission CTA
            if (item.requestedChangeDescription !== undefined && item.requestedChangeTitle !== undefined) {
                let missionUpdateRef = firestore.collection('missions').doc(item.contentId);
                let missionUpdateRefOps = missionUpdateRef.set({
                    title: item.requestedChangeTitle,
                    description: item.requestedChangeDescription,
                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                }, {merge: true});
                missionUpdateRefOps.then(() => {
                    console.log("mission updated ");
                  //  sendEmailToUserById(item.requestedById, 'Your ' + collectionName.toUpperCase() + ' Change Request Was Approved', message);
                });
            }
            else if (item.requestedChangeDescription !== undefined ) {
                let missionUpdateRef = firestore.collection('missions').doc(item.contentId);
                let missionUpdateRefOps = missionUpdateRef.set({
                    description: item.requestedChangeDescription,
                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                }, {merge: true});
                missionUpdateRefOps.then(() => {
                    console.log("mission updated ");
                });
            }
            else if (item.requestedChangeTitle !== undefined) {
                let missionUpdateRef = firestore.collection('missions').doc(item.contentId);
                let missionUpdateRefOps = missionUpdateRef.set({
                    title: item.requestedChangeTitle,
                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                }, {merge: true});
                missionUpdateRefOps.then(() => {
                    console.log("mission updated ");
                });
            }
        }
        else if (collectionName === 'contentUpdateRequest') {
            //update content description
            let contentUpdateRef = firestore.collection('content').doc(item.contentId);
            let contentUpdateRefOps = contentUpdateRef.set({
                contentDescription: item.requestedChangeDescription,
                lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
            }, {merge: true});
            contentUpdateRefOps.then(() => {
                console.log("content updated ");
            });
        }
        else if (collectionName === 'waitlistRequests') {
            //update character name
            let userUpdateRef = firestore.collection('users').doc(item.requestedById);
            if (item.requestedByRole === 'client') {
                let userUpdateRefOps = userUpdateRef.set({
                    characterName: item.requestedChange,
                    characterName_nocase: item.requestedChange.toLowerCase(),
                    clientName: item.requestedChange,
                    clientName_nocase: item.requestedChange.toLowerCase(),
                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                }, {merge: true});
                userUpdateRefOps.then(() => {
                    console.log("character name updated ");
                });
            }
            else {
                let userUpdateRefOps = userUpdateRef.set({
                    characterName: item.requestedChange,
                    characterName_nocase: item.requestedChange.toLowerCase(),
                    heroName: item.requestedChange,
                    heroName_nocase: item.requestedChange.toLowerCase(),
                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                }, {merge: true});
                userUpdateRefOps.then(() => {
                    console.log("character name updated ");
                });
            }

        }

        let changeRequestRef = firestore.collection(collectionName).doc(item.uid);
        let changeRequestRefOps = changeRequestRef.set({
            status: 'approved',
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        }, {merge: true});

        let emailSubject = 'Your ';
        if (collectionName === 'missionUpdateRequests') {
            emailSubject = emailSubject + ' Mission ';
        }
        else if (collectionName === 'contentUpdateRequest') {
            emailSubject = emailSubject + ' Content Description ';
        }
        else if (collectionName === 'waitlistRequests') {
            emailSubject = emailSubject + ' Character Name';
        }
        emailSubject = emailSubject + ' Change Request Was Approved';

        changeRequestRefOps.then(() => {
            console.log("send email");
            sendEmailToUserById(item.requestedById, emailSubject, message);
        });
    }


    function denyChangeRequest(item,collectionName, message) {
        console.log("deny"+collectionName);
        let changeRequestRef = firestore.collection(collectionName).doc(item.uid);
        let changeRequestRefOps = changeRequestRef.set({
            status: 'denied',
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        }, {merge: true});

        changeRequestRefOps.then(() => {
            console.log("send email");
            sendEmailToUserById(item.requestedById, 'Your ' + collectionName.toUpperCase() + ' Change Request Was Denied', message);
        });
    }

    return (
        <>
        <IonButton onClick={() => setShowApproveDeny(true)}> Approve/Deny</IonButton>
        <IonAlert
            isOpen={showApproveDeny}
            header="These changes affect the entire universe."
            buttons={[

                {
                    text: 'Approve',
                    handler: (data) => {

                        approveChangeRequest(props.item, props.dbTableName, data['message']);
                        // sendEmail(props.item, props.dbTableName, data['message']);
                    }
                },
                {
                    text: 'Deny',
                    handler: (data) => {

                        denyChangeRequest(props.item, props.dbTableName, data['message']);
                        // sendEmail(props.item, props.dbTableName, data['message']);
                    }
                },
                'Cancel'
            ]}
            inputs={[
                {
                    type: "textarea",
                    name: 'message',
                    placeholder: `Send A Message`
                }
            ]}
            onDidDismiss={() => setShowApproveDeny(false)}
        />
        </>
    );
};

export default ChangeRequestAction;


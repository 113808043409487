import React, {useEffect, useState } from 'react';
import {

    IonRow,
    IonSearchbar,

    IonCol,
    IonText,
    IonLabel,
    IonCard
} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import VideoContentPlayer from "../../components/Player/VideoContentPlayer";
import ChangeRequestAction from './ChangeRequestAction';
import Moment from 'react-moment';



interface CharacterNameRequestsProps {

}


const CharacterNameRequests: React.FC<CharacterNameRequestsProps> = (props) => {
    const [characterNameRequestsList, setCharacterNameRequestsList] = useState([]);

    const [searchByCharacterName, setSearchByCharacterName] = useState('');

    useEffect(() => {
        console.log("props show", props);
        let characterNameChangeRef =  firestore.collection('waitlistRequests').orderBy("timeCreated", "desc")
            .onSnapshot(snapshot => {
                let changeList = [];

                snapshot.forEach(doc =>

                    changeList.push({ ...doc.data(), uid: doc.id }),
                );
                setCharacterNameRequestsList(changeList);

            });



    }, [props]);

    return (
        <>
            <IonSearchbar value={searchByCharacterName} onIonChange={e => setSearchByCharacterName(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}/>

            {characterNameRequestsList.map((nameRequestItem, idxChar) => {
                return (
                    <div key={idxChar}>
                        <IonRow style={{fontSize:'8px', textAlign: 'center'}} className="ion-justify-content-center">

                            <Moment format='MMMM Do YYYY, h:mm a' style={{fontSize:'8px', opacity: '.5'}}>{nameRequestItem.timeCreated.toDate()}</Moment>

                        </IonRow>
                    <IonCard>
                        <VideoContentPlayer item={nameRequestItem} tab={'brandAdminCharacters'} index={idxChar}/>

                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Original Character Name </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText> {nameRequestItem.requestedByCharacter} </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Requested  New Character Name </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText> {nameRequestItem.requestedChange} </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            </IonCol>
                            <IonCol>
                                {nameRequestItem.status === 'pending' ?
                                     <ChangeRequestAction item={nameRequestItem} tab={'characterNameRequest'} dbTableName={'waitlistRequests'} index={idxChar}/>
                                     :<IonLabel>{nameRequestItem.status?.toUpperCase()}</IonLabel>
                                }
                            </IonCol>
                        </IonRow>
                    </IonCard>

                </div>
                )
            })}

        </>
    );

}
export default CharacterNameRequests;

import { IonContent, IonPage, IonCol, IonLabel, IonGrid, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import Header from '../components/Header';


 class ApprovalPending extends Component {

  render() {
    return (
      <IonPage>
         <Header menu={true}/>
        <IonContent className="app ion-padding ion-text-center">
            <IonGrid>
                <IonRow></IonRow>
                <IonRow>
                </IonRow>
                <IonRow>
                </IonRow>
                <IonRow></IonRow>
                <IonRow></IonRow>
                <IonRow></IonRow>
                <IonRow></IonRow>
                <IonRow></IonRow>
                <IonRow></IonRow>
                <IonRow>
                    <IonCol>
                    <IonLabel className="appText"> This is gonna be great!  </IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    <IonLabel className="appText"> Pending approval ...  </IonLabel>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
}
export default ApprovalPending;

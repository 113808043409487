
import React, {Component, useEffect, useState} from 'react';
import {  IonButton, IonIcon, IonFooter, IonToolbar, IonImg, IonGrid, IonRow, IonModal, IonContent, IonLabel, IonHeader, IonText, IonCol } from '@ionic/react';
import {globeOutline, playOutline, personCircleOutline} from 'ionicons/icons';
import './Footer.css';
import { toast } from '../toast';
import JoinTest from './JoinTest';
import { sendJoinTestTeamEmail } from '../util/sendEmail';
import authWrapper from './authWrapper';
import JoinPopup from '../pages/Chat/JoinPopup';
import {firestore, getCurrentUser} from "../firebaseConfig";

interface FooterProps {
    user:any;
}
export const Footer:React.FC<FooterProps> = (props) => {


    const goHire = () => {
         window.location.href="/hire";
        // window.location.href="/premiumMissions";
    }

    const goWorld =() => {
        window.location.href="/wurld";
    }


    const [showJoin, setShowJoin] = useState(false);
    const [showPending, setShowPending] = useState(false);

    const joinTestTeam = () => {
        sendJoinTestTeamEmail(props.user);
        setShowJoin(false);
    }


    useEffect(() => {
        setInterval(() => {
            let currentUser = getCurrentUser().then((doc) => {
                if (doc) {
                    let userRef = firestore.doc(`users/${doc['uid']}`);

                    let currentUserFromDB = userRef.get().then(docDB => {
                        if (docDB) {
                            //console.log("docDB", docDB);
                            if (docDB.data() && docDB.data()['pendingRivalRequests'] !== undefined) {
                                setShowPending(docDB.data()['pendingRivalRequests']);

                            }

                            //console.log("check unread", docDB.data() && docDB.data()['unread']);
                        }

                    });
                }

            });
        }, 2000);
        // if (showUnread) {
        //     notification('You have new messages');
        // }
    });
    return (


        <IonFooter>
            <IonModal isOpen={showJoin}
                      cssClass='join-custom-class'
                      onDidDismiss={() => setShowJoin(false)}>
                <IonHeader>
                    <IonRow style={{padding: 10}}>
                        <IonText className="appTextMain center">
                            {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                        </IonText>
                    </IonRow>
                </IonHeader>
                <IonContent className='join-custom-class'
                            scrollEvents={true}
                            onIonScrollStart={() => {
                            }}
                            onIonScroll={() => {
                            }}
                            onIonScrollEnd={() => {
                            }} scrollX scrollY>

                    {props.user.testTeamMember
                        ?<><IonRow><IonCol></IonCol></IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                            <IonRow>
                              <IonText className="appTextMain center">Check your email for the invite! </IonText>
                            </IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                        </>
                        :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                </IonContent>
                <IonFooter>
                    <IonRow className="ion-justify-content-center">

                        {props.user.testTeamMember === undefined  &&
                        <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                            <IonLabel className="italic bolder">
                                Join
                            </IonLabel>
                        </IonButton>}
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonToolbar className="app">
                <IonGrid>
                    <IonRow>
                        <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                            <div className="footerBtnContainer">
                                <IonButton onClick={goWorld} className="footerBtn">
                                {/*<IonButton onClick={() => setShowJoin(true)} expand="block" className="footerBtn">*/}
                                    <IonIcon style={{'width':'30px', 'height':'40px'}} icon={globeOutline} className="menuButton" />
                                </IonButton>
                            </div>
                            <div className="footerBtnContainer">
                                <IonButton href="/watch" className="footerBtn">
                                {/*<IonButton onClick={() => setShowJoin(true)} expand="block" className="footerBtn">*/}
                                    <IonIcon style={{'width':'35px'}} size="large" icon={playOutline} className="menuButton"/>
                                </IonButton>
                            </div>
                            <div className="footerBtnContainer">
                                {/*<IonButton href="/premiumMissions" className="footerBtn">*/}
                                <IonButton onClick={goHire} className="footerBtn">
                                    <IonImg onClick={goHire} style={{'width':'25px'}} src={"assets/img/harmony_white.svg"} className="menuButton"/>
                                </IonButton>
                            </div>
                            <div className="footerBtnContainer">
                                {/*<IonButton onClick={() => setShowJoin(true)} expand="block" className="footerBtn">*/}
                                <IonButton href="/challenges" className="footerBtn">
                                    <IonImg style={{'width':'25px'}} src={"assets/img/super.png"}  className="menuButton"/>
                                </IonButton>
                            </div>
                            <div className="footerBtnContainer">
                                <IonButton  href="/profile" className="footerBtn">
                                    <IonIcon style={{'width':'35px'}} size="large" icon={personCircleOutline} class="menuButton"  />
                                    {showPending ?
                                        <sub
                                            style={
                                                {color:'red',
                                                    height:'10px',
                                                    width:'10px',
                                                    backgroundColor: 'red',
                                                    borderRadius: '50%'}
                                            }/>
                                        : ''}
                                </IonButton>
                            </div>
                        </div>
                    </IonRow>


                </IonGrid>

            </IonToolbar>
        </IonFooter>
    );
};

export default authWrapper(Footer);


import React, { useState } from 'react';
import { storageRef } from '../../firebaseConfig';
import {IonAvatar, IonIcon} from "@ionic/react";
import {playOutline} from "ionicons/icons";



const ChallengeVideoExampleContainer: React.FC = () => {

const [contentUrl, setContentUrl] = useState('');
const [posterContentUrl, setPosterContentUrl] = useState('');

let assetName="app/walkthrough/Challenge-Example-GLITTER.mp4";
let posterAssetName="app/watch/03_Glitter_Girl_Challenge_Origin_THUMBNAIL.jpg";

storageRef.child(assetName).getDownloadURL().then(function(url) {
    setContentUrl(url);
    let img = document.getElementById('challengeVideo');
    img.setAttribute("src", url);
    }).catch(function(error) {
    // Handle any errors
    });

    storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
        setPosterContentUrl(posterUrl);
        let img = document.getElementById('challengeVideo');
        img.setAttribute("poster", posterUrl);
    }).catch(function(error) {
        // Handle any errors
    });

    function onVideoClick(e) {
        let videoEl = document.getElementById('challengeVideo') as HTMLVideoElement;
        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    }
  return (

      <div className="video-wrapper" >
          <div className="overlay"
               onClick={onVideoClick}
          >
              <IonAvatar className="right playBtn">
                  <IonIcon className="highContrast" style={{paddingTop: 2}} icon={playOutline}/>
              </IonAvatar>

          </div>
          <video id="challengeVideo" poster={posterContentUrl} controlsList="nodownload">
              <source src={contentUrl} type="video/mp4" />
          </video>

      </div>
  );
};

export default ChallengeVideoExampleContainer;


import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonPage,
    IonSegmentButton, IonLabel, IonSegment
} from '@ionic/react';
import User from '../../models/User';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from '../../components/authWrapper';


import ViewContent from './ViewContent';

import UserChangeRequests from './UserChangeRequests';


interface UserAdminViewState {
    loading: boolean;
    users: User[];
    user: any;
}

const UserAdminView:React.FC<UserAdminViewState> = (props) => {

    const [currentTab, setCurrentTab]= useState('pendingChanges');
    

    function changeSegmentContent(selectedSegment) {

        let contentContainer = document.getElementById(selectedSegment + 'Feed');
        contentContainer.style.display = 'block';
        let contentFeedContainer = document.getElementById('contentFeed');
        let pendingChangesContainer = document.getElementById('pendingChangesFeed');

        if (selectedSegment === 'content') {
            pendingChangesContainer.style.display='none';
            contentFeedContainer.style.display='block';
            setCurrentTab('content');
        } else if (selectedSegment === 'pendingChanges') {
            // setShowJoin(true);
            contentFeedContainer.style.display='none';
            pendingChangesContainer.style.display='block';
            setCurrentTab('pendingChanges');
        }
    }
    return (

        <IonPage>


            <Header />


            <IonContent>


                <IonSegment value={currentTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)} >
                    <IonSegmentButton value="pendingChanges">
                        <IonLabel className="labelText"> Change Requests </IonLabel>
                    </IonSegmentButton>
                </IonSegment>


                {/*<IonContent id="contentFeed" scrollX scrollY>*/}
                {/*    { currentTab === 'content' &&*/}
                {/*    <ViewContent />}*/}
                {/*</IonContent>*/}
                <IonContent id="pendingChangesFeed"  scrollX scrollY>

                    { currentTab === 'pendingChanges' &&
                    <UserChangeRequests user={props.user}/>}
                </IonContent>

            </IonContent>
            <Footer/>
        </IonPage>

    );
}

export default authWrapper(UserAdminView);

import React, { useState } from 'react';
import { storageRef } from '../../firebaseConfig';

import { Plugins } from '@capacitor/core';
import {playBackCircle, playForward, playOutline} from "ionicons/icons";
import {IonIcon, IonRow} from "@ionic/react";
const { CapacitorVideoPlayer, Device } = Plugins;


const TrainingVideoExampleContainer: React.FC = () => {

const [contentUrl, setContentUrl] = useState('');
const [posterContentUrl, setPosterContentUrl] = useState('');

let assetName="app/walkthrough/JFH_Training-Example.mp4";
let posterAssetName="app/walkthrough/brendanweavertraining.jpg"

storageRef.child(assetName).getDownloadURL().then(function(url) {

    setContentUrl(url);
    let img = document.getElementById('trainingVideo');
    img.setAttribute("src", url);
    }).catch(function(error) {
    // Handle any errors
    });

    storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
        setPosterContentUrl(posterUrl);
        let img = document.getElementById('trainingVideo');
        img.setAttribute("poster", posterUrl);
    }).catch(function(error) {
        // Handle any errors
    });

    function onVideoClick(e) {
        let video  = document.getElementById('trainingVideo') as HTMLVideoElement;
        video.play().then(() => {console.log('video played')});

    }
  return (

      <div className="video-wrapper">

          <div className="overlay"
               onClick={onVideoClick}
          >

              <div style={{margin: 5}} className="left deliveryModuleTitle bolder">

                  <IonRow>
                      <IonIcon style={{padding: 2}} item-end icon={playOutline}/>
                  </IonRow>
              </div>

          </div>
          <video id="trainingVideo" poster={posterContentUrl} controlsList="nodownload">
              <source src={contentUrl} type="video/mp4" />
          </video>


      </div>
  );
};

export default TrainingVideoExampleContainer;


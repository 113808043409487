import React, {useState} from 'react';
import {
    IonContent,
    IonText,

    IonLabel,
    IonRow,

    IonModal,
    IonHeader, IonButton,  IonImg, IonFooter
} from '@ionic/react';
import { sendJoinTestTeamEmail } from '../../util/sendEmail';



interface JoinPopupProps {
    user: any;
}

const JoinPopup: React.FC<JoinPopupProps> = (props) => {
    const [showJoinTest, setShowJoinTest] = useState(true);

    const joinTestTeam = () => {
        //console.log("send email ", props.user);
        sendJoinTestTeamEmail(props.user);
        setShowJoinTest(false);
    }

    return (
        <IonModal isOpen={showJoinTest}
                  cssClass='join-custom-class'
                  onDidDismiss={() => setShowJoinTest(false)}>
            <IonHeader>
                <IonRow style={{padding: 10}}>
                    <IonText className="appTextMain center">
                        To gain access to this section, join the JFH App Test Team.

                    </IonText>
                </IonRow>
            </IonHeader>
            <IonContent className="join-custom-class"
                        scrollEvents={true}
                        onIonScrollStart={() => {
                        }}
                        onIonScroll={() => {
                        }}
                        onIonScrollEnd={() => {
                        }} scrollX scrollY>

                <IonImg src={"/assets/img/JoinTestTeam.png"} style={{height:'150px', width:'300'}}></IonImg>

            </IonContent>
            <IonFooter>
                <IonRow className="ion-justify-content-center">

                    <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                        <IonLabel className="italic bolder">
                            Join
                        </IonLabel>
                    </IonButton>
                </IonRow>
            </IonFooter>
        </IonModal>
    );
};

export default JoinPopup;

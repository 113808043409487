import React, {useState} from 'react';
import {
    IonContent,
    IonPage,

    IonRow,
    IonCol,
    IonHeader,

    IonButtons,
    IonIcon,
    IonList,
    IonItem,
    IonAvatar,
    IonImg,
    IonText,

    IonLabel,
    IonButton,
    IonItemDivider,
    IonToggle,
    IonInput,

    IonGrid,
    IonSegment,
    IonSegmentButton
} from '@ionic/react';
import { firestore } from '../../firebaseConfig';
import {sendOutline} from 'ionicons/icons';
import {toast} from "../../toast";
import {withRouter} from "react-router";
import authWrapper from "../../components/authWrapper";
import firebase from "firebase";
import ChatSubHeader from "../../components/ChatSubHeader";
import Header from '../../components/Header';
import ChatWelcomePopup from "./ChatWelcomePopup";
import Footer from '../../components/Footer';
import Conversations from './Conversations';
import UserAvatar from "../../components/UserAvatar";
import Moment from "react-moment";
import JoinPopup from './JoinPopup';
import { sendWelcomeDM } from './chat';


class ChatHomePage extends React.Component {

    state: any;
    messageEnd: any;
    messageInput: any;
    listEnd: any;

    constructor(props) {
        super(props);

        // this.messageEnd = React.createRef();


        this.state = {
            loading: false,
            chats: [],
            searchText: '',
            checked: true,
            messageContent: '',
            user: props.user,
            showWelcomePopup: false,
            el: '',
            selectedTab: props.selectedTab !== undefined ? props.selectedTab : 'castChat',
            showJoin: false,
        };


    }

    scrollToBottom = () => {
        this.listEnd.scrollIntoView({ behavior: "smooth" });
        this.messageEnd.scrollIntoView({ behavior: "smooth" });
        this.messageInput.focus();
    }

    componentDidMount() {
        // this.setState({ loading: true, checked: true, messageContent: ''});

        if (this.state.user.pages !== undefined ) {
            console.log("pages ", this.state.user.pages);
            let pagesList = this.state.user.pages.filter(item => item.castChat === true);
            if (pagesList && pagesList.length === 1) {
                this.state.showWelcomePopup = false;
            }
            else {
                this.state.showWelcomePopup = true;

                let userDocRef = firestore.collection('users').doc(this.state.user.uid);
                let pagesData = {
                    castChat: true,
                }
                let arrUnion = userDocRef.update({
                    pages: firebase.firestore.FieldValue.arrayUnion(pagesData)
                }).then(() =>
                    console.log("add cast chat to pages visited"))
                    .catch((err) => console.log(err));

                // add message from Justice F Hire
                let botMessageContent = this.state.user.characterName ? this.state.user.characterName : (this.state.user.heroName ? this.state.user.heroName: this.state.user.clientName);
                let botMessageContentRefId = this.state.user.uid;
                const chatFeedItemRef = firestore.collection('chatFeedItem').add({
                    messageContent: botMessageContent+' has entered JFH cast chat for the first time. Say hi.',
                    inCharacter: true,
                    senderId: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
                    senderImage: 'profiles/thumbnails/1618265515397.jpeg',
                    sendTime: firebase.firestore.FieldValue.serverTimestamp(),
                    senderHeroName: 'Justice F Hire',
                    senderPhotoURL: 'brand/icon.png',
                    firstVisitName: botMessageContent,
                    firstVisitId: botMessageContentRefId,
                }).then(()=> console.log("bot message sent"));
                //create DM and send notification
                sendWelcomeDM(this.state.user, this.state.history);
            }
        }
        else {
            this.state.showWelcomePopup = true;

            let userDocRef = firestore.collection('users').doc(this.state.user.uid);
            let pagesData = {
                castChat: true,
            }
            let arrUnion = userDocRef.update({
                pages: firebase.firestore.FieldValue.arrayUnion(pagesData)
            }).then(() =>
                console.log("add cast chat to pages visited"))
                .catch((err) => console.log(err));

            // add message from Justice F Hire
            let botMessageContent = this.state.user.characterName ? this.state.user.characterName : (this.state.user.heroName ? this.state.user.heroName: this.state.user.clientName);
            let botMessageContentRefId = this.state.user.uid;
            const chatFeedItemRef = firestore.collection('chatFeedItem').add({
                messageContent: botMessageContent+' has entered JFH cast chat for the first time. Say hi.',
                inCharacter: true,
                senderId: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
                senderImage: 'profiles/thumbnails/1618265515397.jpeg',
                sendTime: firebase.firestore.FieldValue.serverTimestamp(),
                senderHeroName: 'Justice F Hire',
                senderPhotoURL: 'brand/icon.png',
                firstVisitName: botMessageContent,
                firstVisitId: botMessageContentRefId,
            }).then(()=> console.log("bot message sent"));

            sendWelcomeDM(this.state.user, this.state.history);

        }

        firestore.collection('chatFeedItem').orderBy("sendTime", "asc")
            .onSnapshot(snapshot => {
                let chats = [];

                snapshot.forEach(doc =>
                    chats.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    chats,
                    loading: false,
                    // searchText: '',
                    // messageContent: '',
                });
            });


        setTimeout(this.scrollToBottom, 3000);
    }


    componentDidUpdate() {
        console.log("update props", this.props);
        this.scrollToBottom();
    }

    toggleMessageType = () => {
        this.setState({
            checked: !this.state.checked
        })

    }

    toggleShowJoin = () => {
        this.setState({
            showJoin: !this.state.showJoin
        })

    }

    ionKeyboardDidShow = (event: any) => {

    }

    setMessageContent = (event: any) => {
        this.setState({ messageContent: event.target.value });
    };

    createChatFeedItem = (senderPhotoURL, senderHeroName, senderId,photoURL,messageContent, checked) => {

        if (messageContent == '') {
            return toast('Write a message before sending ');
        }

        const chatFeedItemRef = firestore.collection('chatFeedItem').add({
            senderId: senderId,
            messageContent: messageContent,
            inCharacter: checked,
            senderImage: photoURL,
            sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
            senderHeroName: senderHeroName,
            senderPhotoURL: senderPhotoURL,
        }).then(ref => {
            //
            // console.log('Added message with ID: ', ref.id);
            // window.location.reload(false);
            this.setState({messageContent:''});

        });

        // let contentArea = document.getElementById('castChatFeed');
        // contentArea.scroll(0,9999);
        this.scrollToBottom();
        
    }

    viewProfile = (profileId) => {
        if (profileId!=undefined) {
            window.location.href=`/viewAssociation/${profileId}`;
        }
    }

    // downloadPictureContent(photoPath, id) {
    //     if(photoPath !== '' && photoPath !== undefined) {
    //         storageRef.child(photoPath).getDownloadURL().then(function (pathUrl) {
    //             let img = document.getElementById('profile'+id);
    //             img.setAttribute('src', pathUrl);
    //         }).catch(function (error) {
    //             // Handle any errors
    //         });
    //     }
    //
    //     return '';
    // }

    viewProfileAssociation(id) {
        if (id != undefined) {
            window.location.href='/viewAssociation/'+id;
        }
    }

     changeSegmentContent = (selectedSegment) => {
        //console.log("Change ", selectedSegment);
        let contentContainer = document.getElementById(selectedSegment + 'Feed');
        contentContainer.style.display = 'block';
        let castChatFeedContainer = document.getElementById('castChatFeed');
        let dmFeedContainer = document.getElementById('dmFeed');
        let messageBoxContainer = document.getElementById('messageBox');
        if (selectedSegment === 'castChat') {
            dmFeedContainer.style.display = 'none';
            if (this && this.messageEnd) {
                this.messageEnd.style.display='block';
            }

            if (this) {
                this.setState({
                    selectedTab: 'castChat',
                });
            }


        } else if (selectedSegment === 'dm') {
            this.setState({
                selectedTab: 'dm',
 
            });
             castChatFeedContainer.style.display = 'none';
             this.messageEnd.style.display='none';


        }
    }

    render() {

        const { chats, loading, searchText, checked, messageContent, showWelcomePopup, user, selectedTab, showJoin } = this.state;

        return (
            
            <IonPage style={{'--overflow-scroll': 'true'}}>
                <Header menu={true} selectedTab={selectedTab} />
                <IonHeader>
                    <IonSegment value={selectedTab} scrollable onIonChange={(e) => this.changeSegmentContent(e.detail.value as any)}>
                        <IonSegmentButton value="castChat" onClick={this.scrollToBottom}>
                            <IonLabel className="labelText">Cast Chat </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="dm">
                            <IonLabel className="labelText">DM</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonHeader>

                <IonContent id={"castChatFeed"} className="ion-padding-bottom ion-padding-left"
                    // onIonScrollStart={() => {}}
                            // onIonScroll={() => {}}
                            // onIonScrollEnd={() => {}
                            scrollX
                            >
                    {showWelcomePopup && <ChatWelcomePopup/>}
                    {/*<IonButton onClick={this.scrollToBottom}>Scroll</IonButton>*/}
                    {showJoin && <JoinPopup user={user}/>}
                    <IonList lines="none" id="messageList">
                        {chats.length === 0 &&
                        <IonItemDivider>Welcome To JFH CAST CHAT</IonItemDivider>}
                        {chats && chats.map((chatItem, chatIdx) => {
                            return (
                                <IonItem key={chatIdx} lines="none" className="ion-text-wrap">
                                    <IonGrid style={{'paddingLeft':'0px'}}>
                                        <IonRow>
                                            <IonCol size="2">
                                                <IonAvatar>
                                                    <IonImg style={{'height':'50px','width':'50px', 'paddingLeft':'0px', background:'#005494'}} id={'profile'+chatItem.uid+chatIdx} src={chatItem.senderPhotoURL ? "https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/"+`${encodeURIComponent(chatItem.senderPhotoURL)}`+"?alt=media&token=48cd10e9-8254-44fb-9755-6bcda1cfa957": "https://www.gravatar.com/avatar?d=mm"}
                                                            onClick={() => this.viewProfile(chatItem.senderId)}/>
                                                </IonAvatar>
                                            </IonCol>
                                            <IonCol size="10">

                                                <IonRow>
                                                    <IonText style={{'fontWeight':'bold'}} onClick={() => this.viewProfile(chatItem.senderId)}>{chatItem.senderHeroName}</IonText>
                                                </IonRow>
                                                <IonRow className="castChatText">
                                                    {chatItem.firstVisitId !== undefined && chatItem.firstVisitId!=='' &&
                                                    chatItem.inCharacter  &&   <IonText className="chatText brandText in-character" onClick={() => this.viewProfile(chatItem.firstVisitId)}>
                                                        <span className="bolder"> {chatItem.firstVisitName}</span> has entered JFH cast chat for the first time. Say hi.
                                                    </IonText>
                                                    }

                                                    {chatItem.firstVisitId === undefined && chatItem.messageContent.indexOf('has entered JFH cast ')>0 &&
                                                    chatItem.inCharacter  &&   <IonText className="chatText in-character" onClick={() => this.viewProfile(chatItem.firstVisitId)}>
                                                        {chatItem.messageContent}
                                                    </IonText>
                                                    }

                                                    {chatItem.firstVisitId === undefined && (chatItem.messageContent.indexOf('has entered JFH cast ')<0) &&
                                                    !chatItem.inCharacter  &&   <IonText className="chatText">
                                                        <p> {chatItem.messageContent}</p>
                                                    </IonText>
                                                    }

                                                    {chatItem.firstVisitId === undefined && (chatItem.messageContent.indexOf('has entered JFH cast ') <0) &&
                                                    chatItem.inCharacter  &&   <IonText className="chatText">
                                                        <p className="in-character"> {chatItem.messageContent}</p>
                                                    </IonText>
                                                    }
                                                </IonRow>
                                                <IonRow style={{'fontSize':'8px'}}>
                                                    <Moment format='MMMM Do YYYY, h:mm a' style={{'fontSize':'8px'}}>{chatItem.sendTime?.toDate()}</Moment>
                                                </IonRow>
                                            </IonCol>

                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            );
                        })}
                    </IonList>
                    <div ref={(el) => { this.listEnd = el; }}></div>
                </IonContent>
                <IonContent id="dmFeed" style={{display: 'none', paddingBottom:'4px'}} className="ion-padding-bottom ion-padding-right">
                    <ChatSubHeader/>
                    <Conversations/>
                </IonContent>

                <IonItem className="messageBox"
                    ref={(el) => { this.messageEnd = el; }}>
                    <UserAvatar userPhotoURL={user.photoURL} userId={user.uid} userHeroName={user.characterName} userRole={user.role} showMoreInfo={false}/>

                    <IonInput
                        value={messageContent}
                        onIonChange={this.setMessageContent}
                        autofocus
                        autocapitalize="on"
                        type="text"
                        placeholder={"Write messages here ... "}
                        className="input ion-padding-horizontal"
                        style={{'fontSize':'12px', '--overflow':'hidden'}}
                            ref={(el) => { this.messageInput = el; }}
                    />
                    <IonButtons slot="end">
                        <IonCol>
                            <IonRow>
                                <IonLabel style={{'fontSize':'7px', minWidth: 64}}>{checked ? `In-Character`: 'Out-Of-Character'}</IonLabel>
                            </IonRow>
                            <IonRow>
                                <IonToggle checked={checked} onIonChange={this.toggleMessageType} />
                            </IonRow>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => this.createChatFeedItem(this.state.user.photoURL, this.state.user.characterName, this.state.user.uid, this.state.user.photoURL, this.state.messageContent,checked)}>
                                <IonIcon icon={sendOutline}/>
                            </IonButton>
                        </IonCol>
                    </IonButtons>
                </IonItem>

                <Footer/>
            </IonPage>
        );
    }
}
export default authWrapper(withRouter(ChatHomePage));

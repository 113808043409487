import React, {useEffect, useRef, useState} from 'react';
import { firestore, storageRef } from '../firebaseConfig';
import { IonActionSheet, IonRow, IonIcon, IonLabel, IonAvatar } from '@ionic/react';
import {closeOutline, mail, pauseOutline, playOutline} from 'ionicons/icons';
import {playVideoRef} from "../util/videoUtils";
import {Device} from "@capacitor/core";
import {toast} from "../toast";

interface ProfileVideoAvatarProps {
    photoUrl: string,
    userId: string;
    role: string;
    videoPath: string;
    index: string;
    tab: string;
}

const ProfileVideoAvatar: React.FC<ProfileVideoAvatarProps> = (props) => {

    //console.log("video path", props.videoPath);

    const [showActionSheet, setShowActionSheet] = useState(false);
    const [platformInfoName, setPlatformInfoName] = useState('web');

    const videoRef = useRef(null);

    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            // if (info.platform === 'web' || info.platform ==='android') {
            //
            // }
            setPlatformInfoName(info.operatingSystem);
        } );

    }, [props.videoPath]);

    function downloadVideoContent(videoPath) {
        storageRef.child(videoPath).getDownloadURL().then(function (url) {
            // This can be downloaded directly:
            // let xhr = new XMLHttpRequest();
            // xhr.responseType = 'blob';
            // xhr.onload = function (event) {
            //     let blob = xhr.response;
            // };
            // xhr.open('GET', url);
            // xhr.send();

            // Or inserted into an <img> element:
            let img = document.getElementById('profileVideo');
            img.setAttribute("src", url);
        }).catch(function (error) {
            // Handle any errors
        });
    }

    function downloadPictureContent(photoPath) {
        storageRef.child(photoPath).getDownloadURL().then(function (pathUrl) {
            // This can be downloaded directly:

            // Or inserted into an <img> element:
            let img = document.getElementById('profileVideo');
            img.setAttribute("poster", pathUrl);
        }).catch(function (error) {
            // Handle any errors
        });
    }

    if (props.videoPath !== undefined) {
        downloadVideoContent(props.videoPath)
    } else {

        if (props.userId !== undefined) {
            let profileVideoStorageRef = firestore.collection("heroes");
            if (props.role === 'client') {
                profileVideoStorageRef = firestore.collection("clients");
            }

            let query = profileVideoStorageRef.doc(props.userId);
            query.get()
                .then(querySnapshot => {
                    let profileVideoPath = querySnapshot.data()['profileVideo'];
                    console.log("profile video path", profileVideoPath);
                    if (profileVideoPath!==undefined) {
                        downloadVideoContent(profileVideoPath);

                    }
                }).catch(function (error) {
                console.log("error downloading video content");
            });
        }

    }

    if (props.photoUrl != undefined && props.photoUrl!== '') {
        console.log("profile photo", props.photoUrl);
        downloadPictureContent(props.photoUrl);
    }



    return (

        <div className="profileVideo-wrapper">
            <div className="overlay"
                 onClick={(e) => playVideoRef(e, platformInfoName, props, videoRef)}
            >
                <IonAvatar className="accountPlayBtn" id={'play'+props.index+props.tab}>
                    <IonIcon className="highContrast" style={{paddingTop: 2, paddingLeft:'20%'}} icon={playOutline}/>
                </IonAvatar>

                <IonAvatar id={'stop'+props.index+props.tab} style={{display: 'none'}} className="accountPlayBtn">
                    <IonIcon className="highContrast" style={{paddingTop: 2, paddingLeft:'20%'}} icon={pauseOutline} />

                </IonAvatar>
            </div>
            <video id="profileVideo" poster={props.photoUrl} controlsList="nodownload" controls={false} ref={videoRef} onEnded={e=> videoRef.current.load()} onPause={e=> {videoRef.current.load(); videoRef.current.controls=false;}}>
                <source src={props.videoPath} type="video/mp4" />
            </video>

        </div>
    );
};

export default ProfileVideoAvatar;


import {firestore} from "../../firebaseConfig";

export function markProfileComplete(userID) {
    let userDocRef = firestore.collection('users').doc(userID);
    let userDocRefOps = userDocRef.set({
        complete: true,
    }, {merge: true});

    userDocRefOps.then(() => {
        //window.location.reload(false);
        console.log("complete user profile");
    });
}
import React, {useRef, useState} from 'react';
import {
    IonText,
    IonRow,
    IonCol,
    IonGrid,
    IonImg,
    IonItem,
    IonLabel,
    IonIcon,
    IonSlide,
    IonModal,
    IonHeader, IonContent, IonFooter, IonButton
} from '@ionic/react';
import { playOutline,  chatbubbleOutline, arrowForwardSharp } from 'ionicons/icons';
import {sendJoinTestTeamEmail} from "../../util/sendEmail";
import authWrapper from "../../components/authWrapper";

interface ApprovedUserScreen2Props {
    user:any;
}

const ApprovedUserScreen2: React.FC<ApprovedUserScreen2Props> = (props) => {


    const mySlide = useRef(null);

    const [showJoin, setShowJoin] = useState(false);

    const joinTestTeam = () => {
        console.log("props", props);
        sendJoinTestTeamEmail(props.user);
        setShowJoin(false);
    }

    return (
        <IonSlide ref={mySlide}>
            <IonModal isOpen={showJoin}
                      cssClass='join-custom-class'
                      onDidDismiss={() => setShowJoin(false)}>
                <IonHeader>
                    <IonRow style={{padding: 10}}>
                        <IonText className="appTextMain center">
                            {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                        </IonText>
                    </IonRow>
                </IonHeader>
                <IonContent className='join-custom-class'
                            scrollEvents={true}
                            onIonScrollStart={() => {
                            }}
                            onIonScroll={() => {
                            }}
                            onIonScrollEnd={() => {
                            }} scrollX scrollY>

                    {props.user.testTeamMember
                        ?<><IonRow><IonCol></IonCol></IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                            <IonRow>
                                <IonText className="appTextMain center">Check your email for the invite! </IonText>
                            </IonRow>
                            <IonRow><IonCol></IonCol></IonRow>
                        </>
                        :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                </IonContent>
                <IonFooter>
                    <IonRow className="ion-justify-content-center">

                        {props.user.testTeamMember === undefined  &&
                        <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                            <IonLabel className="italic bolder">
                                Join
                            </IonLabel>
                        </IonButton>}
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonGrid className="stepScreen">
             <IonRow>
            <IonCol>
            <IonText className="appTextMain ion-text-justify" color="light">
                 Here's what you can do inside JFH:
            </IonText>
            </IonCol>
            </IonRow>
            <IonRow>
            <IonCol>
                <IonItem className="app menuButton" href="/hire" lines="none">
                {/*<IonItem className="app menuButton" href="/premiumMissions" lines="none">*/}
                <IonImg style={{'width':'50px', 'height':'25px'}}  src={"assets/img/harmony_white.svg"}  className="menuButton"/>
                  <IonLabel className="appTextMain ion-text-justify">Create or Accept Missions</IonLabel>
                </IonItem>
            </IonCol>
            </IonRow>
            <IonRow>
            <IonCol>
                {/*<IonItem className="app menuButton" onClick={() => setShowJoin(true)}  lines="none">*/}
                <IonItem className="app menuButton" href="/challenges" lines="none">
                  <IonImg style={{'width':'50px', 'height':'26px'}}  src={"assets/img/super.png"}/>
                  <IonLabel className="appTextMain ion-text-justify" >Take on challenges</IonLabel>
                </IonItem>
            </IonCol>
            </IonRow>
            <IonRow>
            <IonCol>
                {/*<IonItem className="app menuButton" onClick={() => setShowJoin(true)}  lines="none">*/}
                <IonItem className="app menuButton" href="/chat" lines="none">
                  <IonIcon  style={{'width':'50px', 'height':'26px'}} icon={chatbubbleOutline} className="menuButton" />
                  <IonLabel className="appTextMain ion-text-justify">Chat with the Cast</IonLabel>
                </IonItem>
            </IonCol>
            </IonRow>
            <IonRow>
            <IonCol>
                <IonItem className="app menuButton" onClick={() => setShowJoin(true)}  lines="none">
                {/*<IonItem className="app menuButton" href="/watch" lines="none">*/}
                  <IonIcon  style={{'width':'50px', 'height':'25px'}}  icon={playOutline} className="menuButton" />
                  <IonLabel className="appTextMain ion-text-justify" >Watch JFH Series</IonLabel>
                </IonItem>
            </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>

                    <IonLabel className="appTextMain ion-text-justify">Let's take a tour. </IonLabel>

                </IonCol>
            </IonRow>
            <IonRow></IonRow>
            <IonRow></IonRow>
            <IonRow></IonRow>
            <IonRow></IonRow>
            <IonRow></IonRow>
            <IonRow></IonRow>
            <IonRow></IonRow>
            </IonGrid>
        </IonSlide>
  );
};


export default ApprovedUserScreen2;



import {IonFooter, IonRow, IonText} from "@ionic/react";
import React, {Component} from "react";


class CheckboxNote extends  Component {
    render() {
        return (
            <IonFooter>
                <IonRow style={{'background':'#005494'}} className="ion-text-center">
                    <li style={{listStyle:'none'}}>
                    <IonText style={{'color': '#ffffff'}}> To record a video, check all boxes. Remember, you already agreed to the rules of the JFH Universe.  </IonText>
                    </li>
                </IonRow>
            </IonFooter>
        );
    }
};

export default CheckboxNote;


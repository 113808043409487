
import React, {Component, useEffect, useState} from 'react';
import {
    IonButtons,
    IonButton,
    IonItem,
    IonIcon,
    IonList,
    IonAvatar,
    IonImg,
    IonText,
    IonModal,
    IonHeader,
    IonRow,
    IonContent,
    IonLabel,
    IonFabButton,
    IonRouterLink,
    IonInput, IonCol, IonChip
} from '@ionic/react';
import {caretDown, chatbubbleOutline, addOutline, people, caretUp} from 'ionicons/icons';
import './ModalStyles.css';
import { ContentItem } from '../../models/ContentItem';

import Moment from 'react-moment';
import {firestore} from "../../firebaseConfig";
import { getPictureAddress, viewAssociation } from '../../util/avatarUtils';

interface DeliveryModuleCommentsProps{
    deliveryModule: ContentItem;
    loggedInUser: any;
    commentsOn: boolean;
    tab: string;
}

const DeliveryModuleComments: React.FC<DeliveryModuleCommentsProps> = ({deliveryModule, loggedInUser, commentsOn, tab}) => {

    const [commentList, setCommentsList] = useState(deliveryModule.comments);
    const [showComments, setShowComments] = useState(commentsOn);



    useEffect(() => {
        //console.log('refresh view comments');
        let commentsList = [];
        const deliveryModuleRef =  firestore.doc(`content/${deliveryModule.uid}`);

        // const deliveryModuleRef = firestore.collection('content').doc(deliveryModule.uid);

        deliveryModuleRef.get().then((doc)=> {

            const commentsListRef = {id: doc.id, ...doc.data()};
            commentsList = commentsListRef['comments'];
            setCommentsList(commentsList);
        });
    }, [showComments]);


    function toggleComment(commentsOn) {
       setShowComments(!commentsOn);
    }


    return (
        <>

            {commentList && commentList.length > 0 &&
            <IonRow className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} style={{'--padding-start':'0px'}}>

                <IonButton onClick={()=> toggleComment(showComments)} className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} style={{"--padding-start":"0px"}}>
                    { showComments &&
                    <IonIcon slot="icon-only" icon={caretUp}>

                    </IonIcon>
                    }
                    {commentList && !showComments &&
                    <IonIcon slot="icon-only" icon={caretDown}>

                    </IonIcon>
                    } <IonText className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'}> View {commentList.length} Comments </IonText>
                </IonButton>
            </IonRow>}
            {showComments &&
            <IonList className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} >
                {commentList && commentList.map((commentItem, cIDx) => {
                    return (
                        <IonItem lines="none" className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} key={cIDx} style={{'--padding-start':'0px'}}>
                            <IonCol size="1" className="ion-align-self-start">
                                    <IonAvatar>
                                        <IonImg id={'viewCommentor'+commentItem.commentAuthorId+tab+cIDx} src={commentItem.commentAuthorPosterUrl !== undefined ? getPictureAddress(commentItem.commentAuthorPosterUrl, commentItem.commentAuthorId, 'viewCommentor'+commentItem.commentAuthorId+tab+cIDx) : "https://www.gravatar.com/avatar?d=mm"}
                                                onClick={() => viewAssociation(commentItem.commentAuthorId, 'none', loggedInUser)}/>
                                    </IonAvatar>

                            </IonCol>
                            <IonCol style={{paddingLeft:'10%'}}>
                                <IonRow>
                                    <IonText><span className="commentAuthor">{commentItem.commentAuthorHeroName}</span>: {commentItem.commentText}</IonText>
                                </IonRow>
                                <IonRow>
                                    <Moment format='MMMM Do YYYY, h:mm a' style={{'fontSize':'8px'}}>{commentItem.commentTime.toDate()}</Moment>
                                </IonRow>
                            </IonCol>

                        </IonItem>
                    )})}
            </IonList>}
        </>
    );

}

export default DeliveryModuleComments;
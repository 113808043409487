import React from 'react';
import {  IonContent} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import { ContentItem } from '../../models/ContentItem';
import DeliveryModuleScreen from '../../components/DeliveryModuleScreen';


  interface MissionFeedContentProps {
    loading: boolean,
    missions: ContentItem[],
    showActionSheet: boolean,
    showPeopleList: boolean,
    showButtons: boolean,
    loggedInUser: any,
    userId: any,
  }


class MissionsFeed extends React.Component<{},MissionFeedContentProps>  {

constructor(props) {
  super(props);

  this.state = {
    loading: false,
    missions: [],
    showActionSheet: false,
    showPeopleList: false,
    showButtons: false,
    loggedInUser: props.user,
    userId: '',
  };
}

componentDidMount() {
  this.setState({ loading: true, 
     showActionSheet: true,
     showPeopleList: true,
     showButtons: true,});
  let missionsRef = firestore.collection('content').where("contentType", "==", 'mission').where('publicContent','==',true).orderBy('timeCreated','desc');

  if (this.state.userId) {
    console.log("Has user:");
    missionsRef = missionsRef.where("completedBy","==", this.state.userId);
  }

  
  missionsRef.onSnapshot(snapshot => {
    let missions = [];

    snapshot.forEach(doc =>
        missions.push({ ...doc.data(), uid: doc.id }),
      );

      this.setState({
        missions,
      });
    });


}


render() {
  const { missions, loading, showActionSheet, showPeopleList,showButtons, loggedInUser} = this.state;
    const hide  = true;
  

  async function viewContent(containerId) {

    var x = document.getElementById(containerId);
   

   }
  return (
    <IonContent className="ion-padding-bottom" scrollX>

       {missions.map((missionContent, idx) => {
            return (
              <DeliveryModuleScreen key={idx} deliveryModule={missionContent} showButtons={true} loggedInUser={loggedInUser} tab="missions"/>
            );  
          })}
          </IonContent>
          
  );
};
}
export default authWrapper(MissionsFeed);

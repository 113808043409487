import React, {useState} from 'react';
import {
    IonPage,
    IonFabButton,
    IonLabel,

    IonCol,
    IonRow,
    IonToggle,
    IonInput,
    IonButton,
    IonContent, IonText,
    IonSpinner
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {firestore, storageRef} from "../../firebaseConfig";
import Footer from "../../components/Footer";
import firebase from "firebase";
import {toast} from "../../toast";
import Header from '../../components/Header';
import {extractFramesFromVideo} from "../../util/videoUtils";
import {uploadInProgressNotification, showNotification} from "../../util/notification";


interface ChallengeDeliveryReviewProps{
    location: any;
}
const ChallengeDeliveryReview: React.FC<ChallengeDeliveryReviewProps> = (props) => {
    const history = useHistory();
    const [publicContent, setPublicContent] = useState(true);
    const [description, setDescription] = useState('');

    const [posterFileContent, setPosterFileContent] = useState('');

    const [showProgress, setShowProgress] = useState(false);

    if (!props.location.state)
        return null
    const {challengeFile, challengeFileURL, challengeTitle, challengeId, challengeType, heroName, heroId, userId, userPhotoURL } = props.location.state as any; // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674
    console.log("props", props.location.state);

    const onUploadImage = (e: any) => {

        let posterFile = e.target.files[0];
        let posterFileUrl = URL.createObjectURL(posterFile);
        let posterFileExtension = posterFile.type.split("/").pop();
        let posterFileName = challengeTitle.replace(/ /g, "_") + "_"+heroName.replace(/ /g, "_") + "."+posterFileExtension;

        let videoProfileEl = document.getElementById('challengeVideoPlayer') as HTMLVideoElement;
        videoProfileEl.setAttribute('poster', posterFileUrl);
        let challengeThumbnailRef = storageRef.child('content/challenges/'+challengeType+"/thumbnails/"+posterFileName);
        challengeThumbnailRef.put(posterFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail');
        }).catch((err)=>toast("error uploading thumbnail"+err));
        setPosterFileContent(challengeThumbnailRef.fullPath);
    }

    async function clickUploadImage(e) {
        document.getElementById('uploadImageBtn').click();
    }

    // completeChallenge(publicContent,challengeTitle,description,heroName,heroId, posterFileContent)}
    function completeChallenge(publicContent, challengeTitle, description, heroName, heroId, posterFileContent) {

        if (description === '') {
            return toast('please enter a description');
        }

        showNotification().then(()=> console.log('upload challenge content notification printed'));

        if (posterFileContent === '') {
            console.log('extract first frame');
            posterFileContent = extractFramesFromVideo('challengeVideoPlayer', challengeFileURL, 1, challengeType, challengeTitle, heroName);
            console.log("poster file", posterFileContent);
        }
        let completeButtonEl = document.getElementById('completeButton');
        completeButtonEl.setAttribute('disabled', 'true');
        setShowProgress(true);
        let fileExtension = challengeFile.type.split("/").pop();

        let heroVideoName = heroName.replace(/ /g, "_");
        let videoChallengeTitle = challengeTitle.replace(/ /g, "_");
        //console.log("heroName", heroVideoName);
        let videoName = videoChallengeTitle+"_"+heroVideoName+"."+fileExtension;
        console.log("video name", videoName);
        console.log("posterFileContent", posterFileContent);
        console.log("challenge type", challengeType);

        let challengeVideoRef = storageRef.child('content/'+challengeType+'/'+videoName);
        let challengeContentData = {
            contentDescription: description,
            contentVideo: challengeVideoRef.fullPath,
            contentType: challengeType,
            status: 'completed',
            completedByUserId: userId,
            completedByHeroId: heroId,
            completedByHeroName: heroName,
            completedByPhotoUrl: userPhotoURL,
            videoContentType: challengeFile.type,
            timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
            objectRefTitle: challengeTitle,
            objectRefId: challengeId,
            title: challengeTitle,
            publicContent: publicContent,
            posterUrl: posterFileContent,
        }
        console.log('challengeVideoRef', challengeVideoRef);

        challengeVideoRef.put(challengeFile).then(function() {
            console.log('Uploaded a blob or file!');
            createChallengeContent(challengeContentData);
        }).catch((err)=>toast("error uploading content"+err));

    }

     function createChallengeContent(challengeContentData) {
         // let progressBarEl = document.getElementById('progressBarCR');
         // progressBarEl.style.display='block';
         // progressBarEl.setAttribute('value', '0.5');
         // progressBarEl.setAttribute('buffer', '0.75');

         let redirectAfterSuccess  = '/wurld';
         if (challengeContentData['publicContent'] === false) {
             redirectAfterSuccess = '/profile';
         }
        const challengeRef = firestore.collection('content').add(challengeContentData)
            .then(ref => {

                console.log('Added challenge content  with ID: ', ref.id);
                window.location.replace(redirectAfterSuccess);
            });

    }

    return (
        <IonPage>
            <Header/>
            <IonContent>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: 20, overflowY: 'auto'}}>

                    <div style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center', margin: 5}}>
                       <IonText style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center'}}> Review Your Challenge</IonText>
                    </div>

                <div style={{}}>

                    <video style={{maxWidth: '100%'}} id="challengeVideoPlayer"  controls controlsList="nodownload" src={challengeFileURL} autoPlay muted>
                        <source src={challengeFileURL} type={challengeFile?.type} />
                    </video>

                    <img id="posterGenerated" alt={'test'} style={{'display':'none'}}/>

                    <div>
                        {/*<span style={{color: '#005494', fontWeight: 'bold', margin: 5}}>Description</span>*/}
                        <span style={{margin: 5}}>
                            <IonInput autocapitalize="on" className="inputBackground" value={description} placeholder="Enter Description" onIonChange={(e: any) => setDescription(e.target
                                .value)}></IonInput>
                          </span>
                    </div>
                </div>
                <div style={{width: '80%'}}>
                    <div style={{color: '#005494', fontWeight: 'bold'}}>
                        {/*<UploadImage/>*/}
                        <IonRow style={{'color':'#005494'}}>
                            <IonCol>
                                <IonButton id="uploadVideoImgBtn"
                                           className="editProfileButton"
                                           onClick={(e) => {
                                               clickUploadImage(e)
                                           }}>
                                    <IonLabel className="editProfileButtonText">
                                        Choose Cover Image </IonLabel>
                                    <input type="file" accept="image/*" className="uploadVideoBtn"
                                           id="uploadImageBtn"
                                           onChange={onUploadImage}
                                    />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>

                <div style={{width: '80%'}}>
                <IonCol>
                    <IonRow>
                        <IonLabel style={{'fontSize':'8px', 'marginLeft':'82%'}}>{publicContent ? `Public`: 'Private'}</IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonToggle style={{'marginLeft':'80%'}} checked={publicContent} onIonChange={e => setPublicContent(e.detail.checked)} />
                    </IonRow>
                </IonCol>
                </div>

                {showProgress ?
                    <IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '10px', marginBottom: '20px'}}>
                        <IonSpinner/>
                    </IonFabButton>
                    : <IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '10px', marginBottom: '20px'}} id="completeButton" onClick={() => completeChallenge(publicContent,challengeTitle,description,heroName,heroId, posterFileContent)}>
                        COMPLETE
                    </IonFabButton>
                }

            </div>
                {/*<IonProgressBar id="progressBarCR" type="determinate" className="progressBar" style={{'display':'none'}}></IonProgressBar>*/}

            </IonContent>

            <Footer />
        </IonPage>
    )
}
export default ChallengeDeliveryReview;


import React, { useState, useRef } from 'react';
import {IonAvatar, IonIcon} from "@ionic/react";
import {playOutline} from "ionicons/icons";
import { storageRef } from '../../firebaseConfig';


interface OnboardingVideoProps {
    tab: string;
    videoAssetUrl: string;
    posterAssetUrl: string;
}
const OnboardingVideo: React.FC<OnboardingVideoProps> = (props) => {

    const [contentUrl, setContentUrl] = useState('');
    const [posterContentUrl, setPosterContentUrl] = useState('');
    const videoRef = useRef(null);

    const {videoAssetUrl,posterAssetUrl} = props as any;

    let img = document.getElementById('callVideo'+props.tab);
    storageRef.child(videoAssetUrl).getDownloadURL().then(function(url) {
        setContentUrl(url);

        img.setAttribute("src", url);
    }).catch(function(error) {
        // Handle any errors
    });

    if (posterAssetUrl!='') {
        storageRef.child(posterAssetUrl).getDownloadURL().then(function(posterUrl) {
            setPosterContentUrl(posterUrl);
            img.setAttribute("poster", posterUrl);
        }).catch(function(error) {
            // Handle any errors
        });
    }






    function onVideoClick(e) {
        let videoEl = document.getElementById('callVideo'+props.tab) as HTMLVideoElement;
        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    }
    return (

        <div className="video-wrapper">
            <div className="overlay"
                 onClick={onVideoClick}
            >
                <IonAvatar className="right playBtn">
                    <IonIcon className="highContrast" style={{padding: 2}} icon={playOutline}/>
                </IonAvatar>

            </div>
            <video id={'callVideo'+props.tab} poster={posterContentUrl} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>
                <source src={contentUrl} type="video/mp4" />
            </video>

        </div>
    );
};

export default OnboardingVideo;



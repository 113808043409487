import React, { useState } from 'react';
import {
    IonInput,
    IonRow,
    IonContent,
    IonButton,
    IonPage,
    IonRouterLink,
    IonCol,
    IonCard,
    IonCardContent,
    IonGrid,
    IonCardHeader,
    IonThumbnail,
    IonImg,
    IonText,
    IonDatetime,
    IonFooter,
    IonModal,
    IonLabel,
     IonButtons,  IonIcon
} from '@ionic/react';

import './Login.css';
import { registerUser, existingHero} from '../firebaseConfig';
import { toast } from '../toast';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase';
import { getDefaultDOB } from '../util/dateUtils';
import { sendVerificationEmail } from '../util/email';
import {sendCompleteProfile} from "../util/sendEmail";
import { chevronBackOutline} from "ionicons/icons";
import {useHistory} from "react-router";

const Register: React.FC = () => {

    const location = useLocation();
    const history = useHistory();

    const backgroundVideoUrl = "https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFHAppBGSignupVideo.mp4?alt=media&token=80dc4d83-fd1c-46c0-8b15-206ea03a0d9a";

    let videoPlayerContainer = document.getElementById("backgroundVideo");
    if (videoPlayerContainer !== null) {
      videoPlayerContainer.setAttribute("autoplay","true");
    }
    const [email, setEmail] = useState('')
    //const [username, setUsername] = useState('')
    const [heroNameInput, setHeroNameInput] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setCPassword] = useState('')
    const [birthday, setBirthday] = useState('')
    // const [approved, setApproved] = useState(false)
    const [error, setError] = useState(null);
    const [showStop, setShowStop] = useState(false);
    const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
    const [showAlready, setShowAlready] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    async function validateHeroAttributes() {
        if (password !== cpassword) {
            return toast('Passwords do not match')
        }

        if (!location.state['heroName']) {
            if (heroNameInput.trim() === '') {
                return toast('Please enter your hero name');
            }

            let heroExist = await  existingHero(heroNameInput);

            if (heroExist > 0) {
                return setShowDuplicatePopup(true);
            }

        }

        if (password.trim() === '' || email.trim() === '') {
            return toast('Please enter email address and password')
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {

            return toast('Please use a valid email address.');
        }

        console.log("birthday", birthday);

        if (birthday === '') {
            return toast('Please fill in your birthday. Minimum age to join JFH is 13.');
        }


        if (!location.state) {
            toast("Please start from the beginning of the registration flow.")
            return
        }

        return true;
    }

    async function register() {


        setEmail(email);
        setHeroNameInput(heroNameInput);
        setBirthday(birthday);

        //console.log("profile video url:", location.state['profileVideoUrl']);

        let registerProfileData = {
            email: email,
            password: password,
            heroId: location.state['hero'] ? location.state['hero'] : '',
            clientId: '',
            role: location.state['role'],
            profileVideo: location.state['profileVideoUrl'] ? location.state['profileVideoUrl'] : '',
            heroName: location.state['heroName'] ? location.state['heroName'] : heroNameInput,
            clientName: location.state['clientName'] ? location.state['clientName'] : '',
            heroNumber: location.state['idx'] ? location.state['idx'] : -1,
            heroName_nocase: location.state['heroName'] ? location.state['heroName'].toLowerCase() : heroNameInput.toLowerCase(),
            clientName_nocase: location.state['clientName'] ? location.state['clientName'].toLowerCase() : '',
            clientNumber: -1,
            photoURL: location.state['photoURL'] ? location.state['photoURL'] : '',
            birthday: birthday,
            complete: true,
            villain: (location.state['villain'] && location.state['villain'] !== undefined) ? location.state['villain']: false,
            dateJoined: firebase.firestore.Timestamp.fromDate(new Date()),
            characterName: location.state['heroName'] ? location.state['heroName'] : heroNameInput,
            characterName_nocase: location.state['heroName'] ? location.state['heroName'].toLowerCase() : heroNameInput.toLowerCase(),
        }
        registerUser(registerProfileData)
            .then(newUser => {
                console.log("newUSER", newUser)
                // debugger;

               sendVerificationEmail(email, registerProfileData.characterName);
                window.location.href = "/welcomeWurld";
                 //window.location.href = "/wurld";
                //window.location.href = "/premiumMissions";

            }).catch(err => {
            //console.log("error registering new user")
            //console.dir(err)
            setError(err)
            if (err.code === "auth/network-request-failed")
                toast("There's been an network error. Please check your connection.");
            else if (err.code === "auth/email-already-in-use")
                toast("The email address is already in use by another account.");
            else
                toast("There's been an error registering a new user..."+ err.message);

        })
    }

    async function cancelRegister() {
        setShowStop(false);

        //console.log("profile video url:", location.state['profileVideoUrl']);

        let registerProfileData = {
            email: email,
            password: password,
            heroId: location.state['hero'] ? location.state['hero'] : '',
            clientId: '',
            role: location.state['role'],
            profileVideo: location.state['profileVideoUrl'] ? location.state['profileVideoUrl'] : '',
            heroName: location.state['heroName'] ? location.state['heroName'] : heroNameInput,
            clientName: location.state['clientName'] ? location.state['clientName'] : '',
            heroNumber: location.state['idx'] ? location.state['idx'] : -1,
            heroName_nocase: location.state['heroName'] ? location.state['heroName'].toLowerCase() : heroNameInput.toLowerCase(),
            clientName_nocase: location.state['clientName'] ? location.state['clientName'].toLowerCase() : '',
            clientNumber: -1,
            photoURL: location.state['photoURL'] ? location.state['photoURL'] : '',
            birthday: birthday,
            complete: false,
            villain: (location.state['villain'] && location.state['villain'] !== undefined) ? location.state['villain']: false,
            dateJoined: firebase.firestore.Timestamp.fromDate(new Date()),
            characterName: location.state['heroName'] ? location.state['heroName'] : heroNameInput,
            characterName_nocase: location.state['heroName'] ? location.state['heroName'].toLowerCase() : heroNameInput.toLowerCase(),

        }
        registerUser(registerProfileData)
            .then(newUser => {
                // toast('Login here or Access from your email later.');
                setShowLogin(true);
                sendCompleteProfile(registerProfileData['email'], registerProfileData['characterName']);
                // console.log("newUSER", newUser)
                // // debugger;
                // window.location.href = "/premiumMissions";
            }).catch(err => {
            //console.log("error registering new user")
            //console.dir(err)
            setError(err)
            if (err.code === "auth/network-request-failed")
                toast("There's been an network error. Please check your connection.");
            else if (err.code === "auth/email-already-in-use")
                toast("The email address is already in use by another account.");
            else
                toast("There's been an error registering a new user.", err.code);
        })
    }

    let confirmAndRegister = () => {
        let validationAnswer = validateHeroAttributes().then(answer => {
            console.log("answer", answer);
            if (answer !== undefined && answer) {
                setShowStop(true);
            }
        });

    }

    function goBackToPrevPage() {
        history.push({
            pathname: '/profileVideoReview',
            state: { fromPage: '/register', role:location.state['role'], showUploadProgress: false},
        });
    }


    return (
    <IonPage>

      <IonContent className="loginPage">
          {/*<IonRow>*/}
          {/*    <IonButtons>*/}
          {/*       <IonButton style={{'--color':'#ffffff', 'marginTop':'10px'}} onClick={goBackToPrevPage}> <IonIcon icon={chevronBackOutline}> </IonIcon> Back </IonButton>*/}
          {/*    </IonButtons>*/}
          {/*</IonRow>*/}
          {/*<div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>*/}

          <video autoPlay playsInline muted loop src={backgroundVideoUrl} className="loginPage__video" controls={false} />
          <IonModal isOpen={showStop}
                    cssClass='stop-modal'
                    onDidDismiss={() => setShowStop(false)}>

              <IonContent className="stop-modal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>


                      <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent:'center'}}>
                          <IonThumbnail>
                              <IonImg src={'/assets/img/stopSign.png'}/>
                          </IonThumbnail>
                      </div>

                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                          To build a Cinematic Universe takes COMMITMENT. <span className="bolder">We’re going to make movies and shows together for years to come, all because of the stories we tell together on this app.</span> When you join JFH, other characters build on your story. Contributing to a crowdsourced cinematic universe is like placing a brick in a bridge. We all rely on each other’s contributions, especially as our universe grows. Therefore, <span className="italic">once you create a profile, you won’t be able to delete it </span>(you’ll be able to make changes, though). Your contributions to the story through your character and content will live on whether or not you continue to create new content in JFH. If your content is ever used in a production that makes money, you will share in the profits. This is not your typical social network. If you’re ready to be a part of a cinematic universe, then we look forward to seeing you inside.                      </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={cancelRegister} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Cancel
                          </IonLabel>
                      </IonButton>
                      <IonButton onClick={register} style={{'--background':'none'}} className="center">
                          <IonLabel className="italic bolder">
                              Agree & Finish
                          </IonLabel>
                      </IonButton>
                  </IonRow>
              </IonFooter>
          </IonModal>

          <IonModal isOpen={showDuplicatePopup}
                    cssClass='duplicateModal'
                    onDidDismiss={() => setShowDuplicatePopup(false)}>

              <IonContent className="duplicateModal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>



                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                          A character with that name already exists. Use a different hero name. If this is your account Login or access from your email later.
                      </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={() => window.location.href='/login'} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Login
                          </IonLabel>
                      </IonButton>

                  </IonRow>
              </IonFooter>
          </IonModal>

          <IonModal isOpen={showAlready}
                    cssClass='duplicateModal'
                    onDidDismiss={() => setShowAlready(false)}>

              <IonContent className="duplicateModal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>



                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                          A character with that name already exists. Use a different hero name. If this is your account Login or access from your email later.
                      </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={() => window.location.href='/login'} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Login
                          </IonLabel>
                      </IonButton>

                  </IonRow>
              </IonFooter>
          </IonModal>

          <IonModal isOpen={showLogin}
                    cssClass='duplicateModal'
                    onDidDismiss={() => setShowLogin(false)}>

              <IonContent className="duplicateModal"
                          scrollEvents={true}
                          onIonScrollStart={() => {
                          }}
                          onIonScroll={() => {
                          }}
                          onIonScrollEnd={() => {
                          }} scrollX scrollY>



                  <IonRow className="ion-justify-content" style={{padding:'10px 10px 10px 10px'}}>
                      <IonText className="appBrandText ion-text-justify">
                           Login or access from your email later.
                      </IonText>
                  </IonRow>

              </IonContent>
              <IonFooter>
                  <IonRow className="ion-justify-content-center">

                      <IonButton onClick={() => window.location.href='/login'} style={{'--background':'none'}} className="center">
                          <IonLabel className="bolder">
                              Login
                          </IonLabel>
                      </IonButton>

                  </IonRow>
              </IonFooter>
          </IonModal>

          <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>

          <IonCard className="ion-justify-content-center center">
         <IonCardHeader>
            <IonRow className="ion-justify-content-center">
                {/*<IonImg src={"/assets/icon/tagline_wide.png"} className="login-box-image" style={{marginBottom: 0, marginTop: 10, height: 100}}/>*/}
                <div style={{marginBottom: 0}}>
                    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFH_LOGIN_PAGE.png?alt=media"} className="login-box-image" style={{marginBottom: 0, marginTop: 10}}></IonImg>
                </div>
                <div style={{marginBottom: 0}}>
                    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Ftagline_login.png?alt=media"} className="login-box-image" style={{marginBottom: 0, marginTop: 10}}></IonImg>
                </div>
            </IonRow>
         </IonCardHeader>
         <IonCardContent className="login-box">
             {location.state && !location.state['heroName'] &&  <IonInput  autocapitalize="on" className="appText" style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Hero Name" onIonChange={(e: any) => setHeroNameInput (e.target
            .value)}></IonInput>}
         <IonInput  style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Email Address" onIonChange={(e: any) => setEmail (e.target
            .value)}></IonInput>
         <IonInput style={{'background':'#ffffff', 'color':'#005494'}} type="password" placeholder="Password" onIonChange={(e: any) => setPassword (e.target
            .value)}></IonInput>
          <IonInput style={{'background':'#ffffff', 'color':'#005494'}} type="password" placeholder="Confirm Password" onIonChange={(e: any) => setCPassword (e.target
            .value)}></IonInput>
          <IonDatetime style={{'background':'#ffffff', 'color':'#005494'}} placeholder="Your REAL Birthday" displayFormat="MMM DD, YYYY"  max={getDefaultDOB()} value={birthday} onIonChange={e => setBirthday(e.detail.value!)}></IonDatetime>
         </IonCardContent>
         <IonGrid>
         <IonRow>
             <IonCol><IonButton onClick={confirmAndRegister} className="appBtn">Register</IonButton></IonCol>
         </IonRow>
             <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
           <IonText> By clicking Register you are accepting the <IonRouterLink href="/pages/termsPage" color="light" style={{'textDecoration': 'underline'}}> Terms of Use</IonRouterLink> and <IonRouterLink href="/privacyPolicy" color="light" style={{'textDecoration': 'underline'}}>  Privacy Policy </IonRouterLink></IonText>
         </div>
             <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
             <IonText className="appText"> Already Have An Account? <IonRouterLink href="/login" color="secondary"> Login</IonRouterLink></IonText>
             </div>
         </IonGrid>

              <IonFooter>

                  <IonGrid>
                      <IonRow className="footerText">
                          <div  style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                              <div>
                                  <IonText >Powered by ReelwUrld™. Patent pending. <br/> All Rights reserved. </IonText>
                              </div>
                          </div>
                      </IonRow>

                  </IonGrid>

              </IonFooter>
         </IonCard>

          </div>

      {/*</div>*/}
      </IonContent>
        
    </IonPage>

  );
};

export default Register;

import React, {useState} from 'react';
import {
    IonPage,
    IonFabButton,
    IonButtons,
    IonCol, IonRow, IonLabel, IonToggle, IonContent, IonButton, IonProgressBar, IonSpinner, IonText, IonItem
} from '@ionic/react';
import {useHistory, withRouter} from 'react-router-dom';

import { storageRef, firestore } from '../firebaseConfig';
import Footer from '../components/Footer';
import Header from "../components/Header";
import {toast} from "../toast";
import firebase from 'firebase';
import {extractFramesFromVideo} from "../util/videoUtils";
import { sendNewMissionNotification } from '../util/email';
import {uploadInProgressNotification} from "../util/notification";

interface ClientDeliveryReviewProps{
    location: any;
}
const ClientDeliveryReview: React.FC<ClientDeliveryReviewProps> = (props) => {

    const [publicContent, setPublicContent] = useState(true);
    const [missionPosterFileContent, setMissionPosterFileContent] = useState('');
    const [showInProgress, setShowInProgress] = useState(false);

    const history = useHistory();

    //console.log("review location and props!", location, props)
    if (!props.location.state)
        return null
    //console.log("props", props.location);

     const {clientDeliveryFile, clientDeliveryFileType, clientDeliveryFileURL,description, problemStatement, createdByName, uid} = props.location.state as any; // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674
     // const {user:{displayName, uid}} = props as any;


    const onChooseImage = (e: any) => {

        let missionPosterFile = e.target.files[0];
        let createPosterFileUrl = URL.createObjectURL(missionPosterFile);
        let missionPosterFileExtension = missionPosterFile.type.split("/").pop();
        console.log('posterFile', missionPosterFile);
        let missionPosterFileName = problemStatement.replace(/ /g, "_") + "_"+createdByName.replace(/ /g, "_") + "."+missionPosterFileExtension;
        console.log("se p", createPosterFileUrl);
        let videoProfileEl = document.getElementById('createVideoPlayer') as HTMLVideoElement;
        console.log("se p", videoProfileEl);
        // videoProfileEl.addAtt('poster', createPosterFileUrl);

        videoProfileEl.poster = createPosterFileUrl;
        let missionThumbnailRef = storageRef.child('content/missions/thumbnails/'+missionPosterFileName);
        missionThumbnailRef.put(missionPosterFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail');
        }).catch((err)=>toast("error uploading thumbnail"+err));
        setMissionPosterFileContent(missionThumbnailRef.fullPath);
        // if(createPosterFileUrl.startsWith('blob:')) {
        //     URL.revokeObjectURL(createPosterFileUrl);
        // }
    }

    async function clickChooseImage(e) {
        document.getElementById('uploadImageBtn').click();
    }

    function completeMission(publicContent,problemStatement, description, createdByName, uid, missionPosterFileContent) {
        //console.log("save info to db", uid + "-"+ displayName+ "contnet tye"+ file.type);
        let missionTitle = problemStatement;
        let missionFileExtension = clientDeliveryFileType.split("/").pop();

        let videoName = missionTitle.replace(/ /g, "_")+Date.now()+ "."+missionFileExtension;
        //console.log("Public content", publicContent);
        let createBtnEl = document.getElementById('createMissionBtn');
         createBtnEl.setAttribute('disabled', 'true');

         uploadInProgressNotification().then(() => console.log("notification in progess"));
        if (missionPosterFileContent === '') {
            let thumbnailRef = extractFramesFromVideo('createVideoPlayer',clientDeliveryFileURL, 1, 'mission', missionTitle, createdByName);
            missionPosterFileContent = thumbnailRef;
           // console.log("thumbnail", thumbnailRef);
        }
        let missionVideoRef = storageRef.child('Missions/content/'+videoName);
        let missionData = {
            ctaVideoUrl: missionVideoRef.fullPath,
            contentUrl: missionVideoRef.fullPath,
            clientName: createdByName,
            problemStatement: problemStatement,
            description: description,
            submittedBy: uid,
            title: missionTitle,
            contentType: clientDeliveryFile.type,
            publicContent: publicContent,
            posterUrl: missionPosterFileContent,
            timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
            lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
            code: uid+'MC',

        };
        // let progressBarEl = document.getElementById('progressBarCDR');
        // progressBarEl.style.display='block';
        // progressBarEl.setAttribute('value', '0.5');
        // progressBarEl.setAttribute('buffer', '0.75');

        missionVideoRef.put(clientDeliveryFile).then(function() {
            console.log('Uploaded mission video file!');
            createMission(missionData);
          });
    }

      //create mission

      function createMission(missionData) {
      //  console.log('public content', missionData['publicContent']);
        let redirectTo='/profile';
        // if (missionData['publicContent'] === false) {
        //     redirectTo = '/profile';
        // }
        const missionRef = firestore.collection('missions').add(missionData).then(ref => {
            sendNewMissionNotification();
            window.location.replace(redirectTo);
            toast('Your Mission was posted. A hero will respond soon.');
            console.log('Added mission  with ID: ', ref.id);
          });
      }
      
    return (
    <IonPage>
        <Header/>

        <IonContent>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: 20, overflowY: 'auto'}}>

            <div style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center', margin: 5}}>
                <IonText style={{color: '#005494', fontWeight: 'bold', justifyContent: 'center'}}> Review Your Mission </IonText>
            </div>

                <video style={{maxWidth: '100%'}} id="createVideoPlayer" controls src={clientDeliveryFileURL} autoPlay={true} muted>
                    <source src={clientDeliveryFileURL} type={clientDeliveryFile?.type}/>
                </video>

                <img id="posterGenerated" alt={'thumbnail'} style={{'display':'none'}}/>
                <IonRow style={{paddingLeft: 10, paddingTop: 10, paddingBotton: 10}}>
                    <span style={{color: '#005494', fontWeight: 'bold', margin: 5}}>{createdByName}</span>
                    <span style={{margin: 5}}>{problemStatement}</span>
                </IonRow>


            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                    <div style={{color: '#005494', fontWeight: 'bold'}}>
                        {/*<UploadImage/>*/}
                        <IonRow>
                            <IonCol>
                                <IonButton id="uploadVideoImgBtn"
                                           className="editProfileButton"
                                           onClick={(e) => {
                                               clickChooseImage(e)
                                           }}>
                                    <IonLabel className="editProfileButtonText"
                                    >
                                        Choose Cover Image </IonLabel>
                                    <input type="file" accept="image/*" className="uploadVideoBtn"
                                           id="uploadImageBtn"
                                           onChange={onChooseImage}
                                    />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
            </div>
            <div style={{width: '80%'}}>
                <IonCol>
                    <IonRow>
                        <IonLabel style={{'fontSize':'8px', 'marginLeft':'82%'}}>{publicContent ? `Public`: 'Private'}</IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonToggle style={{'marginLeft':'80%'}} checked={publicContent} onIonChange={e => setPublicContent(e.detail.checked)} />
                    </IonRow>
                </IonCol>
            </div>
            {showInProgress ?
                <IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '10px', marginBottom: '20px'}}>
                    <IonSpinner/>
                </IonFabButton>
                :  <IonFabButton id='createMissionBtn' style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '10px', marginBottom: '20px'}} onClick={() => completeMission(publicContent,problemStatement, description, createdByName, uid, missionPosterFileContent)}>
                    COMPLETE
                </IonFabButton>
            }


            {/*<IonProgressBar id="progressBarCDR" type="determinate" className="progressBar" style={{'display':'none'}}></IonProgressBar>*/}

        </div>
        </IonContent>
    <Footer />
    </IonPage>
    )
}
export default withRouter(ClientDeliveryReview);

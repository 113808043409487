import React, { useState } from 'react';
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonMenuButton,
    IonRow,
    IonCol,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonTextarea,
    IonToast,
    IonImg,
    IonBackButton, IonIcon
} from '@ionic/react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {chevronBackOutline} from "ionicons/icons";
import authWrapper from "../../components/authWrapper";
import {firestore} from "../../firebaseConfig";

interface OwnProps { }

interface DispatchProps { }

interface ReportRequestProps extends OwnProps, DispatchProps { }

const ReportRequest: React.FC<ReportRequestProps> = (props) => {

    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const {user:{uid, heroName, email}} = props as any;

    const send = (e: React.FormEvent) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!message) {
            setMessageError(true);
        }
        if (message) {
            let reportRequestData = {
                message: message,
                reportById: uid,
                reportedByName: heroName,
                reportedByEmail: email,
                }
            const reportRef = firestore.collection('reports').add(reportRequestData).then(ref => {
                setShowToast(true);
                console.log('Added mission content  with ID: ', ref.id);
            });
            setMessage('');
        }
    };

    return (
        <IonPage id="support-page">
            <Header />
            <IonHeader>
                <IonToolbar>
                    {/*<IonButtons slot="start">*/}
                    {/*    <IonButton style={{'--color':'#005494'}} onClick={() => window.history.back()}> <IonIcon icon={chevronBackOutline}> </IonIcon> Back </IonButton>*/}
                    {/*</IonButtons>*/}
                    <IonTitle style={{'--color':'#005494'}}>Report A Problem </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>


                <form noValidate onSubmit={send}>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked" color="primary" className="ion-text-wrap">
                                Report a problem here. Please let us know as many details as possible about the issues you're encountering.</IonLabel>
                            <IonTextarea name="message" value={message} spellCheck={false} autocapitalize="off" rows={6} onIonChange={e => setMessage(e.detail.value!)}
                                         required placeholder={"Write Here"}>
                            </IonTextarea>
                        </IonItem>

                        {formSubmitted && messageError && <IonText color="danger">
                            <p className="ion-padding-start">
                                Report request is required.
                            </p>
                        </IonText>}
                    </IonList>

                    <IonRow>
                        <IonCol>
                            <IonButton type="submit" expand="block">Send Report Request</IonButton>
                        </IonCol>
                    </IonRow>
                </form>

            </IonContent>

            <IonToast
                isOpen={showToast}
                duration={3000}
                message="Your report request has been sent"
                onDidDismiss={() => setShowToast(false)} />

        </IonPage>
    );
};

export default authWrapper(ReportRequest);

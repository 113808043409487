import React, { useState, useRef, useEffect } from 'react';
import {firestore} from '../firebaseConfig';
import './VideoListItem.css';
import {IonIcon, IonLabel, IonActionSheet, IonRow, IonAlert} from '@ionic/react';
import {playOutline, mail, closeOutline, lockClosedOutline, imageOutline, lockOpenOutline, pencilOutline} from 'ionicons/icons';
import {getPictureAddress} from "../util/avatarUtils";
import {Device} from "@capacitor/core";
import { playVideoFromRef, uploadCTAModuleCover} from "../util/videoUtils";
import { toast } from '../toast';
import firebase from "firebase";
import { sendApprovalPendingEmail } from '../util/sendEmail';


interface CTAVideoListItemProps {
    contentItem: any;
    tab: string;
    enableTogglePrivate: boolean;
}

const CTAVideoListItem: React.FC<CTAVideoListItemProps> = (props) => {

    const [showActionSheet, setShowActionSheet] = useState(false);

    const [showUpdateMission, setShowUpdateMission] = useState(false);

    const [pictureContentUrl, setPictureContentUrl] = useState();
    const [platformInfoName, setPlatformInfoName] = useState('web');

    const ctaVideoRef = useRef(null);


    useEffect(() => {
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);

            setPlatformInfoName(info.operatingSystem);
        } );
    }, [props.contentItem]);

    function playMissionCTA(e) {
        let ctaVideoCurrentRefEl  = ctaVideoRef.current;
        playVideoFromRef(ctaVideoCurrentRefEl, platformInfoName);
    }

    async function clickUploadCoverImage() {
        document.getElementById('uploadCTAImageBtn'+props.contentItem.uid+props.tab).click();
    }

    function onUploadCoverCTA(e) {
        console.log("do 1", props.contentItem.title);
        let ctaModuleRef = uploadCTAModuleCover(e, ctaVideoRef.current,  props.contentItem);
        //setCoverAddress(downloadContent(deliveryModuleCoverImageRef.fullPath));
        console.log("props.contentItem", props.contentItem.uid + props.contentItem.title);
        //setCoverImgAddress(deliveryModuleCoverImageRef.fullPath);
        console.log("cover image address", ctaModuleRef.fullPath);
    }


    return (
        <div className="video-wrapper">
            <div className="overlay"
                 onClick={playMissionCTA}
            >

                <div style={{margin: 5, maxWidth: '80%'}} className="left missionDeliveryTitle bolder">

                    <IonRow>
                        <IonIcon style={{padding: 2}}  item-end icon={playOutline}/>
                        <IonLabel style={{display: 'flex !important', justifyContent: 'center', verticalAlign: 'middle'}}>
                            {props.contentItem?.title}
                        </IonLabel>
                    </IonRow>
                </div>

                <div className="right reportBtn highContrast">
                    <IonLabel style={{padding: 20, paddingTop: 5, cursor: 'pointer'}}
                              onClick={(e) => {setShowActionSheet(true);e.stopPropagation();}}
                    >
                        ...
                    </IonLabel>
                    {props.enableTogglePrivate && props.contentItem.publicContent &&
                    <IonActionSheet
                        isOpen={showActionSheet}
                        onDidDismiss={() => setShowActionSheet(false)}
                        backdropDismiss={false}
                        cssClass='my-custom-class'
                        buttons={[ {
                            text: 'Report a problem',
                            icon: mail,
                            handler: () => {
                                window.location.href='/reportRequest';
                            }
                        }, {
                            text: 'Make Private',
                            icon: lockClosedOutline,
                            handler: () => {
                                firestore.doc(`missions/${props.contentItem.uid}`).update({
                                    publicContent: false,
                                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                                }).then(function() {
                                    window.location.reload(false);
                                });
                            }
                        }, {
                            text: 'Change Cover Photo',
                            icon: imageOutline,
                            handler: () => {
                                // firestore.doc(`content/${contentItem.uid}`).update({
                                //     publicContent: true,
                                // }).then(function() {
                                //     window.location.reload(false);
                                // });
                                clickUploadCoverImage();
                            }
                        }, {
                            text: 'Edit  Title/Description',
                            icon: pencilOutline,
                            handler: () => {
                                // firestore.doc(`content/${contentItem.uid}`).update({
                                //     publicContent: true,
                                // }).then(function() {
                                //     window.location.reload(false);
                                // });
                                //clickUploadCoverImage();
                               // toast('content item '+ JSON.stringify(props.contentItem));
                                setShowUpdateMission(true);
                            }
                        }, {
                                text: 'Cancel',
                                icon: closeOutline,
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }]}
                    >
                    </IonActionSheet>
                    }
                    {props.enableTogglePrivate && !props.contentItem.publicContent &&
                    <IonActionSheet
                        isOpen={showActionSheet}
                        onDidDismiss={() => setShowActionSheet(false)}
                        backdropDismiss={false}
                        cssClass='my-custom-class'
                        buttons={[ {
                            text: 'Report a problem',
                            icon: mail,
                            handler: () => {
                                window.location.href='/reportRequest';
                            }
                        }, {
                            text: 'Make Public',
                            icon: lockOpenOutline,
                            handler: () => {
                                firestore.doc(`missions/${props.contentItem.uid}`).update({
                                    publicContent: true,
                                    lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                                }).then(function() {
                                    window.location.reload(false);
                                });
                            }
                        }, {
                            text: 'Change Cover Photo',
                            icon: imageOutline,
                            handler: () => {
                                // firestore.doc(`content/${contentItem.uid}`).update({
                                //     publicContent: true,
                                // }).then(function() {
                                //     window.location.reload(false);
                                // });
                                clickUploadCoverImage();
                            }
                        },{
                            text: 'Edit  Title/Description',
                            icon: pencilOutline,
                            handler: () => {
                                // firestore.doc(`content/${contentItem.uid}`).update({
                                //     publicContent: true,
                                // }).then(function() {
                                //     window.location.reload(false);
                                // });
                                //clickUploadCoverImage();
                                setShowUpdateMission(true);
                            }
                        }, {
                                text: 'Cancel',
                                icon: closeOutline,
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }]}
                    >
                    </IonActionSheet>
                    }
                    {!props.enableTogglePrivate &&
                    <IonActionSheet
                        isOpen={showActionSheet}
                        onDidDismiss={() => setShowActionSheet(false)}
                        backdropDismiss={false}
                        cssClass='my-custom-class'
                        buttons={[ {
                            text: 'Report a problem',
                            icon: mail,
                            handler: () => {
                                window.location.href='/reportRequest';
                            }
                        }, {
                                text: 'Cancel',
                                icon: closeOutline,
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }]}
                    >
                    </IonActionSheet>
                    }
                </div>
            </div>

            {props.enableTogglePrivate &&
            <input type="file" accept="image/*" className="uploadVideoBtn"
                   id={'uploadCTAImageBtn'+props.contentItem.uid+props.tab}
                   onChange={(e) => onUploadCoverCTA(e)}
            />}

            {props.enableTogglePrivate &&
            <IonAlert
                isOpen={showUpdateMission}
                header="These changes affect the entire universe."
                buttons={[
                    'Cancel',
                    {
                        text: 'Request',
                        handler: (data) => {

                            if (props.contentItem.title !== data['missionTitle'] && props.contentItem.description !== data['missionDescription']) {
                                let missionUpdateRequest = {
                                    message: 'Mission CTA Change Request',
                                    requestedById: props.contentItem.submittedBy,
                                    originalTitle: props.contentItem.title,
                                    originalDescription: props.contentItem.description,
                                    requestedByCharacter: props.contentItem.clientName,
                                    requestedChangeTitle: data['missionTitle'],
                                    requestedChangeDescription: data['missionDescription'],
                                    timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
                                    posterUrl: props.contentItem.posterUrl,
                                    contentVideo: props.contentItem.ctaVideoUrl,
                                    contentId: props.contentItem.uid,
                                    status: 'pending',

                                }
                                let waitlistRef = firestore.collection('missionUpdateRequests').add(missionUpdateRequest).then(ref => {
                                    toast('Approval Pending');
                                    sendApprovalPendingEmail(props.contentItem.submittedBy, 'Mission Details');
                                    console.log('Added mission update request  with ID: ', ref.id);
                                });
                            }
                            else if (props.contentItem.title !== data['missionTitle']) {
                                let missionUpdateRequest = {
                                    message: 'Mission CTA Change Request',
                                    requestedById: props.contentItem.submittedBy,
                                    originalTitle: props.contentItem.title,

                                    requestedByCharacter: props.contentItem.clientName,
                                    requestedChangeTitle: data['missionTitle'],

                                    timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
                                    posterUrl: props.contentItem.posterUrl,
                                    contentVideo: props.contentItem.ctaVideoUrl,
                                    contentId: props.contentItem.uid,
                                    status: 'pending',

                                }
                                let waitlistRef = firestore.collection('missionUpdateRequests').add(missionUpdateRequest).then(ref => {
                                    toast('Approval Pending');
                                    sendApprovalPendingEmail(props.contentItem.submittedBy, 'Mission Title');
                                    console.log('Added mission update request  with ID: ', ref.id);
                                });
                            }
                            else if (props.contentItem.description !== data['missionDescription']) {
                                let missionUpdateRequest = {
                                    message: 'Mission CTA Change Request',
                                    requestedById: props.contentItem.submittedBy,
                                    originalDescription: props.contentItem.description,
                                    requestedByCharacter: props.contentItem.clientName,
                                    requestedChangeDescription: data['missionDescription'],
                                    timeCreated: firebase.firestore.Timestamp.fromDate(new Date()),
                                    posterUrl: props.contentItem.posterUrl,
                                    contentVideo: props.contentItem.ctaVideoUrl,
                                    contentId: props.contentItem.uid,
                                    status: 'pending',

                                }
                                let waitlistRef = firestore.collection('missionUpdateRequests').add(missionUpdateRequest).then(ref => {
                                    toast('Approval Pending');
                                    sendApprovalPendingEmail(props.contentItem.submittedBy, 'Mission Description');
                                    console.log('Added mission update request  with ID: ', ref.id);
                                });
                            }
                            else {
                                toast('No change detected');
                            }
                        }
                    }
                ]}
                inputs={[
                    {
                        type: "text",
                        name: 'missionTitle',
                        id: 'missionTitle',
                        value: (`${props.contentItem.title}` !== '' && `${props.contentItem.title}` !== 'undefined') ? `${props.contentItem.title}`: ``,
                        placeholder: `${props.contentItem.title}` !== '' ? `${props.contentItem.title}`: `Please fill in the  Mission Title`
                    }
                    ,
                    {
                        type: "text",
                        name: 'missionDescription',
                        id: 'missionDescription',
                        value: (`${props.contentItem.description}` !== '' && `${props.contentItem.description}` !== 'undefined') ? `${props.contentItem.description}`: ``,
                        placeholder: `${props.contentItem.description}` !== '' ? `${props.contentItem.description}`: `Please fill in the mission description`
                    }
                ]}
                onDidDismiss={() => setShowUpdateMission(false)}
            />}
            <video ref={ctaVideoRef}
                   poster={getPictureAddress(props.contentItem?.posterUrl, props.contentItem?.uid,props.contentItem?.uid+props.tab)}
                   onEnded={e=> ctaVideoRef.current.load()}
                   onPause={e=> {ctaVideoRef.current.load(); ctaVideoRef.current.controls=false; }} preload="metadata">
                <source src={getPictureAddress(props.contentItem?.ctaVideoUrl ? props.contentItem?.ctaVideoUrl: props.contentItem?.contentUrl, props.contentItem?.uid,props.contentItem?.uid+props.tab)} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

        </div>

    );
};

export default CTAVideoListItem;



import React, { useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,

  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
 IonIcon, IonFooter
} from '@ionic/react';
import Header from '../../components/Header';
import {chevronBackOutline} from "ionicons/icons";
import {firestore} from "../../firebaseConfig";
import authWrapper from "../../components/authWrapper";

interface OwnProps { }

interface DispatchProps { }

interface SupportProps extends OwnProps, DispatchProps { }

const Support: React.FC<SupportProps> = (props) => {

  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const {user:{uid, heroName, email}} = props as any;

  const send = (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!message) {
      setMessageError(true);
    }
    if (message) {
      let supportRequestData = {
        message: message,
        reportById: uid,
        reportedByName: heroName,
        reportedByEmail: email,
      }
      const supportRef = firestore.collection('supportTickets').add(supportRequestData).then(ref => {
        setShowToast(true);
       // console.log('Added support content  with ID: ', ref.id);
      });
      setMessage('');
    }
  };

  return (
    <IonPage id="support-page">
      <Header menu={true}/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
              <IonButton style={{'--color':'#005494'}} onClick={() => window.history.back()}> <IonIcon icon={chevronBackOutline}> </IonIcon> Back </IonButton>
          </IonButtons>
          <IonTitle style={{'--color':'#005494'}}>Support</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <form noValidate onSubmit={send}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary" className="ion-text-wrap"> Enter your support message below. Please let us know as many details as possible about the issues you're encountering.</IonLabel>
              <IonTextarea name="message" value={message} spellCheck={false} autocapitalize="off" rows={6} onIonChange={e => setMessage(e.detail.value!)}
                required>
              </IonTextarea>
            </IonItem>

            {formSubmitted && messageError && <IonText color="danger">
              <p className="ion-padding-start">
                Support message is required.
              </p>
            </IonText>}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Send Support Request</IonButton>
            </IonCol>
          </IonRow>
        </form>

      </IonContent>

      <IonToast
        isOpen={showToast}
        duration={3000}
        message="Your support request has been sent"
        onDidDismiss={() => setShowToast(false)} />

      <IonFooter>
        <IonToolbar color="primary" className="ion-text-center">
          <IonText className="footerText"> Powered by Reelwurld™. Patent pending. <br></br> </IonText>
          <IonText className="appText"> All Rights reserved.</IonText>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default authWrapper(Support);

import {auth, firestore } from "../firebaseConfig";

var admin = require("firebase-admin");

var serviceAccount = require("../util/myappjfh-firebase-adminsdk-o5vl5-e2b8fde54d.json");

admin.initializeApp({
    credential: admin.credential.cert(serviceAccount),
    databaseURL: "https://myappjfh.firebaseio.com"
});


const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for
    // this URL must be whitelisted in the Firebase Console.
    url: 'https://www.justiceforhire.app',
    // This must be true for email link sign-in.
    handleCodeInApp: true,
    iOS: {
        bundleId: 'com.example.ios',
    },
    android: {
        packageName: 'com.example.android',
        installApp: true,
        minimumVersion: '12',
    },
    // FDL custom domain.
    dynamicLinkDomain: 'justiceforhire.app',
};

export function sendEmail(notificationType, notificationMessage, notificationDestination, subjectContent) {
    firestore.collection("mail")
        .add({
            to: notificationDestination,
            bcc: "jan@justiceforhire.com",
            message: {
                subject: notificationType + " " + subjectContent,
                text: notificationMessage,
            },
            secure:true,
        })
        .then(() => console.log("Queued email for delivery!"));
}


export function sendDMNotification(receiverId) {


    const destinationQuery = firestore.doc(`users/${receiverId}`);

    destinationQuery.get()
        .then(function (destinationDoc) {
            firestore.collection("mail")
                .add({
                    to: destinationDoc.data()['email'],
                    message: {
                        subject: 'Check your DMs',
                        html:
                            " Hello " + destinationDoc.data()['characterName']+ ", <br/>" +
                            " Your attention is needed in the JFH app. A Hero, Villain, or Client has DM’d you. You should get back to them ASAP. Someone might need help, or perhaps it’s an important tip for your next Mission."+ "<br/> <br/>" +
                            "   With Love," + "<br/> <br/>" +
                            "   Justice F. Hire" + "<br/> <br/>" +
                            "#HeroesUnitingWorldwide" + " <br/>" +
                            " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",

                    },
                    secure:true,
                })
                .then(() => console.log("DM Notification email for delivery!"));
        })  .catch(function (error) {
        console.log("Error getting receiver info: ", error);
    });

}

export function sendNewMissionNotification() {

    let heroesQueryRef =  firestore.collection('users').where('role','==','hero')
        .onSnapshot(snapshot => {
            let heroesList = [];

            snapshot.forEach(heroDoc => {
                    sendNewMissionNotificationEmail("support@reelwurld.com", heroDoc.data()['characterName']);

                }
             );

        });

}

export function sendNewMissionNotificationEmail(heroName, heroEmail) {
    firestore.collection("mail")
        .add({
            to: heroEmail,
            bcc: "jan@justiceforhire.com",
            message: {
                subject: 'New Mission: Heroes Needed',
                html:
                    " Hello " + heroName + ", <br/> <br/>" +
                    " A new Mission awaits! Head inside the <code><a href='justiceforhire.app'>JFH App</a></code> to answer the call."+ "<br/> <br/>" +
                    " Remember, Missions connect your character’s story to the rest of the Cinematic Universe. Your completed Mission may appear in the central JFH series produced by our team, or it could inspire other Heroes and Villains to build on the storyline you created in your video. No matter what happens, your Mission is an integral part of the narrative from the moment you press send. Every brick in the bridge matters in the JFH Universe, so put your best foot forward for this one. " + "<br/> <br/>" +
                    " We’ll be watching. " + "<br/> <br/>" +
                    " Love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("DM Notification email for delivery!"));
}

function sendDMNotificationEmail(heroName, heroEmail) {
    //not enabled yet
    firestore.collection("mail")
        .add({
            to: heroEmail,
            bcc: "jan@justiceforhire.com",
            message: {
                subject: 'Check your DMs',
                html:
                    " Hello " + heroName + ", <br/> <br/>" +
                    " Your attention is needed in the JFH app. A Hero, Villain, or Client has DM’d you. You should get back to them ASAP. Someone might need help, or perhaps it’s an important tip for your next Mission." + ", <br/> <br/>" +
                    " With Love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("DM Notification email for delivery!"));
}

function sendCustomVerificationEmail(useremail, displayName, link) {
    firestore.collection("mail")
        .add({
            to: useremail,
            bcc: "andra@reelwurld.com",
            message: {
                subject: 'Welcome to JFH',
                html:
                    " Hello " + displayName + ", <br/> <br/>" +
                    " Love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Verification Notification email for delivery!"));
}

function generateAndSendVerificationEmail(useremail, displayName, actionCodeSettings) {
     admin.auth().generateEmailVerificationLink(useremail, actionCodeSettings)
        .then((link) => {
            // Construct email verification template, embed the link and send
            // using custom SMTP server.
            console.log("send generate", link);
            return sendCustomVerificationEmail(useremail, displayName, link);
        })
        .catch((error) => {
            // Some error occurred.
            console.log("error sending verification email");
        });
}

function sendBrandNotification(useremail, displayName) {
    console.log("send brand notification");
    firestore.collection("mail")
        .add({
            to: "jan@justiceforhire.com",
            bcc: "andra@reelwurld.com",
            message: {
                subject: 'JFH New Character Sign Up ',
                html:
                    " Hello Jan" + ", <br/> <br/>" +
                    " <code><strong>" + displayName +"</strong></code>" + " joined the JFH - Justice For Hire Cinematic Universe. " + " <br/> <br/>" +
                    " Love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Brand Notification email for delivery!"));
}

export function sendVerificationEmail(useremail, displayName) {
    console.log("send verification email");

    //generateAndSendVerificationEmail(useremail, displayName, actionCodeSettings);
    sendBrandNotification(useremail, displayName);
}

// export function getLastTimeLoggedIn(email) {
//     admin
//         .auth()
//         .getUserByEmail(email)
//         .then((userRecord) => {
//             // See the UserRecord reference doc for the contents of userRecord.
//             console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
//         })
//         .catch((error) => {
//             console.log('Error fetching user data:', error);
//         });
// }

export function sendWelcomeDMEmail(characterName, characterEmail) {
    firestore.collection("mail")
        .add({
            to: characterEmail,
            cc: "jan@justiceforhire.com",
            message: {
                subject: 'Welcome to JFH Chat',
                html:
                    " Hello " + characterName + ", <br/> <br/>" +
                    " Welcome to JFH Chat! Important note: You’ll see an *in character* and *out of character* toggle in Cast Chat that’s super important. When you want to talk to other heroes as if you’re playing your character on a movie set and its dialogue in the movie, choose *in-character*. If you want to chat as your true identity (ie. your real name), *out-of-character* is best.*" + ", <br/> <br/>" +
                    " With Love," + "<br/> <br/>" +
                    " Justice F. Hire" + "<br/> <br/>" +
                    " #HeroesUnitingWorldwide" + " <br/>" +
                    " <code><a href='https://justiceforhire.app' target='_blank'><p style='font-family: arial, helvetica'>JusticeForHire.app</p></a></code>",
            },
            secure:true,
        })
        .then(() => console.log("Welcome to JFH Cast Chat Notification email for delivery!"));
}

import React, {useEffect, useState } from 'react';
import {IonCard, IonGrid, IonIcon, IonImg, IonRow, IonSearchbar, IonThumbnail} from '@ionic/react';

import { firestore } from '../../firebaseConfig';

import {downloadContent, downloadPictureContent} from "../../util/avatarUtils";
import {cloudDownload} from "ionicons/icons";


interface AdminCTAFeedProps {

}


const AdminCTAFeed: React.FC<AdminCTAFeedProps> = (props) => {
   const [ctaListMissions, setCtaListMissions] = useState([]);
    const [ctaListChallenges, setCtaListChallenges] = useState([]);

    const [searchCTAByUser, setSearchCTAByUser] = useState('');

    useEffect(() => {
        console.log("props show", props);
        let missionsRef =  firestore.collection('missions').where('publicContent', '==', true).orderBy("timeCreated", "desc")
            .onSnapshot(snapshot => {
                let missionsList = [];

                snapshot.forEach(doc =>

                    missionsList.push({ ...doc.data(), uid: doc.id }),
                );
                setCtaListMissions(missionsList);

            });

        let challengesRef = firestore.collection('challenges')
            .onSnapshot(snapshot => {
                let challengesList = [];

                snapshot.forEach(challengeDoc =>

                    challengesList.push({ ...challengeDoc.data(), uid: challengeDoc.id }),
                );
                setCtaListChallenges(challengesList);

            });

    }, [props]);

    return (
        <>
            <IonSearchbar value={searchCTAByUser} onIonChange={e => setSearchCTAByUser(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}></IonSearchbar>

            {ctaListMissions.map((ctaItem, idxCTA) => {
                return (
                    <IonCard key={idxCTA} className="child2">
                        <IonThumbnail style={{'height':'70%', 'width':'100%'}}>
                            <IonImg id={'contentItemAdmin'+idxCTA+ctaItem.uid} src={ctaItem.posterUrl ? downloadPictureContent(ctaItem.posterUrl,ctaItem.uid, 'contentItemAdmin'+`${idxCTA}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    alt={"Content Image"}  />
                        </IonThumbnail>

                        <div>
                            <IonGrid>
                                <IonRow>
                                    {ctaItem.title}_{ctaItem.clientName}
                                </IonRow>
                                <IonRow>
                                    <a href={downloadContent(ctaItem.ctaVideoUrl)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCard>
                )
            })}
            {ctaListChallenges.map((ctaItemChallenge, idxCTAC) => {
                return (
                    <IonCard key={idxCTAC} className="child2">
                        <IonThumbnail style={{'height':'70%', 'width':'100%'}}>
                            <IonImg id={'contentItemAdmin'+idxCTAC+ctaItemChallenge.uid} src={ctaItemChallenge.posterUrl ? downloadPictureContent(ctaItemChallenge.posterUrl,ctaItemChallenge.uid, 'contentItemAdmin'+`${idxCTAC}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    alt={"Content Image"}  />
                        </IonThumbnail>

                        <div>
                            <IonGrid>
                                <IonRow>
                                    {ctaItemChallenge.title}
                                </IonRow>
                                <IonRow>
                                    <a href={downloadContent(ctaItemChallenge.ctaVideoUrl)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCard>
                )
            })}
        </>
    );


}
export default AdminCTAFeed;

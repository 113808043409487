
import React from 'react';
import { IonRow, IonCol, IonText, IonItem, IonGrid, IonPage, IonContent, IonLabel, IonFab, IonFabButton, IonButton } from '@ionic/react';
import {
    MediaFile,
    VideoCapturePlusOptions,
    VideoCapturePlus,
} from "@ionic-native/video-capture-plus";

import { withRouter} from "react-router-dom";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {toast} from "../../toast";

interface ProfileVideoItemProps {
    location: any;
    history: any;
}


const ProfileVideoItem: React.FC<ProfileVideoItemProps> = (props) => {

    if (!props.location.state) {
        return null
    }
    const { user, fromPage } = props?.location.state as any;


    const onChange = async (e: any) => {

        let file = e.target.files[0];
        let fileURL = URL.createObjectURL(file);

        console.log("file duration", file);
        props.history.push({
            pathname: '/profileVideoReview',
            state: {file, fileURL, role: user.role, idx: user.heroIdx, userId:user.uid, fromPage: 'profileVideoItem', update: true, userHeroName: user.heroName, userClientName: user.clientName}
        })
    }



    const onVideoChange = (e: any) => {

        let uploadProfileFile = e.target.files[0];
        let uploadProfileFileURL = URL.createObjectURL(uploadProfileFile);


        props.history.push({
            pathname: '/profileVideoReview',
            state: {file:uploadProfileFile,  fileURL:uploadProfileFileURL, role: user.role, idx: user.heroIdx, userId:user.uid, fromPage: 'profileVideoItem', userHeroName: user.heroName, userClientName: user.clientName, update: true}
        })
    }

    async function clickUploadVideo(e) {
        document.getElementById('uploadProfileVideo').click();
    }

    // const doMediaCapture = async () => {
    //     let options: VideoCapturePlusOptions = { limit: 1, duration: 30 };
    //     let capture:any = await VideoCapturePlus.captureVideo(options);
    //     console.log((capture[0] as MediaFile).fullPath);
    // };


    async function recordVideo() {
        let recorderBtn = document.getElementById('recorderProfile');
        recorderBtn.click();
        //await doMediaCapture();
    }

    return (
        <IonPage>
            <Header fromPage={fromPage}/>

            <IonContent className="app" scrollX scrollY>

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem className="app" lines="none">
                                <IonLabel className="labelText" style={{'color':'#ffffff'}}> PROFILE VIDEO DIRECTION </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="app" lines="none">
                                <IonLabel className="labelText" style={{'color':'#ffffff'}}> {user?.heroName}  <br/><br/><br/><br/>
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow />
                    <IonRow />

                    <IonRow />
                    <IonRow />
                    <IonRow><IonCol></IonCol></IonRow>
                    <IonRow>
                        <IonCol className="app ion-text-center">
                            {user?.role === 'hero' &&  <IonText className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff', '--color':'#ffffff'}}>
                                State your Hero Name and why you’re the hero to hire.
                            </IonText>}
                            {user?.role === 'client' &&  <IonText className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff', '--color':'#ffffff'}}>
                                Introduce yourself and what types of problems you will direct heroes to solve.
                            </IonText>}
                        </IonCol>
                    </IonRow>
                    <IonRow><IonCol></IonCol></IonRow>
                    <IonRow><IonCol></IonCol></IonRow>
                    <IonRow><IonCol></IonCol></IonRow>
                    <IonRow><IonCol></IonCol></IonRow>
                    <IonRow><IonCol></IonCol></IonRow>
                    <IonRow className="ion-justify-content-center">
                        <div style={{height: 106,width: 106}}>
                            <IonFab style={{
                                borderStyle: 'solid',
                                borderRadius: '50%',
                                borderWidth: 3,
                                borderColor: 'red'
                            }}>
                                <IonFabButton id="recordProfile" onClick={() => recordVideo()} style={{
                                    '--background': '#ffffff',
                                    minHeight: 100,
                                    width: 100,
                                    '--border-color': '#FF0000'
                                }}>
                                    <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                                        ACTION
                                    </IonLabel>
                                </IonFabButton>
                                <input type="file" accept="video/*" capture="camera"
                                       id="recorderProfile"
                                       className="uploadVideoBtn"
                                       onChange={onChange}
                                />
                            </IonFab>
                        </div>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton id="uploadProfileVideoBtn"
                                       style={{'--background': '#fffff', 'float':'right', 'marginTop':'10px', 'boxShadow':'none'}}
                                       onClick={(e) => {
                                           clickUploadVideo(e)
                                       }}>
                                <IonLabel style={{'color':'#ffffff', '--boxShadow':'none'}} className="italic"> Upload Video </IonLabel>
                                <input type="file" accept="video/*" className="uploadVideoBtn"
                                       id="uploadProfileVideo"
                                       onChange={onVideoChange}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <Footer />
        </IonPage>
    );
};

export default withRouter(ProfileVideoItem);


import React, {useEffect, useState } from 'react';
import {
    IonCard,
    IonRow,
    IonSearchbar,
    IonCol,
    IonText,
    IonLabel,
    IonCardHeader
} from '@ionic/react';

import { firestore } from '../../firebaseConfig';


import VideoContentPlayer from "../../components/Player/VideoContentPlayer";
import ChangeRequestAction from "./ChangeRequestAction";
import Moment from 'react-moment';


interface MissionCallsChangeRequestsProps {

}


const MissionCallsChangeRequests: React.FC<MissionCallsChangeRequestsProps> = (props) => {
    const [ctaChangeRequestList, setCtaChangeRequestList] = useState([]);

    const [searchCTAByUser, setSearchCTAByUser] = useState('');

    useEffect(() => {
        console.log("props show", props);
        let ctaChangeRequestsRef =  firestore.collection('missionUpdateRequests').orderBy("timeCreated", "desc")
            .onSnapshot(snapshot => {
                let ctaChangeList = [];

                snapshot.forEach(doc =>

                    ctaChangeList.push({ ...doc.data(), uid: doc.id }),
                );
                setCtaChangeRequestList(ctaChangeList);

            });



    }, [props]);

    return (
        <>
            <IonSearchbar value={searchCTAByUser} onIonChange={e => setSearchCTAByUser(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}></IonSearchbar>


            {ctaChangeRequestList.map((ctaRequest, idxCTA) => {
                return (
                    <div key={idxCTA+'row'}>
                        <IonRow style={{fontSize:'8px', textAlign: 'center'}} className="ion-justify-content-center">

                            <Moment format='MMMM Do YYYY, h:mm a' style={{fontSize:'8px', opacity: '.5'}}>{ctaRequest.timeCreated.toDate()}</Moment>

                        </IonRow>
                    <IonCard>
                        <VideoContentPlayer item={ctaRequest} tab={'brandAdminCTA'} index={idxCTA}/>
                        {ctaRequest.originalTitle &&
                            <IonRow>
                                <IonCol>
                                    <IonLabel className="labelText"> Original Title </IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonText> {ctaRequest.originalTitle} </IonText>
                                </IonCol>
                            </IonRow>
                        }
                        {ctaRequest.requestedChangeTitle &&
                            <IonRow>
                                <IonCol>
                                    <IonLabel className="labelText"> Proposed New Title </IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonText> {ctaRequest.requestedChangeTitle} </IonText>
                                </IonCol>
                            </IonRow>
                        }
                        {ctaRequest.originalDescription &&
                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Original Description </IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonText> {ctaRequest.originalDescription} </IonText>
                            </IonCol>
                        </IonRow>
                        }
                        {ctaRequest.requestedChangeDescription &&
                            <IonRow>
                                <IonCol>
                                    <IonLabel className="labelText"> Proposed Description </IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonText> {ctaRequest.requestedChangeDescription} </IonText>
                                </IonCol>
                            </IonRow>
                        }
                        <IonRow>
                            <IonCol>
                                <IonLabel className="labelText"> Proposed By </IonLabel>
                            </IonCol>
                        <IonCol>
                            <IonText> {ctaRequest.requestedByCharacter}</IonText>
                        </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                            </IonCol>
                        <IonCol>
                            {ctaRequest.status === 'pending' ?
                                 <ChangeRequestAction item={ctaRequest} tab={'brandAdminCTA'} dbTableName={'missionUpdateRequests'} index={idxCTA}/>
                                :<IonLabel>{ctaRequest.status?.toUpperCase()}</IonLabel>
                            }
                        </IonCol>
                        </IonRow>


                    </IonCard>

                    </div>
                )
            })}

        </>
    );


}
export default MissionCallsChangeRequests;

import React from 'react';
import {IonPage, IonFabButton, IonText} from '@ionic/react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Hire : React.FC = () => {
  function clientDelivery() {
    // let audioEl =  document.getElementById("mainSound");
    // console.log("Audio element", audioEl);
    // (audioEl as HTMLAudioElement).play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    window.location.href="/clientDelivery";
  }
  function heroDelivery() {
    // let audioEl =  document.getElementById("mainSound");
    // console.log("Audio element", audioEl);
    // (audioEl as HTMLAudioElement).play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    window.location.href="/missions";
  }
  return (
      <IonPage>
          <Header menu={true}/>

              <div style={{
                  backgroundImage: 'url(assets/img/Blue_White_Bkg.jpg)',
                  backgroundSize: 'cover',
                  backgroundPositionY: 'center',
                  display: 'flex',
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
              }}>

                  <IonFabButton onClick={clientDelivery} style={{
                      '--background': '#ffffff', "margin": "auto", 'minHeight': '111px', 'width':'111px'
                  }}>
                      <IonText className="btnTitle" style={{'color':'#005494'}}>HIRE</IonText>
                  </IonFabButton>
                  <IonFabButton onClick={heroDelivery} style={{
                      '--background': '#005494', "margin": "auto", 'minHeight': '111px', 'width':'111px'
                  }}>
                      <IonText className="btnTitle">HERO</IonText>
                  </IonFabButton>
              </div>

          <Footer/>
      </IonPage >
  );
}
export default Hire;

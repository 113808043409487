import React, {useState} from 'react';
import {
    IonPage,
    IonFabButton,
    IonInput,
    IonButton,
   IonContent, IonRow, IonCol, IonLabel, IonItem, IonSpinner
} from '@ionic/react';
import { useHistory } from 'react-router';
import Footer from "../../components/Footer";
import {firestore, storageRef} from "../../firebaseConfig";
import {toast} from "../../toast";
import Header from "../../components/Header";
import firebase from 'firebase';
import {extractFramesFromVideo} from "../../util/videoUtils";
import {uploadInProgressNotification} from "../../util/notification";

interface ProfileVideoDeliveryProps{
   location: any;
}

const ProfileVideoReview: React.FC<ProfileVideoDeliveryProps> = (props) => {

    const [heroName, setHeroName] = useState('');
    const [clientName, setClientName] = useState('');

    const [profilePosterFileContent, setProfilePosterFileContent] = useState('');
    const [showUploadProgress, setShowUploadProgress] = useState(false);

    const history = useHistory();
    if (!props.location.state)
        return null
    const {file, fileURL, role, idx, userId, fromPage, update, userHeroName, userClientName, villain, showProgressBar} = props.location.state as any;


    function completeProfile(name, role, userId, idx,profilePosterFileContent, update) {

        if (name === undefined || name.trim() === '') {
            return toast('Please enter your ' + role + ' name');
        }

        setShowUploadProgress(true);
        uploadInProgressNotification().then(() => console.log("upload in progress"));

        let fileExtension = file.type.split("/").pop();

        let videoContainer  = document.getElementById('profileVideoPlayer') as HTMLVideoElement;
       // console.log("video info", videoContainer.duration);

        // if (videoContainer.duration > 60) {
        //     URL.revokeObjectURL(fileURL);
        //     return toast('Video exceeds the 1-minute limit. Please upload shorter video');
        // }

        //   // let videoName = name ? name: userHeroName;


        let videoName = name.replace(/ /g, "_")+'profile'+Date.now()+'.'+fileExtension;
        // console.log("role", role);
        // console.log("video name", videoName);
        // console.log("user hero name", userHeroName);
        // console.log("hero name", heroName);
        // console.log("name", name);

        if (profilePosterFileContent === '') {
            let thumbnailRef = extractFramesFromVideo('profileVideoPlayer',fileURL, 1, 'profile', role, update ? userHeroName: name);
            profilePosterFileContent = thumbnailRef;
           // console.log("thumbnail", thumbnailRef);
        }
        let profileVideoRef = storageRef.child('profileVideos/'+role+'/'+videoName);
     //   console.log("profile video ref ", profileVideoRef);
        profileVideoRef.put(file).then(function() {
            console.log('Uploaded a profile video');
            if (update) {
                console.log('update' + role + userId);
                let userDocRef = firestore.collection('users').doc(userId);

                userDocRef.update({
                    profileVideo: profileVideoRef.fullPath,
                    profileVideoUrl: profileVideoRef.fullPath,
                    photoURL: profilePosterFileContent,
                })
                    .then(function() {
                        console.log("Profile video successfully updated!");
                        window.location.href='/profile';
                    })
                    .catch(function(error) {
                        // The document probably doesn't exist.
                        console.error("Error updating profile Video: ", error);
                    });
            }
            else {
                if (role === 'hero') {
                    createHeroProfile(profileVideoRef, name, userId, idx, profilePosterFileContent);
                }
                else if (role === 'villain') {
                    createVillainProfile(profileVideoRef, name, userId, idx, profilePosterFileContent);
                }
                else {
                    createClientProfile(profileVideoRef, name, userId, idx, profilePosterFileContent);
                }
                //setShowUploadProgress(false);
            }
        });


    }

    function createHeroProfile(profileVideoRef,  name, userId, idx, profilePosterFileContent) {

        const heroProfileRef = firestore.collection("heroes").add({
            heroName: name,
            profileVideo: profileVideoRef.fullPath,
            profileVideoUrl: profileVideoRef.fullPath,
            contentType: file.type,
            userId: userId,
            heroNumber: idx,
            photoURL: profilePosterFileContent,
            poseAsVillain: villain,
        }).then(ref => {
            history.push({
                pathname: '/register',
                state: { hero: ref.id, role:'hero', photoURL: profilePosterFileContent, profileVideoUrl: profileVideoRef.fullPath, heroName: name, idx: idx
                  ,villain: villain}
            });
            console.log('Added hero  with ID: ', ref.id);
        });
    }

    function createVillainProfile(profileVideoRef,  name, userId, idx, profilePosterFileContent) {

        const heroProfileRef = firestore.collection("villain").add({
            characterName: name,
            characterName_nocase: name.toLowerCase(),
            profileVideo: profileVideoRef.fullPath,
            profileVideoUrl: profileVideoRef.fullPath,
            contentType: file.type,
            userId: userId,
            heroNumber: idx,
            photoURL: profilePosterFileContent,
            poseAsVillain: true,
        }).then(ref => {
            history.push({
                pathname: '/register',
                state: { hero: ref.id, role:'villain', photoURL: profilePosterFileContent, profileVideoUrl: profileVideoRef.fullPath, heroName: name, idx: idx
                    ,villain: villain}
            });
            console.log('Added villain  with ID: ', ref.id);
        });
    }

    function createClientProfile(profileVideoRef,  name, userId, idx, profilePosterFileContent) {

        const clientProfileRef = firestore.collection("clients").add({
            clientName: name,
            profileVideo: profileVideoRef.fullPath,
            profileVideoUrl: profileVideoRef.fullPath,
            contentType: file.type,
            userId: userId,
            clientNumber: idx,
            photoURL: profilePosterFileContent,
        }).then(ref => {
            history.push({
                pathname: '/registerClient',
                state: {client: ref.id, role: 'client',photoURL: profilePosterFileContent, profileVideoUrl: profileVideoRef.fullPath, idx: idx, clientName: name}
            });
            console.log('Added client  with ID: ', ref.id);
        });
    }


    const onUploadProfileImage = (e: any) => {

        let profilePosterFile = e.target.files[0];
        let profilePosterFileUrl = URL.createObjectURL(profilePosterFile);
        let profilePosterFileExtension = profilePosterFile.type.split("/").pop();

        let profilePosterFileName = heroName ? heroName: (clientName? clientName: 'user_'+idx);

        if (update) {
            profilePosterFileName = userHeroName ? userHeroName: (userClientName? userClientName: 'user_'+idx);
        }
        
        profilePosterFileName = profilePosterFileName + firebase.firestore.Timestamp.now();

        profilePosterFileName = profilePosterFileName.replace(/\./g,' ');
        profilePosterFileName = profilePosterFileName.replace(/ /g, "_");

        let posterFileName = profilePosterFileName + "."+profilePosterFileExtension;

        console.log('profilePosterFileName', posterFileName);

        let profileVideoEl = document.getElementById('profileVideoPlayer') as HTMLVideoElement;
        console.log("videoProfileEl", profileVideoEl);

        profileVideoEl.setAttribute('poster', profilePosterFileUrl);

        let profileThumbnailRef = storageRef.child('profiles/thumbnails/'+posterFileName);
        profileThumbnailRef.put(profilePosterFile).then(function(snapshot) {
            console.log('Uploaded the thumbnail for the profile');
        }).catch((err)=>toast("error uploading thumbnail"+err));
        setProfilePosterFileContent(profileThumbnailRef.fullPath);

    }

    async function clickUploadProfileImage(e) {
        document.getElementById('uploadImageBtn').click();
    }

    return (


        <IonPage>
            <Header noChat={true}/>
            <IonContent>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: 20, overflowY: 'auto'}}>
                    <div style={{}}>
                        <video style={{maxWidth: '100%'}} id="profileVideoPlayer" controls controlsList="nodownload" src={fileURL} autoPlay muted>
                            <source src={fileURL} type={file?.type}/>
                        </video>
                        <img id="posterGenerated" alt={'thumbnail'} style={{'display':'none'}}/>
                        {!update &&
                        <div>
                            <span style={{color: '#005494', fontWeight: 'bold', margin: 5}}>
                                <IonItem className="inputBackground">
                                    {role === 'hero'
                                    &&  <IonInput autocapitalize="on" className="appText" style={{color:'#005494'}} placeholder={"Input your " + role + " name"} onIonChange={(e: any) => setHeroName (e.target
                                        .value)}></IonInput>
                                    }
                                    {role === 'villain' &&
                                    <IonInput autocapitalize="on" className="appText" style={{color:'#005494'}} placeholder={"Input your " + role + " name"} onIonChange={(e: any) => setHeroName (e.target
                                        .value)}></IonInput>}
                                    {role === 'client' && <IonInput autocapitalize="on" className="appText" style={{color:'#005494'}} placeholder="Input Your Client name" onIonChange={(e: any) => setClientName(e.target
                                        .value)}></IonInput>}
                                </IonItem>
                            </span>

                        </div>}

                    </div>

                    <IonRow style={{'color':'#005494'}}>
                        <IonCol>
                            <IonButton id="uploadVideoImgBtn"
                                       className="editProfileButton"
                                       style={{'float':'right', 'fill':'none'}}
                                       onClick={(e) => {
                                           clickUploadProfileImage(e)
                                       }}>
                                <IonLabel className="editProfileButtonText">
                                    Choose Cover Image </IonLabel>
                                <input type="file" accept="image/*" className="uploadVideoBtn"
                                       id="uploadImageBtn"
                                       onChange={onUploadProfileImage}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    {role === 'hero'
                        &&
                    (showUploadProgress ?
                            <IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px'}}>
                                <IonSpinner/>
                            </IonFabButton>
                        :<IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px'}} onClick={() => completeProfile(heroName ? heroName : userHeroName, role, userId, idx,profilePosterFileContent, update)}>
                            COMPLETE
                        </IonFabButton>)
                    }

                    {role === 'villain'
                    && (showUploadProgress ?
                        <IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px'}}>
                            <IonSpinner/>
                        </IonFabButton>
                     :<IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px'}} onClick={() => completeProfile(heroName ? heroName : userHeroName, role, userId, idx,profilePosterFileContent, update)}>
                        COMPLETE
                    </IonFabButton>)}
                    {role === 'client' &&
                    (showUploadProgress
                        ?<IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px'}}>
                            <IonSpinner/>
                        </IonFabButton>
                        :<IonFabButton style={{'--background': '#005494', minHeight: 100, width: 100, fontWeight: 'bold', fontSize: 15, marginTop: '60px', marginBottom: '20px'}} onClick={() => completeProfile(clientName? clientName: userClientName, role, userId, idx, profilePosterFileContent,update)}>
                            COMPLETE
                        </IonFabButton>)}

                </div>
            </IonContent>
            {update &&
            <Footer/>}
        </IonPage>
    )
}
export default ProfileVideoReview;


import React from 'react';
import {
    IonButtons,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButton
} from '@ionic/react';
import { createOutline} from 'ionicons/icons';
import './Header.css';


const ChatSubHeader: React.FC = () => {

    return (
        <IonHeader class="ion-no-border">
            <IonToolbar>
                <IonButtons slot="end" className="ion-padding-start">
                    <IonButton href="/startConversation" style={{height:'fit-content'}}>
                        <IonIcon icon={createOutline} size="large"></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default ChatSubHeader;

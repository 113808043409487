import React, { useState }from 'react';
import { IonContent, IonPage,  IonRow, IonCol, IonGrid,
     IonInput, IonLabel,  IonItemGroup, IonButton, IonText, IonFab,
    IonFabButton} from '@ionic/react';

import { withRouter } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import authWrapper from "../../components/authWrapper";
import firebase from "firebase";
import DirectionVideoContainer from "../../components/DirectionVideoContainer";


interface CreateTrainingChallengeItemProps {
    history: any;
}
const CreateTrainingChallengeItem: React.FC<CreateTrainingChallengeItemProps> = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")


    const {user:{characterName, uid}} = props as any;

    console.log("user id in client delivery", uid);
    const onChange = (e: any) => {

        let ugDeliveryFile = e.target.files[0];
        let ugDeliveryFileType = ugDeliveryFile.type.split("/").pop();
        let ugDeliveryFileURL = URL.createObjectURL(ugDeliveryFile);

        let createdByName = characterName;
        props.history.push({
            pathname: '/ugChallengeReview',
            state: {
                ugDeliveryFile,
                ugDeliveryFileType,
                ugDeliveryFileURL,
                title,
                description,
                createdByName,
                uid,
                type: 'training'
            }
        })
    }

    async function clickUpload(e) {
        document.getElementById('uploadVideo').click();
    }

    async function recordVideo() {
        document.getElementById('recorder').click();

    }

    return (
        <IonPage>
            <Header/>
            <IonContent className="app" id="mainSection" scrollX scrollY>


                <IonGrid id="preRecording">

                    <IonRow><IonCol/></IonRow>
                    <IonRow><IonCol/></IonRow>
                    <DirectionVideoContainer assetName={"brand/explainers/training/training_challenge_assembly_JAN_tweak_HD.mp4"}
                                             posterAssetName={"brand/explainers/training/training_challenge_explainer.png"}/>
                    <IonRow><IonCol/></IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel className="appTextMain"> Create a Challenge for other Heroes to train their abilities.</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow><IonCol/></IonRow>

                    <IonRow>
                        <IonItemGroup className="app" style={{'paddingRight': '20px', 'paddingLeft': '20px'}}>
                            <IonLabel className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff', '--color':'#ffffff'}}>
                                Title of Challenge
                            </IonLabel>
                            <IonInput
                                value={title}
                                autocapitalize="on"
                                style={{
                                    'color': '#005494',
                                    '--background': '#ffffff',
                                    '--placeholder-color': '#005494',
                                    '--placeholder-opacity': 0.75,
                                    'align': 'center'
                                }}
                                type="text"
                                placeholder="Pick a name that matches the Challenge."
                                maxlength={30}
                                onKeyUp={(e: any) => {if (e.target.value.toString().length==30) alert('You can use maximum 30 characters for the name of the challenge');}}
                                onIonChange={(e: any) => setTitle(e.target.value)}
                            />
                        </IonItemGroup>
                    </IonRow>

                    <IonRow/>
                    <IonRow/>
                    <IonRow/>

                    <IonRow>
                        <IonItemGroup className="app"
                                      style={{'paddingRight': '20px', 'paddingLeft': '20px', 'paddingTop': '20px'}}>
                            <IonLabel className="appTextMain" style={{'paddingBottom': '10px', 'color':'#ffffff','--color':'#ffffff'}}>
                                What should a Hero do?
                            </IonLabel>
                            <IonInput
                                value={description}
                                autocapitalize="on"
                                style={{
                                    '--background': '#ffffff',
                                    '--color': '#005494',
                                    '--placeholder-opacity': 0.75,
                                    '--placeholder-color': '#005494',
                                    'padding':'10px'
                                }}
                                type="text"
                                placeholder={"Describe what a Hero should do."}
                                onIonChange={(e: any) => setDescription(e.target.value)}
                            >
                            </IonInput>
                        </IonItemGroup>
                    </IonRow>

                    <IonRow><IonCol/></IonRow>
                    <IonRow><IonCol/></IonRow>
                    <IonRow><IonCol/></IonRow>
                    <IonRow><IonCol/></IonRow>

                    <IonRow>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <IonText className="appTextMain" style={{'paddingBottom': '30px', 'color':'#ffffff', '--color':'#ffffff'}}>
                                Demonstrate your Challenge in a short video.
                            </IonText>
                            <div style={{height: 106,width: 106}}>
                                <IonFab style={{
                                    borderStyle: 'solid',
                                    borderRadius: '50%',
                                    borderWidth: 3,
                                    borderColor: 'red'
                                }}>
                                    <IonFabButton onClick={() => recordVideo()} style={{
                                        '--background': '#ffffff',
                                        minHeight: 100,
                                        width: 100,
                                        '--border-color': '#FF0000'
                                    }}>
                                        <IonLabel className="btnTitle" style={{'color':'#005494'}}>
                                            ACTION
                                        </IonLabel>
                                    </IonFabButton>
                                    <input type="file" accept="video/*" capture="camera"
                                           id="recorder"
                                           className="uploadVideoBtn"
                                           onChange={onChange}
                                    />
                                </IonFab>
                            </div>
                        </div>
                    </IonRow>

                    <IonRow/>
                    <IonRow className="ion-justify-content-end">
                        <IonCol>
                            <IonButton id="uploadVideoImgBtn"
                                       className="noShadowBtnBox"
                                       style={{'background': 'none', 'float':'right'}}
                                       onClick={(e) => {
                                           clickUpload(e)
                                       }}>
                                <IonText style={{'color':'#ffffff'}} className="italic"> Upload Video </IonText>
                                <input type="file" accept="video/*" className="uploadVideoBtn"
                                       id="uploadVideo"
                                       onChange={onChange}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>


                </IonGrid>

            </IonContent>
            <Footer />
        </IonPage>
    )
};


export default authWrapper(withRouter(CreateTrainingChallengeItem));

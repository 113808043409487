import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';

import { firestore} from '../../firebaseConfig';
import { IonPage, IonRow,  IonImg, IonContent, IonLabel,  IonAvatar, IonButton, IonList, IonItem,  IonFabButton, IonText, IonListHeader, IonRouterLink, IonSearchbar, IonIcon } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import {

    rival
} from "./allyrival";
import Header from "../../components/Header";
import { personAddOutline } from 'ionicons/icons';
import { downloadPictureContent, getPictureAddress } from '../../util/avatarUtils';

import {confirmRival, createRelationship, rejectWithdrawRival, updatePendingRequests} from "./relationships";

interface RivalsPageProps extends RouteComponentProps<{
    userId: string,
   }> {}


async function getRelationshipArray(userId) {
    let rivalsOut = firestore.collection('relationshipRequests').where('senderId', '==', userId).get();
    let rivalsIn = firestore.collection('relationshipRequests').where('rivalId', '==', userId).get();

    const [rivalsOutSnapshot, rivalsInSnapshot] = await Promise.all([
        rivalsOut,
        rivalsIn
    ]);


    const rivalsOutArray = rivalsOutSnapshot.docs;
    const rivalsInArray = rivalsInSnapshot.docs;

    const rivalsArray = rivalsOutArray.concat(rivalsInArray);

    return rivalsArray;
}

const Rivals: React.FC<RivalsPageProps> = ({match}) => {

    const [rivalsList, setRivalsList] = useState<any>(['']);
    const [alliesList, setAlliesList] = useState<any>(['']);
    const [suggestionList, setSuggestionList] = useState<any>(['']);
    const [searchText, setSearchText] = useState('');
    const [senderInfo, setSenderInfo] = useState({});

    const {userId} = match.params;

    useEffect(() => {

        //let isSubscribed = true;

        // setInterval(() => {
            //let rivalsList = [];

            let userDocRef = firestore.collection('users').doc(userId);

            userDocRef.get().then((doc) => {
                const rivalsListRef = {id: doc.id, ...doc.data()};

                //console.log("rivals:", rivalsListRef['rivals']);
                //rivalsList = rivalsListRef['rivals'];
                setSenderInfo(doc.data());

                getRelationshipArray(userId).then(snapshotRelationships => {
                    let rivalsList = [];
                    let alliesList = [];
                    snapshotRelationships.forEach(snapshotRelationship => {
                        if (snapshotRelationship.data()['relationshipType'] === 'rival') {
                            rivalsList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id})
                        }
                        else if (snapshotRelationship.data()['relationshipType'] === 'ally') {
                            alliesList.push({...snapshotRelationship.data(), uid: snapshotRelationship.id});
                        }
                        // if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {


                    });

                    if (searchText !== '') {
                        if (rivalsList !== undefined) {
                            rivalsList = rivalsList.filter(rivalItem => rivalItem.rivalName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                        }
                    }
                    setRivalsList(rivalsList);
                    setAlliesList(alliesList);


                    let rivalSuggestionRef = firestore.collection('users').where("complete", "==", true);
                    let query = searchText.toLowerCase()
                    let stringBound = query.slice(0, query.length-1)
                        + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)

                    if (query!=='' && query.length >=1) {
                       // console.log("suggestions", suggestionsRef);
                        rivalSuggestionRef.where('characterName_nocase','>=', query)
                            .where('characterName_nocase','<', stringBound)
                            .where('characterName_nocase','!=', '')

                    }
                    rivalSuggestionRef = rivalSuggestionRef.orderBy("characterName_nocase", "asc");

                    rivalSuggestionRef.onSnapshot(snapshot => {
                        let suggestionList = [];

                        snapshot.forEach(suggestionRivalDoc => {
                            if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] !== 'client') {
                                suggestionList.push({...suggestionRivalDoc.data(), uid: suggestionRivalDoc.id})
                            }
                        });
                        suggestionList = suggestionList.filter(item => item.uid !== userId);

                        if (rivalsList && rivalsList.length >= 1) {
                            for (let i = 0; i < rivalsList.length; i++) {
                                let rivalId = rivalsList[i]['rivalId'];
                                let senderId = rivalsList[i]['senderId'];
                                console.log("rival id", rivalId);
                                suggestionList = suggestionList.filter(item => item.uid !== rivalId);
                                suggestionList = suggestionList.filter(item => item.uid !== senderId);
                                //console.log(suggestionList);
                            }
                        }

                        if (alliesList && alliesList.length >= 1) {
                            for (let i = 0; i < alliesList.length; i++) {
                                let allyId = alliesList[i]['rivalId'];
                                let senderId = alliesList[i]['senderId'];
                                console.log("ally ", allyId);
                                suggestionList = suggestionList.filter(item => item.uid !== allyId);
                                //console.log(suggestionList);
                            }
                        }


                        if (searchText !== '') {
                            suggestionList = suggestionList.filter(item => item.characterName.trim().toLowerCase().startsWith(searchText.toLowerCase()));
                        }
                        setSuggestionList(suggestionList);
                    });
                });
            });
        // }, 3000);
        //return () => {isSubscribed = false}

     }, [userId, searchText]);


     function sendRivalRequest(rivalId, rivalName, rivalPhotoUrl) {
         let rivalRequestData = {
             senderId: userId,
             senderName: senderInfo['characterName'],
             senderPhotoURL: senderInfo['photoURL'],
             rivalId: rivalId,
             rivalPhotoUrl: rivalPhotoUrl,
             rivalName: rivalName,
             status: 'pending',
             relationshipType: 'rival',
         }

         createRelationship(rivalRequestData);
         updatePendingRequests(rivalId);
         updatePendingRequests(userId);
     }



    function viewProfile(profileId, relationship)  {
        if (profileId !== undefined) {
            window.location.href=`/viewAssociation/${profileId}/${relationship}`;
        }
    }
            return (
                <IonPage>
                <Header/>
                <IonContent className="ion-padding-bottom" scrollX scrollY>
                    { (rivalsList === undefined || rivalsList?.length < 1) &&
                    <IonFabButton disabled style={{
                        '--background': '#ffffff', "margin": "20px auto 20px auto", 'minHeight': '111px', 'width':'111px'
                    }}>
                        <IonIcon icon={personAddOutline} style={{'color': '#005494'}}/>
                    </IonFabButton>
                    }
                    { (rivalsList === undefined || rivalsList?.length < 1) &&
                    <IonRow className="ion-justify-content-center" style={{margin: "10px 10px 10px 10px", textAlign:'center'}}>

                        <IonLabel className="sectionTitle"
                                  style={{'color': '#005494'}}>
                            <IonText> Follow other Heroes as either Allies or Rivals on their Profile Page.</IonText>
                        </IonLabel>
                    </IonRow>}

                    <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} debounce={0}></IonSearchbar>

                    {rivalsList && rivalsList.length >= 1 &&
                    <IonList>
                        <IonListHeader>
                            <IonLabel className="sectionTitle"
                                      style={{'align': 'center', 'color': '#005494'}}> Rivals
                            </IonLabel>
                        </IonListHeader>
                        {rivalsList && rivalsList.map((rival, index) => {
                            return (

                                <IonItem key={index} lines="none">

                                    <IonAvatar slot="start">
                                        {rival.rivalId === userId ?

                                             <IonImg id={'rivalProfile'+rival.senderId} src={rival.senderPhotoURL ? getPictureAddress(rival.senderPhotoURL,rival.senderId, 'rivalProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                              onClick={() => viewProfile(rival.senderId, 'rival')}/>
                                           :<IonImg id={'rivalProfile'+rival.rivalId} src={rival.rivalPhotoUrl ? getPictureAddress(rival.rivalPhotoUrl,rival.rivalId, 'rivalProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                                                         onClick={() => viewProfile(rival.rivalId, 'rival')}/>
                                        }
                                    </IonAvatar>

                                    <IonLabel>
                                        {rival.rivalId === userId ?
                                            <IonRouterLink href={`/viewAssociation/${rival?.senderId}/${'rival'}`}>
                                                {rival.senderName}
                                            </IonRouterLink>
                                            :<IonRouterLink href={`/viewAssociation/${rival?.rivalId}/${'rival'}`}>
                                                {rival.rivalName}
                                            </IonRouterLink>
                                        }

                                    </IonLabel>
                                    <div style={{
                                        marginRight: -17,
                                        marginTop: 0,
                                        width: 25, height: 42,
                                        transform: "skew(20deg)",
                                        backgroundColor: '#005494',
                                        boxShadow: "-4px 0px 4px -4px dimgray",

                                    }}/>

                                    {/*{rival.senderId} {rival.rivalId} {userId}*/}
                                    {rival.status === 'pending' && rival.senderId === userId &&
                                        <>
                                        <IonButton className="rivalsBtn" onClick={() => rejectWithdrawRival(rival.uid)} >

                                             {/*<IonButton className="rivalsBtn" onClick={() => removeRival(rival.rivalId, rival.rivalName, rival.rivalPhotoUrl, rival.status)} >*/}
                                                {rival.senderId === userId ?  'Pending/Withdraw': 'Awaiting/Confirm'}
                                            </IonButton>

                                        </>
                                    }
                                    {rival.status === 'pending' && rival.rivalId === userId &&
                                    <>
                                        <IonButton className="rivalsBtn"
                                                   onClick={() => confirmRival(rival.uid)}> Approve </IonButton>
                                        <IonButton className="rivalsBtn" onClick={() => rejectWithdrawRival(rival.uid)} > Reject  </IonButton>
                                    </>}

                                    {rival.status === 'approved' && (rival.senderId === userId || rival.rivalId === userId) && 

                                        <>
                                            <IonButton className="rivalsBtn" onClick={() => rejectWithdrawRival(rival.uid)} >
                                                Remove
                                            </IonButton>

                                        </>

                                    }
                                </IonItem>
                            );
                        })}
                    </IonList>
                    }
                    <IonRow>
                        <IonLabel className="sectionTitle"
                                  style={{'paddingLeft': '20px', 'color': '#005494'}}>
                            Suggestions
                        </IonLabel>
                    </IonRow>
                    <IonList>
                    {suggestionList && suggestionList.map((suggestion, index) => {
                        return (

                           <IonItem key={index} lines="none">

                              <IonAvatar slot="start">
                                  <IonImg id={'rivalSuggestionProfile'+suggestion.uid} src={suggestion.photoURL ? downloadPictureContent(suggestion.photoURL,suggestion.uid, 'rivalSuggestionProfile') : "https://www.gravatar.com/avatar?d=mm"}
                                          onClick={() => viewProfile(suggestion.uid, 'none')}/>
                              </IonAvatar>

                               <IonLabel>
                               <IonRouterLink href={`/viewAssociation/${suggestion?.uid}/${'none'}`}>
                                    {suggestion?.characterName !== '' ? suggestion?.characterName: suggestion?.username}
                               </IonRouterLink>
                               </IonLabel>
                               <div style={{
                                    marginRight: -17,
                                    marginTop: 0,
                                    width: 25, height: 42,
                                    transform: "skew(20deg)",
                                    backgroundColor: '#005494',
                                    boxShadow: "-4px 0px 4px -4px dimgray",

                                }}/>
                              <IonButton className="rivalsBtn appBtn" onClick={() => sendRivalRequest(suggestion.uid, suggestion?.characterName, suggestion?.photoURL !== undefined ? suggestion?.photoURL : ' ') }> Rival  </IonButton>


                           </IonItem>
                        );
                    })}
                    </IonList>
                </IonContent>
                <Footer/>
            </IonPage>
            )

    }

 export default Rivals;

import React, {useEffect, useState } from 'react';
import {IonCard, IonContent, IonGrid, IonIcon, IonImg, IonRow, IonSearchbar, IonThumbnail} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import authWrapper from '../../components/authWrapper';
import { ContentItem } from '../../models/ContentItem';
import DeliveryModuleScreen from '../../components/DeliveryModuleScreen';
import WurldDeliveryModuleScreen from '../../components/WurldDeliveryModuleScreen';
import {downloadContent, downloadPictureContent} from "../../util/avatarUtils";
import {cloudDownload, search} from "ionicons/icons";


interface AdminMissionFeedProps {

}


const AdminMissionFeed: React.FC<AdminMissionFeedProps> = (props) => {

    const [missionList, setMissionList] = useState([]);
    const [searchMission, setSearchMission] = useState('');

    useEffect(() => {

        let queryMissionsFeed = searchMission.toLowerCase()
        let stringBoundMission = searchMission.slice(0, searchMission.length-1)
            + String.fromCharCode(search.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (searchMission!='' && searchMission.length >=1) {
            // console.log("query", query);
            const searchMissionRef = firestore.collection('content').where('contentType', '==', 'mission')
                .where('completedByHeroName','>=', searchMission)
                .where('completedByHeroName','<', stringBoundMission)
                .where('completedByHeroName','!=', '')
                .orderBy('completedByHeroName', 'asc')
                .orderBy('timeCreated', 'desc')

            searchMissionRef.onSnapshot(snapshot => {
                setMissionList(snapshot.docs.map(missionDoc => ({...missionDoc.data(), uid: missionDoc.id })));

            });

        }
        else {

            let missionsRef =  firestore.collection('content').where('contentType', '==', 'mission').orderBy("timeCreated", "desc")
                .onSnapshot(snapshot => {

                    let missionsList = [];

                    snapshot.forEach(castDoc =>
                        missionsList.push({...castDoc.data(), uid: castDoc.id}),
                    );

                    setMissionList(missionsList);
                });


        }


    }, [searchMission]);

    return (
        <>
            <IonSearchbar value={searchMission} autoCapitalize="on" onIonChange={e => setSearchMission(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}></IonSearchbar>

            {missionList.map((missionItem, idxM) => {
                return (
                    <IonCard key={idxM} className="child2">
                        <IonThumbnail style={{'height':'70%', 'width':'100%'}}>
                            <IonImg id={'contentItemAdmin'+idxM+missionItem.uid} src={missionItem.posterUrl ? downloadPictureContent(missionItem.posterUrl,missionItem.uid, 'contentItemAdmin'+`${idxM}`) : "https://www.gravatar.com/avatar?d=mm"}
                                    alt={"Content Image"}  />
                        </IonThumbnail>

                        <div>
                            <IonGrid>
                                <IonRow>
                                    {missionItem.objectRefTitle}_{missionItem.completedByHeroName}
                                </IonRow>
                                <IonRow>
                                    <a href={downloadContent(missionItem.contentVideo)} target="_blank"><IonIcon icon={cloudDownload}/></a>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCard>
                )
            })}
        </>
    );


}
export default AdminMissionFeed;

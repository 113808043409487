
import React from 'react';
import { IonRow, IonCol, IonText, IonImg, IonAvatar, IonGrid, IonCard } from '@ionic/react';

import { useHistory} from "react-router";
import Moment from 'react-moment';
import { Conversation } from '../../models/Conversation';
import {downloadPictureContent, getPictureAddress, viewProfileAssociation} from "../../util/avatarUtils";
import moment from "moment";
import { firestore } from '../../firebaseConfig';


interface ConversationItemProps {
    conversation: Conversation;
    user: any;
    index: any;
}


const ConversationItem: React.FC<ConversationItemProps> = (props) => {

    const history = useHistory();

    let {conversation, index, user} = props as any;

    const viewConversation = (conversation) => {
        console.log("messages", conversation.senderId);
        let notifyUserId = conversation.senderId;
        console.log("sender", conversation.senderId);

        if (user.uid === conversation.receiverId) {
            notifyUserId = conversation.receiverId;
        }

        let notifyUserRef = firestore.collection('users').doc(notifyUserId);

        let notifyUserRefQuery = notifyUserRef.set({
            unread: false
        }, {merge: true});

        notifyUserRefQuery.then((notificationDestination) => {
            console.log("notify user ", notificationDestination);

            //notification('You have new messages');
            /*
                        notification('Hey, this is your first time in JFH Chat. Welcome! Important note: You’ll see an “in character” and “out of character” toggle in Cast Chat that’s super important. When you want to talk to other heroes as if you’re playing your character on a movie set and its dialogue in the movie, choose “in-character.” If you want to chat as your true identity (ie. your real name), “out-of-character” is best. ')
            */
        });

        history.push({
            pathname: "/conversationThread",
            state: {conversationId: conversation.uid, sender: {uid: conversation.senderId, photoURL: conversation.senderPhotoURL, heroName: conversation.senderHeroName, role: 'hero'}, receiver: {uid: conversation.receiverId, photoURL: conversation.receiverPhotoURL, heroName: conversation.receiverHeroName, role: 'hero'}, user: user, fromPage: 'chat', selectedTab: 'dm'},
        });
    }

    return (
            <IonCard>
                <IonRow onClick={()=> viewConversation(conversation)}>
                    <IonCol size="2">
                        <IonAvatar>
                            {conversation.senderId === user.uid
                            &&
                            <IonImg id={'receiver'+conversation.receiverId+index} src={conversation.receiverPhotoURL ? getPictureAddress(conversation.receiverPhotoURL,conversation.receiverId, 'receiverConvo'+index) : "https://www.gravatar.com/avatar?d=mm"}
                                    onClick={() => viewProfileAssociation(conversation.receiverId)}/>}
                            {conversation.receiverId === user.uid
                            &&
                            <IonImg id={'receiver'+conversation.senderId+index} src={conversation.senderPhotoURL ? getPictureAddress(conversation.senderPhotoURL,conversation.receiverId, 'senderConvo'+index) : "https://www.gravatar.com/avatar?d=mm"}
                                    onClick={() => viewProfileAssociation(conversation.senderId)}/>}
                        </IonAvatar>
                    </IonCol>
                    <IonCol size="8">
                        <IonRow>
                            <IonText style={{fontWeight:'bold',paddingLeft:'1px'}}>{conversation.senderId === user.uid ? conversation.receiverHeroName: conversation.senderHeroName}</IonText>
                        </IonRow>
                        <IonRow>
                            {conversation.messages !== undefined && conversation.messages[conversation.messages?.length-1]?.checked ?
                                <IonText style={{flexWrap:'noWrap', color:'#005494', fontWeight:(conversation.seen !== undefined && !conversation.seen && user.unread)? 'bold':''}}>
                                    {conversation.messages !== undefined ? (conversation.messages[conversation.messages?.length-1]?.answerContent.length > 30 ? conversation.messages[conversation.messages?.length-1]?.answerContent.slice(0,30) + '...': conversation.messages[conversation.messages?.length-1]?.answerContent): ''}
                                </IonText>
                                : <IonText style={{flexWrap:'noWrap', fontWeight:(conversation.seen !== undefined && !conversation.seen && user.unread)? 'bold':''}}>
                                    {conversation.messages !== undefined ? (conversation.messages[conversation.messages?.length-1]?.answerContent.length > 30 ? conversation.messages[conversation.messages?.length-1]?.answerContent.slice(0,30) + '...': conversation.messages[conversation.messages?.length-1]?.answerContent) :''}
                                </IonText>
                            }
                        </IonRow>
                        <IonRow style={{'fontSize':'8px'}}>
                            <Moment format='MMMM Do YYYY, h:mm a' style={{'fontSize':'8px'}}>{conversation.lastUpdated?.toDate()}</Moment>

                            {/*<Moment format='MMMM Do YYYY, h:mm:ss a' style={{'fontSize':'8px'}}>{conversation?.messages !== undefined ? conversation.messages[conversation.messages?.length-1]?.sendTime?.toDate(): ''}</Moment>*/}
                        </IonRow>

                    </IonCol>

                </IonRow>
            </IonCard>
    );
};

export default ConversationItem;


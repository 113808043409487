import React from 'react';

import './Challenges.css';

import { firestore } from '../../firebaseConfig';
import { Challenge } from '../../models/Challenge';
import authWrapper from '../../components/authWrapper';
import ChallengeListItem from "./ChallengeListItem";

interface UGCTrainingListPageState {
    loading: boolean;
    ugcTrainingList: Challenge[];
    user: any;
}
class UGCTrainingListPage extends React.Component<{}, UGCTrainingListPageState>  {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ugcTrainingList: [],
            user: props.user,

        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        firestore.collection('challenges').where("type", "==", 'training').where("ugc", "==", true).orderBy('index','asc')
            .onSnapshot(snapshot => {
                let ugcTrainingList = [];

                snapshot.forEach(doc =>
                    ugcTrainingList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    ugcTrainingList,
                    loading: false,
                });
            });
    }



    render() {
        const { ugcTrainingList, loading, user} = this.state;

        const hide  = true;

        return (

            <>

                {ugcTrainingList.map((training, index) => {
                    return (
                        <ChallengeListItem key={index} challenge={training} index={index} showButtons={true} user={user}/>

                    );
                })}


            </>

        );
    };
}

export default authWrapper(UGCTrainingListPage);

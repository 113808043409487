import React  from 'react';
import { IonRow,  IonLabel, IonAvatar, IonImg, IonCol} from '@ionic/react';
import {downloadPictureContent, viewProfileAssociation} from "../util/avatarUtils";

interface UserDetailsProps {
    userPhotoURL: string;
    userId: string;
    userHeroName: string;
    userRole: string;
    showMoreInfo: boolean;
    userRealName: string;
    tab: string;
    index: number;
}

const UserDetails: React.FC<UserDetailsProps> = (props) => {

    return (
            <IonRow className="ion-justify-content-left">
                <IonCol size="2">
                    <IonAvatar>
                        <IonImg  id={props.tab+props.index+props.userId} src={props.userPhotoURL ? downloadPictureContent(props.userPhotoURL,props.userId, props.tab+props.index) : "https://www.gravatar.com/avatar?d=mm"}
                                onClick={() => viewProfileAssociation(props.userId)}/>
                    </IonAvatar>
                </IonCol>
                <IonCol size="4">
                    {props.showMoreInfo &&
                    <IonRow className="ion-text-sm-wrap">
                        <IonLabel className="ion-text-sm-wrap" style={{textAlign:'left', marginLeft:'12px'}} onClick={() => viewProfileAssociation(props.userId)}>{props.userHeroName}</IonLabel>
                    </IonRow>
                    }
                    {props.showMoreInfo &&
                    <IonRow className="ion-text-sm-wrap">
                        <IonLabel style={{textAlign:'left', marginLeft:'12px'}} >Role: {props.userRole?.toUpperCase()}</IonLabel>
                    </IonRow>}
                </IonCol>
                <IonCol style={{marginLeft:'3px',textAlign:'left'}}>
                    {props.userRealName ? props.userRealName : ''}
                </IonCol>
            </IonRow>
    );
};

export default UserDetails;


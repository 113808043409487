
import React, { useState, useRef } from 'react';
import {IonAvatar, IonIcon} from "@ionic/react";
import {playOutline} from "ionicons/icons";
import { storageRef } from '../../firebaseConfig';
import { openFullScreen } from '../../util/videoUtils';


interface ChallengePromptVideoProps {
    tab: string;
    platformName: string;
}
const ChallengePromptVideo: React.FC<ChallengePromptVideoProps> = (props) => {

    const [contentUrl, setContentUrl] = useState('');
    const [posterContentUrl, setPosterContentUrl] = useState('');
    const videoRef = useRef(null);
     let assetName="Challenges/development/Challenge-Development-RecreateJFHEpisode03.mp4";
     let posterAssetName="app/watch/ep3_cta.png";

    storageRef.child(assetName).getDownloadURL().then(function(url) {
        setContentUrl(url);
        let img = document.getElementById('challengeVideoPrompt'+props.tab);
        img.setAttribute("src", url);
    }).catch(function(error) {
        // Handle any errors
    });

    if (posterAssetName!='') {
        storageRef.child(posterAssetName).getDownloadURL().then(function(posterUrl) {
            setPosterContentUrl(posterUrl);
            let img = document.getElementById('challengeVideoPrompt');
            img.setAttribute("poster", posterUrl);
        }).catch(function(error) {
            // Handle any errors
        });
    }



    function onVideoClick(e) {
        let videoEl = videoRef.current;

        // let playBtn = document.getElementById('play'+item.code);
        // let stopBtn = document.getElementById('stop'+item.code);

        if (props.platformName ==='android') {
            videoEl.onfullscreenchange = (e=> {
                if (!document.fullscreenElement)
                    videoEl.pause();
            })
        }

        if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
            //hide play. show stop
            // playBtn.style.display='block';
            // stopBtn.style.display='none';

            videoEl.pause();
        }
        else if (videoEl.paused || videoEl.ended) {
            // videoEl.webkitEnterFullScreen();
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (props.platformName ==='android') {
                        openFullScreen(videoEl);
                    }
                    console.log("resume video");
                    console.log("video play time", videoEl.currentTime);
                }
            ).catch(() => console.log("error playing video"));
        }
        else {
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (props.platformName ==='android') {
                        openFullScreen(videoEl);
                    }
                }
            ).catch(() => console.log("error playing video"));
        }
    }
    return (

        <div className="video-wrapper">
            <div className="overlay"
                 onClick={onVideoClick}
            >
                <IonAvatar className="right playBtn">
                    <IonIcon className="highContrast" style={{padding: 2}} icon={playOutline}/>
                </IonAvatar>

            </div>
            <video id={'challengeVideoPrompt'+props.tab} poster={posterContentUrl} controlsList="nodownload" ref={videoRef} onEnded={e=> videoRef.current.load()}>
                <source src={contentUrl} type="video/mp4" />
            </video>

        </div>
    );
};

export default ChallengePromptVideo;



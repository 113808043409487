import React from 'react';
import '../Challenges/Challenges.css';

import { firestore } from '../../firebaseConfig';
import {PremiumContent} from "../../models/PremiumContent";
import PremiumContentPlayer from "./PremiumContentPlayer";
import {
    IonCard,
    IonSlide,
    IonSlides,
    IonCardContent,
    IonButton,
    IonRow,
    IonIcon,
    IonImg,
    IonRouterLink,
    IonItem,
    IonToolbar,
    IonGrid,
    IonButtons, IonSegment, IonSegmentButton, IonLabel, IonContent, IonText, IonCol, IonNote, IonCardHeader, IonThumbnail
} from "@ionic/react";
import './Sliders.css';
import {star} from "ionicons/icons";
import UserAvatar from "../../components/UserAvatar";
import UserDetails from "../../components/UserDetails";
import ContentPlayer from "../../components/Player/ContentPlayer";
import ProfileCallVideo from './Universe/ProfileCallVideo';
import MissionDeliveryComponent from '../../components/MissionDeliveryComponent';
import authWrapper from '../../components/authWrapper';
import {downloadPictureContent, viewProfileAssociation } from '../../util/avatarUtils';
import { useHistory } from 'react-router';
import PremiumDevelopmentChallenges from "../Challenges/PremiumDevelopmentChallenges";
import PremiumDevelopmentChallengesItems from "../Challenges/PremiumDevelopmentChallengesItems";

interface ProductionContentFeedState {
    productionContentList: PremiumContent[],
    loggedInUser: any,
    history: any,
    activeIdx: string,
}


class ProductionContentFeed extends React.Component<{},ProductionContentFeedState>   {

    constructor(props) {
        super(props);

        this.state = {
            productionContentList: [],
            loggedInUser: props.user,
            history: props.history,
            activeIdx: null,
        };
    }

    componentDidMount() {

        firestore.collection('premiumContent').where('contentType','==','production').orderBy('date','desc')
            .onSnapshot(snapshot => {
                let productionContentList = [];
                snapshot.forEach(doc =>
                    productionContentList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    productionContentList,
                });
            });

    }


    render() {

        const { productionContentList, loggedInUser, history, activeIdx} = this.state;

        let missionBackup = {code: 'HB', title: 'Hero Backup', uid: 'uFPkuZHelTiTHmSfBL5Y',
           clientName: 'Justice F. Hire',
            posterUrl: 'Missions/thumbnails/Mission-Hero-Backup-CTA-thumbnail.jpg',
            contentUrl: 'Missions/content/Mission-Hero-Backup-CTA.MOV',
            completedBy: [''],
            description: 'Join another hero to help on their mission.',
            direction: ['Back another hero up on either an original mission or an existing JFH mission.', 'Keep your camera beside the hero you are backing up. Make sure to keep them, yourself, and the action in the shot. '],
            ctaVideoUrl: 'Missions/content/Mission-Hero-Backup-CTA.MOV',
            contentType: 'video/quicktime',
            submittedBy: '',
        };

        let missionWTH = {code: 'WTH', title: 'Wash The Hate', uid: '9y1YwB2dJqd0fUawLq7p',
            clientName: 'Telly Wong',
            posterUrl: 'Missions/thumbnails/#WashTheHate_YouTubeCover.jpg',
            contentUrl: 'Missions/content/Wash_the_Hate_JFH_CTA_1080p.mp4',
            completedBy: [''],
            direction: ['Always start your mission by clearly stating your  hero name followed by the phrase "on mission".', 'No firearms or anything that resembles a real weapon'],
            description: 'Hate is our common enemy. Take on this mission and stand up for human rights.',
            ctaVideoUrl: 'Missions/content/Wash_the_Hate_JFH_CTA_1080p.mp4',
            contentType: 'video/mp4',
            submittedBy: '',
        };

        let missionCWB = {code: 'AaQJb3iqc1gA3S5WLY64sS92wpy2MC', title: 'Enter The Cowboy', uid: 'zwSnzv00X8uRENeBDM4S',
            clientName: 'Justice F. Hire',
            posterUrl: 'Missions/thumbnails/cowboy_premiummission_coverimage.jpg',
            contentUrl: 'Missions/content/Enter_The_Cowboy1624477749720.mp4',
            completedBy: [''],
            direction: [''],
            description: 'Cowboy is spying and hunting JFH Heroes as part of a bigger plot. To participate, show yourself in-character doing whatever your character does, but with a cowboy hat in the shot..',
            ctaVideoUrl: 'Missions/content/Enter_The_Cowboy1624477749720.mp4',
            contentType: 'video/mp4',
            submittedBy: '',
        };
        //console.log("this.state.props", this.state);
        const slideOpts = {
            effect: 'cube',
            zoom: false
        };

        const showHideUniverse = idx => {
            let productionContentContainer = document.getElementById('productionUniverseContent'+idx);
            let universeCardContentContainer = document.getElementById('productionUniverseCardContent'+idx);
            let productionCardHeaderContainer = document.getElementById('productionCardHeader'+idx);
            if (!productionContentContainer) return
            if (productionContentContainer.style.display === 'block') {
                productionContentContainer.style.display='none';
                universeCardContentContainer.style.display='none';
                productionCardHeaderContainer.style.display='none';
                this.setState({activeIdx: null})
            }
            else {
                productionContentContainer.style.display='block';
                universeCardContentContainer.style.display='block';
                productionCardHeaderContainer.style.display='block';
                this.setState({activeIdx: idx})
            }
        }

        function changeSegmentContent(selectedSegment, idx) {
            let castFeedContainer = document.getElementById('productionCastFeed'+idx);
            let ctaFeedContainer = document.getElementById('productionCtaFeed'+idx);
            let ugcFeedContainer = document.getElementById('productionUgcFeed'+idx);

            if (selectedSegment === ('productionCast'+idx)) {
                castFeedContainer.style.display='block';
                ctaFeedContainer.style.display = 'none';
                ugcFeedContainer.style.display='none';

            } else if (selectedSegment ===  ('productionCta'+idx)) {
                ctaFeedContainer.style.display = 'block';
                castFeedContainer.style.display = 'none';
                ugcFeedContainer.style.display='none';
            }
            else if (selectedSegment ===  ('productionUgc'+idx)) {
                ugcFeedContainer.style.display='block';
                ctaFeedContainer.style.display = 'none';
                castFeedContainer.style.display='none';
            }
        }

        let completeProfile = () => {
            window.location.href='/profile';
        }

        return (
            <IonSlides pager options={slideOpts} onIonSlideDidChange={() => showHideUniverse(activeIdx)}
>

                {productionContentList && productionContentList.map((contentItem, idx) => {
                    return (
                        <IonSlide key={idx}>
                            <IonCard className='widthFullCard'>
                                <PremiumContentPlayer key={idx} item={contentItem}/>
                                <IonGrid style={{'background':'#005494'}}>
                                    <IonButton id={'productionUniverseBtn'+idx} className="universeBtn italic" onClick={() => showHideUniverse(idx)}> UNIVERSE
                                        <IonIcon slot="end" src={"assets/img/universe.svg"} />
                                    </IonButton>

                                    <IonCard id={'productionUniverseContent'+idx}  style={{'background':'#ffffff', 'display':'none', 'margin':'0'}}>
                                        <IonCardHeader id={'productionCardHeader'+idx} style={{'display':'none'}}>
                                            <IonRow className="ion-text-justify">
                                                <IonText style={{ 'color':'#005494'}} className="bolder">{contentItem.title}</IonText>
                                            </IonRow>
                                            <IonRow className="ion-text-justify" style={{ 'marginBottom':'4px'}}>
                                                <IonText style={{ 'color':'#005494'}}>{contentItem.description}</IonText>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent id={'productionUniverseCardContent'+idx} style={{'display':'none'}}>
                                            <IonSegment value={'productionCast'+idx} onIonChange={(e) => changeSegmentContent(e.detail.value as any, idx)}>
                                                <IonSegmentButton value={'productionCast'+idx}>
                                                    <IonLabel className="labelText">Cast</IonLabel>
                                                </IonSegmentButton>
                                                <IonSegmentButton value={'productionCta'+idx}>
                                                    <IonLabel className="labelText">Prompts</IonLabel>
                                                </IonSegmentButton>
                                                <IonSegmentButton value={'productionUgc'+idx}>
                                                    <IonLabel className="labelText">Content</IonLabel>
                                                </IonSegmentButton>
                                            </IonSegment>
                                            <div id={'productionCastFeed'+idx} className="ion-text-justify">
                                                <IonRow className="ion-text-justify">
                                                    <IonCol>
                                                        <IonText style={{ 'color':'#005494'}} className="bolder">
                                                            Appearing in this episode
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="6"><IonLabel className="brandText bolder">In-Character</IonLabel></IonCol>
                                                    <IonCol><IonLabel className="brandText bolder">Out-Of-Character</IonLabel></IonCol>
                                                </IonRow>
                                                <IonText style={{'color':'#005494'}}>
                                                        {contentItem.cast && contentItem.cast.map((castMember, castIdx) =>{
                                                            return(
                                                                 <UserDetails  key={castIdx+'production'} userPhotoURL={castMember?.photoURL} userId={castMember.castId} userHeroName={castMember.castName} userRole={castMember.castRole} userRealName={castMember.castRealName} showMoreInfo={true} tab={'production'} index={castIdx}/>
                                                            )
                                                        })}
                                                </IonText>
                                                <IonRow>
                                                    <IonNote style={{'color':'#005494', 'fontSize':'5px'}}>Characters are portrayed by and are copyrights © of their respective owners, used with permission under the non-exclusive license granted by the <IonRouterLink href="/pages/TermsPage"><span className={"italic bolder"}>Terms of Service</span></IonRouterLink>.</IonNote>
                                                </IonRow>
                                            </div>
                                            <div id={'productionCtaFeed'+idx} style={{'display':'none'}} className="ion-text-justify">
                                                <IonRow className="ion-text-justify">

                                                    <IonText style={{ 'color':'#005494'}} >
                                                        <span className="bolder">Contribute to this episode</span> by completing the CTA below
                                                    </IonText>

                                                </IonRow>
                                                <IonCard className="missionContainer" style={{margin: '10px 0px 10px 0px'}}>
                                                    <ProfileCallVideo tab={'productionCTA'}/>
                                                    <IonGrid>
                                                        <IonRow style={{'float':'right'}}>
                                                            <IonButton onClick={completeProfile} shape="round" fill="solid" style={{ '--background': '#66cc33', '--color':'#ffffff','height':'25px'}} className="btn-complete-mission">COMPLETE PROFILE</IonButton>
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCard>

                                                {contentItem.ctaList && contentItem.ctaList.map((ctaItem, ctaItemIdx) =>{
                                                    return(
                                                        <IonCard key={ctaItemIdx} style={{margin: '10px 0px 10px 0px'}}>
                                                            {/*{idx === 0 &&*/}
                                                            {/*<MissionDeliveryComponent*/}
                                                            {/*    mission={missionBackup}*/}
                                                            {/*    index={ctaItemIdx}*/}
                                                            {/*    showButtons={true}*/}
                                                            {/*    loggedInUser={loggedInUser}*/}
                                                            {/*    fromPage="watch"*/}
                                                            {/*    showCompletedSection={false}*/}
                                                            {/*/>}*/}
                                                            {idx === 0 &&
                                                             <PremiumDevelopmentChallengesItems/>}
                                                            {idx === 2 &&
                                                            <MissionDeliveryComponent
                                                                mission={missionWTH}
                                                                index={ctaItemIdx}
                                                                showButtons={true}
                                                                loggedInUser={loggedInUser}
                                                                fromPage="watch"
                                                                showCompletedSection={false}

                                                            />}
                                                            {idx === 1 &&
                                                            <MissionDeliveryComponent
                                                                mission={missionCWB}
                                                                index={ctaItemIdx}
                                                                showButtons={true}
                                                                loggedInUser={loggedInUser}
                                                                fromPage="watch"
                                                                showCompletedSection={false}
                                                            />}

                                                        </IonCard>
                                                    )
                                                })}

                                            </div>
                                            <div id={'productionUgcFeed'+idx} style={{'display':'none'}} className="ion-text-justify">
                                                <IonRow className="ion-text-justify">
                                                    <IonCol>
                                                        <IonText style={{ 'color':'#005494'}} >
                                                            <span className="bolder">Content selected </span> for this episode so far
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                        {contentItem.content && contentItem.content.map((userContentDetails, userContentIdx) =>{
                                                            return(
                                                                <IonCard  className="child4" key={userContentIdx}>
                                                                    <IonThumbnail style={{'height':'80%', 'width':'100%'}}>


                                                                        <IonImg id={'prodContent'+idx+userContentDetails.castId} src={userContentDetails.castPhotoURL ? downloadPictureContent(userContentDetails.castPhotoURL,userContentDetails.castId, 'prodContent'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                                                                                onClick={() => viewProfileAssociation(userContentDetails.castId)}/>

                                                                    </IonThumbnail>
                                                                    <IonText className="brandText bolder" style={{'fontSize':'7px'}}>
                                                                        {userContentDetails.castName}
                                                                    </IonText>
                                                                </IonCard>

                                                            )
                                                        })}
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </IonGrid>
                            </IonCard>

                        </IonSlide>
                    )
                })}
            </IonSlides>
        );
    };
}
export default authWrapper(ProductionContentFeed);

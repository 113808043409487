export async function notification(message: string, duration = 2000) {
    const toast = document.createElement('ion-toast')
    toast.message = message.length > 30 ? message.slice(0,30) + '...' : message
    toast.duration = duration
    toast.position = 'top'

    document.body.appendChild(toast)
    return toast.present()
}

export async function message(message: string, duration = 5000) {
    const toast = document.createElement('ion-toast')
    toast.message = message
    toast.duration = duration
    toast.position = 'top'

    document.body.appendChild(toast)
    return toast.present()
}

export async function uploadInProgressNotification() {
    const toast = document.createElement('ion-toast')
    toast.message = 'Stay on this page. You will be automatically redirected once your upload is complete.'
    toast.duration = 5000
    toast.position = 'bottom'

    document.body.appendChild(toast)
    return toast.present()
}
export async function showNotification() {
    const toast = document.createElement('ion-toast')
    toast.message = 'Stay on this page. You will be automatically redirected once your upload is complete.'
    toast.position = 'bottom'

    document.body.appendChild(toast)
    return toast.present()
}


import React, {useEffect, useState } from 'react';
import { IonCard,  IonRow, IonCol, IonButtons, IonButton,  IonIcon, IonLabel, IonText } from '@ionic/react';

import {people, playOutline} from 'ionicons/icons';
import { useHistory} from "react-router-dom";
import {Challenge} from "../../models/Challenge";
import VideoList from "../../components/VideoList";
import { firestore } from '../../firebaseConfig';
import { toast } from '../../toast';
import {message, notification } from '../../util/notification';
import VideoListNoControls from '../../components/VideoListNoControls';
import { Device } from '@capacitor/core';
import { openFullScreen } from '../../util/videoUtils';

interface PremiumChallengeListItemProps {
    challenge: Challenge;
    index: number;
    showButtons: boolean;
    user: any;
    // userId: string;
    // heroName: string;
    // heroId: string;
    // userPhotoURL: string;
}



const PremiumChallengeListItem: React.FC<PremiumChallengeListItemProps> = ({challenge, index, showButtons, user}) => {

    const [showPeopleList, setShowPeopleList] = useState(false);
    const [actionName, setActionName] = useState('accept');
    const [divisionFactor, setDivisionFactor] = useState(1);
    const [platformInfoName, setPlatformInfoName] = useState('web');

    const history = useHistory();

    useEffect(() => {
        let isSubscribed = true;
        Device.getInfo().then(function(info) {
            console.log("platform", info.platform);
            setPlatformInfoName(info.operatingSystem);
        } );

        let query = firestore.collection('content').where('objectRefId', '==', challenge.uid).where('completedByUserId', '==', user.uid);
        query.get()
            .then(function(snapshotContent)  {
                let isCompleted = false;
                console.log(snapshotContent.size);
                if (snapshotContent.size > 0) {
                    isCompleted = true;
                }
                let actionName = 'accept';
                if (challenge.episodic && isCompleted) {
                    actionName = 'add more';
                }
                else if (!challenge.episodic && isCompleted) {
                    actionName = 'completed';
                }
                setActionName(actionName);
            }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
        if (challenge.type === 'training') {
            setDivisionFactor(18);
        }
        if (challenge.type === 'development') {
            setDivisionFactor(13);
        }
        return () => {isSubscribed = false}
    }, [challenge]);

    function viewContent(containerId, videoContainerId) {
        //console.log("view id ", containerId + "video id", videoContainerId);
        let journeyEl=document.getElementById(containerId+videoContainerId);
        journeyEl.style.display = 'block';
        //console.log("Video container ID", videoContainerId);
        //let videoContainer= document.getElementById(videoContainerId);
        let videoEl = journeyEl as HTMLVideoElement;
        // console.log("Video el id:",videoEl);

        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));

        // let playBtn = document.getElementById('play'+item.code);
        // let stopBtn = document.getElementById('stop'+item.code);

        if (platformInfoName ==='android') {
            videoEl.onfullscreenchange = (e=> {
                if (!document.fullscreenElement)
                    videoEl.pause();
            })
        }

        if (videoEl.currentTime > 0 && !videoEl.ended && !videoEl.paused) {
            //hide play. show stop
            // playBtn.style.display='block';
            // stopBtn.style.display='none';

            videoEl.pause();
        }
        else if (videoEl.paused || videoEl.ended) {
            // videoEl.webkitEnterFullScreen();
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (platformInfoName ==='android') {
                        openFullScreen(videoEl);
                    }
                    console.log("resume video");
                    console.log("video play time", videoEl.currentTime);
                }
            ).catch(() => console.log("error playing video"));
        }
        else {
            videoEl.play().then(
                () => {
                    // playBtn.style.display='none';
                    // stopBtn.style.display='block';
                    if (platformInfoName ==='android') {
                        openFullScreen(videoEl);
                    }
                }
            ).catch(() => console.log("error playing video"));
        }
    }



    let acceptChallenge = (challenge) => {
        if (user.role === 'client') {
            message('Only Heroes or Villains can take on Challenges');
        }
        else {
            history.push({
                pathname: '/challengeListItemDetail',
                state: {challengeId:challenge.uid, challengeTitle:challenge.title, challengeType: challenge.type, heroId: user.uid, heroName: user.characterName, userId: user.uid, userPhotoURL: user.photoURL, fromPage: 'trainingChallenges'},
            });
        }
    }


    return (
        <IonCard key={index}>
            <div id={challenge.index}>
                <VideoListNoControls item={challenge}/>
            </div>

            <IonRow style={{'paddingTop':'10px', 'paddingLeft':'10px'}}>
                <IonCol size="7">
                    <IonText class="contentTextTitle" color="primary"> {challenge.title} </IonText>
                </IonCol>

                <IonCol size="2">
                    <IonButtons key={index}>
                        <IonButton shape="round"  size="default" className={(actionName ==='accept')? 'acceptBtn bolder': 'completeBtn'}  onClick={() => acceptChallenge(challenge)}> {actionName.toUpperCase()} </IonButton>

                        <IonButton  className="play-button-div" onClick={() => viewContent(challenge.uid, challenge.type)} style={{'color':'#005494'}}>
                            <IonIcon className="play-button" icon={playOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonCol>
            </IonRow>
            <IonRow style={{'paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonCol size="4">
                    <IonLabel class="contentSubtitle" color="primary">

                        ({index+1}/{divisionFactor})

                    </IonLabel>
                </IonCol>
            </IonRow>

            <IonRow style={{'paddingTop':'0px','paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonText className="contentText ion-text-justify" color="primary">
                    COMIC CON SPECIAL EVENT: Make an <span style={{fontStyle:'italic'}}>ACTION SCENE</span> and you can get featured alongside martial arts movie icons on the 24th annual Superhero Kung Fu Extravaganza. <br/><span style={{fontStyle:'italic', fontWeight:'bold'}}> This event itself has already passed, but you can still take on the Challenge.</span>             </IonText>
            </IonRow>

        </IonCard>
    );
};

export default PremiumChallengeListItem;


import React from 'react';
import {
    IonContent,
    IonText,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonPage,
    IonGrid,
    IonList,
    IonButtons,
    IonButton,
    IonIcon,
    IonInput,
    IonToggle
} from '@ionic/react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {  withRouter} from 'react-router';

import { firestore } from '../../firebaseConfig';

import authWrapper from '../../components/authWrapper';
import {sendOutline} from "ionicons/icons";
import {toast} from "../../toast";
import firebase from "firebase";
import UserAvatar from "../../components/UserAvatar";
import Moment from "react-moment";
import ChatDeliveryModule from "../../components/ChatDeliveryModule";

import { sendDMNotification } from '../../util/email';

class ConversationThread extends React.Component {

    state: any;
    chatListEnd: any;
    answerInput: any;
    answerEnd: any;
    timer: any;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            messages: [],
            messageList: [],
            checked: true,
            answerContent: '',
            user: props.location.state.user,
            sender: props.location.state.sender,
            receiver: props.location.state.receiver,
            fromPage: props.location.state.fromPage,
            conversationId: props.location.state.conversationId,
            conversationSender: null,
            conversationReceiverId: null,
            history: props.history,
            location: props.location,
            el: '',
            newMessages: false,
            selectedTab: 'dm',
        };

    }

    scrollToBottom = () => {
        this.chatListEnd.scrollIntoView({ behavior: "smooth" });
        this.answerEnd.scrollIntoView({ behavior: "smooth" });
        this.answerInput.focus();
    }

    componentDidMount() {


        this.timer = setInterval(() => {
            const messageRef = firestore.collection('conversation').doc(this.state.conversationId);

            messageRef.get().then((doc) => {
                let messages = [];

                const messageListRef = {id: doc.id, ...doc.data()};
                // console.log("messages:", messageListRef['messages']);
                messages = messageListRef['messages'];

                this.setState({
                    messages,
                    messageList: messages,
                    loading: false,
                    conversationSender: doc.data()['senderId'],
                    conversationReceiver: doc.data()['receiverId'],
                    //answerContent: '',
                });
            });
        }, 1000);

        setTimeout(this.scrollToBottom, 2000);

    }

    // componentDidUpdate() {
    //     setTimeout(this.scrollToBottom, 1000);
    // }


    componentWillUnmount() {
        clearInterval(this.timer);
    }

    sendReply = (answerContent,checked,messages,sender, receiver, user, conversationReceiver) => {
         console.log("sender", sender);
         console.log("receiver", receiver);
         console.log("user", user);
         console.log("conversation receiver", conversationReceiver);

        if (answerContent === '') {
            return toast('Enter a message before sending');
        }

        let messageType = 'outgoing';
        if (conversationReceiver === user.uid) {
            messageType = 'incoming';
        }


        let messageDetails = {
            answerContent,
            checked,
            messageType:messageType,
            messageFrom: user.uid,
            messageTo: conversationReceiver,
            sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
        };


        let convoRef = firestore.collection('conversation').doc(this.state.conversationId);

       // let messagesList = [];
       // set(messageList);
        let convoOps;
        if (messages === undefined) {
            console.log("Created");

            messages = [messageDetails];
            console.log("Messages", messages);
            convoOps = convoRef.set({
                lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                messages: messages
            }, {merge: true});
        }
        else {
            // console.log("update");
            console.log("Messages", messages);
            if (messages[0] === '') {
                messages[0]= messageDetails;
            }

            else {
                messages.push(messageDetails);
            }
            convoOps = convoRef.update({
                lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
                messages: firebase.firestore.FieldValue.arrayUnion(messageDetails)
            });

        }


        let receiverRefId = receiver.uid !== undefined ? receiver.uid : receiver.id;

        if (messageType === 'incoming') {
            receiverRefId = sender.uid  !== undefined ? sender.uid : sender.id;
        }

        console.log("receiver", receiver);
        let receiverRef = firestore.collection('users').doc(receiverRefId);

        let receiverDMNotificationOps = receiverRef.set({
            unread: true
        }, {merge: true});

        receiverDMNotificationOps.then(() => {
            console.log("notify user created", receiverRefId);
            sendDMNotification(receiverRefId);
        });


        convoOps.then(()=> {
            console.log('Sent Message');


            this.setState({
                messages,
                messageList: messages,
                loading: false,
                answerContent: '',
                answerInput: '',
                newMessages: true,
            });

            setTimeout(this.scrollToBottom, 2000);

        });

        this.answerInput ='';

    }

    toggleAnswerType = () => {
        this.setState({
            checked: !this.state.checked
        })

    }

    setAnswerContent = (event: any) => {
        this.setState({ answerContent: event.target.value });
    };

    render() {
        const { fromPage, sender, receiver,user,messages,messageList,conversationId, checked, answerContent, newMessages, selectedTab, conversationSender, conversationReceiver} = this.state as any;

        return (

            <IonPage>
                <Header fromPage={fromPage} selectedTab={selectedTab} noChat={true}/>

                <IonContent className="ion-padding" scrollX>
                    {sender?.uid === user?.uid ?
                        <UserAvatar userPhotoURL={receiver?.photoURL} userId={receiver?.uid} userHeroName={receiver?.heroName} userRole={receiver?.role} showMoreInfo={true}/>
                        :<UserAvatar userPhotoURL={sender?.photoURL} userId={sender?.uid} userHeroName={sender?.heroName} userRole={sender?.role} showMoreInfo={true}/>
                    }

                    <IonList lines="none" style={{background:'none'}}>
                        {/*{messages && messages !== undefined && messages.length === 0 &&*/}
                        {/*<IonItemDivider>Welcome To JFH CAST CHAT DM</IonItemDivider>}*/}
                        {messageList && messageList.length > 0 && messageList[0]!== '' &&  messageList?.map((chatItem, chatIdx) => {
                            return (

                                <IonItem key={chatIdx} lines="none" style={(chatItem.messageFrom && chatItem.messageFrom === user?.uid)  ?
                                    {paddingLeft:'20%'}:{paddingRight:'20%'}}>
                                    {/*// (conversationSender === user.uid ?*/}
                                    {/*//     {paddingLeft:'20%'}:{paddingRight:'20%'}*/}
                                    {/*// ):*/}
                                    {/*// (conversationSender === user.uid ?*/}
                                    {/*// {paddingRight:'20%'}:{paddingLeft:'20%'})}>*/}
                                {/*// <IonItem key={chatIdx} lines="none" style={conversationSender === user.uid*/}
                                {/*//     ? (chatItem.messageType === 'incoming' ? {paddingLeft: '20%'} : {paddingRight: '20%'})*/}
                                {/*//     : (chatItem.messageType === 'outgoing' ? {paddingLeft: '20%'} : {paddingRight: '20%'})*/}
                                {/*//  }>*/}
                                    <IonGrid>
                                        {/*<IonRow>*/}
                                        {/*    {chatItem.messageFrom} {user.uid}*/}
                                        {/*</IonRow>*/}
                                        {/*{conversationSender} {user.uid} {receiver.uid}*/}
                                        {/*{chatItem.messageType} {JSON.stringify(conversationSender === user.uid)}*/}
                                        {/*<IonRow className={sender.uid === user.uid ? (chatItem.messageType === 'outgoing' ? 'mine':'yours') : (chatItem.messageType === 'incoming' ? 'yours': 'mine')}>*/}
                                        <IonRow style={
                                            (chatItem.messageFrom && chatItem.messageFrom === user?.uid) ? {background: '#bad5d2',borderRadius: '10px'} :
                                                {}}
                                                >
                                            <IonCol>
                                                <IonRow>
                                                    {chatItem.contentId !== undefined
                                                    && <ChatDeliveryModule deliveryModuleId={chatItem.contentId} showButtons={true} loggedInUser={user} tab={'dm'} messageId={conversationId+chatIdx} sentObjectType={chatItem.sentObjectType}/>
                                                    }
                                                </IonRow>
                                                <IonRow>
                                                    {chatItem.checked ?
                                                        <IonText style={{'color':'#005494', whiteSpace: 'pre-line'}}>
                                                            {chatItem.answerContent.replaceAll('<br/>', '\n')}
                                                        </IonText>
                                                        :<IonText style={{whiteSpace: 'pre-line'}}>
                                                            {chatItem.answerContent.replaceAll('<br/>', '\n')}
                                                        </IonText>
                                                    }
                                                </IonRow>
                                                <IonRow style={{'fontSize':'8px'}}>

                                                    <Moment format='MMMM Do YYYY, h:mm a' style={{fontSize:'8px', opacity: '.5'}}>{chatItem.sendTime.toDate()}</Moment>

                                                </IonRow>
                                            </IonCol>
                                        </IonRow>

                                    </IonGrid>
                                </IonItem>
                            );
                        })}
                    </IonList>
                    <div ref={(el) => { this.chatListEnd = el; }}>

                    </div>
                    {/*{newMessages && <IonIcon icon={arrowUp} style={{color:'#005494'}}></IonIcon>}*/}

                </IonContent>
                <IonItem slot="fixed" lines="none"
                         className="messageBox"
                         ref={(el) => { this.answerEnd = el; }}
                         >
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                             >
                            <IonButtons slot="start">
                                {user && <UserAvatar userPhotoURL={user.photoURL} userId={user.uid} userHeroName={user.heroName} userRole={user.role} showMoreInfo={false}/>}
                            </IonButtons>

                            <IonInput
                                value={answerContent}
                                onIonChange={this.setAnswerContent}
                                autocapitalize="on"
                                clearInput
                                type="text"
                                placeholder={"Write Message here ... "}
                                className="input ion-padding-horizontal"
                                style={{'fontSize':'12px'}}
                                ref={(el) => { this.answerInput = el; }}
                            ></IonInput>

                            <IonButtons slot="end">
                                <IonCol>
                                    <IonRow>
                                        <IonLabel style={{'fontSize':'7px', minWidth: 64}}>{checked ? `In-Character`: 'Out-Of-Character'}</IonLabel>
                                    </IonRow>
                                    <IonRow>
                                        <IonToggle checked={checked} onIonChange={this.toggleAnswerType} />

                                    </IonRow>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={() => this.sendReply(answerContent,checked,messages, sender,receiver,user, conversationReceiver)}>
                                        <IonIcon icon={sendOutline}/>
                                    </IonButton>
                                </IonCol>
                            </IonButtons>
                        </div>


                </IonItem>
                <Footer />
            </IonPage>
        );
    }


}

export default authWrapper(withRouter(ConversationThread));

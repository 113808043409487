import React from 'react';
import {   IonText,IonRow, IonCol,  IonPage, IonContent, IonThumbnail, IonImg, IonButton, IonIcon, IonGrid, IonHeader, IonToolbar, IonFooter} from '@ionic/react';
import {  chevronForwardOutline } from 'ionicons/icons';
import Header from "../../components/Header";
import {useHistory} from "react-router";

const PreRegistration1: React.FC = () => {
    const history = useHistory();

    function next() {
        history.push({pathname: '/chooseRole'});
      }

  return (
    <IonPage style={{backgroundColor: '#005494'}}>
        <Header noChat={true} fromPage="login"/>

        <IonContent style={{
            // backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FHowTheAppWorks.png?alt=media)',
            // backgroundSize: 'cover',
            // backgroundPosition: 'center',
            // display: 'flex',
            // flex: 1,
            // flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "space-around",
            '--background': '#005494'

        }} scrollX onClick={() => next()}>
        {/*<div style={{paddingBottom: 30, display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>*/}

              {/*<IonText className="appLabelText"  style={{margin: 10, textAlign: 'center'}}>*/}
              {/*  <span className="italic">Justice For Hire</span> is*/}
              {/*</IonText>*/}


              {/*<IonText className="appLabelText" style={{margin: 10, textAlign: 'center'}}>*/}
              {/*YOUR CINEMATIC UNIVERSE.*/}
              {/*</IonText>*/}

              {/*<IonText className="appLabelText ion-text-align-left" style={{margin:40, textAlign: 'center'}}>*/}
              {/* Hire a hero or become one and get paid.*/}
              {/*</IonText>*/}

              <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FHowTheAppWorks.png?alt=media"} onClick={()=> next()}/>
              
        </IonContent>
        <IonFooter style={{textAlign:'center'}}>
            <IonButton className="appBtn footerBtn" onClick={()=> next()}>
                <IonIcon style={{'height':'50px', 'width':'50px'}} icon={chevronForwardOutline}/>
            </IonButton>
        </IonFooter>
    </IonPage>
  );
};


export default PreRegistration1;



import React, { Component } from 'react';
// import { Plugins, Capacitor } from '@capacitor/core';
import * as CapacitorVPPlugin from 'capacitor-video-player';
import { IonContent, IonButton, IonPage, IonHeader, IonRow, IonIcon, IonList, IonToolbar, IonTitle, IonButtons, IonMenuButton } from '@ionic/react';
import './VideoStyles.css';
import { playOutline } from 'ionicons/icons';
// const { CapacitorVideoPlayer, Device } = Plugins;


export const FullImages = [
    { title: 'P E R S O N A L T R A I N E R', image: 'https://firebasestorage.googleapis.com/v0/b/findmypt-a8d29.appspot.com/o/brand%2FPersonalTrainer.jpg?alt=media' },
    { title: 'P H Y S I C A L T H E R A P Y', image: 'https://firebasestorage.googleapis.com/v0/b/findmypt-a8d29.appspot.com/o/brand%2FPhysicalTherapy.JPG?alt=media' },
    { title: 'K I D S   A C T I V I T I E S', image: 'https://firebasestorage.googleapis.com/v0/b/findmypt-a8d29.appspot.com/o/brand%2FKidsActivities.jpg?alt=media' },
    { title: 'P I L A T E S', image: 'https://firebasestorage.googleapis.com/v0/b/findmypt-a8d29.appspot.com/o/brand%2FPilates.jpg?alt=media' },
    { title: 'Y O G A ', image: 'https://firebasestorage.googleapis.com/v0/b/findmypt-a8d29.appspot.com/o/brand%2FYoga.jpg?alt=media' },
    { title: 'M A S S A G E   S P A', image: 'https://firebasestorage.googleapis.com/v0/b/findmypt-a8d29.appspot.com/o/brand%2FMassage_%20Spa.jpg?alt=media' },
]

class VideoContainer extends Component {
    constructor(props: any) {
        super(props);
        this.state = {};
    }
     init = async (fullscreen: boolean) => {
    //     let videoPlayer: any;
    //     const info = await Device.getInfo();
    //     if (info.platform === "ios" || info.platform === "android") {
    //         videoPlayer = CapacitorVideoPlayer;
    //     } else {
    //         videoPlayer = CapacitorVPPlugin.CapacitorVideoPlayer;
    //     }
    //     if (fullscreen) {
    //         await videoPlayer.initPlayer({ mode: "fullscreen", url: "https://www.youtube.com/watch?v=KIroJYVOlRs" });
    //     }
    //     else {
    //         await videoPlayer.initPlayer({ mode: "embedded", url: "https://www.youtube.com/watch?v=KIroJYVOlRs", playerId: "embedded", width: 300, height: 250 });
    //     }
     }

    render() {
        const GridImages = FullImages.map((data, index) => (
            <IonRow key={index}>
                <div className="imagediv" style={{
                    backgroundImage: `linear-gradient(0deg,rgba(88, 87, 88, 0.3),rgba(94, 93, 94, 0.3)),url('${data.image}')`
                }}>
                    <div className="play-button-div" onClick={() => this.init(true)}>
                        <IonIcon slot="start" className="play-button" icon={playOutline} color='light'></IonIcon>
                    </div>
                </div>
            </IonRow>
        ))
        return (
            <>
                <IonPage id="main">
                    <IonHeader>
                        <IonToolbar color='dark'>
                            <IonButtons slot="start">
                                <IonButton>
                                    <IonMenuButton color='light'></IonMenuButton>
                                </IonButton>
                            </IonButtons>
                            <IonButtons slot='end'></IonButtons>

                            <IonTitle slot="start" className="ion-text-center"> VideoPlayer</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonList className="ion-no-padding">
                            {/*{GridImages}*/}
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/KIroJYVOlRs"
                                    title="JFH APP" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </IonList>
                    </IonContent >
                </IonPage >
            </>
        );
    }
}

export default VideoContainer;
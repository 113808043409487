import React from 'react';
import {IonContent, IonPage} from '@ionic/react';
import Header from "../components/Header";
import {Footer} from "../components/Footer";
import authWrapper from "../components/authWrapper";

interface JoinTestScreen {

    user: any;

}

const JoinTestTeam: React.FC<JoinTestScreen> = (props) => {


    return (
        <IonPage className="app-page">
            <Header />
            <IonContent className="app home ion-padding ion-justify-content-center">

                <iframe src="https://us02web.zoom.us/meeting/register/tZ0kdOGhpz4vGtUO4ttOt6hSy5zyk_tlaj_t
                    " style={{width: '100%', height: '100%'}}></iframe>

            </IonContent>

            <Footer user={props.user}/>
        </IonPage>
    );
};


export default authWrapper(JoinTestTeam);

import React  from 'react';
import './ExploreContainer.css';

import { IonCard } from '@ionic/react';

import VideoListItem from './VideoListItem';
import { ContentItem } from '../models/ContentItem';
import DeliveryModuleBtnContainer from '../pages/Wurld/DeliveryModuleBtnContainer';


interface DeliveryModuleProps {
  deliveryModule: ContentItem,
  showButtons: boolean,
  loggedInUser: any,
  tab: string,
}


const DeliveryModuleScreen: React.FC<DeliveryModuleProps> = ({ deliveryModule, showButtons, loggedInUser, tab }) => {

  return (
     <IonCard className={deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'}>  
         <VideoListItem contentItem={deliveryModule} tab={tab} enableTogglePrivate={deliveryModule.completedByUserId === loggedInUser.uid}/>
         <DeliveryModuleBtnContainer deliveryModule={deliveryModule} loggedInUser={loggedInUser} tab={tab}/>

    </IonCard>
  );
};

export default DeliveryModuleScreen;


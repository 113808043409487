
import React, {useEffect, useState } from 'react';
import { IonCard,  IonRow, IonCol, IonButtons, IonButton,  IonIcon, IonLabel, IonText } from '@ionic/react';

import {people, playOutline} from 'ionicons/icons';
import { useHistory} from "react-router-dom";
import {Challenge} from "../../models/Challenge";
import VideoList from "../../components/VideoList";
import { firestore } from '../../firebaseConfig';
import { toast } from '../../toast';
import { message } from '../../util/notification';
import {viewCharacterProfileDetails} from "../../util/avatarUtils";

interface ChallengeListItemProps {
    challenge: Challenge;
    index: number;
    showButtons: boolean;
    user: any;
}



const ChallengeListItem: React.FC<ChallengeListItemProps> = ({challenge, index, showButtons, user}) => {

    const [showPeopleList, setShowPeopleList] = useState(false);
    const [actionName, setActionName] = useState('accept');
    const [divisionFactor, setDivisionFactor] = useState(15);
    const history = useHistory();

    useEffect(() => {
        let isSubscribed = true;
        // console.log("challenge id", challenge.uid);
         console.log("completed by hero id" +  user + "user id" + user);

        let query = firestore.collection('content').where('objectRefId', '==', challenge.uid).where('completedByUserId', '==', user.uid);
        query.get()
            .then(function(snapshotContent)  {
              let isCompleted = false;
                // console.log("how manu times " + snapshotContent.size);
                if (snapshotContent.size > 0) {
                    isCompleted = true;
                }
                let actionName = 'accept';
               if (challenge.episodic && isCompleted) {
                   actionName = 'add more';
               }
               else if (!challenge.episodic && isCompleted) {
                   actionName = 'completed';
               }
                setActionName(actionName);
            }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
        if (challenge.type === 'training') {
            setDivisionFactor(18);
        }
        if (challenge.type === 'development') {
            setDivisionFactor(14);
        }
        return () => {isSubscribed = false}
    }, [challenge]);

    function viewContent(containerId, videoContainerId) {
        //console.log("view id ", containerId + "video id", videoContainerId);
        let journeyEl=document.getElementById(containerId);
        journeyEl.style.display = 'block';
        //console.log("Video container ID", videoContainerId);
        let videoContainer= document.getElementById(videoContainerId);
        let videoEl = videoContainer as HTMLVideoElement;
        // console.log("Video el id:",videoEl);
        videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    }

     let acceptChallenge = (challenge) => {
        if (user.role === 'client') {
            message('Only Heroes or Villains can take on Challenges');
        }
        else {
            history.push({
                pathname: '/challengeListItemDetail',
                state: {
                    challengeId: challenge.uid,
                    challengeTitle: challenge.title,
                    challengeType: challenge.type,
                    heroId: user.uid,
                    heroName: user.characterName,
                    userId: user.uid,
                    userPhotoURL: user.photoURL,
                    fromPage: 'challenges'
                },
            });
        }
    }

    // let isCompleted = async (challenge) => {
    //     console.log("challenge episodic", challenge.episodic);
    //     //find content
    //
    //     let query = firestore.collection('content').where('objectRefId', '==', challenge.uid).where('completedBy', '==', heroId);
    //
    //     let challengeCompleted = await query.get().then(function (contentSnapshot) {
    //         // console.log('challengeCompleted', contentSnapshot.size);
    //         return contentSnapshot.size;
    //     });
    //     return challengeCompleted;
    // }
    //
    // let nextAction = async (challenge) => {
    //     let isChallengeCompleted =  await isCompleted(challenge);
    //
    //     console.log('challengeCompleted', isChallengeCompleted);
    //
    //     if (challenge.episodic) {
    //         return 'add more'
    //     }
    //     else {
    //         return 'completed';
    //     }
    //     return 'accept';
    // }

    return (
        <IonCard key={index}>
            <div id={challenge.index} style={{'display':'none'}}>
                <VideoList containerName="challenges" uid={challenge.uid} locationAttribute={challenge.ctaVideoUrl} posterUrl={challenge.posterUrl}/>
            </div>
            {/*<IonRow>*/}

            {/*    <IonButtons key={index}>*/}
            {/*        {nextAction(challenge) === 'completed'*/}
            {/*        && <IonLabel style={{'background':'#005494', 'color':'#ffffff'}}> COMPLETED </IonLabel>*/}
            {/*        }*/}
            {/*        {*/}
            {/*            (nextAction(challenge) !== 'completed')*/}
            {/*            &&  <IonButton shape="round" fill="solid" size="large" style={{'--background':'yellow', '--color':'#005494'}} className="bolder" onClick={() => acceptChallenge(challenge)}>  {nextAction(challenge).toUpperCase()} </IonButton>*/}
            {/*        }*/}
            {/*        {*/}
            {/*            nextAction(challenge) !== 'completed' &&*/}
            {/*            <IonButton  className="play-button-div" onClick={() => viewContent(challenge.index, challenge.uid)} style={{'color':'#005494'}}>*/}
            {/*                <IonIcon slot="start" className="play-button" icon={playOutline}></IonIcon>*/}
            {/*            </IonButton>}*/}
            {/*    </IonButtons>*/}


            {/*</IonRow>*/}
            <IonRow style={{'paddingTop':'10px', 'paddingLeft':'10px'}}>
                <IonCol size="7">
                    <IonText class="contentTextTitle" color="primary"> {challenge.title} </IonText>
                </IonCol>

                <IonCol size="2">
                    <IonButtons key={index}>
                         <IonButton shape="round"  size="default" className={(actionName ==='accept')? 'acceptBtn bolder': 'completeBtn'}  onClick={() => acceptChallenge(challenge)}> {actionName.toUpperCase()} </IonButton>

                        <IonButton  className="play-button-div" onClick={() => viewContent(challenge.index, challenge.uid)} style={{'color':'#005494'}}>
                            <IonIcon className="play-button" icon={playOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonCol>
            </IonRow>
            <IonRow style={{'paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonCol size="6">
                    <>
                        {challenge.type !== 'journey'
                            ? (challenge.ugc ?
                                <IonLabel color="primary">
                                    <IonText className="creditLabel">Created by </IonText><IonText className="creditLabelName" onClick={() => viewCharacterProfileDetails(challenge.createdById)}>{challenge.createdByName}</IonText></IonLabel>
                              :<IonLabel class="contentSubtitle" color="primary">
                                    {index+2}/{divisionFactor}
                            </IonLabel>)
                            : <IonLabel class="contentSubtitle" color="primary">{index+1}/{divisionFactor}</IonLabel>
                        }
                     </>


                </IonCol>
            </IonRow>

            <IonRow style={{'paddingTop':'0px','paddingLeft':'12px','paddingBottom':'10px'}}>
                <IonText className="contentText ion-text-justify" color="primary">{challenge.description} </IonText>
            </IonRow>

        </IonCard>
    );
};

export default ChallengeListItem;


import React, {useEffect, useState } from 'react';
import {IonContent, IonSearchbar} from '@ionic/react';

import { firestore } from '../../firebaseConfig';
import WurldDeliveryModuleScreen from '../../components/WurldDeliveryModuleScreen';
import {search} from "ionicons/icons";


interface WurldMissionsListProps {
    loading: boolean,
    user: any,
    showButtonShare: boolean,
}


const WurldMissionsList: React.FC<WurldMissionsListProps> = (props) => {

    const [missionList, setMissionList] = useState([]);
    const [searchWurldMission, setSearchWurldMission] = useState('');

    useEffect(() => {

        let queryMissionsFeed = searchWurldMission.toLowerCase()
       // let textQuery = queryMissionsFeed.slice(0,1).toUpperCase() + queryMissionsFeed.slice(1, queryMissionsFeed.length);


        let stringBoundMission = queryMissionsFeed.slice(0, queryMissionsFeed.length-1)
            + String.fromCharCode(search.slice(-1).charCodeAt(0) + 1)
        console.log("search missions", queryMissionsFeed);
        console.log("search missions string bound", stringBoundMission);

        if (queryMissionsFeed!='' && queryMissionsFeed.length >=1) {
            // console.log("query", query);
            const searchWurldMissionRef = firestore.collection('content').where('publicContent','==',true).where('contentType', '==', 'mission').where('liveContent', '==', true)
                .where('completedByHeroName','>=', queryMissionsFeed)
                // .where('completedByHeroName','<', stringBoundMission)
                // .where('completedByHeroName','!=', '')
                .orderBy('completedByHeroName', 'asc')
                .orderBy('timeCreated', 'desc')

            searchWurldMissionRef.onSnapshot(snapshot => {
                setMissionList(snapshot.docs.map(missionDoc => ({...missionDoc.data(), uid: missionDoc.id })));

            });

        }
        else {

            let missionsRef =  firestore.collection('content').where('publicContent','==',true).where('contentType', '==', 'mission').where('liveContent', '==', true).orderBy("timeCreated", "desc")
                .onSnapshot(snapshot => {

                    let missionsList = [];

                    snapshot.forEach(castDoc =>
                        missionsList.push({...castDoc.data(), uid: castDoc.id}),
                    );

                    setMissionList(missionsList);
                });


        }

    }, [searchWurldMission]);

    return (
        <>
            {/*<IonSearchbar value={searchWurldMission} autoCapitalize="on" onIonChange={e => setSearchWurldMission(e.detail.value!)} placeholder="Search by character name ... " debounce={1000}></IonSearchbar>*/}

            {missionList.map((missionItem, idxM) => {
                return (
                    <WurldDeliveryModuleScreen key={idxM} deliveryModule={missionItem} showButtons={true} showPopup={props.showButtonShare} loggedInUser={props.user} tab="mission" />
                )
            })}
        </>
    );


}
export default WurldMissionsList;
